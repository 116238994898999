import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chipHolder: {
            display: "flex",
            fontSize: theme.typography.pxToRem(14),
            gap: theme.spacing(0.5),
        },
        select: {
            width: "100%",
        }
    })
);

export default useStyles;
