/**
 * Return not of two arrays of numbers
 * @param a
 * @param b
 */
export function not(a: number[], b: number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

/**
 * Return intersection of two arrays of numbers
 * @param a
 * @param b
 */
export function intersection(a: number[], b: number[]): number[] {
    return a.filter((value) => b.indexOf(value) !== -1);
}

/**
 * Check whether two arrays has same items
 * @param arr1
 * @param arr2
 */
export const equalMembers = (arr1, arr2) => {
    let difference = arr1
        .filter(x => !arr2.includes(x as string))
        .concat(arr2.filter(x => !arr1.includes(x)));

    return !difference?.length;
}