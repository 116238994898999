import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultMappingPanelSize = 40;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        page: {
            height: "100%",
            "&>.ContentWrapper": {
                height: "100%",
            }
        },
    })
);

export default useStyles;
