import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { ListSubheader, MenuItem } from "@material-ui/core";
import "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
    CreateIssueRemoteMutationVariables,
    useCreateIssueRemoteMutation,
    useGetFormConfigQuery,
} from "../../../graphql/generated/graphql";
import { saveMarkup } from "../../../packages/Api/data/markups/client";
import { IPageParams } from "../../../pages/type";
import { onCreateChange, onCreateDone, useIssueCreate, useIssueViewer } from "../../../redux/issue";
import { IIssueCreateState, IIssueViewer } from "../../../redux/issue/reducer";
import { onMarkupsSetData, onMarkupsSetExternalHandling, useMarkupsData } from "../../../redux/viewer";
import globalMessages from "../../App/messages";
import { ExternalSystem } from "../../Viewer/type";
import CreateIssue from "../CreateIssue/CreateIssue";
import { buildMarkupID } from "../helpers";
import useIssuesStyles from "../IssuesWrapper/styles";
import { IIssueTab } from "../type";
import { IMarkupsData } from "../../../redux/viewer/reducer";

interface ICreateIssueWrapperProps {
    handleChangeTab: (tab: IIssueTab) => void;
}

const CreateIssueWrapper: React.FC<ICreateIssueWrapperProps> = ({ handleChangeTab }) => {
    const { accountUrn, projectUrn, urn: fileUrn } = useParams<IPageParams>();
    const classes = useIssuesStyles();
    const dispatch = useDispatch<DispatchAction>();
    const issueCreate = useIssueCreate();
    const issueViewer: IIssueViewer = useIssueViewer();
    const markupsData: IMarkupsData = useMarkupsData();

    const [createIssueMutation] = useCreateIssueRemoteMutation({
        refetchQueries: ["GetIssuesRemoteLazyQuery, GetIssuesRemoteQuery"],
    });
    const { data: formConfigData, loading: formConfigLoading, error: formConfigError } = useGetFormConfigQuery({
        variables: {
            accountUrn,
            projectUrn,
        },
    });

    // translations
    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });

    if (formConfigError) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            })
        );
    }

    const [selectTypes, setSelectTypes] = useState<any[]>([]);
    const [selectStatuses, setSelectStatuses] = useState<any[]>([]);
    const [selectCauses, setSelectCauses] = useState<any[]>([]);

    const [saving, setSaving] = useState<boolean>(false);

    const handleChange = (prop: keyof IIssueCreateState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        dispatch(onCreateChange({ prop, value }));
    };

    const handleChangeDate = (date: Date | null) => {
        dispatch(onCreateChange({ prop: "dueDate", value: date }));
    };

    const getMarkupsObjectID = (createIssueData: any): string | null => {
        const externalID = createIssueData?.issueExternals?.find(
            (ie) => ie?.externalSystem?.code === ExternalSystem.Forge
        )?.externalID;
        return buildMarkupID(externalID);
    };

    const handleCreate = async () => {
        setSaving(true);
        const dataToSend: CreateIssueRemoteMutationVariables = {
            issue: {
                name: issueCreate.title,
                locationDescription: issueCreate.locationDetails,
                description: issueCreate.description,
                externalSystemData: {
                    externalSystemCode: ExternalSystem.Forge,
                    issueTypeID: issueCreate.type,
                    issueStatusID: issueCreate.status,
                    versionFrom: issueViewer?.version?.data?.version,
                    location: JSON.stringify(issueCreate.locationInfo),
                },
            },
            externalArguments: [
                { key: "accountId", value: accountUrn },
                { key: "projectId", value: projectUrn },
                { key: "fileId", value: fileUrn as string },
            ],
        };

        if (issueCreate.rootCause && issueCreate.rootCause !== "0") {
            dataToSend.issue.externalSystemData.issueCauseID = issueCreate.rootCause;
        }

        if (issueCreate.dueDate) {
            dataToSend.issue.dueDate = issueCreate.dueDate;
        }

        let createdIssue;
        await createIssueMutation({
            variables: dataToSend,
        })
            .then((createIssueData) => {
                createdIssue = createIssueData?.data?.createIssueRemote;
                console.log("createIssueData");
                console.log(createIssueData);
            })
            .catch((createIssueError) => {
                console.log("createIssueError");
                console.log(createIssueError);
            });

        if (issueCreate.attachMarkups) {
            // attach markups to issue
            const objectID = getMarkupsObjectID(createdIssue);
            if (objectID) {
                await saveMarkup(objectID, {
                    content: markupsData.data,
                    placement: JSON.stringify(markupsData.viewerState),
                    name: issueCreate.title as string,
                }).then(() => {
                    // clear markups
                    dispatch(onMarkupsSetData({ markupsData: null }));
                });
            } else {
                console.error("ObjectID not generated");
            }
        }

        setSaving(false);
        dispatch(onCreateDone());
        handleChangeTab(IIssueTab.List);
    };

    const isValid = (): boolean => {
        return (
            issueCreate.type !== null &&
            issueCreate.status !== null &&
            issueCreate.title !== "" &&
            issueCreate.dueDate !== null &&
            (!issueCreate.attachMarkups || markupsData != null)
        );
    };

    const renderStatus = (status: any) => {
        return (
            <span
                className={classes.status}
                style={{
                    borderLeftColor: status?.highlightColor,
                }}
            />
        );
    };

    useEffect(() => {
        dispatch(onMarkupsSetExternalHandling({ externalHandling: true }));

        return () => {
            dispatch(onMarkupsSetData({ markupsData: null }));
            dispatch(onMarkupsSetExternalHandling({ externalHandling: false }));
        };
    }, []);

    useEffect(() => {
        const selectTypesItems: any[] = [];
        formConfigData?.types?.map((type, index) => {
            if (type && type?.children && type?.children?.length > 0) {
                selectTypesItems.push(
                    <ListSubheader key={index} className={classes.listSubheader}>
                        {type?.name}
                    </ListSubheader>
                );
                type?.children?.map((item, index2) => {
                    selectTypesItems.push(
                        <MenuItem
                            key={`${index}-${index2}`}
                            value={
                                item?.issueTypeExternals?.find(
                                    (ite) => ite?.externalSystem?.code === ExternalSystem.Forge
                                )?.externalID as string
                            }
                        >
                            {item?.name}
                        </MenuItem>
                    );
                });
            }
        });
        setSelectTypes(selectTypesItems);
    }, [formConfigData, setSelectTypes]);

    useEffect(() => {
        const selectStatusesItems: any[] = [];
        formConfigData?.statuses?.map((status, index) => {
            if (status?.creationEnabled) {
                selectStatusesItems.push(
                    <MenuItem
                        key={index}
                        value={
                            status?.issueStatusExternals?.find(
                                (ite) => ite?.externalSystem?.code === ExternalSystem.Forge
                            )?.externalID as string
                        }
                        className={classes.selectStatuses}
                        onClick={() => handleChangeStatus(status)}
                    >
                        <div>
                            {renderStatus(status)} {status?.name} {status?.description}
                        </div>
                        {status?.description ? (
                            <div className={classes.statusDescription}>{status?.description}</div>
                        ) : null}
                    </MenuItem>
                );
            }
        });
        setSelectStatuses(selectStatusesItems);
    }, [formConfigData, setSelectStatuses]);

    useEffect(() => {
        const selectCausesItems: any[] = [];
        formConfigData?.causes?.map((cause, index) => {
            selectCausesItems.push(
                <MenuItem
                    key={index}
                    value={
                        cause?.issueCauseExternals?.find((ite) => ite?.externalSystem?.code === ExternalSystem.Forge)
                            ?.externalID as string
                    }
                >
                    {cause?.name}
                </MenuItem>
            );
        });
        setSelectCauses(selectCausesItems);
    }, [formConfigData, setSelectCauses]);

    const handleChangeStatus = (status: any) => {
        dispatch(onCreateChange({ prop: "color", value: status?.highlightColor }));
    };

    return (
        <>
            <CreateIssue
                issue={issueCreate}
                markups={markupsData}
                isValid={isValid}
                selectTypes={selectTypes}
                selectStatuses={selectStatuses}
                selectCauses={selectCauses}
                formConfigLoading={formConfigLoading || saving}
                formConfigError={formConfigError}
                handleChangeTab={handleChangeTab}
                handleChange={handleChange}
                handleChangeDate={handleChangeDate}
                handleCreate={handleCreate}
            />
        </>
    );
};

export default CreateIssueWrapper;
