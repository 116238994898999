import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    CircularProgress,
    IconButton,
    Input,
    MenuItem,
    Radio,
    TableSortLabel,
    TextField,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import MuiVirtualizedTable from "mui-virtualized-table";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoSizer } from "react-virtualized";
import { IElementsPosition } from "../../../../packages/Api/data/elements/types";
import { IGraphics, IGraphicsPattern } from "../../../../packages/Api/data/graphics/types";
import {
    onAddGraphicsCatalogue,
    onAddGraphicsToPosition,
    onDeleteGraphicsCatalogue,
    onGraphicsCatalogue,
    onGraphicsPatternCatalogue,
    onUpdateGraphicsCatalogue,
    useDetailPosition,
    useGraphicsCatalogue,
    useGraphicsLoading,
    useGraphicsPatternCatalogue,
    useGraphicsPatternLoading,
} from "../../../../redux/mapping";
import { useActiveProject } from "../../../../redux/project";
import Icon from "../../../Icon/Icon";
import { ProjectData } from "../../../ProjectSelectorWrapper/type";
import ConfirmDialog from "../../../Dialogs/ConfirmDialog/ConfirmDialog";
import RowSubMenu from "../RowSubMenu/RowSubMenu";
import useStyles from "./styles";
import { getComparator, Order, stableSort } from "./utils";
import ColorPickerPopover from "../../ColorPickerPopover/ColorPickerPopover";
import { Skeleton } from "@material-ui/lab";
import { useIntl } from "react-intl";
import messages from "./messages";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";
import { useSecurityContext } from "../../../../redux/keyclock/securityContext";
import SecuredComponent from "../../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IDefaultProps {
    headerHeight: number;
    rowHeight: number;
    minTableWidth: number;
}

interface IGraphicsUpdate extends IGraphics {
    isUpdateMode: boolean;
    isAddMode: boolean;
}

interface IGraphicsDialogProps {
    addModeActive: boolean;
    selectedGraphicsIds: number[];
    handleAddModeActive: (active: boolean) => void;
    handleSelectedGraphicsIds: (rowIDs: number[]) => void;
    handleClose: () => void;
    ref: any;
}

const GraphicsDialog: React.FC<IGraphicsDialogProps> = forwardRef(
    ({ addModeActive, selectedGraphicsIds, handleAddModeActive, handleSelectedGraphicsIds, handleClose }, ref) => {
        const classes = useStyles();
        const { tokenInitialized } = useSecurityContext();
        const dispatch = useDispatch<DispatchAction>();
        const activeProject: ProjectData | undefined = useActiveProject();
        const detailPosition: IElementsPosition | undefined = useDetailPosition();

        const graphicsLoading: boolean = useGraphicsLoading();
        const graphicsCatalogue: IGraphics[] = useGraphicsCatalogue();
        const graphicsPatternLoading: boolean = useGraphicsPatternLoading();
        const graphicsPatternCatalogue: IGraphicsPattern[] = useGraphicsPatternCatalogue();

        const addModeRef = useRef(null);

        const [defaultProps] = useState<IDefaultProps>({
            headerHeight: 28,
            rowHeight: 28,
            minTableWidth: 2000,
        });

        const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = useState<IGraphicsUpdate | null>(null);

        const [updateGraphicsCatalogue, setUpdateGraphicsCatalogue] = useState<IGraphicsUpdate[]>([]);
        const [previous, setPrevious] = useState({});

        const [order, setOrder] = useState<Order>(Order.ASC);
        const [orderBy, setOrderBy] = useState<keyof IGraphicsUpdate>("graphicsID");


        // translations
        const intl = useIntl();
        const transName = intl.formatMessage({ ...messages.name });
        const transShading = intl.formatMessage({ ...messages.shading });
        const transTransparency = intl.formatMessage({ ...messages.transparency });
        const transCutBackgroundPattern = intl.formatMessage({ ...messages.cutBackgroundPattern });
        const transCutBackground = intl.formatMessage({ ...messages.cutBackground });
        const transCutForegroundPattern = intl.formatMessage({ ...messages.cutForegroundPattern });
        const transCutForeground = intl.formatMessage({ ...messages.cutForeground });
        const transSurfaceBackgroundPattern = intl.formatMessage({ ...messages.surfaceBackgroundPattern });
        const transSurfaceBackground = intl.formatMessage({ ...messages.surfaceBackground });
        const transSurfaceForegroundPattern = intl.formatMessage({ ...messages.surfaceForegroundPattern });
        const transSurfaceForeground = intl.formatMessage({ ...messages.surfaceForeground });
        const transNewGraphics = intl.formatMessage({ ...messages.newGraphics });
        const transSaveGraphics = intl.formatMessage({ ...messages.saveGraphics });
        const transDiscard = intl.formatMessage({ ...messages.discard });
        const transClose = intl.formatMessage({ ...messages.close });
        const transDelete = intl.formatMessage({ ...messages.delete });
        const transEdit = intl.formatMessage({ ...messages.edit });
        const transBack = intl.formatMessage({ ...messages.back });
        const transConfirmMessage = intl.formatMessage({ ...messages.confirmMessage });

        const handleConfirmOpen = () => {
            setConfirmOpen(true);
        };

        const handleConfirmClose = () => {
            setConfirmOpen(false);
        };

        useImperativeHandle(ref, () => ({
            handlePrepareAddGraphics() {
                // @ts-ignore
                addModeRef?.current?.scrollIntoView();
                setUpdateGraphicsCatalogue((prevState) => {
                    return [
                        ...prevState,
                        {
                            // graphicsID: updateGraphicsCatalogue?.[updateGraphicsCatalogue?.length - 1]?.graphicsID + 1,
                            graphicsID: -666,
                            name: "New graphics",
                            shading: "",
                            transparency: 0,
                            cutBackgroundPatternID: undefined,
                            cutBackground: "",
                            cutForegroundPatternID: undefined,
                            cutForeground: "",
                            surfaceBackgroundPatternID: undefined,
                            surfaceBackground: "",
                            surfaceForegroundPatternID: undefined,
                            surfaceForeground: "",
                            isAddMode: true,
                            isUpdateMode: true,
                        },
                    ];
                });
                handleAddModeActive(true);
            },

            handleAddGraphicsToPosition() {
                if (!activeProject && !detailPosition) return;
                dispatch(
                    onAddGraphicsToPosition({
                        projectID: activeProject?.projectID as number,
                        positionID: detailPosition?.positionID as number,
                        graphicsID: selectedGraphicsIds[0], // todo: ps
                    })
                );
                handleAddModeActive(false);
                handleSelectedGraphicsIds([]);
                handleClose();
            },
        }));

        const handleAddGraphics = (row: IGraphicsUpdate) => {
            dispatch(
                onAddGraphicsCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    request: {
                        name: row?.name,
                        shading: row?.shading,
                        transparency: row?.transparency,
                        cutBackgroundPatternID: row?.cutBackgroundPatternID,
                        cutBackground: row?.cutBackground,
                        cutForegroundPatternID: row?.cutForegroundPatternID,
                        cutForeground: row?.cutForeground,
                        surfaceBackgroundPatternID: row?.surfaceBackgroundPatternID,
                        surfaceBackground: row?.surfaceBackground,
                        surfaceForegroundPatternID: row?.surfaceForegroundPatternID,
                        surfaceForeground: row?.surfaceForeground,
                    },
                })
            );
            handleAddModeActive(false);
        };

        const handleDiscardAddGraphics = (graphicsID: number) => {
            handleAddModeActive(false);
            setUpdateGraphicsCatalogue((prevState) => {
                return prevState?.filter((graphics) => graphics?.graphicsID !== graphicsID);
            });
        };

        const handleChange = (event, row: IGraphicsUpdate, dependantEntity?: string) => {
            event.stopPropagation();
            const value = event.target.value;
            const name = event.target.name;
            handleChangeInternal(value, name, row, dependantEntity);
        };

        const handleChangeInternal = (value, name, row: IGraphicsUpdate, dependantEntity?: string) => {
            if (!previous[row?.graphicsID] && !row?.isAddMode) {
                setPrevious((prevState) => ({ ...prevState, [row?.graphicsID]: row }));
            }
            const updatedGraphicsCatalogue = updateGraphicsCatalogue?.map((graphics) => {
                if (Object.is(graphics?.graphicsID, row?.graphicsID)) {
                    const out = { ...graphics, [name]: value };
                    if (dependantEntity) {
                        out[dependantEntity] = graphicsPatternCatalogue.find(p => p.graphicsPatternID == value);
                    }
                    return out;
                }
                else {
                    return graphics;
                }
            });
            setUpdateGraphicsCatalogue(updatedGraphicsCatalogue);
        }

        const handleToggleUpdateMode = (graphicsID: number) => {
            setUpdateGraphicsCatalogue((prevState) => {
                return prevState?.map((graphics) => {
                    return graphics?.graphicsID === graphicsID ? { ...graphics, isUpdateMode: !graphics.isUpdateMode } : graphics;
                });
            });
        };

        const handleUpdateGraphics = (graphicsID: number) => {
            const updatedGraphics = updateGraphicsCatalogue?.find((uwc) => uwc?.graphicsID === graphicsID);
            if (!updatedGraphics) return;

            dispatch(
                onUpdateGraphicsCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    graphicsID: graphicsID,
                    request: {
                        name: updatedGraphics?.name,
                        shading: updatedGraphics?.shading,
                        transparency: updatedGraphics?.transparency,
                        cutBackgroundPatternID: updatedGraphics?.cutBackgroundPatternID,
                        cutBackground: updatedGraphics?.cutBackground,
                        cutForegroundPatternID: updatedGraphics?.cutForegroundPatternID,
                        cutForeground: updatedGraphics?.cutForeground,
                        surfaceBackgroundPatternID: updatedGraphics?.surfaceBackgroundPatternID,
                        surfaceBackground: updatedGraphics?.surfaceBackground,
                        surfaceForegroundPatternID: updatedGraphics?.surfaceForegroundPatternID,
                        surfaceForeground: updatedGraphics?.surfaceForeground,
                    },
                })
            );
            handleToggleUpdateMode(graphicsID);
        };

        const handleRevertGraphics = (graphicsID: number) => {
            const revertedGraphicsCatalogue = updateGraphicsCatalogue?.map((graphics) => {
                return graphics.graphicsID === graphicsID && previous[graphicsID] ? previous[graphicsID] : graphics;
            });

            const revertedGraphics = revertedGraphicsCatalogue?.find((rwc) => rwc?.graphicsID === graphicsID);
            if (!revertedGraphics) return;

            setUpdateGraphicsCatalogue(revertedGraphicsCatalogue);
            setPrevious((prevState) => {
                delete prevState[graphicsID];
                return prevState;
            });

            dispatch(
                onUpdateGraphicsCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    graphicsID: graphicsID,
                    request: {
                        name: revertedGraphics?.name,
                        shading: revertedGraphics?.shading,
                        transparency: revertedGraphics?.transparency,
                        cutBackgroundPatternID: revertedGraphics?.cutBackgroundPatternID,
                        cutBackground: revertedGraphics?.cutBackground,
                        cutForegroundPatternID: revertedGraphics?.cutForegroundPatternID,
                        cutForeground: revertedGraphics?.cutForeground,
                        surfaceBackgroundPatternID: revertedGraphics?.surfaceBackgroundPatternID,
                        surfaceBackground: revertedGraphics?.surfaceBackground,
                        surfaceForegroundPatternID: revertedGraphics?.surfaceForegroundPatternID,
                        surfaceForeground: revertedGraphics?.surfaceForeground,
                    },
                })
            );
            handleToggleUpdateMode(graphicsID);
        };

        const handleDeleteGraphics = (row: IGraphicsUpdate) => {
            setConfirmDelete(row);
            handleConfirmOpen();
        };

        const handleConfirmDeleteGraphics = () => {
            if (!confirmDelete) return;

            dispatch(
                onDeleteGraphicsCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    graphicsID: confirmDelete?.graphicsID,
                })
            );
            setConfirmDelete(null);
            handleConfirmClose();
        };

        const handleRequestSort = (property: keyof IGraphicsUpdate) => () => {
            const isAsc = orderBy === property && order === Order.ASC;
            setOrder(isAsc ? Order.DESC : Order.ASC);
            setOrderBy(property);
        };

        const tooltipable = (text?: string) => {
            if (!text) {
                return null;
            }
            else {
                return <NullableTooltip title={text}>
                    <Typography variant={"body2"}>{text}</Typography>
                </NullableTooltip>;
            }
        }

        useEffect(() => {
            // @ts-ignore
            setUpdateGraphicsCatalogue(stableSort(updateGraphicsCatalogue, getComparator(order, orderBy)));
        }, [order, orderBy]);

        useEffect(() => {
            // @ts-ignore
            setUpdateGraphicsCatalogue(stableSort(graphicsCatalogue, getComparator(order, orderBy)));
        }, [graphicsCatalogue]);

        useEffect(() => {
            dispatch(onGraphicsCatalogue({ }));
            dispatch(onGraphicsPatternCatalogue({ }));
        }, [tokenInitialized]);

        return (
            <Box className={classes.dialogBox}>
                {graphicsLoading && (
                    <Box className={classes.skeletonBox}>
                        <CircularProgress size={40} className={classes.circularProgress} />
                    </Box>
                )}
                <AutoSizer>
                    {({ width, height }) => (
                        <MuiVirtualizedTable
                            data={updateGraphicsCatalogue}
                            columns={[
                                {
                                    name: "checkbox",
                                    header: (
                                        <TableSortLabel></TableSortLabel>
                                    ),
                                    cell: (row) => (
                                        <Radio
                                            size="small"
                                            checked={selectedGraphicsIds?.some((id) => row?.graphicsID === id)}
                                            tabIndex={row?.graphicsID}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "name",
                                    header: (
                                        <NullableTooltip title={transName}>
                                            <TableSortLabel
                                                active={orderBy === "name"}
                                                direction={orderBy === "name" ? order : Order.ASC}
                                                onClick={handleRequestSort("name")}
                                            >
                                                    {transName}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="name"
                                                    value={row?.name}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.graphicsID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddGraphics(row)
                                                                : handleUpdateGraphics(row?.graphicsID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.name
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "subMenu",
                                    header: "",
                                    cell: (row) => (
                                        <RowSubMenu
                                            isAddMode={row?.isAddMode as boolean}
                                            isUpdateMode={row?.isUpdateMode as boolean}
                                            disabledRevert={!previous[row?.graphicsID]}
                                            row={row}
                                            rowID={row?.graphicsID}
                                            handleAdd={handleAddGraphics}
                                            handleUpdate={handleUpdateGraphics}
                                            handleRevert={handleRevertGraphics}
                                            handleDiscardAdd={handleDiscardAddGraphics}
                                            handleToggleUpdateMode={handleToggleUpdateMode}
                                            handleDelete={handleDeleteGraphics}
                                            updatePermission={{resource: "mapping", scope: "graphics:update", isProject: true}}
                                            deletePermission={{resource: "mapping", scope: "graphics:delete", isProject: true}}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "shading",
                                    header: (
                                        <NullableTooltip title={transShading}>
                                            <TableSortLabel
                                                active={orderBy === "shading"}
                                                direction={orderBy === "shading" ? order : Order.ASC}
                                                onClick={handleRequestSort("shading")}
                                            >
                                                {transShading}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <ColorPickerPopover
                                            color={row?.shading}
                                            onColorChange={(a) => {
                                                handleChangeInternal(a, "shading", row);
                                            }}
                                            disabled={!row?.isUpdateMode}
                                        />
                                    ),
                                },
                                {
                                    name: "transparency",
                                    header: (
                                        <NullableTooltip title={transTransparency}>
                                            <TableSortLabel
                                                active={orderBy === "transparency"}
                                                direction={orderBy === "transparency" ? order : Order.ASC}
                                                onClick={handleRequestSort("transparency")}
                                            >
                                                {transTransparency}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 70,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="transparency"
                                                    value={row?.transparency}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.graphicsID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddGraphics(row)
                                                                : handleUpdateGraphics(row?.graphicsID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.transparency
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "cutBackgroundPatternID",
                                    header: (
                                        <NullableTooltip title={transCutBackgroundPattern}>
                                            <TableSortLabel
                                                active={orderBy === "cutBackgroundPatternID"}
                                                direction={orderBy === "cutBackgroundPatternID" ? order : Order.ASC}
                                                onClick={handleRequestSort("cutBackgroundPatternID")}
                                            >
                                                {transCutBackgroundPattern}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {graphicsPatternLoading ? <Skeleton /> : (
                                                <>
                                                    {row?.isUpdateMode ? (
                                                        <TextField
                                                            name="cutBackgroundPatternID"
                                                            value={row?.cutBackgroundPatternID}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => handleChange(event, row, "cutBackgroundPattern")}
                                                            className={classes.mvtInput}
                                                            tabIndex={row?.graphicsID}
                                                            select
                                                        >
                                                            {graphicsPatternCatalogue.map(gp => {
                                                                return <MenuItem key={gp.graphicsPatternID} value={gp.graphicsPatternID}>
                                                                    {gp.name}
                                                                </MenuItem>
                                                            })}
                                                        </TextField>
                                                    ) : (
                                                        tooltipable(row?.cutBackgroundPattern?.name)
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "cutBackground",
                                    header: (
                                        <NullableTooltip title={transCutBackground}>
                                            <TableSortLabel
                                                active={orderBy === "cutBackground"}
                                                direction={orderBy === "cutBackground" ? order : Order.ASC}
                                                onClick={handleRequestSort("cutBackground")}
                                            >
                                                {transCutBackground}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <ColorPickerPopover
                                            color={row?.cutBackground}
                                            onColorChange={(a) => {
                                                handleChangeInternal(a, "cutBackground", row);
                                            }}
                                            disabled={!row?.isUpdateMode}
                                        />
                                    ),
                                },
                                {
                                    name: "cutForegroundPatternID",
                                    header: (
                                        <NullableTooltip title={transCutForegroundPattern}>
                                            <TableSortLabel
                                                active={orderBy === "cutForegroundPatternID"}
                                                direction={orderBy === "cutForegroundPatternID" ? order : Order.ASC}
                                                onClick={handleRequestSort("cutForegroundPatternID")}
                                            >
                                                {transCutForegroundPattern}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {graphicsPatternLoading ? <Skeleton /> : (
                                                <>
                                                    {row?.isUpdateMode ? (
                                                        <TextField
                                                            name="cutForegroundPatternID"
                                                            value={row?.cutForegroundPatternID}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => handleChange(event, row, "cutForegroundPattern")}
                                                            className={classes.mvtInput}
                                                            tabIndex={row?.graphicsID}
                                                            select
                                                        >
                                                            {graphicsPatternCatalogue.map(gp => {
                                                                return <MenuItem key={gp.graphicsPatternID} value={gp.graphicsPatternID}>
                                                                    {gp.name}
                                                                </MenuItem>
                                                            })}
                                                        </TextField>
                                                    ) : (
                                                        tooltipable(row?.cutForegroundPattern?.name)
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "cutForeground",
                                    header: (
                                        <NullableTooltip title={transCutForeground}>
                                            <TableSortLabel
                                                active={orderBy === "cutForeground"}
                                                direction={orderBy === "cutForeground" ? order : Order.ASC}
                                                onClick={handleRequestSort("cutForeground")}
                                            >
                                                {transCutForeground}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <ColorPickerPopover
                                            color={row?.cutForeground}
                                            onColorChange={(a) => {
                                                handleChangeInternal(a, "cutForeground", row);
                                            }}
                                            disabled={!row?.isUpdateMode}
                                        />
                                    ),
                                },
                                {
                                    name: "surfaceBackgroundPatternID",
                                    header: (
                                        <NullableTooltip title={transSurfaceBackgroundPattern}>
                                            <TableSortLabel
                                                active={orderBy === "surfaceBackgroundPatternID"}
                                                direction={orderBy === "surfaceBackgroundPatternID" ? order : Order.ASC}
                                                onClick={handleRequestSort("surfaceBackgroundPatternID")}
                                            >
                                                {transSurfaceBackgroundPattern}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {graphicsPatternLoading ? <Skeleton /> : (
                                                <>
                                                    {row?.isUpdateMode ? (
                                                        <TextField
                                                            name="surfaceBackgroundPatternID"
                                                            value={row?.surfaceBackgroundPatternID}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => handleChange(event, row, "surfaceBackgroundPattern")}
                                                            className={classes.mvtInput}
                                                            tabIndex={row?.graphicsID}
                                                            select
                                                        >
                                                            {graphicsPatternCatalogue.map(gp => {
                                                                return <MenuItem key={gp.graphicsPatternID} value={gp.graphicsPatternID}>
                                                                    {gp.name}
                                                                </MenuItem>
                                                            })}
                                                        </TextField>
                                                    ) : (
                                                        tooltipable(row?.surfaceBackgroundPattern?.name)
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "surfaceBackground",
                                    header: (
                                        <NullableTooltip title={transSurfaceBackground}>
                                            <TableSortLabel
                                                active={orderBy === "surfaceBackground"}
                                                direction={orderBy === "surfaceBackground" ? order : Order.ASC}
                                                onClick={handleRequestSort("surfaceBackground")}
                                            >
                                                {transSurfaceBackground}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <ColorPickerPopover
                                            color={row?.surfaceBackground}
                                            onColorChange={(a) => {
                                                handleChangeInternal(a, "surfaceBackground", row);
                                            }}
                                            disabled={!row?.isUpdateMode}
                                        />
                                    ),
                                },
                                {
                                    name: "surfaceForegroundPatternID",
                                    header: (
                                        <NullableTooltip title={transSurfaceForegroundPattern}>
                                            <TableSortLabel
                                                active={orderBy === "surfaceForegroundPatternID"}
                                                direction={orderBy === "surfaceForegroundPatternID" ? order : Order.ASC}
                                                onClick={handleRequestSort("surfaceForegroundPatternID")}
                                            >
                                                {transSurfaceForegroundPattern}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {graphicsPatternLoading ? <Skeleton /> : (
                                                <>
                                                    {row?.isUpdateMode ? (
                                                        <TextField
                                                            name="surfaceForegroundPatternID"
                                                            value={row?.surfaceForegroundPatternID}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => handleChange(event, row, "surfaceForegroundPattern")}
                                                            className={classes.mvtInput}
                                                            tabIndex={row?.graphicsID}
                                                            select
                                                        >
                                                            {graphicsPatternCatalogue.map(gp => {
                                                                return <MenuItem key={gp.graphicsPatternID} value={gp.graphicsPatternID}>
                                                                    {gp.name}
                                                                </MenuItem>
                                                            })}
                                                        </TextField>
                                                    ) : (
                                                        tooltipable(row?.surfaceForegroundPattern?.name)
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "surfaceForeground",
                                    header: (
                                        <NullableTooltip title={transSurfaceForeground}>
                                            <TableSortLabel
                                                active={orderBy === "surfaceForeground"}
                                                direction={orderBy === "surfaceForeground" ? order : Order.ASC}
                                                onClick={handleRequestSort("surfaceForeground")}
                                            >
                                                {transSurfaceForeground}
                                            </TableSortLabel>
                                        </NullableTooltip>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <ColorPickerPopover
                                            color={row?.surfaceForeground}
                                            onColorChange={(a) => {
                                                handleChangeInternal(a, "surfaceForeground", row);
                                            }}
                                            disabled={!row?.isUpdateMode}
                                        />
                                    ),
                                },
                                {
                                    name: "update",
                                    header: "",
                                    cell: (row) => (
                                        <SecuredComponent permission={{resource: "mapping", scope: "graphics:update", isProject: true}}>
                                            {row?.isUpdateMode ? (
                                                <>
                                                    <NullableTooltip
                                                        title={row?.isAddMode ? transNewGraphics : transSaveGraphics}
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="save"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                row?.isAddMode
                                                                    ? handleAddGraphics(row)
                                                                    : handleUpdateGraphics(row?.graphicsID);
                                                            }}
                                                            className={clsx(classes.iconButton, classes.iconButtonSave)}
                                                        >
                                                            <Icon name="check" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                    {!row?.isAddMode && (
                                                        <NullableTooltip title={transBack} placement="top">
                                                            {/*Revert*/}
                                                            <IconButton
                                                                size="small"
                                                                aria-label="revert"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleRevertGraphics(row?.graphicsID);
                                                                }}
                                                                className={clsx(
                                                                    classes.iconButton,
                                                                    classes.iconButtonRevert,
                                                                    {
                                                                        [classes.disabled]: !previous[row?.graphicsID],
                                                                    }
                                                                )}
                                                                disabled={!previous[row?.graphicsID]}
                                                            >
                                                                <Icon name="history" size={18} />
                                                            </IconButton>
                                                        </NullableTooltip>
                                                    )}
                                                    <NullableTooltip
                                                        title={row?.isAddMode ? transDiscard : transClose}
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="close"
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                row?.isAddMode
                                                                    ? handleDiscardAddGraphics(row?.graphicsID)
                                                                    : handleToggleUpdateMode(row?.graphicsID);
                                                            }}
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.iconButtonClose
                                                            )}
                                                        >
                                                            <Icon name="close" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                </>
                                            ) : (
                                                <NullableTooltip title={transEdit} placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="update"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleToggleUpdateMode(row?.graphicsID);
                                                        }}
                                                        className={classes.iconButton}
                                                    >
                                                        <Icon name="pencil-solid" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </SecuredComponent>
                                    ),
                                    width: 100,
                                },
                                {
                                    name: "delete",
                                    header: "",
                                    cell: (row) => (
                                        <SecuredComponent permission={{resource: "mapping", scope: "graphics:delete", isProject: true}}>
                                            {!row?.isAddMode && (
                                                <NullableTooltip title={transDelete} placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDeleteGraphics(row);
                                                        }}
                                                        className={clsx(classes.iconButton, classes.iconButtonClose)}
                                                    >
                                                        <Icon name="trash-alt" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </SecuredComponent>
                                    ),
                                    width: 50,
                                },
                            ]}
                            width={width}
                            maxHeight={height}
                            includeHeaders={true}
                            fixedRowCount={1}
                            fixedColumnCount={3}
                            rowHeight={defaultProps.rowHeight}
                            isCellSelected={(column, row) => selectedGraphicsIds?.some((id) => row && row?.graphicsID === id)}
                            onCellClick={(event, { column, rowData, data }) => {
                                const graphicsID = rowData?.graphicsID as number;
                                if (graphicsID) {
                                    handleSelectedGraphicsIds([graphicsID]);
                                }
                            }}
                            orderBy={orderBy}
                            orderDirection={order}
                            classes={{
                                table: classes.mvtTable,
                                cellHeader: classes.mvtCellHeader,
                                cellContents: classes.mvtCellContents,
                                cellSelected: classes.mvtCellSelected,
                            }}
                        />
                    )}
                </AutoSizer>
                <ConfirmDialog
                    open={confirmOpen}
                    handleClose={handleConfirmClose}
                    handleConfirm={handleConfirmDeleteGraphics}
                    messages={{
                        dialogTitle: transDelete, // "Delete confirm"
                        confirmMessage: (
                            <>
                                {transConfirmMessage}
                                <strong>
                                    {confirmDelete?.graphicsID} - {confirmDelete?.name}
                                </strong>
                                ?
                            </>
                        ),
                        closeButton: transClose,
                        confirmButton: transDelete,
                    }}
                />
            </Box>
        );
    }
);

export default GraphicsDialog;
