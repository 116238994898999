import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        boxSync: {
            display: "flex",
            flexFlow: "column",
            width: "100%",
            flexBasis: "100%",
        },
        boxSyncInner: {
            display: "flex",
            flexFlow: "row",
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
    })
);

export default useStyles;
