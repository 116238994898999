import React from "react";
import { Button } from "@mui/material";
import { Menu, MenuItem } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";

export interface IMenuSwitchOption {
    key: any;
    name: string;
}

export interface IMenuSwitchProps {
    currentValue: any;
    options?: IMenuSwitchOption[];
    onChange: (value: any) => void,
    disabled?: boolean,
    noValueText?: string;
    noValueOption?: IMenuSwitchOption;
}

const MenuSwitch: React.FC<IMenuSwitchProps> = ({ currentValue, options , onChange, disabled, noValueText, noValueOption}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const classes = useStyles();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (value: any) => () => {
        handleClose();
        if (currentValue !== value) {
            onChange(value);
        }
    }

    const getCurrentValueText = () => {
        return options?.find(o => o.key === currentValue)?.name ?? noValueOption?.name ?? noValueText;
    }

    return (
        <>
            <Button
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                size={"small"}
                onClick={handleClick}
                disabled={disabled}
                className={clsx({[classes.noOptionButton]: !currentValue})}
            >
                {getCurrentValueText()}
            </Button>
            {!disabled && (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    onClose={handleClose}
                >
                    {noValueOption && (
                        <MenuItem className={classes.clearOption} onClick={handleChange(noValueOption.key)} value={noValueOption.key} key={`option-undefined`}>{noValueOption.name}</MenuItem>
                    )}
                    {options?.map((o) => (
                        <MenuItem onClick={handleChange(o.key)} value={o.key} key={`option-${o.key}`}>{o.name}</MenuItem>
                    ))}
                </Menu>
            )}
        </>
    );
};

export default MenuSwitch;