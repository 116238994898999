import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { treeDataNode } from "../../packages/Api/data/tree/client";
import { ITree, ITreeResponse, IVersion } from "../../packages/Api/data/tree/types";
import { onIssueViewer, onIssueViewerChangeVersion, useIssueViewer } from "../../redux/issue";
import { IIssueViewer } from "../../redux/issue/reducer";
import VersionSelector from "../VersionSelector/VersionSelector";
import { useActiveViewables } from "../../redux/viewer";
import { useSecurityContext } from "../../redux/keyclock/securityContext";

export interface ModelData {
    id: string;
    name: string;
}

interface IVersionSelectorWrapperProps {
    fileId: string;
    handleChangeUrn: (urn: string) => void;
    handleChangeVersionId?: (versionId: string) => void;
    isIssuesPanel?: boolean;
}

const VersionSelectorWrapper: React.FC<IVersionSelectorWrapperProps> = ({
    fileId,
    handleChangeUrn,
    handleChangeVersionId,
    isIssuesPanel,
}) => {
    const dispatch = useDispatch<DispatchAction>();
    const issueViewer: IIssueViewer = useIssueViewer();
    const [version, setVersion] = useState<IVersion>();
    const [versions, setVersions] = useState<IVersion[]>([]);
    const { tokenInitialized } = useSecurityContext();

    useEffect(() => {
        if (tokenInitialized) {
            // load version from API for specific fileId
            const fetchVersions = async () => {
                await treeDataNode(fileId, 1).then((tree: ITree) => {
                    const data = tree.children as unknown as IVersion[];
                    setVersions((data as unknown) as IVersion[]);

                    if (isIssuesPanel) {
                        const versionOnFirstLoad: IVersion = issueViewer?.version !== null ? issueViewer?.version : data[0];
                        setVersion(versionOnFirstLoad);
                        dispatch(onIssueViewerChangeVersion({ version: versionOnFirstLoad }));
                        handleChangeUrn(issueViewer?.urn !== null ? issueViewer?.urn : data?.[0]?.data?.urn);
                    } else {
                        setVersion(data?.[0]);
                        handleChangeUrn(data?.[0]?.data?.urn);
                        dispatch(onIssueViewer({ urn: data?.[0]?.data?.urn, fileId, version: data?.[0] }));
                    }
                });
            };

            fetchVersions();
        }

        return () => {
            setVersion(undefined);
            setVersions([]);
        };
    }, [fileId, tokenInitialized]);

    const handleChangeVersion = (newVersion: IVersion) => {
        setVersion(newVersion);
        handleChangeUrn(newVersion?.data?.urn);
        handleChangeVersionId && handleChangeVersionId(newVersion?.id);
        dispatch(onIssueViewer({ urn: newVersion?.data?.urn, fileId, version: newVersion }));
    };

    return <VersionSelector version={version} versions={versions} handleChangeVersion={handleChangeVersion} />;
};

export default VersionSelectorWrapper;
