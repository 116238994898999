import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { DataType } from "../../components/Tree/TreeWrapper/TreeWrapper";
import { ITree, ITreeFail, ITreeManualData, ITreeResponse } from "../../packages/Api/data/tree/types";

export enum ActionTypes {
    OnManualData = "tree/ON_MANUAL_DATA",
    OnManualDataDone = "tree/ON_MANUAL_DATA_DONE",
    OnManualDataFail = "tree/ON_MANUAL_DATA_FAIL",
    OnLoadDataLoadingDone = "tree/ON_LOAD_DATA_LOADING_DONE",
    OnLoadNode = "tree/ON_LOAD_NODE",
    OnLoadNodeDone = "tree/ON_LOAD_NODE_DONE",
    OnLoadNodeContentDone = "tree/ON_LOAD_NODE_CONTENT_DONE",
    OnLoadNodeFail = "tree/ON_LOAD_NODE_FAIL",
    OnResetData = "tree/ON_RESET_DATA",
    SetActiveNodeId = "tree/SET_ACTIVE_NODE_ID",
    SetExpandedNodes = "tree/SET_EXPANDED_NODES",
}

export type SelectUrnOptions = {
    urn: string;
};

export type OnManualDataOptions = {
    data: ITreeManualData;
};

export type OnLoadNodeOptions = {
    nodeId: string;
    depth: number;
    parentNodeType?: DataType;
};

export type SetActiveNodeIdOptions = {
    activeNodeId: string;
};

export type SetExpandedNodesOptions = {
    expandedNodes: string[];
};

export const Actions = {
    onManualData: (options: OnManualDataOptions) => createAction(ActionTypes.OnManualData, options),
    onManualDataDone: (options: ITreeResponse) => createAction(ActionTypes.OnManualDataDone, options),
    onManualDataFail: (options: ITreeFail) => createAction(ActionTypes.OnManualDataFail, options),
    onLoadDataLoadingDone: () => createAction(ActionTypes.OnLoadDataLoadingDone),
    onLoadNode: (options: OnLoadNodeOptions) => createAction(ActionTypes.OnLoadNode, options),
    onLoadNodeDone: (options: ITreeResponse) => createAction(ActionTypes.OnLoadNodeDone, options),
    onLoadNodeContentDone: (options: ITree[] | undefined) => createAction(ActionTypes.OnLoadNodeContentDone, options),
    onLoadNodeFail: (options: ITreeFail) => createAction(ActionTypes.OnLoadNodeFail, options),
    onResetData: () => createAction(ActionTypes.OnResetData),
    setActiveNodeId: (options: SetActiveNodeIdOptions) => createAction(ActionTypes.SetActiveNodeId, options),
    setExpandedNodes: (options: SetExpandedNodesOptions) => createAction(ActionTypes.SetExpandedNodes, options),
};

export function onManualData(options: OnManualDataOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualData(options));
    };
}

export function onManualDataDone(options: ITreeResponse): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualDataDone(options));
    };
}

export function onManualDataFail(options: ITreeFail): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onManualDataFail(options));
    };
}

export function onLoadNode(options: OnLoadNodeOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadNode(options));
    };
}

export function onLoadNodeDone(options: ITreeResponse): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadNodeDone(options));
    };
}

export function onLoadDataLoadingDone(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadDataLoadingDone());
    };
}

export function onLoadNodeContentDone(options: ITree[] | undefined): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadNodeContentDone(options));
    };
}

export function onLoadNodeFail(options: ITreeFail): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onLoadNodeFail(options));
    };
}

export function onResetData(): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.onResetData());
    };
}

export function setActiveNodeId(options: SetActiveNodeIdOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setActiveNodeId(options));
    };
}

export function setExpandedNodes(options: SetExpandedNodesOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setExpandedNodes(options));
    };
}
