import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { addMemberToProject, getProjectMembers } from "../../../packages/Api/data/projectMembers/client";
import { IoLabsBimProjectsApiModelsProjectMembersResponse as ProjectMembersResponse } from "../../../packages/Api/data/projectMembers/io-labs-bim-projects-api-models-project-members-response";
import { MemberLead } from "../../../packages/Api/data/projectMembers/member-lead";

export interface IProjectMembersContext {
    projectID?: number;
    listResponse?: ProjectMembersResponse;
    invalidateList: () => void
    addMember: (value: MemberLead[]) => Promise<void>
}


export const ProjectMembersContext = createContext<IProjectMembersContext>({
    invalidateList: () => {},
    addMember: (value: MemberLead[]) => new Promise<void>((resolve => {}))
});


export const useProjectMembersContext = () => {
    const { projectID, listResponse, invalidateList, addMember } = useContext<IProjectMembersContext>(ProjectMembersContext)

    return {
        projectID, listResponse, invalidateList, addMember
    }
}


interface IProjectMembersProviderProps {
    projectID?: number
}

const ProjectMembersProvider: React.FC<PropsWithChildren<IProjectMembersProviderProps>> = ({ children , projectID}) => {
    const [ state, setState ] = useState<number>(0);

    const [ listResponse, setListResponse ] = useState<ProjectMembersResponse|undefined>();


    const invalidateList = () => {
        setState((s) => s+1);
    }

    useEffect(() => {
        if (projectID) {
            getProjectMembers(projectID, true).then((data) => {
                setListResponse(data);
            })
        }
    }, [projectID, state]);

    const addMember = (value: MemberLead[]) => {
        return new Promise<void>((resolve, reject) => {
            if (projectID) {
                addMemberToProject(projectID, value.map(m => m.userID as number))
                    .then(res => {
                        resolve()
                        invalidateList();
                    })
            }
            else {
                reject();
            }

        })

    }

    const value = { listResponse, projectID, invalidateList, addMember };

    return <ProjectMembersContext.Provider value={value}>{children}</ProjectMembersContext.Provider>
}
export default ProjectMembersProvider
