import React, { useState } from "react";
import { Box, Checkbox, Chip, ListItemText, MenuItem, Select } from "@material-ui/core";
import { HubRole } from "../../../packages/Hubs/api/types";
import { equalMembers } from "../../../utils/Array";
import useStyles from "./styles";

export interface IMenuSwitchOption {
    key: any;
    name: string;
}

export interface IProjectMemberRolesSwitchProps {
    currentRoles: number[];
    memberID: number,
    projectID: number,
    onChange?: () => void,
    onChangeDone?: (roleIds: number[]) => void,
    disabled?: boolean,
    roleOptions?: HubRole[],
}

export const isRoleSelected = (role: HubRole, selected: number[]) => {
    return selected?.includes(role.hubRoleID as number);
}


const ProjectMemberRolesSwitch: React.FC<IProjectMemberRolesSwitchProps> = ({ projectID, memberID, currentRoles , onChange, disabled, roleOptions, onChangeDone}) => {

    const [ selected, setSelected ] = useState<number[]>(currentRoles);

    const classes = useStyles();

    const updateRolesHandler = (event) => {
        setSelected(event.target.value);
        event.stopPropagation();
    }

    const isChecked = (role: HubRole) => {
        return isRoleSelected(role, selected);
    }

    const handleClosed = () => {
        if (onChangeDone && !equalMembers(selected, currentRoles)) {
            onChangeDone(selected);
        }
    }

    return (
        <Select
            autoFocus
            onChange={updateRolesHandler}
            onClose={handleClosed}
            value={selected}
            className={classes.select}
            variant={"outlined"}
            renderValue={(values) => (
                <Box className={classes.chipHolder}>
                    {
                        roleOptions?.filter(o => isChecked(o)).map(o => (
                            <Chip size="small" key={o.hubRoleID} label={o.name} />
                        ))
                    }
                </Box>
            )}
            multiple={true}
        >
            {roleOptions?.map((o) => (
                <MenuItem value={o.hubRoleID} key={`option-${o.hubRoleID}`}>
                    <Checkbox checked={isChecked(o)} />
                    <ListItemText primary={o.name} />
                </MenuItem>
            ))}
        </Select>
    );
};

export default ProjectMemberRolesSwitch;