import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import {
    IProjectRequestData,
    ICreateProjectResponseData,
    IProjectListResponseData,
    IProjectOptionsResponseData, CheckProjectRequest, CheckProjectResponse,
} from "./types";

const projectsApi = new ApiClient("/Projects", config.api.baseUrl);
const forgeProjectsApi = new ApiClient("/ForgeProjects", config.api.baseUrl);

/**
 * Returns a list of options for project configuration form
 */
export const getProjectOptions = (): Promise<IProjectOptionsResponseData> => {
    return projectsApi.get("/options").then((response: AxiosResponse<IProjectOptionsResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Returns a list of projects
 */
export const getProjectList = (force: boolean = false): Promise<IProjectListResponseData[]> => {
    return projectsApi.get("/list", {
        clearCacheEntry: force
    }).then((response: AxiosResponse<IProjectListResponseData[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Returns synced project
 * @param projectID
 */
export const syncProject = (projectID: number): Promise<IProjectListResponseData> => {
    return projectsApi.get(`/${projectID}/sync`).then((response: AxiosResponse<IProjectListResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Synchronize resources
 * @param projectID
 */
export const syncProjectResources = (projectID: number): Promise<void> => {
    return projectsApi.get(`/${projectID}/SyncResources`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Initialize modules
 * @param projectID
 */
export const initializeModules = (projectID: number): Promise<IProjectListResponseData> => {
    return projectsApi.get(`/${projectID}/InitializeModules`).then((response: AxiosResponse<IProjectListResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Creates projects in system and returns its entity representation.
 * @param data
 */
export const createProject = (
    data: IProjectRequestData
): Promise<ICreateProjectResponseData> => {
    return projectsApi.post("/create", data).then((response: AxiosResponse<ICreateProjectResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Updates projects in system and returns its entity representation.
 * @param data
 */
export const updateProject = (
    projectID: number,
    data: IProjectRequestData
): Promise<ICreateProjectResponseData> => {
    return projectsApi.post(`/${projectID}/update`, data).then((response: AxiosResponse<ICreateProjectResponseData>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Check project existance
 * @param data
 */
export const checkProject = (
    data: CheckProjectRequest
): Promise<CheckProjectResponse> => {
    return forgeProjectsApi.post("/Check", data).then((response: AxiosResponse<CheckProjectResponse>) => {
        return Promise.resolve(response.data);
    });
};
