import React from "react";
import { useDetailPosition, useMappingBasicPositionsLoading } from "../../../redux/mapping";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import FixedQuantityForm from "./FixedQuantityForm";
import { Skeleton } from "@material-ui/lab";

interface IStepElementCommonProps {
}

const StepElementCommon: React.FC<IStepElementCommonProps> = () => {
    const detailPosition: IElementsPosition | undefined = useDetailPosition();
    const positionLoading = useMappingBasicPositionsLoading();

    return detailPosition ? (
        <FixedQuantityForm position={detailPosition} positionLoading={positionLoading} />
    ) : (
        <Skeleton />
    );
}

export default StepElementCommon;