import { useGlobalDialog } from "@iolabs/wip-dialog";
import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IDerivedPosition } from "../../../packages/Api/data/elements/types";
import ConfirmDialog from "../../Dialogs/ConfirmDialog/ConfirmDialog";
import messages from "./messages";
import DerivedPositionIfConditionDialog
    from "../Dialogs/DerivedPositionIfConditionDialog/DerivedPositionIfConditionDialog";
import { IMappingElements } from "../../../packages/Api/data/mapping/types";
import { getQuery } from "../StepContentWrapper/utils";
import Icon from "../../Icon/Icon";
import ContextMenuItem from "../../ContextMenuIcon/ContextMenuItem";

interface IStepElementDerivedPositionsPopover {
    derivedPosition: IDerivedPosition;
    handleSaveQuery?: (condition?: IMappingElements) => void;
}

const StepElementDerivedPositionsPopover: React.FC<IStepElementDerivedPositionsPopover> = ({ derivedPosition, handleSaveQuery }) => {
    const dispatch = useDispatch<DispatchAction>();
    const theme = useTheme();

    const { setGlobalDialogOpen } = useGlobalDialog();

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [ifConditionDialogOpen, setIfConditionDialogOpen] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "master-item-popover" : undefined;

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showNewPositionForm, setShowNewPositionForm] = useState<boolean>(false);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };



    const handleIfConditionDialogClose = () => {
        setIfConditionDialogOpen(false);
    };

    const handleSaveIfCondition = (condition?: IMappingElements) => {
        if (handleSaveQuery) {
            handleSaveQuery(condition);
        }
    }

    const handleAddIfConditionClick = (event) => {
        setIfConditionDialogOpen(true);
        handleClosePopover(event);
    };

    const handleEditIfConditionClick = (event) => {
        // set edit
        setIfConditionDialogOpen(true);
        handleClosePopover(event);
    };

    const handleDeleteIfConfition = (event) => {
        handleSaveIfCondition();
        setConfirmOpen(false);
    };

    const handleDeleteIfConditionClick = (event) => {
        event.stopPropagation();
        handleClosePopover(event);
        setConfirmOpen(true);
    };

    const handleConfirmClose = (event) => {
        event.stopPropagation();
        setConfirmOpen(false);
    };

    const navigateToDerivedPosition = (event) => {
        event.stopPropagation();
        handleClosePopover(event);
        setGlobalDialogOpen(true);
    }

    // translations
    const intl = useIntl();
    const transMappingDetail = intl.formatMessage({ ...messages.mappingDetail });
    const transIfConditionSet = intl.formatMessage({ ...messages.ifConditionSet });
    const transIfConditionDelete = intl.formatMessage({ ...messages.ifConditionDelete });
    const transIfConditionEdit = intl.formatMessage({ ...messages.ifConditionEdit });
    const transConfirmDeleteDialogTitle = intl.formatMessage({ ...messages.confirmDeleteDialogTitle });
    const transConfirmDeleteDialogMessage = intl.formatMessage({ ...messages.confirmDeleteDialogMessage });
    const transConfirmDeleteDialogDelete = intl.formatMessage({ ...messages.confirmDeleteDialogDelete });
    const transConfirmDeleteDialogClose = intl.formatMessage({ ...messages.confirmDeleteDialogClose });

    return (
        <Box onClick={(e) => {e.stopPropagation()}}>
            <IconButton aria-label="actions" onClick={handleOpenPopover}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List component="nav">
                    <ListItem button onClick={navigateToDerivedPosition}>
                        <ContextMenuItem>
                            <Icon name="info" size={20} />
                        </ContextMenuItem>
                        <ListItemText primary={transMappingDetail} />
                    </ListItem>
                    {
                        derivedPosition.conditionalRule ?
                            <>
                                <ListItem button onClick={handleDeleteIfConditionClick} >
                                    <ContextMenuItem>
                                        <Icon name="trash-alt" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={transIfConditionDelete} />
                                </ListItem>
                                <ListItem button onClick={handleEditIfConditionClick}>
                                    <ContextMenuItem>
                                        <Icon name="pencil-solid" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={transIfConditionEdit} />
                                </ListItem>
                            </> :
                            <>
                                <ListItem button onClick={handleAddIfConditionClick}>
                                    <ContextMenuItem>
                                        <Icon name="folder-plus" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={transIfConditionSet} />
                                </ListItem>
                            </>
                    }
                </List>
            </Popover>
            <Dialog
                open={ifConditionDialogOpen}
                onClose={handleIfConditionDialogClose}
                aria-labelledby="derived-position-dialog-title"
                aria-describedby="derived-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
            >
                <DerivedPositionIfConditionDialog
                    onClose={handleIfConditionDialogClose}
                    onSave={handleSaveIfCondition}
                    query={derivedPosition.conditionalRule ? getQuery(derivedPosition.conditionalRule, [], []) : undefined}
                />
            </Dialog>
            <ConfirmDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleConfirm={handleDeleteIfConfition}
                messages={{
                    dialogTitle: transConfirmDeleteDialogTitle,
                    confirmMessage: (
                        <>
                            {transConfirmDeleteDialogMessage}{" "}
                            <strong>
                                {derivedPosition.position?.code} - {derivedPosition.position?.name}
                            </strong>
                            ?
                        </>
                    ),
                    closeButton: transConfirmDeleteDialogClose,
                    confirmButton: transConfirmDeleteDialogDelete,
                }}
            />
        </Box>
    );
};

export default StepElementDerivedPositionsPopover;
