import { addNotification, INotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import "date-fns";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Issue, useGetIssuesRemoteQuery } from "../../../graphql/generated/graphql";
import { IPageParams } from "../../../pages/type";
import { onCreateModeActive, onIssuesList, useIssuesList, useIssueViewer } from "../../../redux/issue";
import { IIssueViewer } from "../../../redux/issue/reducer";
import { useIssuables, useViewable } from "../../../redux/viewer";
import { IIssueRenderer } from "../../../redux/viewer/reducer";
import messages from "./messages";
import useIssuesStyles from "../IssuesWrapper/styles";
import ListIssue from "../ListIssue/ListIssue";
import { IIssueTab } from "../type";
import SecuredComponent, { SecurityMode } from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { useGetPath } from "../../../utils/Menu";

interface IListIssueWrapperProps {
    handleChangeTab: (tab: IIssueTab) => void;
    handleIssueEdit: (issue: Issue) => void;
}

const ListIssueWrapper: React.FC<IListIssueWrapperProps> = ({ handleChangeTab, handleIssueEdit }) => {
    const { accountUrn, projectUrn, urn: fileUrn } = useParams<IPageParams>();

    const classes = useIssuesStyles();
    const issuables = useIssuables();
    const issues = useIssuesList();
    const dispatch = useDispatch<DispatchAction>();
    const issueViewer: IIssueViewer = useIssueViewer();
    const viewable: any = useViewable();
    const { getProjectPermission } = useGetPath();

    const {
        data: projectIssuesData,
        loading: projectIssuesLoading,
        error: projectIssuesError,
    } = useGetIssuesRemoteQuery({
        variables: {
            projectUrn,
            accountUrn,
            fileUrn: fileUrn as string,
            fromVersion: issueViewer?.version?.data?.version ? `${issueViewer?.version?.data?.version}` : "",
            viewableGuid: viewable ? `${viewable?.data?.guid}` : "",
        },
        fetchPolicy: "network-only", // disable GraphQL query caching
    });

    useEffect(() => {
        dispatch(onIssuesList({ issues: projectIssuesData?.issues as Issue[] }));
    }, [projectIssuesData]);

    useEffect(() => {
        issuables.forEach((issuable) => {
            issuable.renderIssues(
                issues?.map((issue) => {
                    return {
                        issue,
                        editAllowed: false,
                    } as IIssueRenderer;
                }) as IIssueRenderer[]
            );
        });
        return () => {
            issuables.forEach((issuable) => {
                issuable.renderIssues([]);
            });
        };
    }, [issuables, projectIssuesData, issues]);

    // translations
    const intl = useIntl();
    const transPushpin = intl.formatMessage({ ...messages.pushpin });
    const transBox = intl.formatMessage({ ...messages.box });
    const transCreate = intl.formatMessage({ ...messages.create });

    const handleCreateMode = () => {
        const notification: INotification = {
            variant: "info",
            message: transPushpin,
        };
        dispatch(addNotification({ notification }));
        dispatch(onCreateModeActive({ isModeActive: true }));
        handleChangeTab(IIssueTab.Create);
    };

    return (
        <>
            <DialogTitle id="issues-dialog-title" className={classes.title}>
                {transBox}
            </DialogTitle>
            <DialogContent className={clsx(classes.content, classes.contentList)}>
                <SecuredComponent permission={getProjectPermission("issues", "issue:view")} securityMode={SecurityMode.HIDDEN}>
                    {projectIssuesLoading || projectIssuesError ? (
                        <>
                            {[1, 2, 3].map((index) => (
                                <Box className={classes.skeletonBoxIssue} key={index}>
                                    <Box display="flex" justifyContent="space-between">
                                        <Skeleton variant="rect" height={18} width="30%" />
                                        <Skeleton variant="rect" height={18} width="30%" />
                                    </Box>
                                    <Skeleton variant="rect" height={22} />
                                    <Skeleton variant="rect" height={15} width="70%" />
                                    <Skeleton variant="rect" height={15} width="70%" />
                                </Box>
                            ))}
                        </>
                    ) : (
                        <ListIssue handleIssueEdit={handleIssueEdit} />
                    )}
                </SecuredComponent>
            </DialogContent>
            <DialogActions className={clsx(classes.actions, classes.actionsList)}>
                <SecuredComponent permission={getProjectPermission("issues", "issue:create")} securityMode={SecurityMode.DISABLED}>
                    <Button variant="contained" color="secondary" size="large" onClick={handleCreateMode}>
                        {transCreate}
                </Button>
                </SecuredComponent>
            </DialogActions>
        </>
    );
};

export default ListIssueWrapper;
