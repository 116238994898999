import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IMappingElements } from "../mapping/types";
import { InstancesAttributes } from "./types";
import AwesomeDebouncePromise from "awesome-debounce-promise";

const lookupApi = new ApiClient("/Lookup", config.api.baseUrl);


/**
 * Load matching elements
 * @param viewableID
 * @param rule
 */
export const loadMatchingInstances = (
    viewableID: number,
    rule: IMappingElements
): Promise<InstancesAttributes> => {
    return lookupApi
        .post(`/${viewableID}/Instances`, rule)
        .then((response: AxiosResponse<InstancesAttributes>) => {
            return Promise.resolve(response.data);
        });
};

export const loadMatchingInstancesDebounced = AwesomeDebouncePromise(loadMatchingInstances, 3000)