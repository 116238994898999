import { AnyAction } from "redux";
import { ActionTypes, loadSpecialInstancesDone, setHubs, setProjects } from "./action";
import { getSpecialInstances } from "../../packages/Api/data/instances/client";
import { logger } from "workbox-core/_private";
import { getProjectList } from "../../packages/Api/data/projects/client";
import { IProjectListResponseData } from "../../packages/Api/data/projects/types";
import { getHubs } from "../../packages/Hubs/api/client";
import { HubResponse } from "../../packages/Hubs/api/types";

const onProjectsMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);
    // load special instances
    if (action.type === ActionTypes.LoadSpecialInstances) {
        const { projectID, forceReload } = action.payload;
        const state = getState();

        if (!state.projectState.specialInstances || forceReload) {

            getSpecialInstances(projectID)
                .then((response) => {
                    dispatch(
                        loadSpecialInstancesDone({
                            response
                        })
                    );
                })
                .catch((error) => {
                    logger.error(error, "unable to load special instances")
                });
        }

    }


    // load hubs
    if (action.type === ActionTypes.LoadHubs) {

        const { forceReload} = action.payload;

        getHubs(forceReload)
            .then((response: HubResponse[]) => {
                dispatch(
                    setHubs({ hubs: response })
                );
            })
            .catch((error) => {
                logger.error(error, "unable to load hubs")
            });
    }

    // load projects
    if (action.type === ActionTypes.LoadProjects) {
        logger.error("Loading projects is deprecated");
        //
        // const { forceReload} = action.payload;
        //
        // getProjectList(forceReload)
        //     .then((response: IProjectListResponseData[]) => {
        //         dispatch(
        //             setProjects({ projects: response })
        //         );
        //     })
        //     .catch((error) => {
        //         logger.error(error, "unable to load projects")
        //     });

    }

    return resolve;

};

export default [onProjectsMiddleware];
