import React, { FC } from "react";
import useStyles from "./styles";
import { HubResponse } from "../../packages/Hubs/api/types";
import { Button, List } from "@material-ui/core";
import { useIntl } from "react-intl";
import messages from "./messages";
import ProjectSelectorHub from "./ProjectSelectorHub";
import clsx from "clsx";

interface IProjectSelectorHubsProps {
    hubs: HubResponse[]|undefined;
    switchHubHandler: (hub: HubResponse) => void;
    hudAdminClickHandler?: (hub: HubResponse) => void;
}

const ProjectSelectorHubs: FC<IProjectSelectorHubsProps> = ({ hubs, switchHubHandler, hudAdminClickHandler}) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transSwitchHub = intl.formatMessage({ ...messages.switchHub });

    const handleClickHub = (hub: HubResponse) => () => {
        if (switchHubHandler) {
            switchHubHandler(hub)
        }
    }

    return (
        <>
            <List component="nav" aria-label="pages" classes={{ root: classes.listRoot }}>
                {hubs?.sort((a,b) => a.name.localeCompare(b.name)).map((hub) => (
                    <Button
                        className={clsx(classes.link, classes.hubLink)}
                        onClick={handleClickHub(hub)}
                        key={`hub-${hub.hubID}`}
                    >
                        <ProjectSelectorHub hub={hub} hudAdminClickHandler={hudAdminClickHandler} />
                    </Button>
                ))}
            </List>
        </>
    )
}
export default ProjectSelectorHubs;