import { createSelector } from "reselect";
import { IProjectModuleState, IProjectState } from "./reducer";
import { HubResponse } from "../../packages/Hubs/api/types";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { flattenProjects } from "./utils";

export function selectProjectState(state: IProjectModuleState): IProjectState {
    return state.projectState;
}
export const selectActiveProject = createSelector(selectProjectState, (state) => state.activeProject);
export const selectProjects = createSelector(selectProjectState, (state) => state.projects);
export const selectHubs = createSelector(selectProjectState, (state) => state.hubs);
export const selectHubsLoading = createSelector(selectProjectState, (state) => state.hubsLoading);
export const selectSpecialInstances = createSelector(selectProjectState, (state) => state.specialInstances);
