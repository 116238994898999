import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            ...theme.typography.h3,
            paddingBottom: 0,
            cursor: "move",
        },
        closeButton: {
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey["500"],
        },
        grid: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
        },
        box: {
            border: `1px solid ${theme.palette.text.secondary}`,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(1),
            marginRight: theme.spacing(3),
            "& p:first-child": {
                color: theme.palette.grey["500"],
                fontWeight: 500,
                marginBottom: "9px",
            },
            "& p:nth-child(2)": {
                fontWeight: 400,
            },
        },
        positionTable: {
        },
        subTable: {
            marginBottom: theme.spacing(2),
        },
        table: {
            height: "100%",
            width: "100%",
            overflowX: "visible",
            "& thead": {
                "& td, th": {
                    height: "40px",
                    borderBottomColor: theme.palette.text.secondary,
                    color: theme.palette.grey["500"],
                    fontWeight: 500,
                    fontSize: theme.typography.pxToRem(12),
                },
            },
            "& td, th": {
                position: "relative",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                height: "28px",
                padding: "0 6px 0 0",
                backgroundColor: theme.palette.common.white,
                fontSize: theme.typography.pxToRem(12),
            },
            "& td:last-child, th:last-child": {
                paddingRight: "6px",
                textAlign: "right",
            },
        },
        overflow: {
            overflow: "visible!important",
        },
        inputHighlighted: {
            backgroundColor: theme.palette.grey[100],
        },
        input: {
            fontSize: "inherit",
            width: "6em",
            paddingRight: "0.3em",
            "& input": {
                marginRight: "0.3em",
                textAlign: "right",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                    "-webkit-appearance": "none",
                    margin: 0,
                },
            },
        },
        columnPrice: {
            width: "6em",
        },
        columnProfit: {
            width: "4em",
        },
        syncContainer: {
            textAlign: "right",
            marginTop: "0.5em",
        },
        iconButton: {
            "& svg": {
                fontSize: "1rem",
                fill: theme.palette.grey[500],
            },
        },
        linePrefix: {
            position: "absolute",
            left: "-1em",
            paddingTop: "0.2em",
        }
    })
);

export default useStyles;
