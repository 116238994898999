import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect } from "react";
import { generatePath, Redirect, Route, Router, Switch, useHistory, useParams } from "react-router";
import { IIcon } from "../components/Icon/IconSet";
import DashboardPage from "./DashboardPage/DashboardPage";
import DocumentWrapperPage from "./DocumentWrapperPage/DocumentWrapperPage";
import IconsPage from "./IconsPage/IconsPage";
import MappingManagerPage from "./MappingManagerPage/MappingManagerPage";
import NotFoundPage from "./NotFoundPage/NotFoundPage";
import ProjectNewPage from "./ProjectNewPage/ProjectNewPage";
import ProjectSettingsPage from "./ProjectSettingsPage/ProjectSettingsPage";
import SpecificationPage from "./SpecificationPage/SpecificationPage";
import RootPage from "./RootPage/RootPage";
import UsersPage from "./UsersPage/UsersPage";
import SecuredComponent, { SecurityMode } from "../redux/keyclock/SecuredComponent/SecuredComponent";
import { useGetPath } from "../utils/Menu";
import ProjectDashboardPage from "./ProjectDashboardPage/ProjectDashboardPage";
import useStyles from "./styles";
import HubsPage from "./HubsPage/HubsPage";
import CockpitPage from "./CockpitPage/CockpitPage";
import { loadHubs, setActiveProject, useActiveProject, useProjects } from "../redux/project";
import { useDispatch } from "react-redux";
import { IPageParams } from "./type";
import { ProjectData } from "../components/ProjectSelectorWrapper/type";
import { useLocation } from "react-router-dom";

export interface AppPage {
    title: string;
    path: string;
    exact?: boolean;
    icon: IIcon;
    component?: any;
    pages?: AppPage;
    wip?: boolean;
    inMenu?: boolean;
    children?: AppPage[];
    resource?: string;
    projectResource?: boolean;
    scope?: string;
}

export enum Path {
    ROOT = "/",
    ICONS = "/icons",
    FORGE = "/forge",
    PROJECTS = "/projects",
    DASHBOARD = "/dashboard",
    PROJECT_DASHBOARD = "/:accountUrn/:projectUrn", // Versionsvergleich
    VERSION_COMPARATOR = "/:accountUrn/:projectUrn/version-comparator", // Versionsvergleich
    COST_COMPARATOR = "/:accountUrn/:projectUrn/cost-comparator", // Kostenschätzung
    SPECIFICATIONS = "/:accountUrn/:projectUrn/specifications", // Leistungsverzeichnis
    DOCUMENTS = "/:accountUrn/:projectUrn/documents/:nodeId?",
    DOCUMENTS_WITH_VIEWER = "/:accountUrn/:projectUrn/documents/:nodeId/viewer/:urn/:fileId/:tool?",
    PROJECT_NEW = "/new-project",
    PROJECT_SETTINGS = "/:accountUrn/:projectUrn/project-settings/:tool?",
    MAPPING_MANAGER = "/:accountUrn/:projectUrn/mapping-manager",
    USERS = "/users",
    HUBS = "/hubs",
    COCKPIT = "/cockpit",
}

export const appPages: AppPage[] = [
    // {
    //     title: "Projects",
    //     path: Path.PROJECTS,
    //     exact: true,
    //     icon: "chevron-left",
    //     component: CameraPage,
    //     wip: true,
    //     inMenu: true,
    // },
    {
        title: "Dashboard",
        path: Path.DASHBOARD,
        exact: true,
        icon: "chart-pie",
        component: DashboardPage,
        wip: true,
        inMenu: true,
    },
    {
        title: "Users",
        path: Path.USERS,
        exact: false,
        icon: "person",
        component: UsersPage,
        inMenu: false,
        resource: "users/all",
        scope: "user:view"
    },
    {
        title: "Hubs",
        path: Path.HUBS,
        exact: false,
        icon: "person",
        component: HubsPage,
        inMenu: false,
        resource: "hubs",
        scope: "hub:view"
    },
    {
        title: "Cockpit",
        path: Path.COCKPIT,
        exact: false,
        icon: "maze",
        component: CockpitPage,
        inMenu: false,
        resource: "cockpit",
        scope: "cockpit:view"
    },
    {
        title: "Project Dashboard",
        path: Path.PROJECT_DASHBOARD,
        exact: true,
        icon: "version",
        component: ProjectDashboardPage,
        wip: false,
        inMenu: false,
        projectResource: true,
        scope: "project:view"
    },
    {
        title: "Version comparator",
        path: Path.VERSION_COMPARATOR,
        exact: true,
        icon: "version",
        component: null,
        wip: true,
        inMenu: true,
    },
    {
        title: "Cost comparator",
        path: Path.COST_COMPARATOR,
        exact: true,
        icon: "dollar",
        component: null,
        wip: true,
        inMenu: true,
    },
    {
        title: "Leistungsverzeichnis",
        path: Path.SPECIFICATIONS,
        exact: true,
        icon: "list-border",
        component: SpecificationPage,
        inMenu: true,
        resource: "specifications",
        projectResource: true,
        scope: "specification:view"
    },
    {
        title: "Viewer",
        path: Path.DOCUMENTS_WITH_VIEWER,
        exact: true,
        icon: "file",
        component: DocumentWrapperPage,
        inMenu: false,
        resource: "documents",
        projectResource: true,
        scope: "document:view"
    },
    {
        title: "Documents",
        path: Path.DOCUMENTS,
        exact: true,
        icon: "file",
        component: DocumentWrapperPage,
        inMenu: true,
        resource: "documents",
        projectResource: true,
        scope: "document:view"
    },
    {
        title: "New project",
        path: Path.PROJECT_NEW,
        exact: true,
        icon: "file",
        component: ProjectNewPage,
        inMenu: false,
        resource: "projects",
        scope: "project:create"
    },
    {
        title: "Project settings",
        path: Path.PROJECT_SETTINGS,
        exact: true,
        icon: "file",
        component: ProjectSettingsPage,
        inMenu: false,
        projectResource: true,
        scope: "project:update"
    },
    {
        title: "Mapping manager",
        path: Path.MAPPING_MANAGER,
        exact: true,
        icon: "file",
        component: MappingManagerPage,
        inMenu: false,
        resource: "mapping",
        projectResource: true,
        scope: "mapping:view"
    },
    {
        title: "Icons",
        path: Path.ICONS,
        exact: true,
        icon: "file",
        component: IconsPage,
        inMenu: false,
    },
    {
        title: "Root",
        path: Path.ROOT,
        exact: true,
        icon: "chart-pie",
        component: RootPage,
        wip: true,
        inMenu: false,
    },
];

function PageRoute({ component: Component, appPage, ...rest }) {
    const { getPermission } = useGetPath();
    const classes = useStyles();

    return <Route {...rest} render={(routerProps) => (
        <ProjectRouteHandler>
            <SecuredComponent className={classes.page} permission={getPermission(appPage)} securityMode={SecurityMode.FORBIDDEN}>
                <Component {...routerProps} />
            </SecuredComponent>
        </ProjectRouteHandler>
    )} />;
}

function ProjectRouteHandler({ children }) {
    const dispatch = useDispatch<DispatchAction>();
    const { projectUrn } = useParams<IPageParams>();
    const projects = useProjects();
    const history = useHistory();
    const location = useLocation();
    const activeProject: ProjectData | undefined = useActiveProject();

    useEffect(() => {
        if (!projects) {
            dispatch(loadHubs({
                forceReload: true
            }));
        }
    }, []);

    useEffect(() => {
        if (projects?.length) {
            if(!projectUrn && location.pathname == Path.ROOT) {
                const project = projects[0];
                history.push(
                    generatePath(project.modules.includes("documents") ? Path.DOCUMENTS : Path.PROJECT_DASHBOARD, {
                        accountUrn: project.externalData?.[0]?.accountUrn,
                        projectUrn: project.externalData?.[0]?.projectUrn,
                    })
                );
            }
            else if (activeProject?.externalData?.[0]?.projectUrn != projectUrn) {
                const project = projectUrn ? projects.find(pr => pr?.externalData?.[0]?.projectUrn == projectUrn) : undefined;
                dispatch(setActiveProject({ activeProject: project }));
            }
        }
    }, [projectUrn, projects, activeProject]);

    return <>
        {children}
    </>
}

const PageRouter: React.FC = () => {
    const history = useHistory();

    return (
        <Router history={history}>
                <Switch>
                    {appPages.map((appPage, index) => (
                        <PageRoute key={index} path={appPage.path} exact={appPage.exact} component={appPage.component} appPage={appPage} />
                    ))}
                    <Route path="/" exact>
                        <Redirect to={generatePath(Path.DASHBOARD)} />
                    </Route>
                    <Route component={NotFoundPage} />
                </Switch>
        </Router>
    );
};

export default PageRouter;
