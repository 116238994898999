import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import {
    onMappingPositions,
    setMasterLeafPositions,
    useMappingPositions,
    useMappingPositionsLoading,
} from "../../../redux/mapping";
import * as uft from "../../../utils/Flatten";
import MasterDataTree from "../MasterDataTree/MasterDataTree";
import MasterDataDeleteResponseDialog from "../Dialogs/MasterDataDeleteResponseDialog/MasterDataDeleteResponseDialog";

interface IMasterDataTreeWrapper {
    checked: number[];
    handleChangeMainView: (mainView: boolean) => void;
    handleToggle: (positionID: number, isLeft: boolean) => void;
    navigation: IMappingNavigation;
    search: string;
}

const MasterDataTreeWrapper: React.FC<IMasterDataTreeWrapper> = ({
    checked,
    handleChangeMainView,
    handleToggle,
    navigation,
    search,
}) => {
    const dispatch = useDispatch<DispatchAction>();
    const mappingPositionsLoading: boolean = useMappingPositionsLoading();
    const mappingPositions: IElementsPosition[] = useMappingPositions();

    const [flattenPositions, setFlattenPositions] = useState<IElementsPosition[]>([]);

    useEffect(() => {
        if (mappingPositions?.length !== 0) return;
        dispatch(onMappingPositions({ }));
    }, []);

    useEffect(() => {
        const flatten = uft.flattenPositions(
            mappingPositions,
            true,
            navigation,
            search,
            (node) => node?.childPositions as any,
            (node) => node?.derivedPositions as any,
            (node, parentNode) => {
                return Object.assign(node, parentNode?.positionID && { parentID: parentNode?.positionID, parent: parentNode });
            }
        );

        setFlattenPositions(flatten);
    }, [mappingPositions, navigation, search]);


    useEffect(() => {
        recursivePositions(mappingPositions);
    }, [mappingPositions]);

    const recursivePositions = (positions: IElementsPosition[]) => {
        // all leaf positions for future navigation (previous / next) in detail
        const leafPositions: IElementsPosition[] = [];
        positions?.map((position) => {
            return recursivePosition(position, 0, leafPositions);
        });
        dispatch(setMasterLeafPositions({ leafPositions }));
    };

    const recursivePosition = (position: IElementsPosition, level: number, leafPositions: IElementsPosition[]) => {
        if (!position.isFromEbkpCatalogue || renderLogic(position, navigation, level) && level > 3) {
            leafPositions.push(position);
        }
        if (position?.childPositions) {
            position?.childPositions?.map((position) => recursivePosition(position, level + 1, leafPositions));
        }
    };

    const renderLogic = (position: IElementsPosition, navigation: IMappingNavigation, level: number): boolean => {
        if (!navigation?.showHierarchy && !navigation?.showDerived && level === 3) {
            return true;
        }

        if (navigation?.showHierarchy && !navigation?.showDerived && level < 4) {
            return true;
        }

        if (!navigation?.showHierarchy && navigation?.showDerived && level >= 3) {
            return true;
        }

        return !!(navigation?.showHierarchy && navigation?.showDerived);
    };

    return (
        <>
            <MasterDataTree
                checked={checked}
                handleChangeMainView={handleChangeMainView}
                handleToggle={handleToggle}
                loading={mappingPositionsLoading}
                navigation={navigation}
                positions={flattenPositions}
            />
            <MasterDataDeleteResponseDialog />
        </>
    );
};

export default MasterDataTreeWrapper;
