import React, { useState } from "react";
import { Button, Grid, TextField } from "@material-ui/core";
import clsx from "clsx";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useHubRolesContext } from "./HubRolesContext";
import { ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";

interface IHubRoleAddFormProps {

}
const HubRoleAddForm: React.FC<IHubRoleAddFormProps> = ({ }) => {
    const classes = useStyles();

    const [value, setValue] = useState<string>();

    const intl = useIntl();
    const transAddRole = intl.formatMessage({ ...messages.addRole });

    const { addRole } = useHubRolesContext();

    const handleAdd = () => {
        if (value) {
            addRole({
                name: value,
            }).then(res => {
                setValue("");
            });
        }
    }

    return <>
        <Grid container className={classes.searchBox} spacing={2}>
            <Grid item className={clsx(classes.searchField)}>
                <TextField
                    id="add-members"
                    value={value}
                    variant="outlined"
                    onChange={(event) => {
                        setValue(event.target.value);
                    }}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    className={classes.addBtn}
                    onClick={handleAdd}
                    disabled={!value}
                >
                    {transAddRole}
                </Button>
            </Grid>
        </Grid>
    </>;
}
export default HubRoleAddForm
