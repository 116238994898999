import { defineMessages } from "react-intl";

export default defineMessages({
    addToHub: {
        id: "hubs.components.hubMembers.addToHub",
        defaultMessage: "Add to hub",
    },
    alreadyMember: {
        id: "hubs.components.hubMembers.alreadyMember",
        defaultMessage: "Is in hub",
    },
});
