import React from "react";
import { Box, Button, Card, CardContent, Grid, IconButton, Link, Tooltip, Typography } from "@material-ui/core";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../redux/project";
import { Link as LinkDom } from "react-router-dom";
import { Module, ProjectFile, useGetProjectQuery } from "../../graphql/generated/graphql";
import { getExternalIdFromFile, getFilesOfType, getForgeIdFromProject } from "../Viewer/utils/Project";
import { FileType } from "../Viewer/type";
import useStyles from "./styles";
import { Alert, Skeleton } from "@material-ui/lab";
import ProjectViewer from "../ProjectViewer/ProjectViewer";
import { SplitViewType } from "../../pages/ViewerPage/ViewerPage";
import { Path } from "../../pages/PageRouter";
import { useGetPath } from "../../utils/Menu";
import Icon from "../Icon/Icon";
import bim360Logo from "./../../assets/images/bim-360.png";
import LinkIcon from '@material-ui/icons/Link';
import SettingsIcon from "@material-ui/icons/Settings";
import SecuredComponent from "../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IProjectDashboardProps {
}


const ProjectDashboard: React.FC<IProjectDashboardProps> = ({ }) => {

    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();

    const { data, loading: loadingProjects, error: errorProjects } = useGetProjectQuery({
        variables: {
            code: activeProject?.code,
        },
        skip: !activeProject?.code
    });

    const { getPath } = useGetPath();

    const project = data?.projects?.[0];
    const geometryFiles = getFilesOfType(project, FileType.Geometry);

    const renderGeometryFile = (file: ProjectFile) => {
        return <ProjectViewer projectId={activeProject?.externalData?.[0]?.projectUrn as string} urn={getExternalIdFromFile(file)} splitView={SplitViewType["3D"]} />
    }
    const linkToDocs = () => {
        const projectID = activeProject?.externalData?.[0]?.projectUrn as string;

        if (projectID?.startsWith("b.")) {
            return (
                <Tooltip title={"BIM360 Docs"}>
                    <Link target={"_blank"} href={`https://docs.b360.autodesk.com/projects/${projectID.substring(2)}`}>
                        <IconButton className={classes.bim360}>
                            <LinkIcon />
                        </IconButton>
                    </Link>
                </Tooltip>
            )
        }
        else {
            return null;
        }
    }
    const getMainScope = (module: Module) => {
        return `${module.code?.endsWith('s') ? module.code?.substring(0, module.code?.length-1) : module.code}:view`;
    }

    return (
        loadingProjects ? (
            <Skeleton></Skeleton>
        ) : (
            <Box className={classes.root}>
                <Typography variant="h3">{activeProject?.name}{activeProject?.code ? ` [${activeProject?.code}]` : ""}{linkToDocs()}</Typography>
                <Box className={classes.settings}>
                    <LinkDom to={getPath(Path.PROJECT_SETTINGS)}>
                        <SettingsIcon fontSize="small" />
                    </LinkDom>
                </Box>
                {activeProject?.isInitialized ? (
                    <>
                        <Box className={classes.filesList}>
                            <Typography variant="subtitle1">Geometry file:</Typography>
                            {geometryFiles?.length > 0 ? (
                                geometryFiles?.map(f => (
                                    <Box key={`file-${f.projectFileID}`}>
                                        <Typography variant={"body2"} >{f.name} (V{f.projectFileVersions[0].version})</Typography>
                                        <Box className={classes.viewer}>
                                            {renderGeometryFile(f)}
                                        </Box>
                                    </Box>
                                ))) : (
                                <Alert severity={"warning"}>Project has no mapped geometry file</Alert>
                            )
                            }
                        </Box>
                        <Box className={classes.modules}>
                            <Typography variant="subtitle1">Modules:</Typography>
                            <Grid container spacing={2}>
                                {project?.projectModules?.map(m => (
                                        <Grid item xs={6} sm={3} md={2} key={`module-${m?.module?.code}`}>
                                            <SecuredComponent permission={{resource: m?.module?.code as string, scope: getMainScope(m?.module as Module), isProject: true}}>
                                                <Card>
                                                    <CardContent>
                                                        <Typography variant="h5" component="div">
                                                            {m?.module?.name}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            </SecuredComponent>
                                        </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </>
                ) : (
                    <Box>
                        <Alert severity={"warning"}>Project is not initialized</Alert>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            component={LinkDom}
                            to={getPath(Path.PROJECT_SETTINGS)}
                        >
                            Initialize
                        </Button>
                    </Box>

                )}

            </Box>
        )
    );
}
export default ProjectDashboard;