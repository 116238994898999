import { HubResponse } from "../../packages/Hubs/api/types";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";

export const getEnhancedHubProjects = (hub: HubResponse): ProjectData[] => {
    const projects: ProjectData[] = [];
    hub.projects?.forEach(project => {
        projects.push({
            ...project,
            hub: hub
        })
    });
    return projects;
}
export const flattenProjects = (hubs?: HubResponse[]): ProjectData[] => {
    const projects: ProjectData[] = [];
    hubs?.forEach(hub => {
        projects.push(...getEnhancedHubProjects(hub))
    });
    return projects;
}

export const filterProjects = (search: string, projects?: ProjectData[]): ProjectData[]|undefined => {
    return projects?.filter(pr => `${pr.name} ${pr.code} ${pr.hub.code} ${pr.hub.name}`.toLowerCase().includes(search.toLowerCase()));
};