import { useGlobalDialog } from "@iolabs/wip-dialog";
import { IconButton } from "@material-ui/core";
import React from "react";
import theme from "../../../theme/Theme";
import Icon from "../../Icon/Icon";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { useIntl } from "react-intl";
import messages from "./messages";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { useGetPath } from "../../../utils/Menu";

interface IDocumentMarkupsProps {
    item: Data;
}

const DocumentMarkups: React.FC<IDocumentMarkupsProps> = ({ item }) => {
    const { setGlobalDialogOpen } = useGlobalDialog();

    const { getProjectPermission } = useGetPath();

    // translations
    const intl = useIntl();
    const transTooltipHasMarkups = intl.formatMessage({ ...messages.tooltipHasMarkups });
    const transTooltipNoMarkups = intl.formatMessage({ ...messages.tooltipNoMarkups });

    return (
        <SecuredComponent permission={getProjectPermission("markups", "markup:view")}>
            <NullableTooltip title={item.mark ? transTooltipHasMarkups : transTooltipNoMarkups} placement="top">
                <IconButton aria-label="go to the markups" onClick={() => setGlobalDialogOpen(true)}>
                    <Icon
                        name="pencil-solid"
                        size={18}
                        fill={item.mark ? theme.palette.success.dark : theme.palette.text.secondary}
                    />
                </IconButton>
            </NullableTooltip>
        </SecuredComponent>
    );
};

export default DocumentMarkups;
