import React, { useCallback, useEffect, useState } from "react";
import { ItemField } from "../../../packages/Crudder/CrudModule";
import CrudList from "../../../packages/Crudder/components/CrudList/CrudList";
import { GridApi, GridRowsProp } from "@mui/x-data-grid";
import { deleteProjectMember, updateMember } from "../../../packages/Api/data/projectMembers/client";
import ProjectMemberLevelSwitch from "../ProjectMemberLevelSwitch/ProjectMemberLevelSwitch";
import { useProjectMembersContext } from "../ProjectMembers/ProjectMembersContext";
import { ProjectMemberLevel } from "../../../graphql/generated/graphql";
import ActionCheckboxSwitch from "../../ActionCheckboxSwitch/ActionCheckboxSwitch";
import ProjectMemberRolesSwitch, { isRoleSelected } from "./ProjectMemberRolesSwitch";
import {
    IoLabsBimProjectsApiModelsProjectModuleMemberUpdateRequest as ProjectModuleMemberUpdateRequest,
} from "../../../packages/Api/data/projectMembers/io-labs-bim-projects-api-models-project-module-member-update-request";
import {
    IoLabsCoreDataEnumsProjectMemberLevel
} from "../../../packages/Api/data/projectMembers/io-labs-core-data-enums-project-member-level";
import { Box, Chip } from "@material-ui/core";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import {
    IoLabsBimProjectsApiModelsProjectMember
} from "../../../packages/Api/data/projectMembers/io-labs-bim-projects-api-models-project-member";

interface IProjectMemberListProps {
}


const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50
        },
    },
    {
        name: "firstName",
        title: "First name",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "lastName",
        title: "Last name",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "email",
        title: "Email",
        columnDef: {
            flex: 2
        },
    },
];


const ProjectMembersList: React.FC<IProjectMemberListProps> = ({ }) => {

    const classes = useStyles();

    const [ fieldsDef, setFieldsDef ] = useState<ItemField[]>(fields);

    const [ listData, setListData ] = useState<IoLabsBimProjectsApiModelsProjectMember[]>();

    const { projectID, listResponse, invalidateList } = useProjectMembersContext();

    const refreshData = () => {
        invalidateList();
    }

    const switchAdmin = (memberID: number) => (isAdmin: boolean) => {
        const data: ProjectModuleMemberUpdateRequest = {
            isProjectAdmin: isAdmin,
        }
        updateMemberInternal(memberID, data);
    }

    const updateRolesHandler = (memberID: number, api: GridApi) => (roles: number[]) => {
        const data: ProjectModuleMemberUpdateRequest = {
            templateRolesIDs: roles,
        }
        api.setCellMode(memberID, "roleTemplates", "view");
        updateMemberInternal(memberID, data);
    }

    const updateMemberInternal = (memberID: number, data: ProjectModuleMemberUpdateRequest) => {
        updateMember(projectID as number, memberID, data).then(() => {
            refreshData();
        });
    }

    useEffect(() => {
        if (listResponse) {
            // const normalizedData = listResponse.members?.map((member) => {
            //     return {
            //         ...member,
            //         id: member.userID
            //     }
            // });
            // setData(normalizedData);
            const moduleFields : ItemField[] = listResponse.modules ? listResponse.modules?.map((m) => {
                return {
                    name: m.code,
                    title: m.name,
                    columnDef: {
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <>
                                    <ProjectMemberLevelSwitch
                                        projectID={projectID as number}
                                        moduleID={m.moduleID as number}
                                        memberID={params.id as number}
                                        disabled={params.row.isProjectAdmin}
                                        currentLevel={params.row.isProjectAdmin ? IoLabsCoreDataEnumsProjectMemberLevel.Administrator : params.row.moduleAccess.find(ma => ma.moduleID == m.moduleID)?.memberLevel}
                                        onChange={refreshData}
                                    />
                                </>
                            )
                        }
                    },
                } as ItemField
            }) : [];

            setFieldsDef([
                ...fields,
                {
                    name: "roleTemplates",
                    title: "Roles",
                    columnDef: {
                        flex: 2,
                        type: "singleSelect",
                        editable: true,
                        renderCell: ({id, value, api, field, row}) => {
                            const selected = listResponse.roleTemplates?.filter(o => isRoleSelected(o, value as number[]));
                            return (
                                <NullableTooltip title={`${selected?.map(r => r.name).join(", ")}`}>
                                    <Box className={classes.chipHolder}>
                                        {
                                            selected?.map(o => (
                                                <Chip size="small" key={o.hubRoleID} label={o.name} />
                                            ))
                                        }
                                    </Box>
                                </NullableTooltip>
                            )
                        },
                        renderEditCell: ({id, value, api, field, row}) => {
                            return (
                                <>
                                    <ProjectMemberRolesSwitch
                                        projectID={projectID as number}
                                        memberID={id as number}
                                        disabled={row.isProjectAdmin}
                                        currentRoles={value}
                                        onChange={refreshData}
                                        onChangeDone={updateRolesHandler(id as number, api)}
                                        roleOptions={listResponse.roleTemplates}
                                    />
                                </>
                            )
                        }
                    },
                },
                {
                    name: "isAdmin",
                    title: "Project admin",
                    columnDef: {
                        width: 110,
                        renderCell: (params) => {
                            return (
                                <>
                                    <ActionCheckboxSwitch
                                        checked={params.row.isProjectAdmin}
                                        onChange={switchAdmin(params.id as number)}
                                    />
                                </>
                            )
                        }
                    },
                },
                ...moduleFields
            ]);

            setListData(listResponse.members ? listResponse.members : []);
        }
        else {
            setFieldsDef([]);
            setListData([]);
        }
    }, [listResponse]);


    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteProjectMember(projectID as number, id).then((response) => {
                refreshData();
                resolve();
            });
        })
    }, []);

    return listData ? <CrudList<IoLabsBimProjectsApiModelsProjectMember>
        config={{
            title: "Member list",
            disableAdd: true,
            disableEdit: true,
            getRowID: (projectMember) => projectMember.userID
        }}
        itemFields={fieldsDef}
        data={listData}
        onDelete={handleDelete}
    /> : <></>
};

export default ProjectMembersList;