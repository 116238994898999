import React, { ReactElement, useEffect, useState } from "react";
import { Box, MenuItem } from "@material-ui/core";
import { TextField, Autocomplete } from "mui-rff";
import clsx from "clsx";
import { OnChange } from "react-final-form-listeners";
import useStyles from "./styles";
import { INormalizationFunction } from "./type";
import { useInstancesAttributes, useMappingAttributeNormalizationFunctions } from "../../../redux/mapping";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useField, useForm } from "react-final-form";

interface IKnownAttributeProps {
    name: string;
    useName?: boolean;
    useDataType?: boolean;
    buttons?: ReactElement;
}

const KnownAttribute: React.FC<IKnownAttributeProps> = ({ name, useName, useDataType, buttons}) => {
    const classes = useStyles();
    const { input } = useField(name);
    const form = useForm();

    const normalizationFunctions: INormalizationFunction[] | null = useMappingAttributeNormalizationFunctions();
    const [ normalizationFunctionCode, setNormalizationFunctionCode ] = useState<string | null>(input.value.function);

    const instances = useInstancesAttributes();
    const [ psetData, setPsetData ] = useState<{[key: string]: string[]}|undefined>();

    const [ psets, setPsets ] = useState<string[]>([]);
    const [ attributes, setAttributes ] = useState<string[]>([]);

    const [ selectedPset, setSelectedPset ] = useState<string|undefined>(input.value.propertySet);


    // translations
    const intl = useIntl();
    const transFieldTypeNUnitPerInstance = intl.formatMessage({ ...messages.fieldTypeNUnitPerInstance });
    const transFieldTypeNUnitPerProject = intl.formatMessage({ ...messages.fieldTypeNUnitPerProject });
    const transFieldTypeEqualUnits = intl.formatMessage({ ...messages.fieldTypeEqualUnits });
    const transFieldTypeMapping = intl.formatMessage({ ...messages.fieldTypeMapping });
    const transFieldTypeImplicit = intl.formatMessage({ ...messages.fieldTypeImplicit });
    const transFieldTypeFreeForm = intl.formatMessage({ ...messages.fieldTypeFreeForm });
    const transFieldTypeCaptureFreeForm = intl.formatMessage({ ...messages.fieldTypeCaptureFreeForm });
    const transFieldTypeCapture = intl.formatMessage({ ...messages.fieldTypeCapture });

    const transLabelName = intl.formatMessage({ ...messages.labelName });
    const transInputPlaceholderName = intl.formatMessage({ ...messages.inputPlaceholderName })
    const transLabelPset = intl.formatMessage({ ...messages.labelPset });
    const transInputPlaceholderPset = intl.formatMessage({ ...messages.inputPlaceholderPset });

    const transLabelFunction = intl.formatMessage({ ...messages.labelFunction });
    const transInputPlaceholderFunction = intl.formatMessage({ ...messages.inputPlaceholderFunction });

    const transLabelAttribute = intl.formatMessage({ ...messages.labelAttribute });
    const transInputPlaceholderAttribute = intl.formatMessage({ ...messages.inputPlaceholderAttribute });
    const transLabelParameter = intl.formatMessage({ ...messages.labelParameter });
    const transInputPlaceholderParameter = intl.formatMessage({ ...messages.inputPlaceholderParameter });
    const transLabelDataType = intl.formatMessage({ ...messages.labelDataType });
    const transInputPlaceholderDataType = intl.formatMessage({ ...messages.inputPlaceholderDataType });

    useEffect(() => {
        if(instances?.propertySets) {
            setPsetData(instances?.propertySets);
            setPsets(Object.keys(instances?.propertySets)?.sort((a, b) => a.localeCompare(b)))
        }
    }, [instances]);

    useEffect(() => {
        if (psetData && selectedPset) {
            const a = psetData[selectedPset];
            setAttributes(a ? a.sort((a, b) => a.localeCompare(b)) : [])
        }
    }, [psetData, selectedPset]);


    const hasInput = (normalizationFunctionCode) => {
        if (!normalizationFunctionCode) return true;
        const normalizationFunction : INormalizationFunction|undefined = normalizationFunctions?.find(nf => nf.code == normalizationFunctionCode);
        return normalizationFunction ? normalizationFunction.hasInput : true;
    }

    const hasParam = (normalizationFunctionCode) => {
        if (!normalizationFunctionCode) return true;
        const normalizationFunction : INormalizationFunction|undefined = normalizationFunctions?.find(nf => nf.code == normalizationFunctionCode);
        if (!normalizationFunction) return true;

        return !!normalizationFunction?.parameterDataTypes?.length;
    }

    const translateNormalizationFunction = (nf: INormalizationFunction) => {
        switch (nf.code) {
            case "implicit":
                return transFieldTypeImplicit;
            case "freeForm":
                return transFieldTypeFreeForm;
            case "captureFreeForm":
                return transFieldTypeCaptureFreeForm;
            case "capture":
                return transFieldTypeCapture;
            case "nPiecesPerInstance":
                return transFieldTypeNUnitPerInstance;
            case "nPiecesPerProject":
                return transFieldTypeNUnitPerProject;
            default:
                return nf.name;
        }
    }

    const showParam = hasParam(normalizationFunctionCode);
    const showInput = hasInput(normalizationFunctionCode);

    return <Box className={classes.controlGroup}>
        {useName && (
            <Box className={classes.controlGroupItem}>
                <TextField
                    required={false}
                    name={`${name}.name`}
                    label={transLabelName} // Beschreibung
                    placeholder={transInputPlaceholderName}
                    fullWidth={false}
                    margin="normal"
                    variant="outlined"
                    color="primary"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
        )}

        {useDataType && (
            <Box className={classes.controlGroupItem}>
                <TextField
                    name={`${name}.dataType`}
                    label={transLabelDataType}
                    placeholder={transInputPlaceholderDataType}
                    fullWidth={false}
                    margin="normal"
                    variant="outlined"
                    color="primary"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Box>
        )}

        <Box className={classes.controlGroupItem}>
            <TextField
                required={false}
                name={`${name}.function`}
                label={transLabelFunction}
                placeholder={transInputPlaceholderFunction}
                fullWidth={false}
                margin="normal"
                variant="outlined"
                color="primary"
                className={clsx(classes.textField, {
                    [classes.placeholder]: !normalizationFunctionCode,
                })}
                InputLabelProps={{
                    shrink: true,
                }}
                select
                SelectProps={{
                    displayEmpty: true,
                }}
            >
                {/*<MenuItem value="" disabled>*/}
                {/*    Placeholder*/}
                {/*</MenuItem>*/}
                {normalizationFunctions?.map((nf, index) => (
                    <MenuItem key={`function-${nf.code}`} value={nf.code}>
                        {translateNormalizationFunction(nf)}
                    </MenuItem>
                ))}
            </TextField>
            <OnChange name={`${name}.function`}>
                {(normalization) => {
                    setNormalizationFunctionCode(normalization);
                }}
            </OnChange>
        </Box>

        <Box className={classes.controlGroupItem}>
            {showInput && (
              <>
                  <Autocomplete
                      name={`${name}.propertySet`}
                      freeSolo
                      label={transLabelPset}
                      options={psets}
                      placeholder={transInputPlaceholderPset}
                      textFieldProps={{
                          variant: "outlined",
                          margin: "normal",
                          InputLabelProps: {
                              shrink: true,
                          }

                      }}
                      className={classes.textField}
                      onChange={(event, pset) => {
                          setSelectedPset(pset as string);
                      }}
                      onInputChange={(event, pset) => {
                          form.change(`${name}.propertySet`, pset);
                      }}
                  />
                  <OnChange name={`${name}.propertySet`}>
                      {(pset) => {
                          setSelectedPset(pset);
                      }}
                  </OnChange>
              </>
            )}
        </Box>
        <Box className={classes.controlGroupItem}>
            {showInput && (
                <Autocomplete
                    name={`${name}.attribute`}
                    freeSolo
                    label={transLabelAttribute}
                    options={attributes}
                    placeholder={transInputPlaceholderAttribute}
                    textFieldProps={{
                        variant: "outlined",
                        margin: "normal",
                        InputLabelProps: {
                            shrink: true,
                        }
                    }}
                    onInputChange={(event, attribute) => {
                        form.change(`${name}.attribute`, attribute);
                    }}
                    className={classes.textField}
                />
            )}
        </Box>
        <Box className={classes.controlGroupItem}>
            {showParam && (
                <TextField
                    required={false}
                    name={`${name}.parameter`}
                    label={transLabelParameter}
                    placeholder={transInputPlaceholderParameter}
                    fullWidth={false}
                    margin="normal"
                    variant="outlined"
                    color="primary"
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        </Box>
        {buttons}
    </Box>
}

export default KnownAttribute;