import React from "react";
import { Button, switchClasses } from "@mui/material";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import {
    IoLabsCoreDataEnumsProjectMemberLevel,
    IoLabsCoreDataEnumsProjectMemberLevel as ProjectMemberLevel,
} from "../../../packages/Api/data/projectMembers/io-labs-core-data-enums-project-member-level";
import { updateMember } from "../../../packages/Api/data/projectMembers/client";

export interface IMemberLevelSwitchProps {
    currentLevel: ProjectMemberLevel;
    memberID: number,
    projectID: number,
    moduleID: number,
    onChange?: () => void,
    disabled?: boolean,
}

const ProjectMemberLevelSwitch: React.FC<IMemberLevelSwitchProps> = ({ projectID, memberID, moduleID, currentLevel , onChange, disabled}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (!disabled) {
            setAnchorEl(event.currentTarget);
        }
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (level: ProjectMemberLevel|undefined) => () => {
        handleClose();
        updateMember(projectID, memberID, {
            accessToModules: [
                {
                    memberLevel: level,
                    moduleID: moduleID
                }
            ]
        }).then((res) => {
            if (onChange) {
                onChange()
            }
        });
    }
    const getIcon = () => {
        switch (currentLevel) {
            case ProjectMemberLevel.Member:
                return "Member";
            case ProjectMemberLevel.Administrator:
                return "Admin";
            default:
                return "No access";
        }
        return <>
            {currentLevel ? currentLevel : "No access"}
            {/*<Typography></Typography>*/}
            {/*<Typography>{`M: ${moduleID}`}</Typography>*/}
            {/*<Typography>{`U: ${memberID}`}</Typography>*/}
            {/*<Typography>{`P: ${projectID}`}</Typography>*/}
        </>
    }

    return (
        <>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                disableElevation
                size={"small"}
                onClick={handleClick}
                disabled={disabled}
            >
                {getIcon()}
            </Button>
            {!disabled && (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleChange(undefined)}>No access</MenuItem>
                    <MenuItem onClick={handleChange(ProjectMemberLevel.Member)}>Member</MenuItem>
                    <MenuItem onClick={handleChange(ProjectMemberLevel.Administrator)}>Administrator</MenuItem>
                </Menu>
            )}
        </>
    );
};

export default ProjectMemberLevelSwitch;