import { useSelector } from "react-redux";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { selectActiveProject, selectHubs, selectHubsLoading, selectProjects, selectSpecialInstances } from "./selector";
import { SpecialInstancesResponse } from "../../packages/Api/data/instances/types";
import { HubResponse } from "../../packages/Hubs/api/types";

export const useActiveProject = (): ProjectData | undefined => useSelector(selectActiveProject);
export const useProjects = (): ProjectData[] | undefined => useSelector(selectProjects);
export const useHubs = (): HubResponse[] | undefined => useSelector(selectHubs);
export const useHubsLoading = (): boolean => useSelector(selectHubsLoading);
export const useSpecialInstances = (): SpecialInstancesResponse | undefined => useSelector(selectSpecialInstances);

