import { Box } from "@material-ui/core";
import React from "react";
import Page from "../Page/Page";
import useStyles from "./styles";
import Cockpit from "../../components/Cockpit/Cockpit";

const CockpitPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page noPadding>
            <Box className={classes.columnBox}>
                <Cockpit />
            </Box>
        </Page>
    );
};

export default CockpitPage;
