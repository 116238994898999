import React from "react";
import {
    IoLabsCoreDataEnumsProjectMemberLevel as ProjectMemberLevel,
} from "../../packages/Api/data/projectMembers/io-labs-core-data-enums-project-member-level";
import MenuSwitch from "../MenuSwitch/MenuSwitch";

export interface IMemberLevelSwitchProps {
    currentLevel: ProjectMemberLevel;
    onChange: (level: ProjectMemberLevel|undefined) => void,
    disabled?: boolean,
}

const AccessLevelSwitch: React.FC<IMemberLevelSwitchProps> = ({ currentLevel , onChange, disabled}) => {

    return (
        <MenuSwitch
            onChange={onChange}
            options={[
                {
                    key: undefined,
                    name: 'No access'
                },
                {
                    key: ProjectMemberLevel.Member,
                    name: 'Member'
                },
                {
                    key: ProjectMemberLevel.Administrator,
                    name: 'Administrator'
                }
            ]}
            currentValue={currentLevel}
            noValueText={"No access"}
            disabled={disabled}
        />
    );
};

export default AccessLevelSwitch;