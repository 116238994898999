export interface ScopePermission {
    resource: string,
    scope: string,
    isProject?: boolean,
}

export const createPermission = (resource, scope): ScopePermission => {
    return {
        resource, scope
    }
}

export const Permissions = {
    Projects: {
        create: createPermission("projects", "project:create"),
        update: createPermission("projects", "project:update"),
    },
    Mapping: {
        view: createPermission("mapping", "mapping:view"),
    },
    Users: {
        view: createPermission("users/all", "user:view"),
    },
    Hubs: {
        view: createPermission("hubs", "hub:view"),
    },
    Cockpit: {
        view: createPermission("cockpit", "cockpit:view"),
    }
}