import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { KeycloakEvent } from "@react-keycloak/web";
import { Permission, TokenResponse, UMA2Configuration } from "./api/types";

export enum ActionTypes {
    OnEvent = "keycloak/ON_EVENT",
    OnTokens = "keycloak/ON_TOKENS",
    SetUmaConfiguration = "keycloak/SET_UMA_CONFIGURATION",
    SetUmaToken = "keycloak/SET_UMA_TOKEN",
    SetUmaPermissions = "keycloak/SET_UMA_PERMISSIONS",

    // uma
    OnFetchUma = "keycloak/ON_FETCH_UMA",
}

export type OnEventOptions = {
    event: KeycloakEvent;
};

export type OnSetUmaConfigurationOptions = {
    config: UMA2Configuration;
};

export type SetUmaTokenOptions = {
    token?: TokenResponse;
};

export type SetUmaPermissionsOptions = {
    permissions: Permission[];
};

export type OnFetchUmaOptions = {
    onFetchToken?: (token: TokenResponse) => void;
    onFetchPermissions?: (permissions: Permission[]) => void;
};

export type OnTokensOptions = {
    idToken: string;
    refreshToken: string;
    token: string;
};

export const Actions = {
    onEvent: (options: OnEventOptions): void => createAction(ActionTypes.OnEvent, options),
    onTokens: (options: OnTokensOptions): void => createAction(ActionTypes.OnTokens, options),
    setUmaConfiguration: (options: OnSetUmaConfigurationOptions): void => createAction(ActionTypes.SetUmaConfiguration, options),
    setUmaToken: (options: SetUmaTokenOptions): void => createAction(ActionTypes.SetUmaToken, options),
    setUmaPermissions: (options: SetUmaPermissionsOptions): void => createAction(ActionTypes.SetUmaPermissions, options),
    fetchUma: (options: OnFetchUmaOptions): void => createAction(ActionTypes.OnFetchUma, options),
};

export function onEvent(options: OnEventOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.onEvent(options));
    };
}

export function onTokens(options: OnTokensOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.onTokens(options));
    };
}

export function setUmaConfiguration(options: OnSetUmaConfigurationOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.setUmaConfiguration(options));
    };
}

export function setUmaToken(options: SetUmaTokenOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.setUmaToken(options));
    };
}

export function setUmaPermissions(options: SetUmaPermissionsOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.setUmaPermissions(options));
    };
}

export function fetchUma(options: OnFetchUmaOptions): DispatchAction {
    // @ts-ignore
    return async (dispatch) => {
        dispatch(Actions.fetchUma(options));
    };
}