import { Box } from "@material-ui/core";
import React from "react";
import Page from "../Page/Page";
import useStyles from "./styles";
import UserModule from "../../packages/Users/components/UserModule/UserModule";

const UsersPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page noPadding>
            <Box className={classes.columnBox}>
                <UserModule />
            </Box>
        </Page>
    );
};

export default UsersPage;
