import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            padding: theme.spacing(1),
        },
        paddedBox: {
            padding: theme.spacing(1),
        },
        search: {
            marginTop: 0,
            width: "100%",
            "& input": {
                paddingTop: theme.spacing(1),
                paddingBottom: theme.spacing(1),
                fontSize: "1rem",
            },
            "& .MuiInputAdornment-root": {
                marginTop: "0px!important",
            },
        },
        icon: {
            marginLeft: theme.spacing(1),
            fill: theme.palette.text.primary,
        },
        listRoot: {
            minWidth: "350px",
        },
        listItemTextPrimary: {
            display: "flex",
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
        },
        listItemTextLoading: {
            textAlign: "center",
        },
        helperIcon: {
            paddingLeft: theme.spacing(1),
            "& img": {
                width: theme.spacing(1.8),
                height: theme.spacing(1.8),
                marginBottom: "-3px",
            },
        },
        circularProgress: {
            color: theme.palette.primary.main,
        },
        link: {
            width: "100%",
            color: theme.palette.text.primary,
            textDecoration: "none",
            display: "flex",
            "&:hover": {
                textDecoration: "none",
            },
        },
        avatar: {
            paddingTop: theme.spacing(0.5),
            "& .MuiAvatar-root": {
                fontSize: "0.5rem",
                textAlign: "center",
            },
        },
        hub: {
            display: "flex",
            alignItems: "center",
            background: "none",
        },
        hubLink: {
            padding: 0,
            "& .MuiButton-label": {
                textAlign: "left",
                padding: 0,
                margin: 0,
            },
            "& .MuiCard-root": {
                width: "100%"
            }
        },
        hubContent: {
            flexGrow: 1,
        },
        hubAvatar: {
            // width: "80px",
            padding: theme.spacing(1),
            "& .MuiAvatar-root": {
                fontSize: "0.8rem",
                textAlign: "center",
                width: theme.spacing(7),
                height: theme.spacing(7),
            },
        },
        listCaption: {
            marginLeft: theme.spacing(1),
            marginTop: theme.spacing(1),
            display: "block"
        },
        switchHub: {
            fontSize: "0.7rem",
            marginLeft: "1rem",
            marginTop: "0.1rem",
            textTransform: "uppercase",
        },
        hubAdmin: {
            marginLeft: "1rem",
            marginTop: "0.1rem",
        },
        noRecords: {
            width: "100%",
        },
    })
);

export default useStyles;
