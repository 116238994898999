export enum UrlType {
    PROJECT = "project",
    FOLDER = "folder",
    FILE = "file",
    VERSION = "version",
    HUB = "hub",
}

export interface IParsedUrlInfo {
    urlType?: UrlType,
    projectID?: string,
    fileID?: string,
    versionID?: string,
    hubID?: string,
}

const regexGuid = "[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";

export const parseUrl = (url?: string):IParsedUrlInfo => {
    const urlInfo: IParsedUrlInfo = {};

    const projectIdMatch = url?.match(RegExp(`projects\/(${regexGuid})`));
    if (projectIdMatch && projectIdMatch[1]) {
        urlInfo.projectID = `b.${projectIdMatch[1]}`;
    }

    const itemIdMatch = url?.match(RegExp(`items\/([^\/]+)`));
    if (itemIdMatch && itemIdMatch[1]) {
        urlInfo.fileID = itemIdMatch[1];
    }

    const hubIdMatch = url?.match(RegExp(`(accounts|admin)\/(${regexGuid})`));
    if (hubIdMatch && hubIdMatch[2]) {
        urlInfo.hubID = `b.${hubIdMatch[2]}`;
    }

    return urlInfo;
}