import React, { FC, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import ProjectNewWizardUrlLoader from "./ProjectNewWizardUrlLoader";
import ProjectFormWrapper from "../ProjectFormWrapper/ProjectFormWrapper";
import { IProjectFormValues } from "../ProjectForm/type";
import { CheckProjectResponse, ICreateProjectResponseData } from "../../../packages/Api/data/projects/types";
import { Paper, Step, StepButton, Stepper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { HubResponse } from "../../../packages/Hubs/api/types";

interface IProjectNewWizardProps {
    hub: HubResponse;
    onCreateNew?: (response: ICreateProjectResponseData) => void;
}

enum ProjectNewWizardStep {
    URL_CHECK,
    FORM
}
const ProjectNewWizard: FC<IProjectNewWizardProps> = ({ hub , onCreateNew}) => {

    const classes = useStyles();

    const [ initialFormValues, setInitialFormValues ] = useState<IProjectFormValues>({});
    const [ step, setStep ] = useState<ProjectNewWizardStep>(ProjectNewWizardStep.URL_CHECK);

    // translations
    const intl = useIntl();
    const transStepUrl = intl.formatMessage({ ...messages.stepUrl });
    const transStepForm = intl.formatMessage({ ...messages.stepForm });

    const handleProjectCheckResponse = (response: CheckProjectResponse | undefined) => {
        if (response) {
            setInitialFormValues({
                name: response.name,
                code: response.name?.match(/\b([A-Z])/g)?.join(''),
                accountUrn: response.hubUrn,
                projectUrn: response.projectUrn,
                fileUrn: response.fileUrn,
                hubId: hub.hubID,
            });
            // set step must be after setting form values
            setStep(ProjectNewWizardStep.FORM)
        }
    }

    const handleProjectCreated = (response: ICreateProjectResponseData) => {
        if(onCreateNew) {
            onCreateNew(response)
        }
    }

    const getStepContent = () => {
        switch (step) {
            case ProjectNewWizardStep.URL_CHECK:
                return <ProjectNewWizardUrlLoader onProjectCheckSuccess={handleProjectCheckResponse} />
            case ProjectNewWizardStep.FORM:
                return <ProjectFormWrapper initialValues={initialFormValues} onCreateNew={handleProjectCreated} />
        }
    }

    return (
        <>
            <Typography component="h5" variant="h5" className={classes.pageHeader}>
                <FormattedMessage values={{ hubName: hub.name }} {...messages.newProjectTitle} />
            </Typography>
            <Stepper alternativeLabel activeStep={step} color={"secondary"} >
                <Step key={ProjectNewWizardStep.URL_CHECK}>
                    <StepButton onClick={() => setStep(ProjectNewWizardStep.URL_CHECK)}>{transStepUrl}</StepButton>
                </Step>
                <Step key={ProjectNewWizardStep.FORM}>
                    <StepButton disabled={!initialFormValues} onClick={() => setStep(ProjectNewWizardStep.URL_CHECK)}>{transStepForm}</StepButton>
                </Step>
            </Stepper>

            <Paper className={classes.stepContentPaper}>
                {getStepContent()}
            </Paper>

        </>
    )
}
export default ProjectNewWizard;