import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultMappingPanelSize } from "../../../pages/MappingManagerPage/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            alignItems: "center",
            backgroundColor: theme.palette.primary[200],
            color: theme.palette.text.primary,
            display: "flex",
            flexFlow: "row nowrap",
            minHeight: `${defaultMappingPanelSize}px`,
            height: `${defaultMappingPanelSize}px`,
            justifyContent: "space-between",
            padding: theme.spacing(0, 2),
            overflow: "hidden"
        },
    })
);

export default useStyles;
