import { DispatchAction } from "@iolabs/redux-utils";
import { Box, CircularProgress, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IAttachedMaterial, IElementsPosition, MaterialType } from "../../../packages/Api/data/elements/types";
import {
    onDeleteAttachedMaterial,
    onMaterialCatalogue,
    onUpdateAttachedMaterial,
    useDetailPosition,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { formatNumber } from "../../../utils/Formatter";
import Icon from "../../Icon/Icon";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

export enum UpdatableField {
    kMenge = "kMenge",
    liPrice = "liPrice",
}

interface IAttachedMaterialUpdate extends IAttachedMaterial {
    isUpdateMode?: {
        [key: string]: boolean;
    };
}

interface IStepWorkFormMaterialProps {
    materialType: MaterialType;
    loading: boolean;
    attachedMaterial: IAttachedMaterial[];
}

const StepWorkFormMaterial: React.FC<IStepWorkFormMaterialProps> = ({ loading, attachedMaterial }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const [updateAttachedMaterial, setUpdateAttachedMaterial] = useState<IAttachedMaterialUpdate[]>([]);
    const [updateAttachedMaterialID, setUpdateAttachedMaterialID] = useState<number | null>(null);
    const kMengeRefs = useRef(new WeakMap());
    const liPriceRefs = useRef(new WeakMap());

    const handleToggleUpdateMode = (attachedMaterialID: number, fieldName: UpdatableField) => {
        setUpdateAttachedMaterial((prevState) => {
            return prevState?.map((amu) => {
                return amu?.attachedMaterialID === attachedMaterialID && fieldName === UpdatableField.liPrice
                    ? {
                          ...amu,
                          isUpdateMode: {
                              ...amu?.isUpdateMode,
                              liPrice: !amu?.isUpdateMode?.liPrice,
                          },
                      }
                    : amu?.attachedMaterialID === attachedMaterialID && fieldName === UpdatableField.kMenge
                    ? {
                          ...amu,
                          isUpdateMode: {
                              ...amu?.isUpdateMode,
                              kMenge: !amu?.isUpdateMode?.kMenge,
                          },
                      }
                    : amu;
            });
        });
    };

    const handleChange = (event, row: IAttachedMaterialUpdate) => {
        const value = parseFloat(event.target.value);
        const name = event.target.name;
        const updatedAttachedMaterial = updateAttachedMaterial?.map((amu) => {
            return amu?.attachedMaterialID === row?.attachedMaterialID
                ? name === "liPrice"
                    ? {
                          ...amu,
                          material: {
                              ...amu?.material,
                              [name]: value,
                          },
                      }
                    : {
                          ...amu,
                          [name]: value,
                      }
                : amu;
        });
        setUpdateAttachedMaterial(updatedAttachedMaterial);
    };

    const handleUpdateMaterial = (attachedMaterialID: number, fieldName: UpdatableField) => {
        setUpdateAttachedMaterialID(attachedMaterialID);
        const updatedAttachedMaterial = updateAttachedMaterial?.find(
            (awu) => awu?.attachedMaterialID === attachedMaterialID
        );
        if (!updatedAttachedMaterial) return;

        dispatch(
            onUpdateAttachedMaterial({
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                attachedMaterialID: updatedAttachedMaterial?.attachedMaterialID,
                data: {
                    liPrice: updatedAttachedMaterial?.material?.liPrice as number,
                    kMenge: updatedAttachedMaterial?.kMenge as number,
                },
            })
        );
        handleToggleUpdateMode(updatedAttachedMaterial?.attachedMaterialID, fieldName);
    };

    const handleDelete = (attachedMaterialID: number) => () => {
        if (activeProject && detailPosition) {
            dispatch(
                onDeleteAttachedMaterial({
                    projectID: activeProject?.projectID,
                    positionID: detailPosition?.positionID,
                    attachedMaterialIDs: [attachedMaterialID],
                })
            );
            dispatch(onMaterialCatalogue({ }));
        }
    };

    useEffect(() => {
        setUpdateAttachedMaterial(attachedMaterial as IAttachedMaterialUpdate[]);
        setUpdateAttachedMaterialID(null);
    }, [attachedMaterial]);

    // translations
    const intl = useIntl();
    const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
    const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
    const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
    const transLabelEp = intl.formatMessage({ ...messages.labelEp });
    const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Box>
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
            </Box>
        </Box>
    );

    return (
        <Box>
            {loading && updateAttachedMaterial?.length === 0 ? (
                <>{skeleton}</>
            ) : (
                <>
                    {updateAttachedMaterial?.length === 0 ? (
                        <span className={classes.empty}>Leer</span>
                    ) : (
                        <>
                            {updateAttachedMaterial?.map((amu, index) => (
                                <Box className={classes.fieldBox} key={index}>
                                    <TextField
                                        label={transLabelItemNumber}
                                        value={amu?.material?.itemNumber}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transLabelProductId}
                                        value={amu?.material?.productID}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    {!amu?.isUpdateMode?.kMenge ? (
                                        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                                            <TextField
                                                name="kMenge"
                                                label="K.-Menge" // kMenge // todo translate de
                                                value={amu?.kMenge && formatNumber(amu?.kMenge)}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                style={{
                                                    minWidth: "170px",
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                onClick={() => {
                                                    // focus input on update click
                                                    kMengeRefs?.current?.get(amu)?.focus();

                                                    handleToggleUpdateMode(
                                                        amu?.attachedMaterialID,
                                                        UpdatableField.kMenge
                                                    );
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {loading &&
                                                            updateAttachedMaterialID === amu?.attachedMaterialID ? (
                                                                <CircularProgress
                                                                    size={16}
                                                                    className={classes.circularProgress}
                                                                />
                                                            ) : (
                                                                    <IconButton
                                                                        aria-label="toggle update mode"
                                                                        onClick={() => {
                                                                            // focus input on update click
                                                                            kMengeRefs?.current?.get(amu)?.focus();

                                                                            handleToggleUpdateMode(
                                                                                amu?.attachedMaterialID,
                                                                                UpdatableField.kMenge
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon name="pencil-solid" size={18} />
                                                                    </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />

                                        </SecuredComponent>
                                    ) : (
                                        <TextField
                                            name="kMenge"
                                            label="K.-Menge" // kMenge // todo translate de
                                            value={amu?.kMenge}
                                            type="number"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.textField}
                                            style={{
                                                minWidth: "170px",
                                            }}
                                            inputRef={(input) => kMengeRefs.current.set(amu, input)}
                                            onFocus={(event) => {
                                                if (amu?.isUpdateMode?.kMenge) {
                                                    event.target.select();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (amu?.isUpdateMode?.kMenge) {
                                                    handleChange(event, amu);
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUpdateMaterial(
                                                        amu?.attachedMaterialID,
                                                        UpdatableField.kMenge
                                                    )
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                readOnly: !amu?.isUpdateMode?.kMenge,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {loading &&
                                                        updateAttachedMaterialID === amu?.attachedMaterialID ? (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        ) : (
                                                            <>
                                                                <IconButton
                                                                    aria-label="save changes"
                                                                    onClick={() =>
                                                                        handleUpdateMaterial(
                                                                            amu?.attachedMaterialID,
                                                                            UpdatableField.kMenge
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name="check"
                                                                        size={18}
                                                                        className={classes.iconSave}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="cancel"
                                                                    onClick={() =>
                                                                        handleToggleUpdateMode(
                                                                            amu?.attachedMaterialID,
                                                                            UpdatableField.kMenge
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name="close"
                                                                        size={18}
                                                                        className={classes.iconClose}
                                                                    />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}

                                    {!amu?.isUpdateMode?.liPrice ? (
                                        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                                            <TextField
                                                name="liPrice"
                                                label={transLabelListPrice}
                                                value={amu?.material?.liPrice && formatNumber(amu?.material?.liPrice)}
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                color="primary"
                                                className={classes.textField}
                                                style={{
                                                    minWidth: "170px",
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true,
                                                }}
                                                onClick={() => {
                                                    handleToggleUpdateMode(
                                                        amu?.attachedMaterialID,
                                                        UpdatableField.liPrice
                                                    );

                                                    // focus input on update click
                                                    liPriceRefs?.current?.get(amu)?.focus();
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            {loading &&
                                                            updateAttachedMaterialID === amu?.attachedMaterialID ? (
                                                                <CircularProgress
                                                                    size={16}
                                                                    className={classes.circularProgress}
                                                                />
                                                            ) : (
                                                                <IconButton
                                                                    aria-label="toggle update mode"
                                                                    onClick={() => {
                                                                        handleToggleUpdateMode(
                                                                            amu?.attachedMaterialID,
                                                                            UpdatableField.liPrice
                                                                        );

                                                                        // focus input on update click
                                                                        liPriceRefs?.current?.get(amu)?.focus();
                                                                    }}
                                                                >
                                                                    <Icon name="pencil-solid" size={18} />
                                                                </IconButton>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </SecuredComponent>
                                    ) : (
                                        <TextField
                                            name="liPrice"
                                            label={transLabelListPrice}
                                            value={amu?.material?.liPrice}
                                            type="number"
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.textField}
                                            style={{
                                                minWidth: "170px",
                                            }}
                                            inputRef={(input) => liPriceRefs.current.set(amu, input)}
                                            onFocus={(event) => {
                                                if (amu?.isUpdateMode?.liPrice) {
                                                    event.target.select();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (amu?.isUpdateMode?.liPrice) {
                                                    handleChange(event, amu);
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUpdateMaterial(
                                                        amu?.attachedMaterialID,
                                                        UpdatableField.kMenge
                                                    )
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {loading &&
                                                        updateAttachedMaterialID === amu?.attachedMaterialID ? (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        ) : (
                                                            <>
                                                                <IconButton
                                                                    aria-label="save changes"
                                                                    onClick={() =>
                                                                        handleUpdateMaterial(
                                                                            amu?.attachedMaterialID,
                                                                            UpdatableField.liPrice
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name="check"
                                                                        size={18}
                                                                        className={classes.iconSave}
                                                                    />
                                                                </IconButton>
                                                                <IconButton
                                                                    aria-label="cancel"
                                                                    onClick={() =>
                                                                        handleToggleUpdateMode(
                                                                            amu?.attachedMaterialID,
                                                                            UpdatableField.liPrice
                                                                        )
                                                                    }
                                                                >
                                                                    <Icon
                                                                        name="close"
                                                                        size={18}
                                                                        className={classes.iconClose}
                                                                    />
                                                                </IconButton>
                                                            </>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    <TextField
                                        label={transLabelEp}
                                        value={amu?.material?.ep && formatNumber(amu?.material?.ep)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {loading &&
                                                        updateAttachedMaterialID === amu?.attachedMaterialID && (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        label={transLabelSurcharge}
                                        value={
                                            amu?.material?.surcharge && `${formatNumber(amu?.material?.surcharge)} %`
                                        }
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="EP/Menge" // EP Per Menge // todo translate de
                                        value={amu?.epPerMenge && formatNumber(amu?.epPerMenge)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {loading &&
                                                        updateAttachedMaterialID === amu?.attachedMaterialID && (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box display="flex" alignItems="center">
                                        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                                            <IconButton
                                                aria-label="remove"
                                                color="secondary"
                                                className={classes.removeButton}
                                                onClick={handleDelete(amu?.attachedMaterialID)}
                                            >
                                                <Icon name="minus" size={20} />
                                            </IconButton>
                                        </SecuredComponent>
                                    </Box>
                                </Box>
                            ))}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default StepWorkFormMaterial;
