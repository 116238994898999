import { createAction, DispatchAction } from "@iolabs/redux-utils";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { SpecialInstancesResponse } from "../../packages/Api/data/instances/types";
import { HubResponse } from "../../packages/Hubs/api/types";

export enum ActionTypes {
    SetActiveProject = "project/SET_ACTIVE_PROJECT",
    SetProjects = "project/SET_PROJECTS",
    LoadProjects = "project/LOAD_PROJECTS",

    SetHubs = "project/SET_HUBS",
    LoadHubs = "project/LOAD_HUBS",

    LoadSpecialInstances = "project/LOAD_SPECIAL_INSTANCES",
    LoadSpecialInstancesDone = "project/LOAD_SPECIAL_INSTANCES_DONE",
}

export type SetActiveProjectOptions = {
    activeProject: ProjectData | undefined;
};

export type SetProjectsOptions = {
    projects: ProjectData[];
};

export type LoadProjectsOptions = {
    forceReload?: boolean;
};

export type SetHubsOptions = {
    hubs: HubResponse[];
};

export type LoadHubsOptions = {
    forceReload?: boolean;
};
export type LoadSpecialInstancesOptions = {
    projectID: number;
    forceReload?: boolean;
};

export type LoadSpecialInstancesDoneOptions = {
    response: SpecialInstancesResponse;
};

export const Actions = {
    setActiveProject: (options: SetActiveProjectOptions) => createAction(ActionTypes.SetActiveProject, options),
    setProjects: (options: SetProjectsOptions) => createAction(ActionTypes.SetProjects, options),
    loadProjects: (options: LoadProjectsOptions) => createAction(ActionTypes.LoadProjects, options),
    setHubs: (options: SetHubsOptions) => createAction(ActionTypes.SetHubs, options),
    loadHubs: (options: LoadHubsOptions) => createAction(ActionTypes.LoadHubs, options),
    loadSpecialInstances: (options: LoadSpecialInstancesOptions) => createAction(ActionTypes.LoadSpecialInstances, options),
    loadSpecialInstancesDone: (options: LoadSpecialInstancesDoneOptions) => createAction(ActionTypes.LoadSpecialInstancesDone, options),
};

export function setActiveProject(options: SetActiveProjectOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setActiveProject(options));
    };
}

export function setProjects(options: SetProjectsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setProjects(options));
    };
}
export function loadProjects(options: LoadProjectsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.loadProjects(options));
    };
}
export function setHubs(options: SetHubsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.setHubs(options));
    };
}
export function loadHubs(options: LoadHubsOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.loadHubs(options));
    };
}

export function loadSpecialInstances(options: LoadSpecialInstancesOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.loadSpecialInstances(options));
    };
}

export function loadSpecialInstancesDone(options: LoadSpecialInstancesDoneOptions): DispatchAction {
    return async (dispatch) => {
        dispatch(Actions.loadSpecialInstancesDone(options));
    };
}
