import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IMappingWork, IMappingWorkAddRequest, IMappingWorkUpdateRequest } from "./types";
import fileDownload from "js-file-download";

const workApi = new ApiClient("/Work", config.api.baseUrl);

/**
 * Returns a list of all work.
 */
export const getWorkCatalogue = (): Promise<IMappingWork[]> => {
    return workApi
        .get("/list", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IMappingWork[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Create work into catalogue and return created entity.
 * @param data
 */
export const addWorkCatalogue = (data: IMappingWorkAddRequest): Promise<IMappingWork> => {
    return workApi.post("/add", data).then((response: AxiosResponse<IMappingWork>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update work in catalogue and return updated entity.
 * @param workID
 * @param data
 */
export const updateWorkCatalogue = (
    workID: number,
    data: IMappingWorkUpdateRequest
): Promise<IMappingWork> => {
    return workApi.post(`/${workID}/update`, data).then((response: AxiosResponse<IMappingWork>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Deletes work from catalogue.
 * @param workID
 */
export const deleteWorkCatalogue = (workID: number): Promise<boolean> => {
    return workApi.get(`/${workID}/delete`).then((response: AxiosResponse<boolean>) => {
        return Promise.resolve(response.status === 204);
    });
};

/**
 * Export work, file is downloaded
 */
export const exportWork = (
): Promise<void> => {

    return workApi
        .get(`/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "work.xlsx");
            return;
        });
};

/**
 * Import work, file is uploaded
 * @param data
 */
export const importWork = (
    data: FormData
): Promise<void> => {
    return workApi
        .post(`/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((response) => {
            return Promise.reject(response.data)
        });
};