import { AxiosResponse } from "axios";
import ApiClient from "../../../packages/ApiClient/ApiClient";
import { Permission, TokenResponse, UMA2Configuration } from "./types";

let keycloakApi:ApiClient;


export const initKeycloakApi = (authServerUrl: string, realm: string ) => {
    keycloakApi = new ApiClient(`/realms/${realm}`, authServerUrl as string);
}

/**
 * Get UMA configuration
 */
export const getUmaConfiguration = (): Promise<UMA2Configuration> =>
    keycloakApi
        .get(`/.well-known/uma2-configuration`)
        .then((response: AxiosResponse<UMA2Configuration>) => {
            return Promise.resolve(response.data);
        });

const getUmaTokenRequest = (audience: string, params?: {[key: string]: string}) => {
    const data = new URLSearchParams();
    data.append("grant_type", "urn:ietf:params:oauth:grant-type:uma-ticket");
    data.append("audience", audience);
    if (params) {
        Object.keys(params).forEach(key => {
            data.append(key, params[key]);
        })
    }
    return data;
}

/**
 * Get UMA token
 */
export const getUmaToken = (audience: string, token: string): Promise<TokenResponse> => {
    return keycloakApi
        .post(`/protocol/openid-connect/token`, getUmaTokenRequest(audience), {
            overrideToken: token
        })
        .then((response: AxiosResponse<TokenResponse>) => {
            return Promise.resolve(response.data);
        });
}


/**
 * Get UMA permissions
 */
export const getUmaPermissions = (audience: string, token: string): Promise<Permission[]> =>
    keycloakApi
        .post(`/protocol/openid-connect/token`, getUmaTokenRequest(audience, {
            "response_mode": "permissions",
        }), {
            overrideToken: token
        })
        .then((response: AxiosResponse<Permission[]>) => {
            return Promise.resolve(response.data);
        });
