import React, { useCallback, useEffect, useState } from "react";
import { GridRowsProp } from "@mui/x-data-grid";
import { deleteHubMember, updateMember } from "../../api/client";
import { useHubMembersContext } from "./HubMembersContext";
import { ItemField } from "../../../Crudder/CrudModule";
import CrudList from "../../../Crudder/components/CrudList/CrudList";
import ActionCheckboxSwitch from "../../../../components/ActionCheckboxSwitch/ActionCheckboxSwitch";
import { MenuItem, Select } from "@material-ui/core";
import MenuSwitch from "../../../../components/MenuSwitch/MenuSwitch";
import { HubMember, HubMembersResponse, HubMemberUpdateRequest } from "../../api/types";

interface IProjectMemberListProps {
}


const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50,
            ignore: true,
        },
    },
    {
        name: "firstName",
        title: "First name",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "lastName",
        title: "Last name",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "email",
        title: "Email",
        columnDef: {
            flex: 2
        },
    },
];


const HubMembersList: React.FC<IProjectMemberListProps> = ({ }) => {

    const [ fieldsDef, setFieldsDef ] = useState<ItemField[]>(fields);

    const [ listData, setListData ] = useState<HubMember[]>();

    const { hubID, listResponse, invalidateList } = useHubMembersContext();

    const refreshData = () => {
        invalidateList();
    }

    const createUpdateRoleTemplateHandler = (hubID: number, memberID: number) => (defaultRoleTemplateID?: number) => {
        const data: HubMemberUpdateRequest = {}

        if (defaultRoleTemplateID) {
            data.defaultRoleTemplateID = defaultRoleTemplateID
        }
        else {
            data.clearDefaultRoleTemplate = true
        }
        updateMember(hubID, memberID, data).then(() => {
            refreshData();
        });
    }

    const createUpdateIsAdminHandler = (hubID: number, memberID: number) => (checked?: boolean) => {
        updateMember(hubID, memberID, {
            isHubAdmin: checked
        }).then(() => {
            refreshData();
        });
    }

    useEffect(() => {
        if (listResponse) {
            // const normalizedData = listResponse.members?.map((member) => {
            //     return {
            //         ...member,
            //         id: member.userID
            //     }
            // });
            // setData(normalizedData);

            setFieldsDef([
                ...fields,
                {
                    name: "defaultRoleTemplateID",
                    title: "Default role template",
                    columnDef: {
                        flex: 1,
                        renderCell: (params) => (
                            <MenuSwitch
                                onChange={createUpdateRoleTemplateHandler(hubID as number, params.id as number)}
                                options={listResponse.roleTemplates?.map(r => ({
                                    key: r.hubRoleID,
                                    name: r.name as string
                                }))}
                                currentValue={params.value}
                                noValueText={"+"}
                                noValueOption={{
                                    key: undefined,
                                    name: "No role"
                                }}
                            />
                        )
                    },
                },
                {
                    name: "isAdmin",
                    title: "Hub admin",
                    columnDef: {
                        width: 110,
                        renderCell: (params) => {
                            return (
                                <>
                                    <ActionCheckboxSwitch
                                        checked={params.row.isHubAdmin}
                                        onChange={createUpdateIsAdminHandler(hubID as number, params.id as number)}
                                    />
                                </>
                            )
                        }
                    },
                },
            ]);

            setListData(listResponse.members ? listResponse.members : []);
        }
        else {
            setFieldsDef([]);
            setListData([]);
        }
    }, [listResponse]);


    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteHubMember(hubID as number, id).then((response) => {
                refreshData();
                resolve();
            });
        })
    }, []);

    return listData ? <CrudList<HubMember>
        config={{
            title: "Hub member list",
            disableAdd: true,
            disableEdit: true,
            getRowID: (row) => row.userID
        }}
        itemFields={fieldsDef}
        data={listData}
        onDelete={handleDelete}
    /> : <></>
};

export default HubMembersList;