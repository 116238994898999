import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    IconButton,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    Switch,
    Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import {
    onMappingPositionDetails,
    setDetailContext,
    setDetailPosition,
    useCatalogLeafPositions,
    useDetailContext,
    useDetailPosition,
    useMasterLeafPositions,
} from "../../../redux/mapping";
import { IMappingContext } from "../../../redux/mapping/type";
import Icon from "../../Icon/Icon";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import clsx from "clsx";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";

interface IMappingPanelProps {
    navigation: IMappingNavigation;
    handleChangeNavigation: (isRadio?: boolean) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MappingPanel: React.FC<IMappingPanelProps> = ({ navigation, handleChangeNavigation }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const detailPosition: IElementsPosition | undefined = useDetailPosition();
    const masterLeafPositions: IElementsPosition[] = useMasterLeafPositions();
    const catalogLeafPositions: IElementsPosition[] = useCatalogLeafPositions();
    const detailContext: IMappingContext | undefined = useDetailContext();

    const [activeLeaf, setActiveLeaf] = useState<number>();
    const [disabledNext, setDisabledNext] = useState<boolean>(false);
    const [disabledPrevious, setDisabledPrevious] = useState<boolean>(false);

    const [catalogueSwitchEl, setCatalogueSwitchEl] = useState<null | HTMLElement>(null);
    const [positionSwitchEl, setPositionSwitchEl] = useState<null | HTMLElement>(null);

    // translations
    const intl = useIntl();
    const transSystemCatalogue = intl.formatMessage({ ...messages.systemCatalogue });
    const transProjectCatalogue = intl.formatMessage({ ...messages.projectCatalogue });
    const transLabelMainView = intl.formatMessage({ ...messages.labelMainView });
    const transLabelDetailView = intl.formatMessage({ ...messages.labelDetailView });
    const transLabelShowDerived = intl.formatMessage({ ...messages.labelShowDerived });
    const transLabelShowHierarchy = intl.formatMessage({ ...messages.labelShowHierarchy });

    const getNextLeafIndex = (activeLeafIndex: number): number => {
        const positions = getPositions();
        return activeLeafIndex + 1 > positions?.length - 1
            ? positions?.length - 1
            : activeLeafIndex + 1;
    };

    const getPreviousLeafIndex = (activeLeafIndex: number): number => {
        return activeLeafIndex - 1 < 0 ? 0 : activeLeafIndex - 1;
    };

    const handleDisability = (activeLeafIndex: number) => {
        setDisabledNext(activeLeafIndex === getPositions()?.length - 1);
        setDisabledPrevious(activeLeafIndex === 0);
    };

    const getPrevLeaf = (activeLeafIndex: number): IElementsPosition|undefined => {
        if (disabledPrevious) return undefined;
        const dp: IElementsPosition = detailPosition as IElementsPosition
        if (detailContext === IMappingContext.CATALOG && detailPosition && !isInProjectCatalogue(dp)) {
            for (let i = 0; i < catalogLeafPositions.length; i++) {
                if (catalogLeafPositions[i].isFromEbkpCatalogue && dp.code && dp.code.localeCompare(catalogLeafPositions[i].code as string) < 0 && i > 0) {
                    return catalogLeafPositions[i-1];
                }
            }
        }
        else {
            const previousLeafIndex = getPreviousLeafIndex(activeLeafIndex);
            return getPositions()[previousLeafIndex];
        }
    }

    const getNextLeaf = (activeLeafIndex: number): IElementsPosition|undefined => {
        if (disabledNext) return undefined;
        const dp: IElementsPosition = detailPosition as IElementsPosition
        if (detailContext === IMappingContext.CATALOG && detailPosition && !isInProjectCatalogue(dp)) {
            for (let i = 0; i < catalogLeafPositions.length; i++) {
                if (catalogLeafPositions[i].isFromEbkpCatalogue && dp.code && dp.code.localeCompare(catalogLeafPositions[i].code as string) < 0) {
                    return catalogLeafPositions[i];
                }
            }
        }
        else {
            const nextLeafIndex = getNextLeafIndex(activeLeafIndex);
            return getPositions()[nextLeafIndex];
        }
    }

    const handleClickPositionChange = (position: IElementsPosition|undefined, activeLeafIndex: number) => () => {
        handleNavigateLeaf(position, activeLeafIndex)();
        handleClosePositionSwitch();
    }

    const handleNavigateLeaf = (position: IElementsPosition|undefined, activeLeafIndex: number) => () => {
        handleDisability(activeLeafIndex);
        if (position) {
            dispatch(onMappingPositionDetails({ positionID: position.positionID }));
        }
    };

    const getNavigationTooltipMessage = (position?: IElementsPosition): string => {
        return `${!position ? "" : position.code}`;
    }

    const handleClickCatalogueSwitch = (event: React.MouseEvent<HTMLButtonElement>) => {
        setCatalogueSwitchEl(event.currentTarget);
    };

    const handleCloseCatalogueSwitch = () => {
        setCatalogueSwitchEl(null);
    };

    const handleClickPositionSwitch = (event: React.MouseEvent<HTMLButtonElement>) => {
        setPositionSwitchEl(event.currentTarget);
    };

    const handleClosePositionSwitch = () => {
        setPositionSwitchEl(null);
    };

    const isInProjectCatalogue = (position: IElementsPosition): boolean => {
        return catalogLeafPositions.some(p => p.positionID == position.positionID);
    }

    const setContext = (context: IMappingContext) => {
        dispatch(setDetailContext({context}));
        handleCloseCatalogueSwitch();
    }

    const getPositions = (): IElementsPosition[] => {
        return detailContext === IMappingContext.MASTER ? masterLeafPositions : catalogLeafPositions;
    }

    useEffect(() => {
        if (detailPosition) {
            const activeLeafIndex = getPositions()?.findIndex((mlp) => mlp?.positionID === detailPosition?.positionID);

            handleDisability(activeLeafIndex);
            setActiveLeaf(activeLeafIndex);
        }
    }, [detailPosition, detailContext, masterLeafPositions, catalogLeafPositions]);

    const prevLeaf: IElementsPosition|undefined = activeLeaf != undefined ? getPrevLeaf(activeLeaf) : undefined;
    const nextLeaf: IElementsPosition|undefined = activeLeaf != undefined ? getNextLeaf(activeLeaf) : undefined;

    return (
        <Box className={classes.box}>
            <RadioGroup
                row
                aria-label="view"
                name="mainView"
                value={navigation?.mainView}
                onChange={handleChangeNavigation(true)}
                classes={{
                    root: classes.radioGroup,
                }}
            >
                <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label={transLabelMainView}
                    classes={{
                        root: classes.formControlRadioRoot,
                        label: classes.formControlLabel,
                    }}
                    className={navigation?.mainView ? "active" : ""}
                />
                <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label={transLabelDetailView}
                    classes={{
                        root: classes.formControlRadioRoot,
                        label: classes.formControlLabel,
                    }}
                    className={!navigation?.mainView ? "active" : ""}
                    disabled={!detailPosition}
                />
            </RadioGroup>

            {navigation?.mainView ? (
                <FormGroup
                    row
                    classes={{
                        root: classes.switchGroup,
                    }}
                >
                    <FormControlLabel
                        control={
                            <Switch
                                name="showDerived"
                                size="small"
                                checked={navigation?.showDerived}
                                onChange={handleChangeNavigation()}
                            />
                        }
                        label={transLabelShowDerived}
                        classes={{
                            root: classes.formControlSwitchRoot,
                            label: classes.formControlLabel,
                        }}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                name="showHierarchy"
                                size="small"
                                checked={navigation?.showHierarchy}
                                onChange={handleChangeNavigation()}
                            />
                        }
                        label={transLabelShowHierarchy}
                        classes={{
                            root: classes.formControlSwitchRoot,
                            label: classes.formControlLabel,
                        }}
                    />
                </FormGroup>
            ) : (
                <Box display="flex">
                    <Box mr={1}>
                        <Button onClick={handleClickCatalogueSwitch}>
                            <Typography variant="body2">
                                {detailContext === IMappingContext.MASTER ? transSystemCatalogue : transProjectCatalogue}
                            </Typography>
                        </Button>
                        <Menu
                            id="catalog-switch-menu"
                            anchorEl={catalogueSwitchEl}
                            // keepMounted
                            open={Boolean(catalogueSwitchEl)}
                            onClose={handleCloseCatalogueSwitch}
                        >
                            <MenuItem selected={detailContext === IMappingContext.MASTER} onClick={() => setContext(IMappingContext.MASTER)}>{transSystemCatalogue}</MenuItem>
                            <MenuItem selected={detailContext === IMappingContext.CATALOG} onClick={() => setContext(IMappingContext.CATALOG)}>{transProjectCatalogue}</MenuItem>
                        </Menu>
                    </Box>
                    {activeLeaf !== undefined && (
                        <>
                            <NullableTooltip title={getNavigationTooltipMessage(prevLeaf)}>
                                <IconButton
                                    aria-label="previous"
                                    size="small"
                                    onClick={handleNavigateLeaf(prevLeaf, activeLeaf)}
                                    disabled={disabledPrevious}
                                >
                                    <Icon name="chevron-light-left" size={16} />
                                </IconButton>
                            </NullableTooltip>
                            <Button onClick={handleClickPositionSwitch} className={clsx({[classes.nonCataloguePosition] : detailContext === IMappingContext.CATALOG && detailPosition && !isInProjectCatalogue(detailPosition as IElementsPosition)})}>
                                <Typography variant="body2">
                                    <span className={classes.detailPositionCode}>{detailPosition?.code}</span>
                                </Typography>
                            </Button>
                            <Menu
                                id="position-switch-menu"
                                anchorEl={positionSwitchEl}
                                // keepMounted
                                open={Boolean(positionSwitchEl)}
                                onClose={handleClosePositionSwitch}
                            >
                                {getPositions().map(p => {
                                    return <MenuItem
                                        key={`pos-${p.positionID}`}
                                        selected={p.positionID === detailPosition?.positionID}
                                        onClick={handleClickPositionChange(p, activeLeaf)}
                                    >
                                        {p.code}
                                    </MenuItem>
                                })}
                            </Menu>
                            <NullableTooltip title={getNavigationTooltipMessage(nextLeaf)}>
                                <IconButton
                                    aria-label="next"
                                    size="small"
                                    onClick={handleNavigateLeaf(nextLeaf, activeLeaf)}
                                    disabled={disabledNext}
                                >
                                    <Icon name="chevron-light-right" size={16} />
                                </IconButton>
                            </NullableTooltip>
                        </>
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MappingPanel;