import { defineMessages } from "react-intl";

export default defineMessages({
    header: {
        id: "hubs.components.hubTools.header",
        defaultMessage: "Hub tools",
    },
    buttonSynchronizeResources: {
        id: "components.hubTools.synchronizeResources",
        defaultMessage: "Synchronize resources",
    },
    buttonInitializeProjects: {
        id: "components.hubTools.buttonInitializeProjects",
        defaultMessage: "Inititalize projects",
    },
});
