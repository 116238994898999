import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { ErpSyncRequest } from "./types";

const erpApi = new ApiClient("/Erp", config.api.baseUrl);


/**
 * Sync erp data
 * @param deleteAllVoidedRelations
 */
export const syncErpData = (deleteAllVoidedRelations?: boolean): Promise<any> => { // todo type definitions for response
    const data: ErpSyncRequest = {
    }
    if (deleteAllVoidedRelations !== undefined) {
        data.deleteAllVoidedRelations = deleteAllVoidedRelations;
    }
    return erpApi
        .post(`/Sync`, data)
        .then((response: AxiosResponse<any>) => {
            return Promise.resolve(response.data);
        });
};
