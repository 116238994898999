import { CategoryReport } from "../../../graphql/generated/graphql";
import React, { useEffect, useState } from "react";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Typography } from "@material-ui/core";
import ManualPositionQuantityForm from "../../Mapping/StepElement/ManualPositionQuantityForm";
import { IElementsPosition, IManualPosition } from "../../../packages/Api/data/elements/types";
import { getManualPosition } from "../../../packages/Api/data/elements/client";
import { Skeleton } from "@material-ui/lab";
import FixedQuantityForm from "../../Mapping/StepElementCommon/FixedQuantityForm";

interface IDialogPriceManualDataProps {
    category: CategoryReport;
    position: IElementsPosition;
}

const DialogPriceManualData: React.FC<IDialogPriceManualDataProps> = ({ category, position }) => {

    const activeProject: ProjectData | undefined = useActiveProject();
    const intl = useIntl();


    const [ manualPosition, setManualPosition ] = useState<IManualPosition>();

    const transManual = intl.formatMessage({ ...messages.manual });


    useEffect(() => {
        if (category && activeProject) {
            getManualPosition(category.elementCategory?.elementCategoryID as number)
                .then(data => {
                    setManualPosition(data);
                });
        }
    }, [category, activeProject]);

    return (
        <>
            <Typography variant={"subtitle1"}>{transManual}</Typography>
            { manualPosition ? (
                <>
                    <ManualPositionQuantityForm position={position} manualPosition={manualPosition} disableSubmit={true} />
                    <FixedQuantityForm position={position} />
                </>
            ) : (
                <Skeleton />
            )}

        </>
    );
};

export default DialogPriceManualData;
