import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { addMemberToHub, addRoleToHub, getHubMembers, getHubRoles } from "../../api/client";
import { HubMembersResponse, HubRoleRequest, HubRolesResponse } from "../../api/types";
import { MemberLead } from "../../../Api/data/projectMembers/member-lead";

export interface IHubRolesContext {
    hubID?: number;
    listResponse?: HubRolesResponse;
    invalidateList: () => void
    addRole: (value: HubRoleRequest) => Promise<void>
}


export const HubRolesContext = createContext<IHubRolesContext>({
    invalidateList: () => {},
    addRole: (value: HubRoleRequest) => new Promise<void>((resolve => {}))
});


export const useHubRolesContext = () => {
    const { hubID, listResponse, invalidateList, addRole } = useContext<IHubRolesContext>(HubRolesContext)

    return {
        hubID, listResponse, invalidateList, addRole
    }
}


interface IHubRolesProviderProps {
    hubID?: number
}

const HubRolesProvider: React.FC<PropsWithChildren<IHubRolesProviderProps>> = ({ children , hubID}) => {
    const [ state, setState ] = useState<number>(0);

    const [ listResponse, setListResponse ] = useState<HubRolesResponse|undefined>();


    const invalidateList = () => {
        setState((s) => s+1);
    }

    useEffect(() => {
        if (hubID) {
            getHubRoles(hubID, true).then((data) => {
                setListResponse(data);
            })
        }
    }, [hubID, state]);

    const addRole = (value: HubRoleRequest) => {
        return new Promise<void>((resolve, reject) => {
            if (hubID) {
                addRoleToHub(hubID, value)
                    .then(res => {
                        resolve()
                        invalidateList();
                    })
            }
            else {
                reject();
            }

        })

    }

    const value = { listResponse, hubID: hubID, invalidateList, addRole };

    return <HubRolesContext.Provider value={value}>{children}</HubRolesContext.Provider>
}
export default HubRolesProvider
