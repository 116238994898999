import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { ICreateMarkupRequest, IObjectMarkup } from "./types";

const forgeApi = new ApiClient("/Markups", config.api.baseUrl);

export const getMarkups = (objectID: string): Promise<IObjectMarkup[]> => {
    const url = `/${encodeURIComponent(objectID)}`;

    return forgeApi.get(url).then((response: AxiosResponse<IObjectMarkup[]>) => {
        return Promise.resolve(response.data);
    });
};

export const saveMarkup = (
    objectID: string,
    markupRequest: ICreateMarkupRequest
): Promise<IObjectMarkup> => {
    const url = `/${encodeURIComponent(objectID)}`;

    return forgeApi.post(url, markupRequest).then((response: AxiosResponse<IObjectMarkup>) => {
        return Promise.resolve(response.data);
    });
};
