import { defineMessages } from "react-intl";

export default defineMessages({
    heading: {
        id: "components.header.heading",
        defaultMessage: "Pilotprojekt",
    },
    signOut: {
        id: "components.header.signOut",
        defaultMessage: "Sign out",
    },
    profile: {
        id: "components.header.profile",
        defaultMessage: "Profile",
    },
});
