import React from "react";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import ActionButton from "../../../../components/ActionButton/ActionButton";
import { ICrudDetailLoadedProps, ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";
import { inititalizeProjects, syncHubResources } from "../../api/client";
import { HubResponse } from "../../api/types";

interface IHubToolsProps extends ICrudDetailLoadedProps<HubResponse> {
}

const HubTools: React.FC<IHubToolsProps> = ({ data }) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transHeader = intl.formatMessage({ ...messages.header });
    const transButtonSynchronizeResources = intl.formatMessage({ ...messages.buttonSynchronizeResources });
    const transButtonInitializeProjects = intl.formatMessage({ ...messages.buttonInitializeProjects });

    const handleSyncResources = async () => {
        return new Promise((resolve, reject) => {
            syncHubResources(data.hubID as number).then(resolve).catch(reject);
        });
    };

    const handleInitializeProjects = () => {
        return new Promise((resolve, reject) => {
            inititalizeProjects(data.hubID as number).then(resolve).catch(reject);
        });
    };

    return <>
        <Box className={classes.boxSync}>
            <Typography variant={"h2"}>{transHeader}</Typography>
            <ActionButton header={transButtonSynchronizeResources} action={handleSyncResources} />
            <ActionButton header={transButtonInitializeProjects} action={handleInitializeProjects} />
        </Box>
    </>;
};

export default HubTools;