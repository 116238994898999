import { AxiosResponse } from "axios";
import fileDownload from 'js-file-download';
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork,
    ICreateElementsPosition, IDeletePositionsResponse,
    IDerivedPosition,
    IElementsPosition,
    IManualPosition,
    IPositionFormConfiguration, IUpdateAttachedEquipmentRequestData,
    IUpdateAttachedMaterialRequestData,
    IUpdateAttachedWorkRequestData, IUpdateElementPositionFull,
    IUpdateManualPositionRequestData,
    IUpdatePositionBasicsRequestData,
    MaterialType,
} from "./types";
import { IGraphics } from "../graphics/types";

const elementsApi = new ApiClient("/Elements", config.api.baseUrl);

/**
 * Returns a list of position, global or project specific.
 */
export const getElementsPositions = (): Promise<IElementsPosition[]> => {
    return elementsApi
        .get("/positions", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IElementsPosition[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Get position detail
 */
export const getPosition = (positionID: number): Promise<IElementsPosition> => {
    return elementsApi
        .get(`/position/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IElementsPosition>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Returns flat list of positions IDs for specific project.
 * @param projectID
 */
export const getProjectElementsPositions = (projectID: string): Promise<number[]> => {
    return elementsApi
        .get(`/positions/${projectID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<number[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Create new elements position.
 * @param data
 */
export const createElementsPosition = (data: ICreateElementsPosition): Promise<IElementsPosition[]> => {
    return elementsApi.post(`/positions/create`, data).then((response: AxiosResponse<IElementsPosition[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update elements position.
 * @param data
 */
export const updateElementPositionFull = (data: IUpdateElementPositionFull): Promise<IElementsPosition> => {
    return elementsApi.post(`/positions/update`, data).then((response: AxiosResponse<IElementsPosition>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Delete positions from catalogue.
 * @param positionIDs
 */
export const deleteElementsPosition = (positionIDs: number[]): Promise<IDeletePositionsResponse> => {
    return elementsApi.post(`/positions/delete`, positionIDs).then((response: AxiosResponse<IDeletePositionsResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Assign position to project.
 * @param projectID
 * @param positionIDs
 */
export const addProjectElementsPositions = (
    projectID: string,
    positionIDs: number[]
): Promise<number[]> => {
    return elementsApi.post(`/positions/${projectID}/add`, positionIDs).then((response: AxiosResponse<number[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Delete position from project.
 * @param projectID
 * @param positionIDs
 */
export const deleteProjectElementsPositions = (
    projectID: string,
    positionIDs: number[]
): Promise<number[]> => {
    return elementsApi.post(`/positions/${projectID}/delete`, positionIDs).then((response: AxiosResponse<number[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * This action will detect the master file and latest version.
 * @param projectID
 */
export const extractProjectElements = (projectID: number): Promise<void> => {
    return elementsApi.get(`/extract/project/${projectID}`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Return list of derived positions.
 * @param projectID
 * @param positionID
 */
export const getDerivedPositions = (
    projectID: number,
    positionID: number
): Promise<IDerivedPosition[]> => {
    return elementsApi
        .get(`/derived/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IDerivedPosition[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Return list of basic positions.
 * @param projectID
 * @param positionID
 */
export const getBasicPositions = (
    projectID: number,
    positionID: number,
): Promise<IDerivedPosition[]> => {
    return elementsApi
        .get(`/basic/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IDerivedPosition[]>) => {
            return Promise.resolve(response.data);
        });
};


/**
 * Add element category as derived.
 * @param projectID
 * @param positionID
 * @param positionIDs
 * @param revereseMode
 */
export const addDerivedPositions = (
    projectID: number,
    positionID: number,
    positionIDs: number[],
    reverseMode: boolean,
): Promise<IDerivedPosition[]> => {
    return elementsApi
        .post(`/derived/${projectID}/${positionID}/add?reverseMode=${reverseMode?'true':'false'}`, positionIDs)
        .then((response: AxiosResponse<IDerivedPosition[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Update derived element category.
 * @param projectID
 * @param positionID
 * @param positionIDs
 */
export const updateDerivedPosition = (
    derivedPositionID: number,
    derivedPosition: IDerivedPosition
): Promise<IDerivedPosition> => {
    return elementsApi
        .post(`/derived/${derivedPositionID}/update`, derivedPosition)
        .then((response: AxiosResponse<IDerivedPosition>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Delete derived positions.
 * @param projectID
 * @param positionID
 * @param derivedPositionIDs
 */
export const deleteDerivedPositions = (
    derivedPositionIDs: number[]
): Promise<boolean> => {
    return elementsApi
        .post(`/derived/delete`, derivedPositionIDs)
        .then((response: AxiosResponse<boolean>) => {
            return Promise.resolve(response.status === 200 || response.status === 204);
        });
};

/**
 * Get attached work.
 * @param projectID
 * @param positionID
 */
export const getAttachedWork = (projectID: number, positionID: number): Promise<IAttachedWork[]> => {
    return elementsApi
        .get(`/work/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IAttachedWork[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Get attached graphics.
 * @param projectID
 * @param positionID
 */
export const getAttachedGraphics = (projectID: number, positionID: number): Promise<IGraphics> => {
    return elementsApi
        .get(`/graphics/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IGraphics>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Delete work attachment.
 * @param projectID
 * @param positionID
 * @param attachedWorkIDs
 */
export const deleteAttachedWork = (
    projectID: number,
    positionID: number,
    attachedWorkIDs: number[]
): Promise<boolean> => {
    return elementsApi
        .post(`/work/${projectID}/${positionID}/delete`, attachedWorkIDs)
        .then((response: AxiosResponse<boolean>) => {
            return Promise.resolve(response.status === 204);
        });
};

/**
 * Add work to position.
 * @param projectID
 * @param positionID
 * @param workIDs
 */
export const addWorkToPosition = (
    projectID: number,
    positionID: number,
    workIDs: number[]
): Promise<IAttachedWork[]> => {
    return elementsApi
        .post(`/work/${projectID}/${positionID}/add`, workIDs)
        .then((response: AxiosResponse<IAttachedWork[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Add graphics to position.
 * @param projectID
 * @param positionID
 * @param graphicsID
 */
export const addGraphicsToPosition = (
    projectID: number,
    positionID: number,
    graphicsID: number
): Promise<IGraphics> => {
    return elementsApi
        .post(`/graphics/${projectID}/${positionID}/add/${graphicsID}`)
        .then((response: AxiosResponse<IGraphics>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Get attached equipment.
 * @param projectID
 * @param positionID
 */
export const getAttachedEquipment = (projectID: number, positionID: number): Promise<IAttachedEquipment[]> => {
    return elementsApi
        .get(`/equipment/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IAttachedEquipment[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Delete equipment attachment.
 * @param projectID
 * @param positionID
 * @param attachedEquipmentIDs
 */
export const deleteAttachedEquipment = (
    projectID: number,
    positionID: number,
    attachedEquipmentIDs: number[]
): Promise<boolean> => {
    return elementsApi
        .post(`/equipment/${projectID}/${positionID}/delete`, attachedEquipmentIDs)
        .then((response: AxiosResponse<boolean>) => {
            return Promise.resolve(response.status === 204);
        });
};

/**
 * Add equipment to position.
 * @param projectID
 * @param positionID
 * @param equipmentIDs
 */
export const addEquipmentToPosition = (
    projectID: number,
    positionID: number,
    equipmentIDs: number[]
): Promise<IAttachedEquipment[]> => {
    return elementsApi
        .post(`/equipment/${projectID}/${positionID}/add`, equipmentIDs)
        .then((response: AxiosResponse<IAttachedEquipment[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Update attached equipment and return updated entity.
 * @param attachedEquipmentID
 * @param data
 */
export const updateAttachedEquipment = (
    attachedEquipmentID: number,
    data: IUpdateAttachedEquipmentRequestData
): Promise<IAttachedEquipment> => {
    return elementsApi.post(`/equipment/${attachedEquipmentID}/update`, data).then((response: AxiosResponse<IAttachedEquipment>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get attached material.
 * @param projectID
 * @param positionID
 */
export const getAttachedMaterial = (
    projectID: number,
    positionID: number
): Promise<IAttachedMaterial[]> => {
    return elementsApi
        .get(`/material/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IAttachedMaterial[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Delete material attachment.
 * @param projectID
 * @param positionID
 * @param attachedMaterialIDs
 */
export const deleteAttachedMaterial = (
    projectID: number,
    positionID: number,
    attachedMaterialIDs: number[]
): Promise<boolean> => {
    return elementsApi
        .post(`/material/${projectID}/${positionID}/delete`, attachedMaterialIDs)
        .then((response: AxiosResponse<boolean>) => {
            return Promise.resolve(response.status === 204);
        });
};

/**
 * Add material to position.
 * @param projectID
 * @param positionID
 * @param materialType
 * @param materialIDs
 */
export const addMaterialToPosition = (
    projectID: number,
    positionID: number,
    materialType: MaterialType,
    materialIDs: number[]
): Promise<IAttachedMaterial[]> => {
    return elementsApi
        .post(`/material/${projectID}/${positionID}/add/${materialType}`, materialIDs)
        .then((response: AxiosResponse<IAttachedMaterial[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Get unit price for position.
 * @param projectID
 * @param positionID
 */
export const getUnitPrice = (projectID: number, positionID: number): Promise<number> => {
    return elementsApi
        .get(`/unitprice/${projectID}/${positionID}`, {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<number>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Update attached material and return updated entity.
 * @param attachedMaterialID
 * @param data
 */
export const updateAttachedMaterial = (
    attachedMaterialID: number,
    data: IUpdateAttachedMaterialRequestData
): Promise<IAttachedMaterial> => {
    return elementsApi
        .post(`/material/${attachedMaterialID}/update`, data)
        .then((response: AxiosResponse<IAttachedMaterial>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Update attached work and return updated entity.
 * @param attachedWorkID
 * @param data
 */
export const updateAttachedWork = (
    attachedWorkID: number,
    data: IUpdateAttachedWorkRequestData
): Promise<IAttachedWork> => {
    return elementsApi.post(`/work/${attachedWorkID}/update`, data).then((response: AxiosResponse<IAttachedWork>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update position basics - returns updated position.
 * @param projectID
 * @param positionID
 * @param data
 */
export const updatePositionBasics = (
    projectID: number,
    positionID: number,
    data: IUpdatePositionBasicsRequestData
): Promise<IElementsPosition> => {
    return elementsApi
        .post(`/positions/${projectID}/${positionID}/update`, data)
        .then((response: AxiosResponse<IElementsPosition>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Update manual position - returns updated manual position data.
 * @param projectID
 * @param positionID
 * @param data
 */
export const updateManualPosition = (
    projectID: number,
    positionID: number,
    data: IUpdateManualPositionRequestData
): Promise<IManualPosition> => {
    return elementsApi
        .post(`/positions/${projectID}/${positionID}/manual`, data)
        .then((response: AxiosResponse<IManualPosition>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Get manual position - returns manual position data.
 * @param positionID
 */
export const getManualPosition = (positionID: number): Promise<IManualPosition> => {
    return elementsApi.get(`/positions/${positionID}/manual`, {
        clearCacheEntry: true
    }).then((response: AxiosResponse<IManualPosition>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get position form configuration - returns form configuration.
 */
export const getPositionFormConfiguration = (
): Promise<IPositionFormConfiguration> => {
    return elementsApi
        .get(`/positions/form`)
        .then((response: AxiosResponse<IPositionFormConfiguration>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Export positions, file is downloaded
 */
export const exportPositions = (
): Promise<void> => {
    return elementsApi
        .get(`/positions/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "positions.xlsx");
            return;
        });
};

/**
 * Import positions, file is uploaded
 * @param data
 */
export const importPositions = (
    data: FormData
): Promise<void> => {
    return elementsApi
        .post(`/positions/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((exception) => {
            return Promise.reject(exception.response.data);
        });
};