import React from "react";
import { Box, IconButton, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import KnownAttribute from "./KnownAttribute";
import { FieldArray } from "react-final-form-arrays";
import clsx from "clsx";
import Icon from "../../Icon/Icon";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IStepAttributeAdditionalProps {
    values: any,
    push: any,
}

const StepAttributeAdditional: React.FC<IStepAttributeAdditionalProps> = ({ values, push }) => {
    const classes = useStyles();

    return (
        <Box mt={4}>
            <Paper className={classes.paper}>
                <Typography variant="body2" className={classes.fieldset}>
                    Element attributes
                </Typography>
                <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                    <>
                        <FieldArray name="additionalAttributes">
                            {({ fields }) =>
                                fields.map((name, index) => (
                                    <KnownAttribute
                                        name={name}
                                        useName={true}
                                        useDataType={true}
                                        key={`knownAttribute-${index}`}
                                        buttons={(
                                            <Box display="flex" alignItems="center">
                                                <IconButton
                                                    aria-label="remove"
                                                    color="secondary"
                                                    // style={{
                                                    //     visibility: index > 0 ? "visible" : "hidden",
                                                    // }}
                                                    onClick={() => fields.remove(index)}
                                                    className={clsx("globalRemoveButton", classes.removeButton)}
                                                >
                                                    <Icon name="minus" size={20} />
                                                </IconButton>
                                            </Box>
                                        )}
                                    />
                                ))
                            }
                        </FieldArray>
                        <IconButton
                            aria-label="remove"
                            color="primary"
                            onClick={() => push("additionalAttributes", undefined)}
                            className={clsx("globalAddButton", classes.addButton)}
                        >
                            <Icon name="plus" size={20} />
                        </IconButton>
                    </>
                </SecuredComponent>
            </Paper>
        </Box>
    );
};

export default StepAttributeAdditional;