import { addNotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import React, { useState } from "react";
import Icon from "../Icon/Icon";
import { Dialog, IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import useStyles from "./styles";
import ImportDialog from "./ImportDialog";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import NullableTooltip from "../NullableTooltip/NullableTooltip";
import { useGetPath } from "../../utils/Menu";
import { ScopePermission } from "../../redux/keyclock/SecuredComponent/permissions";
import SecuredComponent from "../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IExporterImporterProps {
    fileName: string;
    exportLabel: string;
    importLabel: string;
    exportCallback: (data?: any) => void;
    importCallback: (data: FormData) => Promise<void>;
    onImportDoneCallback?: (response: any) => void;
    exportPermission?: ScopePermission|null;
    importPermission?: ScopePermission|null;
    disableImport?: boolean;
}

const ExporterImporterProps: React.FC<IExporterImporterProps> = ({
    fileName,
    exportLabel,
    importLabel,
    exportCallback,
    importCallback,
    onImportDoneCallback,
    exportPermission,
    importPermission,
    disableImport
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch<DispatchAction>();

    const [importDialogOpen, setImportDialogOpen] = useState<boolean>(false);

    const handleImportDialogClick = (event) => {
        setImportDialogOpen(true);
    };

    const handleImportDialogClose = () => {
        setImportDialogOpen(false);
    };

    const intl = useIntl();
    const transExportStarted = intl.formatMessage({ ...messages.exportStarted });

    const handleExport = () => {
        dispatch(addNotification({
            notification: {
                variant: "success",
                message: transExportStarted,
            }}));
        exportCallback()
    }

    return (
        <>
            <SecuredComponent inline permission={importPermission}>
                <NullableTooltip title={importLabel}>
                    <IconButton aria-label="" className={classes.iconButton} onClick={handleImportDialogClick} disabled={disableImport}>
                        <Icon name="file-upload" size={20} />
                    </IconButton>
                </NullableTooltip>
            </SecuredComponent>
            <SecuredComponent inline permission={exportPermission}>
                <NullableTooltip title={exportLabel}>
                    <IconButton aria-label="" className={classes.iconButton} onClick={handleExport}>
                        <Icon name="arrow-alt-to-bottom" size={20} />
                    </IconButton>
                </NullableTooltip>
            </SecuredComponent>
            <Dialog
                open={importDialogOpen}
                // onClose={handleIfConditionDialogClose}
                aria-labelledby="derived-position-dialog-title"
                aria-describedby="derived-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
            >
                <ImportDialog
                    title={importLabel}
                    handleClose={handleImportDialogClose}
                    handleUpload={(data) => {
                        return importCallback(data);
                    }}
                    onUploadDone={onImportDoneCallback}
                />
            </Dialog>
        </>
    );
};

export default ExporterImporterProps;