import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Divider,
    Grow,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { usePhotoGallery } from "../../../hooks/usePhotoGallery";
import { useActiveNodeId } from "../../../redux/tree";
import Icon from "../../Icon/Icon";
import DropZoneDialog from "../DropZoneDialog/DropZoneDialog";
import messages from "./messages";
import useStyles from "./styles";
import { Capacitor } from "@capacitor/core";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { useGetPath } from "../../../utils/Menu";

interface IUploadButtonProps {
    anchorRef: React.RefObject<HTMLDivElement>;
    openUploadMenu: boolean;
    handleToggleUploadMenu: () => void;
    handleUploadMenuClose: (event: React.MouseEvent<EventTarget>) => void;
    handleListKeyDown: (event: React.KeyboardEvent) => void;
}

const UploadButton: React.FC<IUploadButtonProps> = ({
    anchorRef,
    openUploadMenu,
    handleToggleUploadMenu,
    handleUploadMenuClose,
    handleListKeyDown,
}) => {
    const classes = useStyles();
    const activeNodeId = useActiveNodeId();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const { takeAndUploadPhoto } = usePhotoGallery();
    const [openDropZoneDialog, setOpenDropZoneDialog] = useState<boolean>(false);

    const { getProjectPermission } = useGetPath();

    const handleOpenDropZoneDialog = () => {
        setOpenDropZoneDialog(true);
    };

    const handleCloseDropZoneDialog = () => {
        setOpenDropZoneDialog(false);
    };

    // translations
    const intl = useIntl();
    const transUpload = intl.formatMessage({ ...messages.upload });
    const transFolder = intl.formatMessage({ ...messages.folder });
    const transCameraUpload = intl.formatMessage({ ...messages.cameraUpload });
    const transFileUpload = intl.formatMessage({ ...messages.fileUpload });
    const transFolderUpload = intl.formatMessage({ ...messages.folderUpload });

    const handleButtonDefaultAction = () => {
        if(Capacitor.isNative) {
            takeAndUploadPhoto(activeNodeId)
        }
        else {
            handleOpenDropZoneDialog()
        }
    }

    return (
        <>
            <Box className={classes.box}>
                <ButtonGroup
                    variant="contained"
                    ref={anchorRef}
                    aria-label="split button"
                    className={classes.buttonGroup}
                >
                    <SecuredComponent permission={getProjectPermission("documents", "document:create")}> {/* todo: OR document:update */}
                        <Button
                            className={clsx(classes.button, classes.buttonText)}
                            onClick={handleButtonDefaultAction}
                        >
                            {transUpload}
                        </Button>
                    </SecuredComponent>
                    <Button
                        color="primary"
                        size="small"
                        aria-controls={openUploadMenu ? "upload-dialog-grow" : undefined}
                        aria-expanded={openUploadMenu ? "true" : undefined}
                        aria-label="select upload method"
                        aria-haspopup="true"
                        className={classes.button}
                        onClick={handleToggleUploadMenu}
                    >
                        <Icon name="triangle-down-solid" size={10} className={classes.icon} />
                    </Button>
                </ButtonGroup>
                <Popper
                    open={openUploadMenu}
                    anchorEl={anchorRef?.current}
                    role={undefined}
                    transition
                    disablePortal
                    placement="right-start"
                    className={classes.popper}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: "top left",
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleUploadMenuClose}>
                                    <MenuList
                                        autoFocusItem={openUploadMenu}
                                        id="upload-dialog-grow"
                                        onKeyDown={handleListKeyDown}
                                    >
                                        <MenuItem
                                            onClick={(event) => {
                                                setGlobalDialogOpen(true);
                                                handleUploadMenuClose(event);
                                            }}
                                        >
                                            <ListItemIcon className={classes.itemIcon}>
                                                <Icon name="folder-plus" size={20} />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{transFolder}</Typography>
                                        </MenuItem>
                                        <Divider />
                                        {Capacitor.isNative &&
                                          <MenuItem
                                            onClick={(event) => {
                                                takeAndUploadPhoto(activeNodeId);
                                                handleUploadMenuClose(event);
                                            }}
                                          >
                                              <ListItemIcon className={classes.itemIcon}>
                                                <Icon name="camera" size={20} />
                                              </ListItemIcon>
                                              <Typography variant="inherit">{transCameraUpload}</Typography>
                                          </MenuItem>
                                        }
                                        <SecuredComponent permission={getProjectPermission("documents", "document:create")}> {/* todo: OR document:update */}
                                            <MenuItem
                                                onClick={(event) => {
                                                    handleOpenDropZoneDialog();
                                                    handleUploadMenuClose(event);
                                                }}
                                            >
                                                <ListItemIcon className={classes.itemIcon}>
                                                    <Icon name="file-upload" size={20} />
                                                </ListItemIcon>
                                                <Typography variant="inherit">{transFileUpload}</Typography>
                                            </MenuItem>
                                        </SecuredComponent>
                                        <MenuItem
                                            onClick={(event) => {
                                                setGlobalDialogOpen(true);
                                                handleUploadMenuClose(event);
                                            }}
                                        >
                                            <ListItemIcon className={classes.itemIcon}>
                                                <Icon name="folder-plus" size={20} />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{transFolderUpload}</Typography>
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>

            <DropZoneDialog open={openDropZoneDialog} handleClose={handleCloseDropZoneDialog} nodeId={activeNodeId} />
        </>
    );
};

export default UploadButton;
