export type IPageParams = {
    accountUrn: string;
    projectUrn: string;
    projectCode: string;
    nodeId?: string;
    urn?: string;
    fileId?: string;
    tool?: IPageTool;
};

export enum IPageTool {
    ISSUES = "issues",
}
