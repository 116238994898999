import React from "react";
import { useHubMembersContext } from "./HubMembersContext";
import MembersSearch from "../../../../components/MemberSearch/MembersSearch";
import { useDebouncedMembersLookup } from "../../../../components/MemberSearch/hooks";
import { searchHubLeads } from "../../api/client";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IHubMembersSearchProp {

}
const useSearchMembers = () => useDebouncedMembersLookup((hubID, text) => searchHubLeads(hubID, text))

const HubMembersSearch: React.FC<IHubMembersSearchProp> = ({  }) => {

    const { hubID, addMember } = useHubMembersContext();


    const intl = useIntl();
    const transAddToHub = intl.formatMessage({ ...messages.addToHub });
    const transAlreadyMember = intl.formatMessage({ ...messages.alreadyMember });

    return (
        <MembersSearch
            addMember={addMember}
            ownerID={hubID as number}
            membersLookup={useSearchMembers}
            addText={transAddToHub}
            alreadyMemberText={transAlreadyMember}
        />
    );
};

export default HubMembersSearch;