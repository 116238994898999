import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import clsx from "clsx";
import { useGetPath } from "../../../utils/Menu";
import { Path } from "../../../pages/PageRouter";
import { Link, useLocation } from "react-router-dom";
import PanelMenu from "../../PanelMenu/PanelMenu";

interface IMappingPanelProps {
}

interface IToolButtonProps {
    link: string,
    title: string
}

const ProjectPanel: React.FC<IMappingPanelProps> = ({  }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const { getPath } = useGetPath();

    // translations
    const intl = useIntl();
    const transLabelDetails = intl.formatMessage({ ...messages.details });
    const transLabelMembers = intl.formatMessage({ ...messages.members });
    const transLabelTools = intl.formatMessage({ ...messages.tools });

    return (
        <Box className={classes.box}>
            <PanelMenu items={[
                {
                    link: getPath(Path.PROJECT_SETTINGS),
                    title: transLabelDetails
                },
                {
                    link: getPath(Path.PROJECT_SETTINGS, {tool: "tools"}),
                    title: transLabelTools
                },
                {
                    link: getPath(Path.PROJECT_SETTINGS, {tool: "members"}),
                    title: transLabelMembers
                }
            ]} />
        </Box>
    );
};

export default ProjectPanel;
