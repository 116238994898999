import React, { useCallback, useEffect, useMemo, useState } from "react";
import CrudModule, { ItemField } from "../../../Crudder/CrudModule";
import { createUser, deleteUser, getUsers, updateUser } from "../../api/endpoints/client";
import { IoLabsUsersApiModelsUserResponse } from "../../api/io-labs-users-api-models-user-response";
import { GridRowsProp } from "@mui/x-data-grid";
import { IoLabsUsersApiModelsUserRequest } from "../../api/io-labs-users-api-models-user-request";
import { getHub } from "../../../Hubs/api/client";

const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "userName",
        title: "Username",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "email",
        title: "Email",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "password",
        title: "Password",
        formFieldDef: {
            type: "password"
        },
        columnDef: {
            flex: 2,
            ignore: true,
        },
    },
    {
        name: "firstName",
        title: "First name",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "lastName",
        title: "Last name",
        columnDef: {
            flex: 1
        },
    },
];


const UserModule: React.FC = () => {

    const [ data, setData ] = useState<IoLabsUsersApiModelsUserResponse[]>([]);


    const fetchUsers = useCallback(() => {
        return new Promise<IoLabsUsersApiModelsUserResponse[]>((resolve, reject) => {
            getUsers(true).then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<number>((resolve, reject) => {
            if (formValues["userID"]) {
                // update
                updateUser(formValues["userID"], formValues as IoLabsUsersApiModelsUserRequest).then((response) => {
                    resolve(response.userID);
                })
            }
            else {
                // create
                createUser(formValues as IoLabsUsersApiModelsUserRequest).then((response) => {
                    resolve(response.userID);
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteUser(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<any>((resolve, reject) => {
            resolve(data.find(i => i.userID == id));
        });
    }, [data]);


    return (
        <CrudModule<IoLabsUsersApiModelsUserResponse, IoLabsUsersApiModelsUserResponse>
            name={"Users"}
            list={{
                title: "Users list",
                getRowID: (user) => user.userID
            }}
            detail={{
                title: "User detail",
            }}
            itemFields={fields}
            data={fetchUsers}
            getDetail={getDetail}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
        />
    );
};

export default UserModule;