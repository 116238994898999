import { ProjectData } from "../ProjectSelectorWrapper/type";
import React, { FC, useCallback, useState } from "react";
import { Avatar, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Link, match } from "react-router-dom";
import { generatePath } from "react-router";
import useStyles from "./styles";
import { stringAvatar } from "./avatarUtils";
import NullableTooltip from "../NullableTooltip/NullableTooltip";
import zapperLogo from "../../assets/images/zapper-logo-red-mark.svg";
import bim360Logo from "../../assets/images/bim-360.png";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Path } from "../../pages/PageRouter";
import { Alert } from "@material-ui/lab";

interface IProjectSelectorProjectsProps {
    projects: ProjectData[] | undefined;
    activeProject?: ProjectData;
    onSelectProject?: (project: ProjectData) => void;
}



const ProjectSelectorProjects: FC<IProjectSelectorProjectsProps> = ({ projects,activeProject, onSelectProject}) => {
    const classes = useStyles();


    const [ currentRoute, setCurrentRoute ] = useState<match|null>();

    console.log("XXX active project", activeProject);

    // translations
    const intl = useIntl();
    const transInitialized = intl.formatMessage({ ...messages.initialized });
    const transHasMasterFile = intl.formatMessage({ ...messages.hasMasterFile });
    const transNoProject = intl.formatMessage({ ...messages.noProject });


    const logo = useCallback((project: ProjectData) => {
        if (project.hasMasterFile) {
            return (
                <span className={classes.helperIcon}>
                    <NullableTooltip title={transHasMasterFile}>
                        <img src={zapperLogo} alt={"Z"} />
                    </NullableTooltip>
                </span>
            )
        }
        else if (project.isInitialized) {
            return (
                <span className={classes.helperIcon}>
                    <NullableTooltip title={transInitialized}>
                        <img src={bim360Logo} alt={"B"} />
                    </NullableTooltip>
                </span>
            )
        }
        else {
            return null;
        }
    }, []);

    const handleSelectProject = (project: ProjectData) => {
        if (onSelectProject) {
            onSelectProject(project);
        }
    }

    const getSwitchToPath = (project: ProjectData): string => {
        if (currentRoute?.path == Path.DOCUMENTS_WITH_VIEWER) {
            return Path.DOCUMENTS;
        }
        else if (!currentRoute?.path) {
            return project.modules.includes("documents") ? Path.DOCUMENTS : Path.PROJECT_DASHBOARD;
        }
        return currentRoute?.path as string;
    }

    const getProjectPath = useCallback((project: ProjectData) => {
        const path = generatePath(getSwitchToPath(project), {
            accountUrn: project?.externalData?.[0]?.accountUrn,
            projectUrn: project?.externalData?.[0]?.projectUrn,
        });
        return path;
    }, []);

    return (
        <>
            {projects?.sort((a,b) => a.name.localeCompare(b.name)).map((project, index) => (
                <ListItem
                    key={index}
                    button
                    onClick={() => handleSelectProject(project)}
                    selected={project?.projectID === activeProject?.projectID} // TODO: check projectID @petr (Jakub Jirous 2021-03-05 13:33:28)
                >
                    <Link
                        to={getProjectPath(project)}
                        className={classes.link}
                    >
                        <ListItemAvatar className={classes.avatar}>
                            <Avatar {...stringAvatar(project)} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <>
                                    {project?.name}
                                    {logo(project)}
                                </>
                            }
                            secondary={project.hub.name}
                            classes={{
                                primary: classes.listItemTextPrimary,
                            }}
                        />
                    </Link>

                </ListItem>
            ))}
            {!projects?.length && (
                <ListItem>
                    <Alert className={classes.noRecords} severity={"info"}>{transNoProject}</Alert>
                </ListItem>
            )}
        </>
    );
}
export default ProjectSelectorProjects;
