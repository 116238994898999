import { MarkupsGuiExtension } from "@iolabs/forge-viewer-markups-gui";
import "@iolabs/forge-viewer-markups-gui/dist/main.css";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import ForgeViewer from "iolabs-react-forge-viewer";
import { isEmpty } from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../../config/config";
import { saveMarkup } from "../../../packages/Api/data/markups/client";
import { IObjectMarkup } from "../../../packages/Api/data/markups/types";
import {
    onCreateChangeLocationInfo,
    onIssueChangeVisibility,
    onIssueViewerChangeViewable,
    useIsIssueVisible,
    useIssueTab,
    useIssueViewer,
} from "../../../redux/issue";
import { IIssueViewer } from "../../../redux/issue/reducer";
import {
    onMarkupsSetData,
    onViewable, onViewerTokenRequest, useForgeToken,
    useMarkupsData,
    useMarkupsExternalHandling,
    usePreferredViewableGuid,
} from "../../../redux/viewer";
// import { usePanelSpeed, usePanelTurntable } from "../../redux/version-compare";
import { IViewerState } from "../../../redux/viewer/reducer";
import { IIssueTab } from "../../Issues/type";
// import PrintableComponent from "../../components/ControlPanel/PrintableComponent";
// import IssuableComponent from "../../components/Issues/IssuableComponent";
import { ViewerRole } from "../../ProjectViewer/ProjectViewer";

import {
    ExtensionID as BulkIsolationExtensionID,
    register as registerBulkIsolationExtension,
} from "../extensions/Viewing.Extensions.BulkIsolation/Viewing.Extensions.BulkIsolationExtension";
import {
    ExtensionID as ToolbarExtensionID,
    register as registerToolbarExtension,
} from "../extensions/Viewing.Extensions.Toolbar/Viewing.Extensions.ToolbarExtension";
import {
    ExtensionID as StructureBrowserExtensionID,
    register as registerStructureBrowserExtension,
} from "../extensions/Viewing.Extensions.StructureBrowser/Viewing.Extensions.StructureBrowserExtension";
import {
    register as registerCustomPropertiesExtension,
} from "../extensions/Viewing.Extensions.CustomProperties/Viewing.Extensions.CustomPropertiesExtension";
import MarkupsSample from "../MarkupsSample/MarkupsSample";
import PushpinsInViewer from "../PusphinsInViewer/PusphinsInViewer";
import { Emea } from "../type";
import ViewSelector, { IViewSelectorPosition } from "../ViewSelector/ViewSelector";
import useStyles from "./styles";
import { Issue, useGetIssuesRemoteQuery } from "../../../graphql/generated/graphql";
import { useParams } from "react-router";
import { IPageParams } from "../../../pages/type";
import { isJwtExpired } from "../../../utils/Jwt";
import { getStructure } from "./structure";
import SecuredComponent, { SecurityMode } from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { useGetPath } from "../../../utils/Menu";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";
import { ScopePermission } from "../../../redux/keyclock/SecuredComponent/permissions";

export interface IViewerProps {
    viewerState: IViewerState;
    role?: ViewerRole;
    hiddenControls?: boolean;
    onSelect?: (selection: any, viewer: any) => void;
    onModelLoaded?: (model: Autodesk.Viewing.Model) => void;
    onViewerLoaded?: (viewer: Autodesk.Viewing.Viewer3D) => void;
    onViewableSelected?: (viewable: any) => void;
    disableFetchLatest?: boolean;
    isViewerPage?: boolean;
    isIssuePage?: boolean;
    hideMarkups?: boolean;
    hideAllIssuesBtn?: boolean;
    onStoreState?: (state: IViewerLeaveState) => void;
    stateToRestore?: IViewerLeaveState;
    ref?: any; //temp
    viewSelectorPosition?: IViewSelectorPosition;
}

export interface IViewerLeaveState {
    viewable: string;
    state: any;
}

const Viewer: React.FC<IViewerProps> = forwardRef(
    (
        {
            viewerState,
            role,
            hiddenControls,
            onSelect,
            onModelLoaded,
            onViewerLoaded,
            onViewableSelected,
            isViewerPage,
            isIssuePage,
            hideMarkups,
            hideAllIssuesBtn,
            onStoreState,
            stateToRestore,
            viewSelectorPosition
        },
        ref
    ) => {
        const classes = useStyles();
        const dispatch = useDispatch<DispatchAction>();
        const issueViewer: IIssueViewer = useIssueViewer();
        const isIssueVisible: boolean = useIsIssueVisible();
        const markupsExternalHandling: boolean = useMarkupsExternalHandling();
        const markupsData = useMarkupsData();
        const [viewer, setViewer] = useState<any>();
        const issueTab: IIssueTab = useIssueTab();

        const { getProjectPermission } = useGetPath();
        const { isAllowed } = useSecurityContext();

        // optional
        const { accountUrn, projectUrn, urn: paramUrn } = useParams<IPageParams>();

        const [bulkIsolationExtension, setBulkIsolationExtension] = useState<any>();
        const [markupsCoreExtension, setMarkupsCoreExtension] = useState<any>();
        const [markupsGuiExtension, setMarkupsGuiExtension] = useState<MarkupsGuiExtension>();
        const [toolbarExtension, setToolbarExtension] = useState<any>();

        const [viewables, setViewables] = useState<any>();
        const [viewable, setViewable] = useState<any>();

        // const turnTableEnabled = usePanelTurntable();
        // const turnTableSpeed = usePanelSpeed();
        const [modelLoaded, setModelLoaded] = useState<boolean>(false);
        const [unmounted, setUnmounted] = useState<boolean>(false);
        const [lastAppliedViewerState, setLastAppliedViewerState] = useState<any>({});
        const preferredViewable = usePreferredViewableGuid();

        const forgeToken = useForgeToken();

        const [markupsListHackValue, setMarkupsListHackValue] = useState<number>(1);

        const viewableRef = useRef(viewable);

        const markupsExternalHandlingRef = useRef(markupsExternalHandling);

        // The component instance will be extended
        // with whatever you return from the callback passed
        // as the second argument
        useImperativeHandle(ref, () => ({
            getState() {
                return buildState();
            },
        }));

        const {
            data: projectIssuesData,
            // loading: projectIssuesLoading,
            // error: projectIssuesError
        } = useGetIssuesRemoteQuery({
            variables: {
                projectUrn,
                accountUrn,
                fileUrn: paramUrn as string,
                fromVersion: issueViewer?.version?.data?.version ? `${issueViewer?.version?.data?.version}` : "",
                viewableGuid: viewable ? `${viewable?.data?.guid}` : "",
            },
            skip: !isIssueVisible || !accountUrn || !projectUrn || !isIssueVisible || !paramUrn,
            // fetchPolicy: "network-only", // disable GraphQL query caching
        });

        const handleSetViewable = (data) => {
            viewableRef.current = data;
            setViewable(data);

            dispatch(
                onCreateChangeLocationInfo({
                    locationInfo: {
                        sheetMetadata: {
                            is3D: viewableRef.current.is3D(),
                            sheetGuid: viewableRef.current.guid(),
                            sheetName: viewableRef.current.name(),
                        },
                        // pushpinAttributes: {
                        //     // Data about the pushpin
                        //     type: "TwoDVectorPushpin", // This is the only type currently available
                        //     object_id: issue.objectId, // (Only for 3D models) The object the pushpin is situated on.
                        //     location: issue.position, // The x, y, z coordinates of the pushpin.
                        //     viewer_state: issue.viewerState, // The current Viewer state. For example, angle, camera, zoom.
                        // },
                    },
                })
            );

            dispatch(onViewable({ viewable: data }));

            if (isViewerPage) {
                dispatch(onIssueViewerChangeViewable({ viewable: data }));
            }
        };

        useEffect(() => {
            markupsExternalHandlingRef.current = markupsExternalHandling;
        }, [markupsExternalHandling]);

        useEffect(() => {
            if (markupsGuiExtension) {
                markupsGuiExtension.setOptions({
                    showNameInput: !(isIssuePage && issueTab === IIssueTab.Create), // default true
                    showSaveButton: !(isIssuePage && issueTab === IIssueTab.Create), // default true
                });
            }
        }, [isIssuePage, markupsGuiExtension, issueTab]);

        useEffect(() => {
            if (!hideMarkups && !markupsExternalHandling && markupsGuiExtension) {
                if (markupsData && !markupsData.temp) {
                    markupsGuiExtension.showMarkup(markupsData.data, markupsData.viewerState, markupsData.name);
                } else if (!markupsData) {
                    markupsGuiExtension.deactivateTools(true);
                }
            }
        }, [markupsExternalHandling, markupsData]);

        useEffect(() => {
            if (viewer) {
                const ids = (viewerState.isolateIds as unknown) as string[];
                // renderIsolation(ids);
                renderSelection(ids);
            }
        }, [viewer, viewerState]);

        useEffect(() => {
            if (viewer && bulkIsolationExtension) {
                bulkIsolationExtension.setIsolations(viewerState.bulkIsolations ?? {});
            }
        }, [viewer, viewerState.bulkIsolations, bulkIsolationExtension]);

        useEffect(() => {
            if (viewer && toolbarExtension) {
                toolbarExtension.handleIsVisible(isIssueVisible);
            }
        }, [viewer, toolbarExtension, isIssueVisible]);

        useEffect(() => {
            if (viewer && viewables && preferredViewable) {
                const viewableByGuid = viewables.find((v) => preferredViewable === v.guid());
                if (viewableByGuid) {
                    handleSetViewable(viewableByGuid);
                } else {
                    console.warn(`Unable to navigate to viewable ${preferredViewable}`, viewables);
                }
            }
        }, [viewer, viewables, preferredViewable]);

        useEffect(() => {
            if (viewer) {
                setViewerBackground(viewer);
            }
        }, [viewer]);

        useEffect(() => {
            dispatch(
                onViewerTokenRequest({})
            );
        }, []);

        const handleSelectViewable = (viewable) => {
            handleSetViewable(viewable);

            if (onViewableSelected) {
                onViewableSelected(viewable);
            }
        };

        const handleViewerError = () => {
            console.error("Error loading Viewer.");
        };

        const getViewableName = (viewable: Autodesk.Viewing.BubbleNode): string => {
            return (viewable.data.name as unknown) as string;
        };

        const handleDocumentLoaded = (doc: any, viewables: any[]) => {
            if (!unmounted) {
                if (hiddenControls) {
                    // set first viewable only if ViewSelector is hidden, otherwise, it will select viewable based on its algorithm
                    if (viewables.length === 0) {
                        console.error("Document contains no viewables.");
                    } else if (isIssuePage && issueViewer?.viewable) {
                        const filteredViewable = viewables.filter(
                            (v) => v?.data?.name === issueViewer?.viewable?.data?.name
                        );
                        handleSelectViewable(filteredViewable ? filteredViewable[0] : viewables[0]);
                    } else {
                        handleSelectViewable(viewables[0]);
                    }
                } else if (stateToRestore?.viewable) {
                    const v = viewables.find((v) => v?.data?.guid === stateToRestore?.viewable);
                    setViewable(v);
                }
                else {
                    setViewable(undefined);
                }

                setViewables(viewables);
            }
        };

        const handleViewerLoaded = (viewer: Autodesk.Viewing.Viewer3D) => {
            if (!unmounted) {
                setViewerBackground(viewer);
                setViewer(viewer);
                if (onViewerLoaded) {
                    onViewerLoaded(viewer);
                }
            }
        };

        const handleDocumentError = (viewer: any, error: any) => {
            console.error("Error loading a document.");
        };

        const getMarkupsObjectID = (): string | null => {
            if (viewerState?.urn && viewableRef?.current?.data?.guid) {
                return `${viewerState.urn}-${viewableRef.current.data.guid}`;
            }
            return null;
        };

        const handleChangeMarkups = (content: string, state: string, name?: string) => {
            dispatch(
                onMarkupsSetData({
                    markupsData: {
                        data: content,
                        viewerState: state,
                        name,
                        temp: true,
                    },
                })
            );
        };

        const handleSaveMarkups = (content: string, state: string, name?: string) => {
            if (markupsExternalHandlingRef.current) {
                dispatch(
                    onMarkupsSetData({
                        markupsData: {
                            data: content,
                            viewerState: state,
                            name,
                        },
                    })
                );
            } else {
                // default handler
                const objectID = getMarkupsObjectID();
                if (objectID) {
                    saveMarkup(objectID, {
                        content,
                        placement: JSON.stringify(state),
                        name: name as string,
                    }).then(() => {
                        // force rerender list
                        setMarkupsListHackValue(markupsListHackValue + 1);
                    });
                } else {
                    console.error("ObjectID not generated");
                }
            }
        };

        const setViewerBackground = (v: Autodesk.Viewing.Viewer3D) => {
            v.setBackgroundColor(251, 251, 251, 251, 251, 251);
        };

        const handleModelLoaded = (viewer: Autodesk.Viewing.Viewer3D, model: Autodesk.Viewing.Model) => {
            if (viewer) {
                setViewerBackground(viewer);
                viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
                    // viewer settings - disabled ground shadow
                    viewer.setGroundShadow(false);
                });
                viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, (selection) => {
                    if (onSelect) onSelect(selection, viewer);
                });
                viewer.addEventListener(Autodesk.Viewing.OBJECT_TREE_CREATED_EVENT, (selection) => {
                    viewer
                        .loadExtension(BulkIsolationExtensionID, {
                            isolations: viewerState.bulkIsolations,
                            showIsolatedOnly: true,
                        })
                        .then((extension) => {
                            setBulkIsolationExtension(extension);
                        });

                    viewer
                        .loadExtension(StructureBrowserExtensionID, {
                            tree: getStructure()[0]
                        });
                });

                if (!hideMarkups && isAllowed(getProjectPermission("markups", "markup:create") as ScopePermission)) {
                    viewer.loadExtension("Autodesk.Viewing.MarkupsCore", {}).then((extension) => {
                        setMarkupsCoreExtension(extension);
                    });

                    MarkupsGuiExtension.register(viewer, {
                        showNameInput: !isIssuePage && issueTab !== IIssueTab.Create, // default true
                        showSaveButton: !isIssuePage && issueTab !== IIssueTab.Create, // default true
                        saveHandler: handleSaveMarkups,
                        changeHandler: handleChangeMarkups,
                    }).then((extension: MarkupsGuiExtension) => {
                        setMarkupsGuiExtension(extension);
                    });
                }

                if (!hideAllIssuesBtn) {
                    viewer
                        .loadExtension(ToolbarExtensionID, {
                            isIssueVisible,
                            handleChangeIssueVisibility,
                        })
                        .then((extension) => {
                            setToolbarExtension(extension);
                        });
                }

                // restore state
                if (stateToRestore?.state && stateToRestore.viewable === viewable?.data?.guid) {
                    viewer.restoreState(stateToRestore.state);
                }
            }

            if (!unmounted) {
                setModelLoaded(true);
            }
            if (onModelLoaded) {
                onModelLoaded(model);
            }

            // if (getViewableRole(viewable) === ViewerRole["2D"]) {
            //     // Load Edit2D extension
            //     const edit2d: Promise<any> = viewer.loadExtension("Autodesk.Edit2D");
            //     // Register all standard tools in default configuration
            //     edit2d.then(ext => {
            //         viewer.loadExtension(Edit2DuiExtensionID, { extension: ext });
            //         ext.registerDefaultTools();
            //
            //         // @ts-ignore
            //         window.Edit2D = ext;
            //     });
            // }
        };

        const getViewableRole = (viewable) => {
            return viewable.data ? viewable.data.role : viewable.role;
        };

        const handleForgeScriptLoaded = () => {
            if (!hideAllIssuesBtn) {
                registerToolbarExtension();
            }
            // registerPrintExtension();
            // registerEdit2DuiExtension();
            // registerTurnTableExtension();
            // registerSyncViewNavigationExtension();
            registerBulkIsolationExtension();
            registerStructureBrowserExtension();
            registerCustomPropertiesExtension();
        };

        const handleModelError = (viewer: any, model: any) => {
            console.error("Error loading the model.");
        };

        const handleTokenRequested = (onAccessToken: any) => {
            if (!forgeToken || isJwtExpired(forgeToken, 30)) {
                dispatch(
                    onViewerTokenRequest({
                        onTokenFetched: onAccessToken
                    })
                );
            }
            else {
                onAccessToken(forgeToken);
            }
        };

        const onUnmount = () => {
            if (onStoreState) {
                onStoreState({
                    viewable: viewable?.data?.guid,
                    state: viewer.getState(),
                });
                onStoreState(buildState());
            }
        };

        const buildState = () => {
            return {
                viewable: viewable?.data?.guid,
                state: viewer.getState(),
            };
        };

        const renderSelection = (ids: string[]) => {
            if (viewer.model) {
                if (!isEmpty(ids)) {
                    viewer.select(ids);
                } else {
                    viewer.clearSelection();
                }
                setLastAppliedViewerState({ select: ids });
            }
        };

        const showAll = () => {
            if (viewer.model && viewer.model.getData().instanceTree) {
                for (let i = 0; i < viewer.model.getData().instanceTree.objectCount - 1; i++) {
                    viewer.impl.visibilityManager.setNodeOff(i, false);
                }
            }
        };

        const showIdsOnly = (ids: string[]) => {
            const objectCount = viewer.model.getData().instanceTree.objectCount - 1;
            if (viewer.model && ids.length !== 0) {
                for (let i = 0; i < objectCount; i++) {
                    viewer.impl.visibilityManager.setNodeOff(i, !(ids.indexOf((i as unknown) as string) >= 0));
                }
            }
        };

        const handleChangeIssueVisibility = (isVisible: boolean): void => {
            dispatch(onIssueChangeVisibility({ isVisible }));
        };

        const markupsObjectID = getMarkupsObjectID();
        const onMarkupSelected = (markup: IObjectMarkup) => {
            markupsGuiExtension?.showMarkup(markup.content, JSON.parse(markup.placement), markup.name);
        };

        return (
            <Box className={classes.root}>
                {viewerState.urn && forgeToken ? (
                    <Box
                        className={clsx(classes.box, {
                            [classes.hiddenControls]: hiddenControls,
                        })}
                    >
                        <SecuredComponent permission={getProjectPermission("issues", "issue:view")}>
                            {isIssueVisible && (isIssuePage || isViewerPage) && modelLoaded && (

                                    <PushpinsInViewer
                                        viewer={viewer}
                                        isViewerPage={isViewerPage}
                                        issuesList={projectIssuesData?.issues as Issue[]}
                                    />
                            )}
                        </SecuredComponent>

                        <SecuredComponent inline permission={getProjectPermission("markups", "markup:view")} securityMode={SecurityMode.HIDDEN}>
                            {!hideMarkups && markupsObjectID && (
                                <MarkupsSample
                                    stateHackValue={markupsListHackValue}
                                    objectID={markupsObjectID}
                                    onMarkupSelected={onMarkupSelected}
                                />
                            )}
                        </SecuredComponent>

                        {!hiddenControls && (
                            <ViewSelector
                                viewables={viewables}
                                viewable={viewable ? viewable : viewables?.[0]} // intentionally pass first viewable as default
                                onSelectViewable={handleSelectViewable}
                                getViewableName={getViewableName}
                                role={role}
                                isIssuePage={isIssuePage}
                                viewSelectorPosition={viewSelectorPosition}
                            />
                        )}
                        <ForgeViewer
                            version="7.51"
                            urn={viewerState.urn}
                            api={viewerState.isEmea ? Emea.eu : Emea.default}
                            view={viewable}
                            query={{ type: "geometry" }}
                            headless={false}
                            onViewerError={handleViewerError}
                            onTokenRequest={handleTokenRequested}
                            onDocumentLoad={handleDocumentLoaded}
                            onDocumentError={handleDocumentError}
                            onModelLoad={handleModelLoaded}
                            onModelError={handleModelError}
                            onViewerLoad={handleViewerLoaded}
                            onScriptLoaded={handleForgeScriptLoaded}
                            onUnmount={onUnmount}
                            forgeScriptAlreadyLoaded
                            config={{
                                memory: {
                                    limit: 1000,
                                },
                            }}
                        />
                    </Box>
                ) : (
                    <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                        <Skeleton variant="rect" width="100%" className={classes.skeleton} />
                    </Box>
                )}
            </Box>
        );
    }
);

export default Viewer;
