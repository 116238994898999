import { onStart } from "@iolabs/app";
import ApiClient from "../../packages/ApiClient/ApiClient";
import { Actions, ActionTypes, fetchUma, setUmaPermissions, setUmaToken } from "./action";
import { selectEvent, selectToken } from "./selector";
import { getUmaPermissions, getUmaToken } from "./api/client";
import config from "../../config/config";

// @ts-ignore
const onKeycloackEventMiddleware = ({ dispatch, getState }) => (next) => (action: Actions) => {
    const resolve = next(action);

    if (action.type === ActionTypes.OnEvent) {
        const event = selectEvent(getState());
        if (event === "onReady") {
            return dispatch(onStart());
        }
    }
    if (action.type === ActionTypes.OnTokens) {
    }

    if (action.type === ActionTypes.OnFetchUma) {
        const token = selectToken(getState()); // todo remove
        getUmaToken(config.security.serverId, token).then(umaToken => {
            ApiClient.setAuthorizationToken(umaToken.access_token);
            action.payload.onFetchToken(umaToken);
            dispatch(setUmaToken({
                token: umaToken
            }))
        }).catch(e => {
            action.payload.onFetchToken(undefined);
        })
        getUmaPermissions(config.security.serverId, token).then(umaPermissions => {
            action.payload.onFetchPermissions(umaPermissions);
            dispatch(setUmaPermissions({
                permissions: umaPermissions
            }))
        }).catch(e => {
            action.payload.onFetchPermissions([]);
        })
    }

    return resolve;
};

export default [onKeycloackEventMiddleware];
