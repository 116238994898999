import { defineMessages } from "react-intl";

export default defineMessages({
    stepUrl: {
        id: "components.project.projectNewWizard.stepUrl",
        defaultMessage: "Enter BIM 360 linkage information",
    },
    stepForm: {
        id: "components.project.projectNewWizard.stepForm",
        defaultMessage: "Fill project form data",
    },
    newProjectTitle: {
        id: "components.project.projectNewWizard.newProjectTitle",
        defaultMessage: "Hub {hubName}: New project",
    },
    bim360url: {
        id: "components.project.projectNewWizard.bim360url",
        defaultMessage: "BIM 360 URL",
    },
    bim360urlHelper: {
        id: "components.project.projectNewWizard.bim360urlHelper",
        defaultMessage: "Enter URL taken from address bar of BIM 360 Docs, Zapper will try to autodetect the needed attributes",
    },
    accountID: {
        id: "components.project.projectNewWizard.accountID",
        defaultMessage: "BIM 360 account ID",
    },
    accountIDHelper: {
        id: "components.project.projectNewWizard.accountIDHelper",
        defaultMessage: "If the account identifier will not be entered, Zapper will try to autodetect the account identifier from project identifier",
    },
    projectID: {
        id: "components.project.projectNewWizard.projectID",
        defaultMessage: "BIM 360 project ID",
    },
    projectIDHelper: {
        id: "components.project.projectNewWizard.projectIDHelper",
        defaultMessage: "The project identifier is the only required parameter to create the project in Zapper",
    },
    fileID: {
        id: "components.project.projectNewWizard.fileID",
        defaultMessage: "BIM 360 file ID",
    },
    fileIDHelper: {
        id: "components.project.projectNewWizard.fileIDHelper",
        defaultMessage: "For usage of file related modules (Specifications, Mapping) the mani model file should be set",
    },
    loadInfo: {
        id: "components.project.projectNewWizard.loadInfo",
        defaultMessage: "Load information",
    },
    hubIdNotProvided: {
        id: "components.project.projectNewWizard.hubIdNotProvided",
        defaultMessage: "Hub ID was not provided, will try to autodetect",
    },
    projectIdNotProvided: {
        id: "components.project.projectNewWizard.projectIdNotProvided",
        defaultMessage: "Project ID was not provided, please enter",
    },
    fileIdNotProvided: {
        id: "components.project.projectNewWizard.fileIdNotProvided",
        defaultMessage: "File ID was not provided, some modules will not be initialized",
    },
    processCheckAndContinue: {
        id: "components.project.projectNewWizard.processCheckAndContinue",
        defaultMessage: "Check and continue",
    },
    checkNotFound: {
        id: "components.project.projectNewWizard.checkNotFound",
        defaultMessage: "Project was not found or is not accessible, change input or contact support",
    },
    checkError: {
        id: "components.project.projectNewWizard.checkError",
        defaultMessage: "Error during check, change input or contact support",
    },
});
