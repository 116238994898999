import React, { useCallback } from "react";
import { Route, Router, Switch, useHistory, useParams, useRouteMatch } from "react-router";

import { GridColDef, GridRowsProp } from "@mui/x-data-grid";
import CrudList, { CrudListConfiguration } from "./components/CrudList/CrudList";
import CrudDetail, { CrudDetailConfiguration, ICrudDetailProps } from "./components/CrudDetail/CrudDetail";
import { TEXT_FIELD_TYPE } from "mui-rff/dist/TextField";
import { getNewPath } from "./pathUtils";


export interface CrudModuleProps<LT, DT> {
    name: string,
    itemFields: ItemField[],
    list: CrudListConfiguration<LT>,
    detail: CrudDetailConfiguration<DT>,
    data: () => Promise<LT[]>,
    getDetail: (id: string|number) => Promise<DT>,
    onSubmit: (values: FormData) => Promise<number>,
    onDelete?: (id: number) => Promise<void>,
}

export interface FormFieldDef {
    ignore?: boolean,
    type?: TEXT_FIELD_TYPE,
    render?: (field: ItemField, classes: any, readonly?: boolean) => any,
}
export interface ListColumnDef extends Partial<GridColDef> {
    ignore?: boolean;
}

export interface ItemField {
    name: string,
    title: string,
    description?: string,
    columnDef?: ListColumnDef;
    formFieldDef?: FormFieldDef;

}
export interface IRouteParams {
    itemID: string;
}


export interface RouteDetailProps<DT> {
    itemFields: ItemField[],
    detail: CrudDetailConfiguration<DT>,
    getDetail?: ((id: number|string) => Promise<DT>),
    onSubmit: (values: FormData) => Promise<number>,
    backLink: string;
}

const RouteDetail = <DT,>({
                                                     itemFields,
                                                     detail,
                                                     getDetail,
                                                     onSubmit,
                                                     backLink
                                                 } : RouteDetailProps<DT>) => {
    let DetailComponent: React.FC<ICrudDetailProps<DT>> = detail.component ?? CrudDetail;
    return <DetailComponent config={detail} itemFields={itemFields} data={getDetail as ((id: number|string) => Promise<DT>)} onSubmit={onSubmit} backLink={backLink}  />
};

const RouteNew = <DT,>({
                                                     itemFields,
                                                     detail,
                                                     getDetail,
                                                     onSubmit,
                                                     backLink
                                                 } : RouteDetailProps<DT>) => {
    if (typeof detail.componentNew === "function") {
        return detail.componentNew({
            itemFields,
            config: detail,
            data: getDetail,
            onSubmit,
            backLink
        })
    }

    let DetailComponent: React.FC<ICrudDetailProps<DT>> = detail.componentNew ?? CrudDetail;
    return <DetailComponent config={detail} itemFields={itemFields} data={getDetail} onSubmit={onSubmit} backLink={backLink}  />
};

const CrudModule = <LT, DT>({
    name,
    itemFields,
    list,
    detail,
    data,
    getDetail,
    onSubmit,
    onDelete
} : CrudModuleProps<LT, DT>) => {
    const history = useHistory();
    let { path } = useRouteMatch();

    const handleSubmit = useCallback((values: FormData) => {
        return new Promise<number>((resolve, reject) => {
            onSubmit(values).then(id => {
                history.push(`${path}`);
                resolve(id);
            }).catch(reject);
        })

    }, []);

    return (
        <Router history={history}>
            <Switch>
                <Route path={getNewPath(path)}>
                    <RouteNew<DT> itemFields={itemFields} detail={detail} onSubmit={handleSubmit} backLink={path} />
                </Route>
                <Route path={`${path}/:itemID`}>
                    <RouteDetail<DT> itemFields={itemFields} detail={detail} getDetail={getDetail} onSubmit={handleSubmit} backLink={path} />
                </Route>
                <Route path={path}>
                    <CrudList<LT> config={list} itemFields={itemFields} data={data} onDelete={onDelete} />
                </Route>
            </Switch>
        </Router>
    );
};

export default CrudModule;