import { ScopePermission } from "./permissions";
import React, { ReactElement } from "react";
import { useSecurityContext } from "../securityContext";
import { SecuredComponentWrapper } from "./SecuredComponent";

type ISecuredWrapperProps = {
    permission: ScopePermission|null;
    wrapper: (children: ReactElement) => JSX.Element;
    children: ReactElement;
    inline?: boolean;
}

const SecuredWrapper: React.FC<ISecuredWrapperProps> = ({
    permission,
    wrapper,
    children,
    inline
}) => {
    const { isAllowed } = useSecurityContext();

    return (permission == null || isAllowed(permission)) ? wrapper(children) : (
        <SecuredComponentWrapper inline={inline} allowed={false} permission={permission}>
            {children}
        </SecuredComponentWrapper>
    );
}



export default SecuredWrapper;