import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { defaultDrawerWidth } from "../Layout/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clearOption: {
            opacity: 0.5,
        },
        noOptionButton: {
            opacity: 0.5,
        }
    })
);

export default useStyles;
