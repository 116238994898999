import React from "react";
import { ICrudDetailLoadedProps, ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";
import HubRolesProvider from "./HubRolesContext";
import HubRolesList from "./HubRolesList";
import HubRoleAddForm from "./HobRoleAddForm";
import { HubResponse } from "../../api/types";

interface IHubRolesProp extends ICrudDetailLoadedProps<HubResponse> {

}

const HubRoles: React.FC<IHubRolesProp> = ({ data  }) => {

    return (

        <HubRolesProvider hubID={data.hubID}>
            <HubRoleAddForm />
            <HubRolesList />
        </HubRolesProvider>
    );
};

export default HubRoles;