import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    IconButton,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    Popover,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import Icon from "../Icon/Icon";
import { ProjectData } from "../ProjectSelectorWrapper/type";
import messages from "./messages";
import useStyles from "./styles";
import { match, matchPath, useLocation } from "react-router-dom";
import { appPages } from "../../pages/PageRouter";
import { HubResponse } from "../../packages/Hubs/api/types";
import { Backspace, Search } from "@material-ui/icons";
import { filterProjects, getEnhancedHubProjects } from "../../redux/project/utils";
import ProjectSelectorProjects from "./ProjectSelectorProjects";
import ProjectSelectorHub from "./ProjectSelectorHub";
import ProjectSelectorHubs from "./ProjectSelectorHubs";

interface IProjectSelectorProps {
    projects: ProjectData[] | undefined;
    hubs?: HubResponse[];
    activeProject?: ProjectData;
    loading: boolean;
    onOpened?: () => void
}


const ProjectSelector: FC<IProjectSelectorProps> = ({ projects, hubs, activeProject, loading, onOpened}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const { pathname } = useLocation();

    const [ currentRoute, setCurrentRoute ] = useState<match|null>();
    const [ filteredProjects, setFilteredProjects ] = useState<ProjectData[]>();
    const [ switchAccountsMode, setSwitchAccountMode ] = useState<boolean>();
    const [ selectedHub, setSelectedHub ] = useState<HubResponse>();

    const [ searchText, setSearchText ] = useState<string>("");

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "project-selector-popover" : undefined;

    useEffect(() => {
        const routes = appPages.map(a => a.path);
        const route = matchPath(pathname, routes);
        setCurrentRoute(route)
    }, [pathname]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
    };

    const handleSelectProject = (project: ProjectData) => {
        handleClosePopover();
    };

    // translations
    const intl = useIntl();
    const transSelect = intl.formatMessage({ ...messages.select });
    const transProjects = intl.formatMessage({ ...messages.projects });
    const transHubs = intl.formatMessage({ ...messages.hubs });
    const transBackToProjects = intl.formatMessage({ ...messages.backToProjects });
    const transSearchAllProjects = intl.formatMessage({ ...messages.searchAllProjects });

    // cleanup on exit
    const handleExit = useCallback(() => {
        setSwitchAccountMode(false);
        setSelectedHub(undefined);
        setFilteredProjects(undefined);
        setSearchText("");
    }, []);


    const handleSearch = useCallback((event) => {
        setSearchText(event.target.value);
    }, []);

    useEffect(() => {
        if (searchText) {
            setFilteredProjects(filterProjects(searchText, projects));
        }
        else {
            setFilteredProjects(undefined);
        }
    }, [searchText])

    const handleSwitchAccountMode = (showAccounts: boolean) => () => {
        setSwitchAccountMode(showAccounts)
    }
    const handleSwitchHub = useCallback((hub: HubResponse) => {
        setSelectedHub(hub)
        setSwitchAccountMode(false);
    },[]);

    const handleClearSearch = useCallback(() => {
        setSearchText("");
    }, []);

    const list: ProjectData[]|undefined = useMemo(() => {
        if (filteredProjects) {
            return filteredProjects;
        }
        if (selectedHub) {
            return getEnhancedHubProjects(selectedHub as HubResponse);
        }
        if (activeProject) {
            return getEnhancedHubProjects(activeProject.hub as HubResponse);
        }
        return projects

    }, [filteredProjects, selectedHub, projects, activeProject]);

    const currentHub: HubResponse|undefined = useMemo(() => {

        return selectedHub ?? activeProject?.hub;
    }, [selectedHub, activeProject]);

    const handleEnter = () => {
        if (onOpened) {
            onOpened();
        }
    }

    const hudAdminClickHandler = () => {
        handleClosePopover();
    }

    return (
        <>
            <Button onClick={handleOpenPopover} className={classes.button}>
                {activeProject ? activeProject.name : transSelect}
                <Icon
                    name={openPopover ? "triangle-up-solid" : "triangle-down-solid"}
                    size={10}
                    className={classes.icon}
                />
            </Button>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                TransitionProps={{
                    onExited: handleExit,
                    onEntering: handleEnter,
                }}
            >
               {loading ? (
                    <List component="nav" aria-label="pages" classes={{ root: classes.listRoot }}>
                        <ListItem>
                            <ListItemText
                                primary={<CircularProgress size={18} className={classes.circularProgress} />}
                                classes={{
                                    primary: classes.listItemTextLoading,
                                }}
                            />
                        </ListItem>
                    </List>
                ) : switchAccountsMode ? (
                    <>
                        <Box
                            className={classes.paddedBox}
                        >
                            <Button
                                variant={"outlined"}
                                onClick={handleSwitchAccountMode(false)}
                                startIcon={<Backspace />}
                            >
                                {transBackToProjects}
                            </Button>
                        </Box>
                        <Divider />
                        <Typography variant={"caption"} className={classes.listCaption}>{transHubs}</Typography>
                        <ProjectSelectorHubs hubs={hubs} switchHubHandler={handleSwitchHub} hudAdminClickHandler={hudAdminClickHandler} />
                    </>
                   ) : (
                    <>
                        <Box
                            className={classes.paddedBox}
                        >
                            <TextField
                                id="search"
                                placeholder={transSearchAllProjects}
                                // variant="filled"
                                onChange={handleSearch}
                                className={classes.search}
                                autoFocus
                                value={searchText}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>,
                                    endAdornment: searchText && <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={handleClearSearch}
                                        >
                                            <Backspace />
                                        </IconButton>
                                    </InputAdornment>,
                                }}
                            />
                        </Box>
                        <Divider />
                        {activeProject && !filteredProjects && currentHub && (
                            <>
                                <ProjectSelectorHub hub={currentHub} switchHubHandler={handleSwitchAccountMode(true)} hudAdminClickHandler={hudAdminClickHandler} />
                                <Divider />
                            </>
                        )}
                        <Typography variant={"caption"} className={classes.listCaption}>{transProjects}</Typography>
                        <List component="nav" aria-label="pages" classes={{ root: classes.listRoot }}>
                            <ProjectSelectorProjects projects={list} activeProject={activeProject} onSelectProject={handleSelectProject} />
                        </List>
                    </>
                )}
            </Popover>
        </>
    );
};

export default ProjectSelector;
