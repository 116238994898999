import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            margin: theme.spacing(1, 0),
        },
        buttons: {
            textAlign: "right",
        },
        gridItemContent: {
            padding: theme.spacing(1),
        },
    })
);

export default useStyles;
