import { AxiosResponse } from "axios";
import config from "../../../config/config";
import ApiClient from "../../ApiClient/ApiClient";
import {
    HubCompanyRequest,
    HubCompanyResponse,
    HubMembersResponse, HubMemberUpdateRequest,
    HubRequest,
    HubResponse, HubRoleModuleLevelRequest,
    HubRoleRequest,
    HubRolesResponse,
} from "./types";
import {SearchMemberLeadResponse } from "../../Api/data/projectMembers/search-member-lead-response";
import { IoLabsBimProjectsApiModelsSearchMemberLeadRequest as SearchMemberLeadRequest } from "../../Api/data/projectMembers/io-labs-bim-projects-api-models-search-member-lead-request";
import { AddMembersRequest } from "../../Api/data/projectMembers/add-members-request";
import { IoLabsBimProjectsApiModelsModuleAccess as ModuleAccess } from "../../Api/data/projectMembers/io-labs-bim-projects-api-models-module-access";

const hubsApi = new ApiClient("/Hubs", config.api.baseUrl);

/**
 * Get hubs
 */
export const getHubs = (forceFetch: boolean = false): Promise<HubResponse[]> =>
    hubsApi
        .get(`/List`, {
            clearCacheEntry: forceFetch,
        })
        .then((response: AxiosResponse<HubResponse[]>) => {
            return Promise.resolve(response.data);
        });

/**
 * Get hub detail
 */
export const getHub = (hubID: number, forceFetch: boolean = false): Promise<HubResponse> =>
    hubsApi
        .get(`/${hubID}`, {
            clearCacheEntry: forceFetch,
        })
        .then((response: AxiosResponse<HubResponse>) => {
            return Promise.resolve(response.data);
        });

/**
 * Get hubs
 */
export const createHub = (hub: HubRequest): Promise<HubResponse> =>
    hubsApi
        .put(`/Create`, hub)
        .then((response: AxiosResponse<HubResponse>) => {
            return Promise.resolve(response.data);
        });

/**
 * Update hub
 */
export const updateHub = (hubID: number, hub: HubRequest): Promise<HubResponse> =>
    hubsApi
        .post(`/${hubID}/Update`, hub)
        .then((response: AxiosResponse<HubResponse>) => {
            return Promise.resolve(response.data);
        });
/**
 * Delete hub
 */
export const deleteHub = (hubID: number): Promise<void> =>
    hubsApi
        .delete(`/${hubID}/Delete`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve();
        });


/**
 * Returns a hub leads
 */
export const searchHubLeads = (hubID: number, text: string): Promise<SearchMemberLeadResponse> => {
    return hubsApi.post(`/${hubID}/Members/Leads`, {
        text: text
    } as SearchMemberLeadRequest).then((response: AxiosResponse<SearchMemberLeadResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Add members to hub
 */
export const addMemberToHub = (hubID: number, ids: number[]): Promise<void> => {
    return hubsApi.put(`/${hubID}/Members`, {
        userIdentifiers: ids
    } as AddMembersRequest).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get hub members
 */
export const getHubMembers = (hubID: number, refetch: boolean = false): Promise<HubMembersResponse> => {
    return hubsApi.get(`/${hubID}/Members`,{
        clearCacheEntry: refetch,
    }).then((response: AxiosResponse<HubMembersResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update hub member
 */
export const updateMember = (hubID: number, memberID: number, data: HubMemberUpdateRequest): Promise<void> => {
    return hubsApi.post(`/${hubID}/Members/${memberID}`, data).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Delete member
 */
export const deleteHubMember = (hubID: number, memberID: number): Promise<void> => {
    return hubsApi.delete(`/${hubID}/Members/${memberID}`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Add roles to hub
 */
export const addRoleToHub = (hubID: number, hubRole: HubRoleRequest): Promise<void> => {
    return hubsApi.put(`/${hubID}/Roles`, hubRole).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get hub roles
 */
export const getHubRoles = (hubID: number, refetch: boolean = false): Promise<HubRolesResponse> => {
    return hubsApi.get(`/${hubID}/Roles`,{
        clearCacheEntry: refetch,
    }).then((response: AxiosResponse<HubRolesResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Set access
 */
export const setRoleAccess = (hubID: number, roleID: number, access?: ModuleAccess[], fullList?: boolean, isProjectAdmin?: boolean): Promise<void> => {
    const data: HubRoleModuleLevelRequest = {
        accessToModules: access,
        isProjectAdmin: isProjectAdmin,
        fullList: fullList
    };

    return hubsApi.post(`/${hubID}/Roles/${roleID}/Access`, data).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Delete role
 */
export const deleteHubRole = (hubID: number, roleID: number): Promise<void> => {
    return hubsApi.delete(`/${hubID}/Roles/${roleID}`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get hub companies
 */
export const getHubCompanies = (hubID: number, refetch: boolean = false): Promise<HubCompanyResponse[]> => {
    return hubsApi.get(`/${hubID}/Companies`,{
        clearCacheEntry: refetch,
    }).then((response: AxiosResponse<HubCompanyResponse[]>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get hub company
 */
export const getHubCompany = (hubID: number, companyID: number, refetch: boolean = false): Promise<HubCompanyResponse> => {
    return hubsApi.get(`/${hubID}/Companies/${companyID}`,{
        clearCacheEntry: refetch,
    }).then((response: AxiosResponse<HubCompanyResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Add company to hub
 */
export const addCompanyToHub = (hubID: number, company: HubCompanyRequest): Promise<HubCompanyResponse> => {
    return hubsApi.put(`/${hubID}/Companies`, company).then((response: AxiosResponse<HubCompanyResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Delete company
 */
export const deleteHubCompany = (hubID: number, companyID: number): Promise<void> => {
    return hubsApi.delete(`/${hubID}/Companies/${companyID}`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update company
 */
export const updateHubCompany = (hubID: number, companyID: number, data: HubCompanyRequest): Promise<HubCompanyResponse> => {
    return hubsApi.post(`/${hubID}/Companies/${companyID}`, data).then((response: AxiosResponse<HubCompanyResponse>) => {
        return Promise.resolve(response.data);
    });
};


/**
 * Synchronize resources
 */
export const syncHubResources = (hubID: number): Promise<void> => {
    return hubsApi.get(`/${hubID}/SyncResources`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Synchronize all hub projects modules and resources
 */
export const inititalizeProjects = (hubID: number): Promise<void> => {
    return hubsApi.get(`/${hubID}/InitProjects`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};
