import { useGlobalDialog } from "@iolabs/wip-dialog";
import { List, ListItem, ListItemIcon, ListItemText, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { AppPage, appPages } from "../../pages/PageRouter";
import isMenuItemActive, { useGetPath } from "../../utils/Menu";
import Icon from "../Icon/Icon";
import useStyles from "./styles";
import SecuredComponent from "../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IDrawerProps extends React.HTMLAttributes<HTMLElement> {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
}

const Menu: React.FC<IDrawerProps> = ({ handleDrawerClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { setGlobalDialogOpen } = useGlobalDialog();

    const { getPagePath, getPermission } = useGetPath();

    const location = useLocation();

    const handleClickMenuItem = () => {
        handleDrawerClose();
    };

    const renderMenuItem = (page: AppPage, index) => {
        const path = getPagePath(page);
        const isActive = isMenuItemActive(location.pathname, path);
        const permission = getPermission(page);
        return (
            <SecuredComponent permission={permission} key={index}>
                <ListItem
                    button
                    onClick={() => (page?.wip ? setGlobalDialogOpen(true) : handleClickMenuItem())}
                    className={clsx(classes.listItem, {
                        [classes.active]: isActive,
                    })}
                    disabled={page?.wip}
                >
                    <Link
                        to={path}
                    >
                        <ListItemIcon className={classes.listItemIcon}>
                            <Icon
                                name={page.icon}
                                fill={
                                    isActive
                                        ? theme.palette.secondary.main
                                        : theme.palette.text.secondary
                                }
                                size={18}
                                className={classes.menuIcon}
                            />
                        </ListItemIcon>
                        <ListItemText primary={page.title} className={classes.menuText} />
                    </Link>
                </ListItem>
            </SecuredComponent>
        );
    }

    return (
        <List component="nav" aria-labelledby="menu" className={classes.root}>
            {appPages
                .filter((page) => page?.inMenu)
                .map((page, index) => renderMenuItem(page, index))}
        </List>
    );
};

export default Menu;
