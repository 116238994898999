import { useProjectTitle } from "@iolabs/app";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import {
    Avatar,
    Box,
    Button,
    ClickAwayListener,
    Divider, Link,
    ListItemIcon,
    MenuItem,
    MenuList,
    Paper,
    Popover,
    Toolbar,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LanguageIcon from "@material-ui/icons/Language";
import clsx from "clsx";
import { KeycloakProfile } from "keycloak-js";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import useHeaderContent from "../HeaderProvider/useHeaderContent";
import messages from "./messages";
import useStyles, { HeaderStylesProps } from "./styles";
import { useLanguageProvider } from "@iolabs/language-provider";
import { useKeycloak } from "@react-keycloak/web";
import config from "../../../config/config";

interface IHeaderProps {
    drawerOpen: boolean;
    handleDrawerOpen: () => void;
    logo?: string;
    drawerDisabled?: boolean;
    stylesProps?: HeaderStylesProps;
}

const Header: React.FC<IHeaderProps> = ({ drawerOpen, stylesProps }) => {
    const classes = useStyles(stylesProps);
    const theme = useTheme();
    const { keycloak } = useKeycloak();
    const [menuOpen, setMenuOpen] = useState(false);
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [userData, setUserData] = useState<KeycloakProfile | null>(null);
    const projectTitle = useProjectTitle();
    const { headerContent, headerSettings } = useHeaderContent();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

    const handleMenuToggle = () => {
        setMenuOpen((prevMenuOpen) => !prevMenuOpen);
    };

    const handleMenuClose = (event: React.MouseEvent<EventTarget>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setMenuOpen(false);
    };

    const handleSignOut = () => {
        keycloak?.logout();
    };


    // language switcher
    const { languages, currentLanguage, setCurrentLanguage } = useLanguageProvider();
    const handleLanguageToggle = (language) => {
        setCurrentLanguage(language);
        setMenuOpen(false);
    }


    function handleListKeyDown(event: React.KeyboardEvent) {
        if (event.key === "Tab") {
            event.preventDefault();
            setMenuOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevMenuOpen = React.useRef(menuOpen);

    useEffect(() => {
        Promise.resolve(keycloak?.loadUserProfile()).then((data) => {
            setUserData(data as KeycloakProfile);
            console.log('userdata', data);
        });

        if (prevMenuOpen.current && !menuOpen) {
            anchorRef.current!.focus();
        }

        prevMenuOpen.current = menuOpen;
    }, [keycloak, menuOpen]);

    // translations
    const intl = useIntl();
    const transSignOut = intl.formatMessage({ ...messages.signOut });
    const transProfile = intl.formatMessage({ ...messages.profile });

    const getUserAcronym = () => {
        if (!userData) {
            return "?";
        }
        let acronym = "";
        if (userData.firstName) {
            acronym += userData.firstName.charAt(0);
        }
        if (userData.lastName) {
            acronym += userData.lastName.charAt(0);
        }
        if (!acronym.length && userData.username) {
            acronym = userData.username.substring(0,2);
        }
        return acronym;
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.close]: !drawerOpen,
            })}
        >
            <Box className={classes.headingBox}>{headerContent}</Box>
            <Box className={classes.menu}>
                {isDesktop ? headerSettings : null}

                {userData && (
                    <>
                        <Button
                            ref={anchorRef}
                            aria-controls={menuOpen ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            disableRipple
                            disableFocusRipple
                            disableTouchRipple
                            variant="text"
                            onClick={handleMenuToggle}
                            className={classes.menuButton}
                        >
                            <Avatar className={classes.avatar}>
                                {getUserAcronym()}
                            </Avatar>
                        </Button>
                        <Popover
                            open={menuOpen}
                            anchorEl={anchorRef.current}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            className={classes.popper}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleMenuClose}>
                                    <MenuList
                                        autoFocusItem={menuOpen}
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}
                                        className={classes.menuList}
                                    >
                                        <Box className={classes.menuBox}>
                                            {projectTitle && (
                                                <>
                                                    <MenuItem>{`${projectTitle}`}</MenuItem>
                                                    <Divider className={classes.divider} />
                                                </>
                                            )}
                                            <Box onClick={handleMenuClose}>{headerSettings}</Box>
                                        </Box>

                                        {languages?.map((language, index) => {
                                            if (language !== currentLanguage) {
                                                return (
                                                  <MenuItem onClick={() => handleLanguageToggle(language)} key={index}>
                                                      <ListItemIcon className={classes.menuItemIcon}>
                                                          <LanguageIcon fontSize="small"/>
                                                      </ListItemIcon>
                                                      <Typography variant="inherit">{language?.toUpperCase()}</Typography>
                                                  </MenuItem>
                                                )
                                            } else {
                                                return null;
                                            }
                                        })}
                                        <MenuItem>
                                            <Link href={`${config.security.realmUrl}/realms/${config.security.realm}/account/`} target={"_blank"}>
                                                <ListItemIcon className={classes.menuItemIcon}>
                                                    <AccountCircleIcon fontSize="small" />
                                                </ListItemIcon>
                                                <Typography variant="inherit">{transProfile}</Typography>
                                            </Link>
                                        </MenuItem>
                                        <MenuItem onClick={handleSignOut}  >
                                            <ListItemIcon className={classes.menuItemIcon}>
                                                <ExitToAppIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{transSignOut}</Typography>
                                        </MenuItem>



                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Popover>
                    </>
                )}
            </Box>
        </Toolbar>
    );
};

export default Header;
