import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
        },
        actions: {
            paddingTop: theme.spacing(2),
        },
        modules: {
            paddingTop: theme.spacing(2),
        },
        section: {
            marginTop: theme.spacing(2),
        },
        sectionHeader: {
            marginBottom: theme.spacing(1),
        },
    })
);

export default useStyles;
