import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IMappingEquipment, IMappingEquipmentAddRequest, IMappingEquipmentUpdateRequest } from "./types";
import fileDownload from "js-file-download";

const equipmentApi = new ApiClient("/Equipment", config.api.baseUrl);

/**
 * Returns a list of all equipment.
 */
export const getEquipmentCatalogue = (): Promise<IMappingEquipment[]> => {

    return equipmentApi
        .get("/list", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IMappingEquipment[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Create equipment into catalogue and return created entity.
 * @param data
 */
export const addEquipmentCatalogue = (data: IMappingEquipmentAddRequest): Promise<IMappingEquipment> => {
    return equipmentApi.post("/add", data).then((response: AxiosResponse<IMappingEquipment>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update equipment in catalogue and return updated entity.
 * @param equipmentID
 * @param data
 */
export const updateEquipmentCatalogue = (
    equipmentID: number,
    data: IMappingEquipmentUpdateRequest
): Promise<IMappingEquipment> => {
    return equipmentApi.post(`/${equipmentID}/update`, data).then((response: AxiosResponse<IMappingEquipment>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Deletes equipment from catalogue.
 * @param equipmentID
 */
export const deleteEquipmentCatalogue = (equipmentID: number): Promise<boolean> => {
    return equipmentApi.get(`/${equipmentID}/delete`).then((response: AxiosResponse<boolean>) => {
        return Promise.resolve(response.status === 204);
    });
};

/**
 * Export equipment, file is downloaded
 */
export const exportEquipment = (): Promise<void> => {
    return equipmentApi
        .get(`/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "equipment.xlsx");
            return;
        });
};

/**
 * Import equipment, file is uploaded
 * @param data
 */
export const importEquipment = (
    data: FormData
): Promise<void> => {
    return equipmentApi
        .post(`/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return Promise.resolve(response.data);
        })
        .catch((response) => {
            return Promise.reject(response.data);
        });
};