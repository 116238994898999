import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IoLabsUsersApiModelsUserResponse } from "../io-labs-users-api-models-user-response";
import { IoLabsUsersApiModelsUserRequest } from "../io-labs-users-api-models-user-request";

const usersApi = new ApiClient("/Users", config.api.baseUrl);

/**
 * Get users
 */
export const getUsers = (forceFetch: boolean = false): Promise<IoLabsUsersApiModelsUserResponse[]> =>
    usersApi
        .get(`/List`, {
            clearCacheEntry: forceFetch,
        })
        .then((response: AxiosResponse<IoLabsUsersApiModelsUserResponse[]>) => {
            return Promise.resolve(response.data);
        });

/**
 * Get users
 */
export const createUser = (user: IoLabsUsersApiModelsUserRequest): Promise<IoLabsUsersApiModelsUserResponse> =>
    usersApi
        .post(`/New`, user)
        .then((response: AxiosResponse<IoLabsUsersApiModelsUserResponse>) => {
            return Promise.resolve(response.data);
        });

/**
 * Update user
 */
export const updateUser = (userID: number, user: IoLabsUsersApiModelsUserRequest): Promise<IoLabsUsersApiModelsUserResponse> =>
    usersApi
        .post(`/${userID}`, user)
        .then((response: AxiosResponse<IoLabsUsersApiModelsUserResponse>) => {
            return Promise.resolve(response.data);
        });
/**
 * Delete user
 */
export const deleteUser = (userID: number): Promise<void> =>
    usersApi
        .delete(`/${userID}/Delete`)
        .then((response: AxiosResponse<void>) => {
            return Promise.resolve();
        });
