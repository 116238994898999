import React, { ReactElement } from "react";
import useStyles from "./styles";
import { Box, Tooltip } from "@material-ui/core";
import { useSecurityContext } from "../securityContext";
import { ScopePermission } from "./permissions";
import { Alert } from "@material-ui/lab";
import { useIntl } from "react-intl";
import messages from "./messages";
import clsx from "clsx";
import { useGetPath } from "../../../utils/Menu";

export enum SecurityMode {
    FORBIDDEN,
    HIDDEN,
    DISABLED,
    FALLBACK
}

interface ISecuredComponentProps {
    permission?: ScopePermission|null;
    inline?: boolean;
    className?: string;
    securityMode?: SecurityMode;
    fallbackComponent?: ReactElement;
    isProjectPermission?: boolean;
}
interface IComponentWrapperProps {
    permission?: ScopePermission|null;
    allowed: boolean;
    inline?: boolean;
}

export const SecuredComponentWrapper:  React.FC<IComponentWrapperProps> = ({
    allowed,
    children,
    permission,
    inline
}) => {
    const classes = useStyles();

    return permission  ? (
            <Tooltip title={`${permission.resource}#${permission.scope}`}>
                <Box className={clsx('ContentWrapper', allowed ? classes.allowed : classes.notAllowed, {[classes.wrapperInline]: inline})}>
                    {children}
                </Box>
            </Tooltip>
    ) : (
        <>
            {children}
        </>
    )
}

const SecuredComponent: React.FC<ISecuredComponentProps> = ( {
    permission,
    children ,
    inline,
    className,
    securityMode,
    fallbackComponent
} ) => {
    const classes = useStyles();
    const { isAllowed } = useSecurityContext();

    const { getProjectPermission } = useGetPath();

    // translations
    const intl = useIntl();
    const transForbidden = intl.formatMessage({ ...messages.forbidden });

    if (permission?.isProject) {
        permission = getProjectPermission(permission.resource, permission.scope);
    }

    const getContent = () => {
        if (!permission || isAllowed(permission)) {
            return (
                <SecuredComponentWrapper inline={inline} allowed={true} permission={permission}>
                    {children}
                </SecuredComponentWrapper>
            )
        }
        else {
            switch (securityMode) {
                case SecurityMode.HIDDEN:
                    return null;
                case SecurityMode.FALLBACK:
                    return (
                        <SecuredComponentWrapper inline={inline} allowed={false} permission={permission}>
                            {fallbackComponent}
                        </SecuredComponentWrapper>
                    );
                case SecurityMode.FORBIDDEN:
                    return (
                        <SecuredComponentWrapper inline={inline} allowed={false} permission={permission}>
                            <Alert color={"error"}>{transForbidden}</Alert>
                        </SecuredComponentWrapper>
                    );
                case SecurityMode.DISABLED:
                default:
                    return (
                        <SecuredComponentWrapper inline={inline} allowed={false} permission={permission}>
                            {children}
                        </SecuredComponentWrapper>
                    );
            }
        }
    }

    return (
        <Box className={clsx(classes.root,{[classes.inline]: inline}, className)}>
            {getContent()}
        </Box>
    );
};

export default SecuredComponent;
