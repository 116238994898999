import { DispatchAction } from "@iolabs/redux-utils";
import { IElementsPosition, IManualPosition } from "../../../packages/Api/data/elements/types";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { Box, Button } from "@material-ui/core";
import { TextField } from "mui-rff";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { useIntl } from "react-intl";
import messages from "./messages";
import useStyles from "./styles";
import { IManualPositionFormErrors, IManualPositionFormValues } from "./type";
import { onUpdateManualPosition } from "../../../redux/mapping";
import { useDispatch } from "react-redux";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";

interface IManualPositionQuantityFormProps {
    position: IElementsPosition;
    manualPosition?: IManualPosition;
    manualPositionLoading?: boolean;
    disableSubmit?: true;
}
const ManualPositionQuantityForm: React.FC<IManualPositionQuantityFormProps> = ({ position, manualPosition , manualPositionLoading, disableSubmit}) => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();

    const [
        initialManualPositionFormValues,
        setInitialManualPositionFormValues,
    ] = useState<IManualPositionFormValues | null>(null);

    // translations
    const intl = useIntl();
    const transFieldValue = intl.formatMessage({ ...messages.fieldValue });
    const transFieldReference = intl.formatMessage({ ...messages.fieldReference });
    const transFieldFormula = intl.formatMessage({ ...messages.fieldFormula });
    const transFieldParameter = intl.formatMessage({ ...messages.fieldParameter });
    const transFieldSpecify = intl.formatMessage({ ...messages.fieldSpecify });
    const transValidateValue = intl.formatMessage({ ...messages.validateValue });

    useEffect(() => {
        console.log("XXX manualPosition", manualPosition);
        setInitialManualPositionFormValues({
            value: manualPosition?.value ? +(manualPosition?.value as number).toFixed(2) : 0,
        });
    }, [manualPosition]);

    const validateManualPosition = (values: IManualPositionFormValues): IManualPositionFormErrors | {} => {
        const errors: IManualPositionFormErrors | {} = {};

        if (!values.value) {
            (errors as IManualPositionFormErrors).value = transValidateValue;
        }

        return errors;
    };

    const onSubmitManualPosition = useCallback(async (values: IManualPositionFormValues) => {
        dispatch(
            onUpdateManualPosition({
                projectID: activeProject?.projectID as number,
                positionID: position?.positionID as number,
                data: {
                    value: values?.value,
                },
            })
        );
    }, []);

    return (
        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
            <Form
                onSubmit={onSubmitManualPosition}
                initialValues={initialManualPositionFormValues}
                validate={validateManualPosition}
                className={classes.form}
            >
                {({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <Box className={classes.fieldBox}>
                            <TextField
                                required
                                name="value"
                                label={transFieldValue}
                                placeholder={transFieldSpecify}
                                type="number"
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="reference"
                                label={transFieldReference}
                                placeholder={transFieldSpecify}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="formula"
                                label={transFieldFormula}
                                placeholder={transFieldSpecify}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                name="parameter"
                                label={transFieldParameter}
                                placeholder={transFieldSpecify}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Box>
                        {!disableSubmit && (
                            <Box mt={1} display="flex" flexDirection="row" justifyContent="center">
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="secondary"
                                    size="medium"
                                    disabled={submitting || pristine || manualPositionLoading}
                                >
                                    Save
                                </Button>
                            </Box>
                        )}
                    </form>
                )}
            </Form>
        </SecuredComponent>
    );

};

export default ManualPositionQuantityForm;
