import { LanguageProvider } from "@iolabs/language-provider";
import { GlobalDialogProvider } from "@iolabs/wip-dialog";
import { ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import GraphqlProvider from "../../graphql/GraphqlProvider";
import SecurityProvider from "../../redux/keyclock/provider";
import StoreProvider from "../../redux/provider";
import theme from "../../theme/Theme";
import de from "../../translations/de.json";
import en from "../../translations/en.json";
import App from "../App/App";
import PermissionsInfo from "../../redux/keyclock/PermissionInfo/PermissionsInfo";

const AppWrapper: React.FC = () => {
    return (
        <StoreProvider>
            <ThemeProvider theme={theme}>
                <SecurityProvider>
                        <GraphqlProvider>
                            <LanguageProvider
                                defaultLocale="de"
                                messages={{
                                    de,
                                    en,
                                }}
                            >
                                <GlobalDialogProvider>
                                    <App />
                                    <PermissionsInfo />
                                </GlobalDialogProvider>
                            </LanguageProvider>
                        </GraphqlProvider>
                </SecurityProvider>
            </ThemeProvider>
        </StoreProvider>
    );
};

export default AppWrapper;
