import React from "react";
import { Checkbox } from "@material-ui/core";

export interface IActionCheckboxSwitchProps {
    onChange: (checked: boolean) => void,
    checked: boolean;
}

const ActionCheckboxSwitch: React.FC<IActionCheckboxSwitchProps> = ({ onChange, checked}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked)
    }

    return (
        <Checkbox
            autoFocus
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
        />
    );
};

export default ActionCheckboxSwitch;