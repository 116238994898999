import { defineMessages } from "react-intl";

const messages = defineMessages({
    settings: {
        id: "components.header.headerSettings.settings",
        defaultMessage: "Settings",
    },
    newProject: {
        id: "components.header.headerSettings.newProject",
        defaultMessage: "New project",
    },
    projectSettings: {
        id: "components.header.headerSettings.projectSettings",
        defaultMessage: "Project settings",
    },
    mappingManager: {
        id: "components.header.headerSettings.mappingManager",
        defaultMessage: "Mapping manager",
    },
    users: {
        id: "components.header.headerSettings.users",
        defaultMessage: "Users",
    },
    hubs: {
        id: "components.header.headerSettings.hubs",
        defaultMessage: "Hubs",
    },
    dashboard: {
        id: "components.header.headerSettings.dashboard",
        defaultMessage: "Dashboard",
    },
});

export default messages;
