import React, { useCallback } from "react";
import { ICrudDetailLoadedProps, ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";
import CrudModule, { ItemField } from "../../../Crudder/CrudModule";
import { GridRowsProp } from "@mui/x-data-grid";
import ProjectNewWizard from "../../../../components/Project/ProjectNewWizard/ProjectNewWizard";
import { ICreateProjectResponseData, IProjectListResponseData } from "../../../Api/data/projects/types";
import { useHistory } from "react-router-dom";
import { TabSlugs } from "../HubModule/HubModule";
import { useRouteMatch } from "react-router";
import { HubResponse } from "../../api/types";

interface IHubProjectsProps extends ICrudDetailLoadedProps<HubResponse> {

}


const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "name",
        title: "Name",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "code",
        title: "Code",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "description",
        title: "Description",
        columnDef: {
            flex: 3
        },
    },
];

const HubProjects: React.FC<IHubProjectsProps> = ( { config, itemFields, data, loading, onSubmit, backLink} ) => {

    const history = useHistory();
    let { path } = useRouteMatch();

    const getData = useCallback(() => {
        return new Promise<IProjectListResponseData[]>((resolve, reject) => {
            // const projectsData = data?.projects?.map((project) => {
            //     return {
            //         ...project,
            //         id: project.projectID
            //     }
            // });

            resolve(data?.projects ? data?.projects : []);
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<IProjectListResponseData>((resolve, reject) => {
            const projectData = data?.projects?.find(p => p.projectID == id);
            if (projectData) {
                resolve(projectData)
            }
            else {
                reject("Project not found");
            }

        });
    }, []);

    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<number>((resolve, reject) => {
            // if (formValues["hubID"]) {
            //     // update
            //     updateHub(formValues["hubID"], formValues as IoLabsBimProjectsApiModelsHubRequest).then((response) => {
            //         resolve(response.hubID);
            //     })
            // }
            // else {
            //     // create
            //     createHub(formValues as IoLabsBimProjectsApiModelsHubRequest).then((response) => {
            //         resolve(response.hubID);
            //     })
            // }

        })
    }, []);

    const handleCreateNew = (response: ICreateProjectResponseData) => {
        history.push(path);
    }

    return (
        <>
            <CrudModule<IProjectListResponseData, IProjectListResponseData>
                name={"Hub projects"}
                list={{
                    title: "Hubs projects list",
                    getRowID: (project) => (project.projectID)
                }}
                detail={{
                    title: "Hub project detail",
                    componentNew: () => (
                        <ProjectNewWizard
                            hub={data}
                            onCreateNew={handleCreateNew}
                        />
                    )
                }}
                itemFields={fields}
                data={getData}
                getDetail={getDetail}
                onSubmit={handleSubmit}
            />
        </>
    );
};
export default HubProjects;