import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { TreeNodeType } from "../../../packages/Api/data/tree/client";
import { ITree } from "../../../packages/Api/data/tree/types";
import { useActiveProject } from "../../../redux/project";
import { onManualData, onResetData, useActiveNodeId, useData, useLoading } from "../../../redux/tree";
import Icon from "../../Icon/Icon";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import Tree from "../Tree/Tree";
import messages from "./messages";
import useStyles from "./styles";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";

export enum DataType {
    Hub = "hub",
    Project = "project",
    Folder = "folder",
    Item = "item",
    Version = "version",
}

interface ITreeWrapperProps {
    projectIdFromParam: string;
    activeFoldersIds: string[];
}

const TreeWrapper: React.FC<ITreeWrapperProps> = ({ projectIdFromParam, activeFoldersIds }) => {
    const classes = useStyles();
    const treeData: ITree[] = useData();
    const activeNodeId: string = useActiveNodeId();
    const treeLoading: boolean = useLoading();
    const { tokenInitialized } = useSecurityContext();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();

    useEffect(() => {
        if (tokenInitialized && activeProject) {
            dispatch(
                onManualData({
                    data: {
                        nodeType: TreeNodeType?.Project,
                        parentId: activeProject?.externalData?.[0]?.accountUrn,
                        nodeId: activeProject?.externalData?.[0]?.projectUrn,
                        depth: 2,
                        rootId: activeProject?.externalData?.[0]?.accountUrn,
                    },
                })
            );
        }
        return () => {
            dispatch(onResetData());
        };
    }, [activeProject]);

    const skeleton = (
        <Box p={1} display="flex" flexDirection="column" alignItems="flex-start" mt={-1}>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton20} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton30} />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} className={classes.skeleton10} />
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="text" width="60%" height={40} />
        </Box>
    );

    // translations
    const intl = useIntl();
    const transEmptyProject = intl.formatMessage({ ...messages.emptyProject });

    return (
        <>
            {treeData ? (
                <>
                    {treeData.length !== 0 ? (
                        <Tree
                            projectIdFromParam={projectIdFromParam}
                            activeFoldersIds={activeFoldersIds}
                            treeData={treeData}
                            treeLoading={treeLoading}
                        />
                    ) : (
                        <>
                            {treeLoading ? (
                                <>{skeleton}</>
                            ) : (
                                <Box className={classes.emptyProject}>
                                    <Icon name="folder-open" size={60} />
                                    <Typography variant="body2">{transEmptyProject}</Typography>
                                </Box>
                            )}
                        </>
                    )}
                </>
            ) : (
                <>{skeleton}</>
            )}
        </>
    );
};

export default TreeWrapper;
