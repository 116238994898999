import { DispatchAction } from "@iolabs/redux-utils";
import { Box, CircularProgress, IconButton, InputAdornment, TextField } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { IAttachedWork, IElementsPosition } from "../../../packages/Api/data/elements/types";
import {
    onDeleteAttachedWork,
    onUpdateAttachedWork,
    onWorkCatalogue,
    useDetailPosition,
    useMappingAttachedWork,
    useMappingWorkLoading,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { formatNumber } from "../../../utils/Formatter";
import Icon from "../../Icon/Icon";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

export enum UpdatableField {
    kMenge = "kMenge",
    liPrice = "liPrice",
}

interface IAttachedWorkUpdate extends IAttachedWork {
    isUpdateMode?: {
        [key: string]: boolean;
    };
}

interface IStepWorkFormWorkProps {}

const StepWorkFormWork: React.FC<IStepWorkFormWorkProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const mappingWorkLoading: boolean = useMappingWorkLoading();
    const mappingAttachedWork: IAttachedWork[] = useMappingAttachedWork();

    const [updateAttachedWork, setUpdateAttachedWork] = useState<IAttachedWorkUpdate[]>([]);
    const [updateAttachedWorkID, setUpdateAttachedWorkID] = useState<number | null>(null);
    const kMengeRefs = useRef(new WeakMap());
    const liPriceRefs = useRef(new WeakMap());

    const handleToggleUpdateMode = (attachedWorkID: number, fieldName: UpdatableField) => {
        setUpdateAttachedWork((prevState) => {
            return prevState?.map((awu) => {
                return awu?.attachedWorkID === attachedWorkID && fieldName === UpdatableField.liPrice
                    ? {
                          ...awu,
                          isUpdateMode: {
                              ...awu?.isUpdateMode,
                              liPrice: !awu?.isUpdateMode?.liPrice,
                          },
                      }
                    : awu?.attachedWorkID === attachedWorkID && fieldName === UpdatableField.kMenge
                    ? {
                          ...awu,
                          isUpdateMode: {
                              ...awu?.isUpdateMode,
                              kMenge: !awu?.isUpdateMode?.kMenge,
                          },
                      }
                    : awu;
            });
        });
    };

    const handleChange = (event, row: IAttachedWorkUpdate) => {
        const value = parseFloat(event.target.value);
        const name = event.target.name;
        const updatedAttachedWork = updateAttachedWork?.map((awu) => {
            return awu?.attachedWorkID === row?.attachedWorkID
                ? name === "liPrice"
                    ? {
                          ...awu,
                          work: {
                              ...awu?.work,
                              [name]: value,
                          },
                      }
                    : {
                          ...awu,
                          [name]: value,
                      }
                : awu;
        });
        setUpdateAttachedWork(updatedAttachedWork);
    };

    const handleUpdateWork = (attachedWorkID: number, fieldName: UpdatableField) => {
        setUpdateAttachedWorkID(attachedWorkID);
        const updatedAttachedWork = updateAttachedWork?.find((awu) => awu?.attachedWorkID === attachedWorkID);
        if (!updatedAttachedWork) return;

        dispatch(
            onUpdateAttachedWork({
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                attachedWorkID: updatedAttachedWork?.attachedWorkID,
                data: {
                    liPrice: updatedAttachedWork?.work?.liPrice as number,
                    kMenge: updatedAttachedWork?.kMenge as number,
                },
            })
        );
        handleToggleUpdateMode(updatedAttachedWork?.attachedWorkID, fieldName);
    };

    const handleDelete = (attachedWorkID: number) => () => {
        if (activeProject && detailPosition) {
            dispatch(
                onDeleteAttachedWork({
                    projectID: activeProject?.projectID,
                    positionID: detailPosition?.positionID,
                    attachedWorkIDs: [attachedWorkID],
                })
            );
            dispatch(onWorkCatalogue({ }));
        }
    };

    useEffect(() => {
        setUpdateAttachedWork(mappingAttachedWork as IAttachedWorkUpdate[]);
        setUpdateAttachedWorkID(null);
    }, [mappingAttachedWork]);

    // translations
    const intl = useIntl();
    const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
    const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
    const transLabelName = intl.formatMessage({ ...messages.labelName });
    const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
    const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Box>
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
            </Box>
            <Box mt={3}>
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
                <Skeleton variant="rect" height={20} width={20} className={classes.skeletonCircle} />
            </Box>
        </Box>
    );

    return (
        <Box>
            {mappingWorkLoading && updateAttachedWork?.length === 0 ? (
                <>{skeleton}</>
            ) : (
                <>
                    {updateAttachedWork?.length === 0 ? (
                        <span className={classes.empty}>Leer</span>
                    ) : (
                        <>
                            {updateAttachedWork?.map((awu, index) => (
                                <Box className={classes.fieldBox} key={index}>
                                    <TextField
                                        label={transLabelItemNumber}
                                        value={awu?.work?.itemNumber}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transLabelProductId}
                                        value={awu?.work?.productID}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transLabelName} // Product name
                                        value={awu?.work?.productName}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                                        <TextField
                                            name="kMenge"
                                            label="K.-Menge" // kMenge // todo translate de
                                            value={!awu?.isUpdateMode?.kMenge && awu?.kMenge ? formatNumber(awu?.kMenge) : awu?.kMenge}
                                            type={!awu?.isUpdateMode?.kMenge ? "text" : "number"}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.textField}
                                            inputRef={(input) => kMengeRefs.current.set(awu, input)}
                                            onFocus={(event) => {
                                                if (awu?.isUpdateMode?.kMenge) {
                                                    event.target.select();
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUpdateWork(
                                                        awu?.attachedWorkID,
                                                        UpdatableField.kMenge
                                                    )
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (awu?.isUpdateMode?.kMenge) {
                                                    handleChange(event, awu);
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                readOnly: !awu?.isUpdateMode?.kMenge,
                                            }}
                                            onClick={() => {
                                                if (!awu?.isUpdateMode?.kMenge) {
                                                    // focus input on update click
                                                    kMengeRefs.current.get(awu).focus();

                                                    handleToggleUpdateMode(
                                                        awu?.attachedWorkID,
                                                        UpdatableField.kMenge
                                                    );
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {mappingWorkLoading &&
                                                        updateAttachedWorkID === awu?.attachedWorkID ? (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        ) : (
                                                            <>
                                                                {awu?.isUpdateMode?.kMenge ? (
                                                                    <>
                                                                        <IconButton
                                                                            aria-label="save changes"
                                                                            onClick={() =>
                                                                                handleUpdateWork(
                                                                                    awu?.attachedWorkID,
                                                                                    UpdatableField.kMenge
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                name="check"
                                                                                size={18}
                                                                                className={classes.iconSave}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="cancel"
                                                                            onClick={() =>
                                                                                handleToggleUpdateMode(
                                                                                    awu?.attachedWorkID,
                                                                                    UpdatableField.kMenge
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                name="close"
                                                                                size={18}
                                                                                className={classes.iconClose}
                                                                            />
                                                                        </IconButton>
                                                                    </>
                                                                ) : (
                                                                    <IconButton
                                                                        aria-label="toggle update mode"
                                                                        onClick={() => {
                                                                            // focus input on update click
                                                                            kMengeRefs.current.get(awu).focus();

                                                                            handleToggleUpdateMode(
                                                                                awu?.attachedWorkID,
                                                                                UpdatableField.kMenge
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon name="pencil-solid" size={18} />
                                                                    </IconButton>
                                                                )}
                                                            </>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </SecuredComponent>
                                    <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                                        <TextField
                                            name="liPrice"
                                            label={transLabelListPrice}// li price
                                            value={!awu?.isUpdateMode?.liPrice && awu?.work?.liPrice ? formatNumber(awu?.work?.liPrice) : awu?.work?.liPrice}
                                            type={!awu?.isUpdateMode?.liPrice ? "text" : "number"}
                                            fullWidth
                                            margin="normal"
                                            variant="outlined"
                                            color="primary"
                                            className={classes.textField}
                                            inputRef={(input) => liPriceRefs.current.set(awu, input)}
                                            onFocus={(event) => {
                                                if (awu?.isUpdateMode?.liPrice) {
                                                    event.target.select();
                                                }
                                            }}
                                            onChange={(event) => {
                                                if (awu?.isUpdateMode?.liPrice) {
                                                    handleChange(event, awu);
                                                }
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    handleUpdateWork(
                                                        awu?.attachedWorkID,
                                                        UpdatableField.kMenge
                                                    )
                                                }
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                readOnly: !awu?.isUpdateMode?.liPrice,
                                            }}
                                            onClick={() => {
                                                if (!awu?.isUpdateMode?.liPrice) {
                                                    // focus input on update click
                                                    liPriceRefs.current.get(awu).focus();

                                                    handleToggleUpdateMode(
                                                        awu?.attachedWorkID,
                                                        UpdatableField.liPrice
                                                    );
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        {mappingWorkLoading &&
                                                        updateAttachedWorkID === awu?.attachedWorkID ? (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        ) : (
                                                            <>
                                                                {awu?.isUpdateMode?.liPrice ? (
                                                                    <>
                                                                        <IconButton
                                                                            aria-label="save changes"
                                                                            onClick={() =>
                                                                                handleUpdateWork(
                                                                                    awu?.attachedWorkID,
                                                                                    UpdatableField.liPrice
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                name="check"
                                                                                size={18}
                                                                                className={classes.iconSave}
                                                                            />
                                                                        </IconButton>
                                                                        <IconButton
                                                                            aria-label="cancel"
                                                                            onClick={() =>
                                                                                handleToggleUpdateMode(
                                                                                    awu?.attachedWorkID,
                                                                                    UpdatableField.liPrice
                                                                                )
                                                                            }
                                                                        >
                                                                            <Icon
                                                                                name="close"
                                                                                size={18}
                                                                                className={classes.iconClose}
                                                                            />
                                                                        </IconButton>
                                                                    </>
                                                                ) : (
                                                                    <IconButton
                                                                        aria-label="toggle update mode"
                                                                        onClick={() => {
                                                                            // focus input on update click
                                                                            liPriceRefs.current.get(awu).focus();

                                                                            handleToggleUpdateMode(
                                                                                awu?.attachedWorkID,
                                                                                UpdatableField.liPrice
                                                                            );
                                                                        }}
                                                                    >
                                                                        <Icon name="pencil-solid" size={18} />
                                                                    </IconButton>
                                                                )}
                                                            </>
                                                        )}
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </SecuredComponent>
                                    <TextField
                                        label="EP" // todo translate de
                                        value={awu?.work?.ep && formatNumber(awu?.work?.ep)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {mappingWorkLoading &&
                                                        updateAttachedWorkID === awu?.attachedWorkID && (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <TextField
                                        label={transLabelSurcharge}
                                        value={awu?.work?.surcharge && `${formatNumber(awu?.work?.surcharge)} %`}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label="EP/Menge" // todo translate de
                                        value={awu?.epPerMenge && formatNumber(awu?.epPerMenge)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {mappingWorkLoading &&
                                                        updateAttachedWorkID === awu?.attachedWorkID && (
                                                            <CircularProgress
                                                                size={16}
                                                                className={classes.circularProgress}
                                                            />
                                                        )}
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    <Box display="flex" alignItems="center">
                                        <IconButton
                                            aria-label="remove"
                                            color="secondary"
                                            className={classes.removeButton}
                                            onClick={handleDelete(awu?.attachedWorkID)}
                                        >
                                            <Icon name="minus" size={20} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            ))}
                        </>
                    )}
                </>
            )}
        </Box>
    );
};

export default StepWorkFormWork;
