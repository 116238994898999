import { AutocompleteData } from "mui-rff";
import { useSelector } from "react-redux";
import { INormalizationFunction, IStepperAttributeFormValues } from "../../components/Mapping/StepAttribute/type";
import { IQuery, IQueryOperator } from "../../components/Mapping/ElementQueryBuilder/type";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork, IDeletePositionsResponse,
    IDerivedPosition,
    IElementsPosition,
    IManualPosition,
    IPositionFormConfiguration,
} from "../../packages/Api/data/elements/types";
import { IMappingAttribute } from "../../packages/Api/data/mapping/types";
import { IMappingMaterial } from "../../packages/Api/data/material/types";
import { IMappingWork } from "../../packages/Api/data/work/types";
import {
    selectCatalogLeafPositions,
    selectDetailContext,
    selectDetailPosition, selectDetailPositionError, selectDetailPositionLoading,
    selectElementQuery,
    selectElementQueryAttributes,
    selectElementQueryLoading,
    selectElementQueryOperators,
    selectElementQueryPsets, selectIndexedPositions, selectInstancesAttributes, selectInstancesAttributesLoading,
    selectMappingAttachedEquipment,
    selectMappingAttachedGraphics,
    selectMappingAttachedMaterial,
    selectMappingAttachedMaterialAdditional,
    selectMappingAttachedMaterialPhysical,
    selectMappingAttachedWork,
    selectMappingAttribute,
    selectMappingAttributeAutocompleteAttributes,
    selectMappingAttributeAutocompletePsets,
    selectMappingAttributeFormValues,
    selectMappingAttributeLoading,
    selectMappingAttributeNormalizationFunctions, selectMappingBasicPositions, selectMappingBasicPositionsLoading,
    selectMappingCatalogLoading,
    selectMappingCatalogPositionIDs,
    selectMappingCheckedCatalog,
    selectMappingCheckedMaster,
    selectMappingDerivedPositions,
    selectMappingDerivedPositionsLoading,
    selectMappingEquipmentCatalogue,
    selectMappingEquipmentLoading,
    selectMappingGraphicsCatalogue,
    selectMappingGraphicsLoading,
    selectMappingGraphicsPatternCatalogue,
    selectMappingGraphicsPatternLoading,
    selectMappingManualPosition,
    selectMappingManualPositionLoading,
    selectMappingMaterialCatalogue,
    selectMappingMaterialLoading,
    selectMappingPositionFormConfiguration,
    selectMappingPositionFormConfigurationLoading,
    selectMappingPositions,
    selectMappingPositionsDeleteResult,
    selectMappingPositionsLoading,
    selectMappingUnitPrice,
    selectMappingUnitPriceLoading,
    selectMappingUpdatePositionBasics,
    selectMappingUpdatePositionBasicsLoading,
    selectMappingWorkCatalogue,
    selectMappingWorkLoading,
    selectMasterLeafPositions, selectViewable,
} from "./selector";
import { IIndexedPosition, IMappingContext } from "./type";
import { IMappingEquipment } from "../../packages/Api/data/equipment/types";
import { IGraphics, IGraphicsPattern } from "../../packages/Api/data/graphics/types";
import { ProjectFileVersionViewable } from "../../graphql/generated/graphql";
import { InstancesAttributes } from "../../packages/Api/data/lookup/types";

// mapping positions
export const useMappingPositionsLoading = (): boolean => useSelector(selectMappingPositionsLoading);
export const useMappingPositions = (): IElementsPosition[] => useSelector(selectMappingPositions);
export const useMappingPositionsDeleteResult = (): IDeletePositionsResponse => useSelector(selectMappingPositionsDeleteResult) as IDeletePositionsResponse;
export const useIndexedPositions = (): IIndexedPosition[] => useSelector(selectIndexedPositions);

// catalog positions
export const useMappingCatalogLoading = (): boolean => useSelector(selectMappingCatalogLoading);
export const useMappingCatalogPositionIDs = (): number[] => useSelector(selectMappingCatalogPositionIDs);

// checked master/catalog
export const useMappingCheckedMaster = (): number[] => useSelector(selectMappingCheckedMaster);
export const useMappingCheckedCatalog = (): number[] => useSelector(selectMappingCheckedCatalog);

// catalog
export const useDetailPosition = (): IElementsPosition | undefined => useSelector(selectDetailPosition);
export const useDetailPositionLoading = (): boolean | undefined => useSelector(selectDetailPositionLoading);
export const useDetailPositionError = (): any | undefined => useSelector(selectDetailPositionError);

export const useMasterLeafPositions = (): IElementsPosition[] => useSelector(selectMasterLeafPositions);
export const useCatalogLeafPositions = (): IElementsPosition[] => useSelector(selectCatalogLeafPositions);
export const useDetailContext = (): IMappingContext | undefined => useSelector(selectDetailContext);

// element query
export const useMappingQueryLoading = (): boolean => useSelector(selectElementQueryLoading);
export const useMappingQuery = (): IQuery | undefined => useSelector(selectElementQuery);
export const useMappingQueryPsets = (): string[] => useSelector(selectElementQueryPsets);
export const useMappingQueryAttributes = (): string[] => useSelector(selectElementQueryAttributes);
export const useMappingQueryOperators = (): IQueryOperator[] | null => useSelector(selectElementQueryOperators);

export const useSelectedViewable = (): ProjectFileVersionViewable | undefined => useSelector(selectViewable);

// instances attributes
export const useInstancesAttributes = (): InstancesAttributes | undefined => useSelector(selectInstancesAttributes);
export const useInstancesAttributesLoading = (): boolean => useSelector(selectInstancesAttributesLoading);

// derived positions
export const useMappingDerivedPositionsLoading = (): boolean => useSelector(selectMappingDerivedPositionsLoading);
export const useMappingDerivedPositions = (): IDerivedPosition[] => useSelector(selectMappingDerivedPositions);

// basic positions
export const useMappingBasicPositionsLoading = (): boolean => useSelector(selectMappingBasicPositionsLoading);
export const useMappingBasicPositions = (): IDerivedPosition[] => useSelector(selectMappingBasicPositions);

// attribute
export const useMappingAttributeLoading = (): boolean => useSelector(selectMappingAttributeLoading);
export const useMappingAttribute = (): IMappingAttribute | null => useSelector(selectMappingAttribute);
export const useMappingAttributeAutocompletePsets = (): AutocompleteData[] =>
    useSelector(selectMappingAttributeAutocompletePsets);
export const useMappingAttributeAutocompleteAttributes = (): AutocompleteData[] =>
    useSelector(selectMappingAttributeAutocompleteAttributes);
export const useMappingAttributeFormValues = (): IStepperAttributeFormValues | undefined =>
    useSelector(selectMappingAttributeFormValues);
export const useMappingAttributeNormalizationFunctions = (): INormalizationFunction[] | null =>
    useSelector(selectMappingAttributeNormalizationFunctions);

// work catalogue
export const useMappingWorkLoading = (): boolean => useSelector(selectMappingWorkLoading);
export const useMappingWorkCatalogue = (): IMappingWork[] => useSelector(selectMappingWorkCatalogue);
export const useMappingAttachedWork = (): IAttachedWork[] => useSelector(selectMappingAttachedWork);

// graphics pattern catalogue
export const useGraphicsPatternLoading = (): boolean => useSelector(selectMappingGraphicsPatternLoading);
export const useGraphicsPatternCatalogue = (): IGraphicsPattern[] => useSelector(selectMappingGraphicsPatternCatalogue);

// graphics catalogue
export const useGraphicsLoading = (): boolean => useSelector(selectMappingGraphicsLoading);
export const useGraphicsCatalogue = (): IGraphics[] => useSelector(selectMappingGraphicsCatalogue);
export const useAttachedGraphics = (): IGraphics | undefined => useSelector(selectMappingAttachedGraphics);

// equipment catalogue
export const useMappingEquipmentLoading = (): boolean => useSelector(selectMappingEquipmentLoading);
export const useMappingEquipmentCatalogue = (): IMappingEquipment[] => useSelector(selectMappingEquipmentCatalogue);
export const useMappingAttachedEquipment = (): IAttachedEquipment[] => useSelector(selectMappingAttachedEquipment);

// material catalogue
export const useMappingMaterialLoading = (): boolean => useSelector(selectMappingMaterialLoading);
export const useMappingMaterialCatalogue = (): IMappingMaterial[] => useSelector(selectMappingMaterialCatalogue);

// attached material - all
export const useMappingAttachedMaterial = (): IAttachedMaterial[] => useSelector(selectMappingAttachedMaterial);
// attached material - PHYSICAL
export const useMappingAttachedMaterialPhysical = (): IAttachedMaterial[] =>
    useSelector(selectMappingAttachedMaterialPhysical);
// attached material - ADDITIONAL
export const useMappingAttachedMaterialAdditional = (): IAttachedMaterial[] =>
    useSelector(selectMappingAttachedMaterialAdditional);

// unit price
export const useMappingUnitPriceLoading = (): boolean => useSelector(selectMappingUnitPriceLoading);
export const useMappingUnitPrice = (): number => useSelector(selectMappingUnitPrice);

// update position basics
export const useMappingUpdatePositionBasicsLoading = (): boolean =>
    useSelector(selectMappingUpdatePositionBasicsLoading);
export const useMappingUpdatePositionBasics = (): IElementsPosition | undefined => useSelector(selectMappingUpdatePositionBasics);

// get manual position
// update manual position
export const useMappingManualPositionLoading = (): boolean => useSelector(selectMappingManualPositionLoading);
export const useMappingManualPosition = (): IManualPosition | undefined => useSelector(selectMappingManualPosition);

// get position form configuration
export const useMappingPositionFormConfigurationLoading = (): boolean =>
    useSelector(selectMappingPositionFormConfigurationLoading);
export const useMappingPositionFormConfiguration = (): IPositionFormConfiguration | undefined =>
    useSelector(selectMappingPositionFormConfiguration);
