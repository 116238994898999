import React from "react";
import { useDetailPosition } from "../../../redux/mapping";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import SubcontractorPriceForm from "./SubcontractorForm";
import { Skeleton } from "@material-ui/lab";

interface IStepWorkFormSubcontractorProps {
}

const StepWorkFormSubcontractor: React.FC<IStepWorkFormSubcontractorProps> = ({ }) => {

    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    return detailPosition ? (
        <SubcontractorPriceForm position={detailPosition} />
    )
    : (
        <Skeleton />
    );
};

export default StepWorkFormSubcontractor;
