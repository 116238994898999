import { defineMessages } from "react-intl";

export default defineMessages({
    search: {
        id: "components.memberSearch.search",
        defaultMessage: "Search",
    },
    alreadyMember: {
        id: "components.memberSearch.alreadyMember",
        defaultMessage: "Already a member",
    },
    addMember: {
        id: "components.memberSearch.addMember",
        defaultMessage: "Add member",
    },
});
