import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Icon from "../../components/Icon/Icon";
import MappingDetailView from "../../components/Mapping/MappingDetailView/MappingDetailView";
import MappingMainView from "../../components/Mapping/MappingMainView/MappingMainView";
import MappingPanel from "../../components/Mapping/MappingPanel/MappingPanel";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { IElementsPosition } from "../../packages/Api/data/elements/types";
import { useDetailPosition } from "../../redux/mapping";
import { useActiveProject } from "../../redux/project";
import Page from "../Page/Page";
import useStyles from "./styles";
import { IMappingNavigation } from "./type";
import { Skeleton } from "@material-ui/lab";

const MappingManagerPage: React.FC = () => {
    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const [navigation, setNavigation] = useState<IMappingNavigation>({
        mainView: true, // Hauptansicht / Detailansicht
        showDerived: true, // Abgeleitete anzeigen
        showHierarchy: true, // eBKP-H hierarchie anzeigen
    });

    const handleChangeNavigation = (isRadio = false) => (event: React.ChangeEvent<HTMLInputElement>): void => {
        setNavigation((prevState) => ({
            ...prevState,
            [event.target.name]: isRadio ? event.target.value === "true" : event.target.checked,
        }));
    };

    const handleChangeMainView = (mainView: boolean): void => {
        console.log("XX changing view", mainView)
        setNavigation((prevState) => ({
            ...prevState,
            mainView,
        }));
    };

    useEffect(() => {
        // if (!detailPosition && navigation?.mainView === false) {
        //     setNavigation((prevState) => ({
        //         ...prevState,
        //         mainView: true,
        //     }));
        // }
    }, [detailPosition]);

    // TODO: translations (Jakub Jirous 2021-03-10 11:22:46)

    console.log("XX navigation?.mainView", navigation?.mainView);
    console.log("XX detailPosition", detailPosition);

    return (
        <Page noPadding>
            {activeProject?.isInitialized ? (
                <Box className={classes.columnBox}>
                    <MappingPanel navigation={navigation} handleChangeNavigation={handleChangeNavigation} />

                    {navigation?.mainView ? (
                        <MappingMainView navigation={navigation} handleChangeMainView={handleChangeMainView} />
                    ) : (
                        detailPosition ? (
                            <MappingDetailView />
                        ) : (
                            <Skeleton />
                        )
                    )}
                </Box>
            ) : (
                <Box className={classes.emptyBox}>
                    <Icon name="construction" size={60} className={classes.emptyIcon} />
                    <Typography variant="body2">
                        {!activeProject
                            ? "No mapping is available. Please select the project." // todo translate de
                            : `No mapping is available for ${activeProject?.name}.`}
                    </Typography>
                </Box>
            )}
        </Page>
    );
};

export default MappingManagerPage;
