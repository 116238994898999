import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { createProject, getProjectOptions, updateProject } from "../../../packages/Api/data/projects/client";
import {
    ICreateProjectResponseData,
    IProjectOptionsResponseData,
    IProjectRequestData,
} from "../../../packages/Api/data/projects/types";
import { loadHubs, useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import ProjectForm from "../ProjectForm/ProjectForm";
import { IProjectFormValues } from "../ProjectForm/type";
import { useDispatch } from "react-redux";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";
import { useGetProjectQuery } from "../../../graphql/generated/graphql";
import { getFileUrnFromProject } from "../../Viewer/utils/Project";

interface IProjectFormWrapper {
    editMode?: boolean;
    initialValues?: IProjectFormValues;
    onCreateNew?: (response: ICreateProjectResponseData) => void;
}

const ProjectFormWrapper: React.FC<IProjectFormWrapper> = ({ editMode , initialValues, onCreateNew}) => {
    const { tokenInitialized } = useSecurityContext();
    const activeProject: ProjectData | undefined = useActiveProject();
    const dispatch = useDispatch<DispatchAction>();

    const [loading, setLoading] = useState<boolean>(false);
    const [options, setOptions] = useState<IProjectOptionsResponseData>();
    const [initialFormValues, setInitialFormValues] = useState<IProjectFormValues | null>(initialValues ?? null);
    const [formValues, setFormValues] = useState<IProjectFormValues | null>(null);


    const { data, loading: loadingProjects, error: errorProjects } = useGetProjectQuery({
        variables: {
            code: activeProject?.code,
        },
        skip: !activeProject?.code
    });

    let urn;
    if (activeProject?.code) {
        const project = data?.projects?.[0];
        urn = getFileUrnFromProject(project);
    }
    else {
        urn = null;
    }

    const validate = (values: IProjectFormValues): IProjectFormValues | {} => {
        const errors: IProjectFormValues | {} = {};

        if (!values?.name) {
            (errors as IProjectFormValues).name = "Please enter a project name";
        }
        if (!values?.code) {
            (errors as IProjectFormValues).code = "Please enter a code";
        }
        // if (!values?.lengthUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a length unit";
        // }
        // if (!values?.areaUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a area unit";
        // }
        // if (!values?.volumeUnitID) {
        //     (errors as INewProjectFormValues).lengthUnitID = "Please select a volume unit";
        // }
        // if (!values?.temperatureUnitID) {
        //     (errors as INewProjectFormValues).temperatureUnitID = "Please select a temperature unit";
        // }
        // if (!values?.currencyID) {
        //     (errors as INewProjectFormValues).currencyID = "Please select a currency";
        // }
        // if (!values?.masterFileName) {
        //     (errors as INewProjectFormValues).masterFileName = "Please enter a master file name";
        // }
        // if (!values?.updateTypeID) {
        //     (errors as INewProjectFormValues).updateTypeID = "Please select a type of data update";
        // }
        if (!values?.projectUrn) {
            (errors as IProjectFormValues).projectUrn = "Please enter a project URN";
        }
        if (!values?.accountUrn) {
            (errors as IProjectFormValues).accountUrn = "Please enter a account URN";
        }
        // if (!values?.fileUrn) {
        //     (errors as INewProjectFormValues).fileUrn = "Please enter a file URN";
        // }

        return errors;
    };

    const isInEditMode = () : boolean => {
        return !!(editMode && activeProject?.projectID);
    }
    const isInInitMode = () : boolean => {
        return !!(editMode && !activeProject?.projectID);
    }

    const onSubmit = (values: IProjectFormValues) => {
        return new Promise<void>((resolve, reject) => {
            if (!tokenInitialized) return;

            setFormValues(values);

            const data: IProjectRequestData = {
                hubID: values.hubId as number,
                name: values?.name as string,
                description: values?.description,
                code: values?.code as string,
                lengthUnitID: values?.lengthUnitID,
                areaUnitID: values?.areaUnitID,
                volumeUnitID: values?.volumeUnitID,
                temperatureUnitID: values?.temperatureUnitID,
                currencyID: values?.currencyID,
                updateTypeID: values?.updateTypeID,
                masterFileName: values?.masterFileName,
                externalData: [
                    {
                        externalSystemCode: "forge",
                        accountUrn: values?.accountUrn as string,
                        projectUrn: values?.projectUrn as string,
                    },
                ],
                externalMasterFileData: [
                    {
                        externalSystemCode: "forge",
                        fileUrn: values?.fileUrn as string,
                    },
                ],
                revitEnhancersIDs: values.revitEnhancersIDs
            };


            if (isInEditMode()) {
                // edit
                updateProject(activeProject?.projectID as number, data)
                    .then(async (response) => {
                        // update project list
                        await dispatch(loadHubs({ forceReload: true }));
                        resolve();
                    })
                    .catch((erroe) => {
                        console.log(erroe);
                        reject(erroe);
                    });
            }
            else {
                // insert
                createProject(data)
                    .then((response) => {
                        // update project list
                        dispatch(loadHubs({ forceReload: true }));
                        if (onCreateNew) {
                            onCreateNew(response);
                        }
                        resolve();
                    })
                    .catch((erroe) => {
                        console.log(erroe);
                        reject(erroe);
                    });
            }
        })
    }

    useEffect(() => {
        if (editMode && activeProject && options) {
            setInitialFormValues({
                name: activeProject?.name,
                description: "",
                code: activeProject?.code,
                lengthUnitID: "",
                areaUnitID: "",
                volumeUnitID: "",
                temperatureUnitID: "",
                currencyID: "",
                updateTypeID: "",
                masterFileName: "",
                projectUrn: activeProject?.externalData?.[0]?.projectUrn,
                accountUrn: activeProject?.externalData?.[0]?.accountUrn,
                fileUrn: urn,
                revitEnhancersIDs: options ? options.revitEnhancers.filter(re => activeProject?.revitEnhancers.includes(re.code)).map(re => re.revitEnhancerID) : [],
            });
        }
    }, [editMode, activeProject, options, data]);

    useEffect(() => {
        if (!tokenInitialized) return;

        setLoading(true);
        getProjectOptions().then((response) => {
            setOptions(response);
            setLoading(false);
        });
    }, [tokenInitialized]);

    return (
        <ProjectForm
            loading={loading}
            options={options}
            initialFormValues={initialFormValues}
            formValues={formValues}
            validate={validate}
            onSubmit={onSubmit}
            editMode={isInEditMode()}
            initMode={isInInitMode()}
        />
    );
};

export default ProjectFormWrapper;
