/* tslint:disable */
/* eslint-disable */
/**
 * Grimm Zapper API
 * API definitions for Grimm.Zapper.App
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/**
 * Project member Level.
 * @export
 * @enum {string}
 */
export enum IoLabsCoreDataEnumsProjectMemberLevel {
    Member = 'member',
    Administrator = 'administrator'
}

