import React, { useCallback } from "react";
import { ICrudDetailLoadedProps, ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";
import CrudModule, { ItemField } from "../../../Crudder/CrudModule";
import { GridRowsProp } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { addCompanyToHub, deleteHubCompany, getHubCompanies, getHubCompany, updateHubCompany } from "../../api/client";
import { HubCompanyRequest, HubCompanyResponse, HubResponse } from "../../api/types";
import { deleteUser } from "../../../Users/api/endpoints/client";

interface IHubProjectsProps extends ICrudDetailLoadedProps<HubResponse> {

}


const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "name",
        title: "Name",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "description",
        title: "Description",
        columnDef: {
            flex: 3
        },
    },
    {
        name: "vatNumber",
        title: "VAT",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "telephone",
        title: "Telephone",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "email",
        title: "Email",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "website",
        title: "Website",
        columnDef: {
            flex: 2
        },
    },
];

const HubCompanies = ({ config, itemFields, data, loading, onSubmit, backLink} : ICrudDetailProps<HubResponse> ) => {

    const history = useHistory();
    let { path } = useRouteMatch();

    const getData = useCallback(() => {
        return new Promise<HubCompanyResponse[]>((resolve, reject) => {
            getHubCompanies((data as HubResponse).hubID, true)
                .then((list) => {
                    resolve(list);
                }).catch((e) => {
                    reject(e);
                })
        });
    }, []);

    const getDetail = useCallback((id: string|number) => {
        return new Promise<HubCompanyResponse>((resolve, reject) => {
            getHubCompany((data as HubResponse).hubID, id as number, true)
                .then((company) => {
                    resolve(company);
                })
                .catch((e) => {
                    reject(e);
                })
        });
    }, []);

    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<number>((resolve, reject) => {
            
            if (formValues["companyID"]) {
                // update
                updateHubCompany((data as HubResponse).hubID, formValues["companyID"], formValues as unknown as HubCompanyRequest)
                    .then((response) => {
                        resolve(response.companyID);
                    })
                    .catch(reject)
                ;
            }
            else {
                // create
                addCompanyToHub((data as HubResponse).hubID, formValues as unknown as HubCompanyRequest)
                    .then((response) => {
                        resolve(response.companyID);
                    })
                    .catch(reject)
                ;
            }
        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteHubCompany((data as HubResponse).hubID, id).then((response) => {
                resolve();
            })
        });
    }, []);

    return (
        <>
            <CrudModule<HubCompanyResponse, HubCompanyResponse>
                name={"Hub companies"}
                list={{
                    title: "Hubs company list",
                    getRowID: (row) => row.companyID
                }}
                detail={{
                    title: "Hub company detail",
                }}
                itemFields={fields}
                data={getData}
                getDetail={getDetail}
                onSubmit={handleSubmit}
                onDelete={handleDelete}
            />
        </>
    );
};
export default HubCompanies;
