import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IGraphics, IGraphicsAddRequest, IGraphicsPattern, IGraphicsUpdateRequest } from "./types";
import fileDownload from "js-file-download";

const graphicsApi = new ApiClient("/Graphics", config.api.baseUrl);

/**
 * Returns a list of all graphics.
 */
export const getGraphicsCatalogue = (): Promise<IGraphics[]> => {
    return graphicsApi
        .get("/list", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IGraphics[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Returns a list of all graphics patterns.
 */
export const getGraphicsPatternCatalogue = (): Promise<IGraphicsPattern[]> => {
    return graphicsApi
        .get("/patterns", {
            clearCacheEntry: true,
        })
        .then((response: AxiosResponse<IGraphicsPattern[]>) => {
            return Promise.resolve(response.data);
        });
};

/**
 * Create graphics into catalogue and return created entity.
 * @param data
 */
export const addGraphicsCatalogue = (data: IGraphicsAddRequest): Promise<IGraphics> => {
    return graphicsApi.post("/add", data).then((response: AxiosResponse<IGraphics>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update graphics in catalogue and return updated entity.
 * @param graphicsID
 * @param data
 */
export const updateGraphicsCatalogue = (
    graphicsID: number,
    data: IGraphicsUpdateRequest
): Promise<IGraphics> => {
    return graphicsApi.post(`/${graphicsID}/update`, data).then((response: AxiosResponse<IGraphics>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Deletes graphics from catalogue.
 * @param graphicsID
 */
export const deleteGraphicsCatalogue = (graphicsID: number): Promise<boolean> => {
    return graphicsApi.get(`/${graphicsID}/delete`).then((response: AxiosResponse<boolean>) => {
        return Promise.resolve(response.status === 204);
    });
};

/**
 * Export graphics, file is downloaded
 */
export const exportGraphics = (): Promise<void> => {
    return graphicsApi
        .get(`/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "graphics.xlsx");
            return;
        });
};

/**
 * Import graphics, file is uploaded
 * @param data
 */
export const importGraphics = (
    data: FormData
): Promise<void> => {
    return graphicsApi
        .post(`/import`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            return Promise.resolve(response.data)
        })
        .catch((response) => {
            return Promise.reject(response.data)
        });
};