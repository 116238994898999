import { defineMessages } from "react-intl";

export default defineMessages({
    buttonSynchronize: {
        id: "components.actionButton.buttonSynchronize",
        defaultMessage: "Synchronize",
    },
    processingDone: {
        id: "components.actionButton.processingDone",
        defaultMessage: "Processing done",
    },
    processingError: {
        id: "components.actionButton.processingError",
        defaultMessage: "Processing error",
    },
});
