import { DispatchAction } from "@iolabs/redux-utils";
import { useGlobalDialog } from "@iolabs/wip-dialog";
import { Box, Button, InputAdornment, MenuItem, TextField as MuiTextField, Typography } from "@material-ui/core";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import { TextField } from "mui-rff";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { IMappingNavigation } from "../../../pages/MappingManagerPage/type";
import {
    onAddCatalogPositionIDs,
    onCatalogPositionIDs,
    onCheckedCatalog,
    onCheckedMaster,
    onDeleteCatalogPositionIDs,
    onMappingPositions,
    useMappingCheckedCatalog,
    useMappingCheckedMaster,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import PositionDialog from "../Dialogs/PositionDialog/PositionDialog";
import MasterDataTreeWrapper from "../MasterDataTreeWrapper/MasterDataTreeWrapper";
import ProjectCatalogPopover from "../ProjectCatalogPopover/ProjectCatalogPopover";
import ProjectCatalogTreeWrapper from "../ProjectCatalogTreeWrapper/ProjectCatalogTreeWrapper";
import useStyles from "./styles";
import { exportPositions, importPositions } from "../../../packages/Api/data/elements/client";
import ExporterImporter from "../../ExporterImporter/ExporterImporter";
import { useIntl } from "react-intl";
import messages from "./messages";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { useGetPath } from "../../../utils/Menu";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IMappingMainViewProps {
    navigation: IMappingNavigation;
    handleChangeMainView: (mainView: boolean) => void;
}

const MappingMainView: React.FC<IMappingMainViewProps> = ({ navigation, handleChangeMainView }) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const { setGlobalDialogOpen } = useGlobalDialog();
    const activeProject: ProjectData | undefined = useActiveProject();
    const checkedMaster: number[] = useMappingCheckedMaster();
    const checkedCatalog: number[] = useMappingCheckedCatalog();

    const [initialValues] = useState();
    const [formValues, setFormValues] = useState();

    const [searchMaster, setSearchMaster] = useState<string>("");
    const [searchCatalog, setSearchCatalog] = useState<string>("");

    const { getProjectPermission } = useGetPath();

    const handleSearchMaster = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchMaster(event.target.value);
    };

    const handleClearSearchMaster = () => {
        setSearchMaster("");
    };

    const handleSearchCatalog = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchCatalog(event.target.value);
    };

    const handleClearSearchCatalog = () => {
        setSearchCatalog("");
    };

    const validate = (values) => {
        const errors: any = {};
        // if (!values.name) {
        //     errors.name = "Required";
        // }
        return errors;
    };

    // react final form submitting
    const onSubmit = useCallback(async (values) => {
        setFormValues(values);

        // TODO: call axios API requests (Jakub Jirous )
    }, []);

    const handleToggle = (positionID: number, isMaster: boolean) => {
        const currentIndex = isMaster ? checkedMaster.indexOf(positionID) : checkedCatalog.indexOf(positionID);
        const newChecked = isMaster ? [...checkedMaster] : [...checkedCatalog];

        if (currentIndex === -1) {
            newChecked.push(positionID);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        isMaster
            ? dispatch(onCheckedMaster({ checked: newChecked }))
            : dispatch(onCheckedCatalog({ checked: newChecked }));
    };

    const handleAddPositions = () => {
        if (activeProject) {
            dispatch(
                onAddCatalogPositionIDs({
                    projectID: activeProject?.projectID,
                    positionIDs: checkedMaster,
                })
            );
        }
    };

    const handleDeletePositions = () => {
        if (activeProject) {
            dispatch(
                onDeleteCatalogPositionIDs({
                    projectID: activeProject?.projectID,
                    positionIDs: checkedCatalog,
                })
            );
        }
    };

    useEffect(() => {
        if (activeProject) {
            dispatch(
                onCatalogPositionIDs({
                    projectID: activeProject?.projectID,
                })
            );
        }
    }, [activeProject]);

    // TODO: define translations (Jakub Jirous 2021-03-25 08:30:09)
    // translations
    const intl = useIntl();
    const transExportPositions = intl.formatMessage({ ...messages.exportPositions });
    const transImportPositions = intl.formatMessage({ ...messages.importPositions });


    const transTitleBaseData = intl.formatMessage({ ...messages.titleBaseData });
    const transTitleProjectCatalog = intl.formatMessage({ ...messages.titleProjectCatalog });
    const transLabelTemplate = intl.formatMessage({ ...messages.labelTemplate });
    const transFieldTemplate = intl.formatMessage({ ...messages.fieldTemplate });
    const transFieldSearch = intl.formatMessage({ ...messages.fieldSearch });

    const transTooltipAddCheckedPosition = intl.formatMessage({ ...messages.tooltipAddCheckedPosition });
    const transTooltipDeleteCheckedPosition = intl.formatMessage({ ...messages.tooltipDeleteCheckedPosition });
    const transLabelCopyProjectCatalog = intl.formatMessage({ ...messages.labelCopyProjectCatalog });



    return (
        <Box className={classes.mainViewBox}>
            <Box className={classes.masterBox}>
                <Box className={classes.headingBox}>
                    <Typography>{transTitleBaseData}</Typography>
                </Box>
                <Box className={classes.searchBox}>
                    <MuiTextField
                        id="searchMaster"
                        name="searchMaster"
                        placeholder={transFieldSearch}
                        value={searchMaster}
                        fullWidth
                        margin="none"
                        variant="outlined"
                        className={clsx(classes.textField, classes.searchField)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: searchMaster?.length > 0 && (
                                <InputAdornment
                                    position="end"
                                    onClick={handleClearSearchMaster}
                                    style={{ cursor: "pointer" }}
                                >
                                    <CloseIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearchMaster}
                    />
                    <Box className={classes.exportImport}>
                        <ExporterImporter
                            fileName={"Positions.xlsx"}
                            exportLabel={transExportPositions}
                            importLabel={transImportPositions}
                            exportCallback={(data) => {
                                exportPositions();
                            }}
                            importCallback={(data) => {
                                return importPositions(data);
                            }}
                            onImportDoneCallback={() => {
                                dispatch(onMappingPositions({ }));
                            }}
                            exportPermission={{resource: "mapping", scope: "position:export", isProject: true}}
                            importPermission={{resource: "mapping", scope: "position:import", isProject: true}}
                        />
                    </Box>
                    <PositionDialog />
                </Box>

                <MasterDataTreeWrapper
                    checked={checkedMaster}
                    handleChangeMainView={handleChangeMainView}
                    handleToggle={handleToggle}
                    navigation={navigation}
                    search={searchMaster}
                />
            </Box>
            <Box className={classes.controlBox}>
                <Box className={classes.controlFields}>
                    <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
                        {({ handleSubmit, submitting, pristine, values }) => (
                            <form onSubmit={handleSubmit}>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    flexBasis="70%"
                                >
                                    <TextField
                                        required={false}
                                        name="template"
                                        label={transLabelTemplate} // Vorlage
                                        placeholder={transFieldTemplate}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, {
                                            [classes.placeholder]: !values?.["template"],
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        select
                                        SelectProps={{
                                            displayEmpty: true,
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Placeholder
                                        </MenuItem>
                                        {[0, 1, 2, 3, 4, 5].map((index) => (
                                            <MenuItem key={index} value={index}>
                                                Template {index}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        style={{
                                            marginLeft: "1.5rem",
                                            marginBottom: "0.5rem",
                                            minWidth: 0,
                                            padding: "0.8rem",
                                        }}
                                        disabled={submitting || pristine || !values?.["template"]}
                                        onClick={() => setGlobalDialogOpen(true)}
                                    >
                                        <ChevronRightIcon fontSize="small" />
                                    </Button>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                    alignItems="flex-end"
                                    flexBasis="70%"
                                >
                                    <TextField
                                        required={false}
                                        name="catalog"
                                        label={transLabelCopyProjectCatalog} // Projektkatalog kopieren
                                        placeholder="Placeholder" // todo placeholder
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, {
                                            [classes.placeholder]: !values?.["catalog"],
                                        })}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        select
                                        SelectProps={{
                                            displayEmpty: true,
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Placeholder
                                        </MenuItem>
                                        {[0, 1, 2, 3, 4, 5].map((index) => (
                                            <MenuItem key={index} value={index}>
                                                Catalog {index}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Button
                                        type="submit"
                                        variant="outlined"
                                        color="primary"
                                        size="large"
                                        style={{
                                            marginLeft: "1.5rem",
                                            marginBottom: "0.5rem",
                                            minWidth: 0,
                                            padding: "0.8rem",
                                        }}
                                        disabled={submitting || pristine || !values?.["catalog"]}
                                        onClick={() => setGlobalDialogOpen(true)}
                                    >
                                        <ChevronRightIcon fontSize="small" />
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Form>
                </Box>

                <Box className={classes.controlButtons}>
                    <SecuredComponent permission={getProjectPermission("mapping", "mapping:assign")}>
                        <NullableTooltip title={transTooltipAddCheckedPosition}>
                            <span>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={handleAddPositions}
                                    disabled={checkedMaster?.length === 0}
                                    aria-label="add positions"
                                >
                                    <ChevronRightIcon fontSize="large" />
                                </Button>
                            </span>
                        </NullableTooltip>
                    </SecuredComponent>
                    <SecuredComponent permission={getProjectPermission("mapping", "mapping:assign")}>
                        <NullableTooltip title={transTooltipDeleteCheckedPosition}>
                            <span>
                                <Button
                                    variant="outlined"
                                    size="small"
                                    className={classes.controlButton}
                                    onClick={handleDeletePositions}
                                    disabled={checkedCatalog?.length === 0}
                                    aria-label="delete positions"
                                >
                                    <CloseIcon fontSize="large" />
                                </Button>
                            </span>
                        </NullableTooltip>
                    </SecuredComponent>
                </Box>
            </Box>
            <Box className={classes.projectBox}>
                <Box className={classes.headingBox}>
                    <Typography>{transTitleProjectCatalog}</Typography>
                </Box>
                <Box className={classes.searchBox}>
                    <MuiTextField
                        id="searchCatalog"
                        name="searchCatalog"
                        placeholder={transFieldSearch}
                        value={searchCatalog}
                        fullWidth
                        margin="none"
                        variant="outlined"
                        className={clsx(classes.textField, classes.searchField)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon fontSize="small" />
                                </InputAdornment>
                            ),
                            endAdornment: searchCatalog?.length > 0 && (
                                <InputAdornment
                                    position="end"
                                    onClick={handleClearSearchCatalog}
                                    style={{ cursor: "pointer" }}
                                >
                                    <CloseIcon fontSize="small" />
                                </InputAdornment>
                            ),
                        }}
                        onChange={handleSearchCatalog}
                    />
                    <ProjectCatalogPopover />
                </Box>
                <ProjectCatalogTreeWrapper
                    checked={checkedCatalog}
                    handleChangeMainView={handleChangeMainView}
                    handleToggle={handleToggle}
                    navigation={navigation}
                    search={searchCatalog}
                />
            </Box>
        </Box>
    );
};

export default MappingMainView;
