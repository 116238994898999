import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Paper, Switch, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
    IAttachedMaterial, IElementsPosition,
    MaterialType,
    PositionPriceSource,
    UnitPriceType,
} from "../../../packages/Api/data/elements/types";
import {
    onUpdatePositionBasics, useDetailPosition,
    useMappingAttachedMaterialAdditional,
    useMappingAttachedMaterialPhysical,
    useMappingMaterialLoading,
} from "../../../redux/mapping";
import MaterialDialogWrapper from "../Dialogs/MaterialDialogWrapper/MaterialDialogWrapper";
import WorkDialogWrapper from "../Dialogs/WorkDialogWrapper/WorkDialogWrapper";
import StepWorkFormMaterial from "../StepWorkFormMaterial/StepWorkFormMaterial";
import StepWorkFormWork from "../StepWorkFormWork/StepWorkFormWork";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import EquipmentDialogWrapper from "../Dialogs/EquipmentDialogWrapper/EquipmentDialogWrapper";
import StepWorkFormEquipment from "../StepWorkFormEquipment/StepWorkFormEquipment";
import clsx from "clsx";
import StepWorkFormSubcontractor from "../StepWorkFormSubcontractor/StepWorkFormSubcontractor";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IStepWorkProps {}

const StepWork: React.FC<IStepWorkProps> = () => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const mappingMaterialLoading: boolean = useMappingMaterialLoading();
    const mappingAttachedMaterialPhysical: IAttachedMaterial[] = useMappingAttachedMaterialPhysical();
    const mappingAttachedMaterialAdditional: IAttachedMaterial[] = useMappingAttachedMaterialAdditional();

    // translations
    const intl = useIntl();
    const transMaterial = intl.formatMessage({ ...messages.material });
    const transMaterialAdditional = intl.formatMessage({ ...messages.materialAdditional });
    const transMaterialPhysical = intl.formatMessage({ ...messages.materialPhysical });
    const transWork = intl.formatMessage({ ...messages.work });
    const transEquipment = intl.formatMessage({ ...messages.equipment });
    const transUnitPriceTypeCalculated = intl.formatMessage({ ...messages.unitPriceTypeCalculated });
    const transUnitPriceTypeSubcontractor = intl.formatMessage({ ...messages.unitPriceTypeSubcontractor });

    const handleChangeUnitPriceType = (priceType: UnitPriceType) => {
        dispatch(
            onUpdatePositionBasics({
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number,
                data: {
                    priceSource: priceType == UnitPriceType.SUBCONTRACTOR ? PositionPriceSource.SUBCONTRACTOR : PositionPriceSource.MODEL,
                },
            })
        );
    }
    const handleChangeUnitPriceTypeBySwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChangeUnitPriceType(event.target.checked ? UnitPriceType.SUBCONTRACTOR : UnitPriceType.MODEL);
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.switchBox}>
                <Typography
                    variant="body2"
                    className={clsx(classes.switchLabel, {
                        [classes.switchActive]: detailPosition?.priceSource?.toString() == UnitPriceType.MODEL,
                    })}
                    onClick={() => handleChangeUnitPriceType(UnitPriceType.MODEL)}
                >
                    {transUnitPriceTypeCalculated}
                </Typography>
                <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                    <Switch
                        checked={detailPosition?.priceSource?.toString() == UnitPriceType.SUBCONTRACTOR}
                        onChange={handleChangeUnitPriceTypeBySwitch}
                        color="primary"
                        name="manualPosition"
                        inputProps={{ "aria-label": "model position switch" }}
                        classes={{
                            root: classes.switchRoot,
                            switchBase: classes.switchBase,
                            colorPrimary: classes.switchColorPrimary,
                            thumb: classes.switchThumb,
                            track: classes.switchTrack,
                            checked: classes.switchChecked,
                        }}
                    />
                </SecuredComponent>
                <Typography
                    variant="body2"
                    className={clsx(classes.switchLabel, {
                        [classes.switchActive]: detailPosition?.priceSource?.toString() == UnitPriceType.SUBCONTRACTOR,
                    })}
                    onClick={() => handleChangeUnitPriceType(UnitPriceType.SUBCONTRACTOR)}
                >
                    {transUnitPriceTypeSubcontractor}
                </Typography>
            </Box>
            {detailPosition?.priceSource?.toString() == UnitPriceType.MODEL && (
                <>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                        <Typography variant="body2" className={classes.fieldset}>
                            {transMaterial}
                        </Typography>
                        <MaterialDialogWrapper />
                    </Box>
                    <Paper className={classes.paper}>
                        <Typography variant="body2">{transMaterialPhysical}</Typography>
                        <StepWorkFormMaterial
                            materialType={MaterialType.PHYSICAL}
                            loading={mappingMaterialLoading}
                            attachedMaterial={mappingAttachedMaterialPhysical}
                        />
                        {mappingAttachedMaterialPhysical?.length === 0 && <MaterialDialogWrapper />}
                        <Box mt={1}>
                            <Typography variant="body2">{transMaterialAdditional}</Typography>
                            <StepWorkFormMaterial
                                materialType={MaterialType.ADDITIONAL}
                                loading={mappingMaterialLoading}
                                attachedMaterial={mappingAttachedMaterialAdditional}
                            />
                            <MaterialDialogWrapper />
                        </Box>
                    </Paper>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                        <Typography variant="body2" className={classes.fieldset}>
                            {transWork}
                        </Typography>
                        <WorkDialogWrapper />
                    </Box>
                    <Paper className={classes.paper}>
                        <StepWorkFormWork />
                        <WorkDialogWrapper />
                    </Paper>
                    <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                        <Typography variant="body2" className={classes.fieldset}>
                            {transEquipment}
                        </Typography>
                        <EquipmentDialogWrapper />
                    </Box>
                    <Paper className={classes.paper}>
                        <StepWorkFormEquipment />
                        <EquipmentDialogWrapper />
                    </Paper>
                </>
            )}

            {detailPosition?.priceSource?.toString() == UnitPriceType.SUBCONTRACTOR && (
                <>
                    <Paper className={classes.paper}>
                        <StepWorkFormSubcontractor />
                    </Paper>
                </>
            )}

        </Box>
    );
};

export default StepWork;
