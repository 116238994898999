import { onError } from "@iolabs/app";
import { DispatchAction } from "@iolabs/redux-utils";
import { ListSubheader, MenuItem } from "@material-ui/core";
import "date-fns";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
    Issue, IssueStatus,
    UpdateIssueRemoteMutationVariables,
    useGetFormConfigQuery,
    useUpdateIssueRemoteMutation,
} from "../../../graphql/generated/graphql";
import { getMarkups } from "../../../packages/Api/data/markups/client";
import { IObjectMarkup } from "../../../packages/Api/data/markups/types";
import { IPageParams } from "../../../pages/type";
import { useIssueEdit } from "../../../redux/issue";
import { onMarkupsSetData, useIssuables } from "../../../redux/viewer";
import { IIssueRenderer } from "../../../redux/viewer/reducer";
import globalMessages from "../../App/messages";
import { ExternalSystem } from "../../Viewer/type";
import messages from "../CreateIssue/messages";
import EditIssue from "../EditIssue/EditIssue";
import { buildMarkupID } from "../helpers";
import useIssuesStyles from "../IssuesWrapper/styles";
import { IIssueState, IIssueTab } from "../type";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";

interface IEditIssueWrapperProps {
    handleChangeTab: (tab: IIssueTab) => void;
}

const EditIssueWrapper: React.FC<IEditIssueWrapperProps> = ({ handleChangeTab }) => {
    const { accountUrn, projectUrn, urn: fileUrn } = useParams<IPageParams>();

    const { tokenInitialized } = useSecurityContext();
    const classes = useIssuesStyles();
    const issueEdit: Issue = useIssueEdit() as Issue;
    const issuables = useIssuables();
    const dispatch = useDispatch<DispatchAction>();

    const [updateIssueMutation] = useUpdateIssueRemoteMutation({
        refetchQueries: ["GetIssuesRemoteLazyQuery"],
    });

    const { data: formConfigData, loading: formConfigLoading, error: formConfigError } = useGetFormConfigQuery({
        variables: {
            accountUrn,
            projectUrn,
        },
    });

    const intl = useIntl();
    const transLoadingDataError = intl.formatMessage({ ...globalMessages.loadingDataError });
    const transVoidDescription = intl.formatMessage({ ...messages.voidDescription });

    if (formConfigError) {
        dispatch(
            onError({
                errorMessage: transLoadingDataError,
            })
        );
    }

    const getExternalId = () => {
        return issueEdit?.issueExternals?.[0]?.externalID as string;
    };

    // Number 0 representing placeholder for select fields
    const [issue, setIssue] = useState<IIssueState>({
        issueId: issueEdit?.issueID as number,
        code: issueEdit?.code as string,
        type: issueEdit?.issueType?.issueTypeExternals?.find(
            (ite) => ite?.externalSystem?.code === ExternalSystem.Forge
        )?.externalID as string,
        status: issueEdit?.issueStatus?.issueStatusExternals?.find(
            (ise) => ise?.externalSystem?.code === ExternalSystem.Forge
        )?.externalID as string,
        title: issueEdit?.name as string,
        assignTo: issueEdit?.assignedTo?.userID as number,
        description: issueEdit?.description as string,
        dueDate: issueEdit?.dueDate as Date,
        location: issueEdit?.issueExternals?.[0]?.position as string,
        locationDetails: issueEdit?.locationDescription as string,
        owner: issueEdit?.owner?.userID as number,
        rootCause: issueEdit?.issueCause?.issueCauseExternals?.find(
            (ice) => ice?.externalSystem?.code === ExternalSystem.Forge
        )?.externalID as string,
        externalId: getExternalId(),
        allowedStatuses: issueEdit?.allowedStatuses as IssueStatus[],
    });
    const [selectTypes, setSelectTypes] = useState<any[]>([]);
    const [selectStatuses, setSelectStatuses] = useState<any[]>([]);
    const [selectCauses, setSelectCauses] = useState<any[]>([]);

    useEffect(() => {
        setIssue({
            issueId: issueEdit?.issueID as number,
            code: issueEdit?.code as string,
            type: issueEdit?.issueType?.issueTypeExternals?.find(
                (ite) => ite?.externalSystem?.code === ExternalSystem.Forge
            )?.externalID as string,
            status: issueEdit?.issueStatus?.issueStatusExternals?.find(
                (ise) => ise?.externalSystem?.code === ExternalSystem.Forge
            )?.externalID as string,
            title: issueEdit?.name as string,
            assignTo: issueEdit?.assignedTo?.userID as number,
            description: issueEdit?.description as string,
            dueDate: issueEdit?.dueDate as Date,
            location: issueEdit?.issueExternals?.[0]?.position as string,
            locationDetails: issueEdit?.locationDescription as string,
            owner: issueEdit?.owner?.userID as number,
            rootCause: issueEdit?.issueCause?.issueCauseExternals?.find(
                (ice) => ice?.externalSystem?.code === ExternalSystem.Forge
            )?.externalID as string,
            externalId: getExternalId(),
            allowedStatuses: issueEdit?.allowedStatuses as IssueStatus[],
        });
    }, [issueEdit]);

    useEffect(() => {
        if (tokenInitialized) {
            getMarkups(buildMarkupID(getExternalId()))
                .then((response: IObjectMarkup[]) => {
                    if (response && response.length) {
                        const markup = response[0];
                        dispatch(
                            onMarkupsSetData({
                                markupsData: {
                                    data: window.atob(markup.content),
                                    viewerState: JSON.parse(markup.placement),
                                    name: markup.name,
                                },
                            })
                        );
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [issueEdit, tokenInitialized]);

    useEffect(() => {
        return () => {
            dispatch(onMarkupsSetData({ markupsData: null }));
        };
    }, []);

    const handleChange = (prop: keyof IIssueState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setIssue({ ...issue, [prop]: event.target.value });
    };

    const handleChangeDate = (date: Date | null) => {
        setIssue({ ...issue, dueDate: date });
    };

    const handleEdit = async () => {
        const updateIssueVariables: UpdateIssueRemoteMutationVariables = {
            issue: {
                externalSystemData: {
                    externalSystemCode: ExternalSystem.Forge,
                    issueID: issue?.externalId,
                    issueStatusID: issue.status,
                },
            },
            externalArguments: [
                { key: "accountId", value: accountUrn },
                { key: "projectId", value: projectUrn },
                { key: "fileId", value: fileUrn as string },
            ],
        };

        await updateIssueMutation({
            variables: updateIssueVariables,
        })
            .then((updateIssueData) => {
                console.log("updateIssueData");
                console.log(updateIssueData);
            })
            .catch((updateIssueError) => {
                console.log("updateIssueError");
                console.log(updateIssueError);
            });

        handleChangeTab(IIssueTab.List);
    };

    const isValid = (): boolean => {
        return issue.type !== null && issue.status !== null && issue.title !== "";
    };

    const renderStatus = (status: any) => {
        return (
            <span
                className={classes.status}
                style={{
                    borderLeftColor: status?.highlightColor,
                }}
            />
        );
    };

    // const issueLocationCollected = (issueData: Partial<UpdateIssueRemoteMutationVariables>) => {
    //     setIssueData(issueData);
    // };

    useEffect(() => {
        if (issuables && issueEdit) {
            issuables.forEach((issuable) => {
                issuable.renderIssues([
                    {
                        issue: issueEdit,
                        editAllowed: false,
                    } as IIssueRenderer,
                ]);
            });
        }
        return () => {
            issuables.forEach((issuable) => {
                issuable.renderIssues([]);
            });
        };
    }, [issuables, issueEdit]);

    useEffect(() => {
        const selectTypesItems: any[] = [];
        formConfigData?.types?.map((type, index) => {
            if (type && type?.children && type?.children?.length > 0) {
                selectTypesItems.push(
                    <ListSubheader key={index} className={classes.listSubheader}>
                        {type?.name}
                    </ListSubheader>
                );
                type?.children?.map((item, index2) => {
                    selectTypesItems.push(
                        <MenuItem
                            key={`${index}-${index2}`}
                            value={
                                item?.issueTypeExternals?.find(
                                    (ite) => ite?.externalSystem?.code === ExternalSystem.Forge
                                )?.externalID as string
                            }
                        >
                            {item?.name}
                        </MenuItem>
                    );
                });
            }
        });
        setSelectTypes(selectTypesItems);
    }, [formConfigData, setSelectTypes]);

    useEffect(() => {
        const selectStatusesItems: any[] = [];

        // add actual issue status to collection
        if (issueEdit?.issueStatus) {
            selectStatusesItems.push(
                <MenuItem
                    key={issueEdit?.issueID}
                    value={issueEdit?.issueStatus?.issueStatusExternals?.[0]?.externalID as string}
                    className={classes.selectStatuses}
                    disabled={issueEdit?.issueStatus?.creationEnabled as boolean}
                >
                    <div>
                        {renderStatus(issueEdit?.issueStatus)} {issueEdit?.issueStatus?.name}
                        {issueEdit?.issueStatus?.description}
                    </div>
                    {issueEdit?.issueStatus?.description ? (
                        <div className={classes.statusDescription}>{issueEdit?.issueStatus?.description}</div>
                    ) : null}
                </MenuItem>
            );
        }

        issueEdit?.allowedStatuses?.map((allowedStatus, index) => {
            selectStatusesItems.push(
                <MenuItem
                    key={index}
                    value={allowedStatus?.issueStatusExternals?.[0]?.externalID as string}
                    className={classes.selectStatuses}
                    disabled={allowedStatus?.creationEnabled as boolean}
                >
                    <div>
                        {renderStatus(allowedStatus)} {allowedStatus?.name} {allowedStatus?.description}
                    </div>
                    {allowedStatus?.name === "Void" ? (
                        <div className={classes.statusDescription}>{transVoidDescription}</div>
                    ) : allowedStatus?.description ? (
                        <div className={classes.statusDescription}>{allowedStatus?.description}</div>
                    ) : null}
                </MenuItem>
            );
        });

        setSelectStatuses(selectStatusesItems);
    }, [issueEdit, setSelectStatuses]);

    useEffect(() => {
        const selectCausesItems: any[] = [];
        formConfigData?.causes?.map((cause, index) => {
            selectCausesItems.push(
                <MenuItem
                    key={index}
                    value={
                        cause?.issueCauseExternals?.find((ite) => ite?.externalSystem?.code === ExternalSystem.Forge)
                            ?.externalID as string
                    }
                >
                    {cause?.name}
                </MenuItem>
            );
        });
        setSelectCauses(selectCausesItems);
    }, [formConfigData, setSelectCauses]);

    return (
        <EditIssue
            issueEdit={issueEdit}
            issue={issue}
            isValid={isValid}
            selectTypes={selectTypes}
            selectStatuses={selectStatuses}
            selectCauses={selectCauses}
            formConfigLoading={formConfigLoading}
            formConfigError={formConfigError}
            handleChangeTab={handleChangeTab}
            handleChange={handleChange}
            handleChangeDate={handleChangeDate}
            handleEdit={handleEdit}
        />
    );
};

export default EditIssueWrapper;
