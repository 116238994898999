import { DispatchAction } from "@iolabs/redux-utils";
import React, { useCallback, useEffect, useState } from "react";
import { Form } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { Box, InputAdornment } from "@material-ui/core";
import { TextField } from "mui-rff";
import createDecorator from "final-form-calculate";
import { IElementsPosition, IPrice } from "../../../packages/Api/data/elements/types";
import useStyles from "./styles";
import { OnBlur, OnChange } from 'react-final-form-listeners'
import { useDispatch } from "react-redux";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { onUpdatePositionBasics, useDetailPosition } from "../../../redux/mapping";
import { useIntl } from "react-intl";
import messages from "./messages";

interface ISubcontractorPriceFormProps {
    position: IElementsPosition;
}
const SubcontractorPriceForm: React.FC<ISubcontractorPriceFormProps> = ({ position }) => {


    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);

    const [initialFormValues, setInitialFormValues] = useState<IPrice|undefined>();

    const [filteringTimeout, setFilteringTimeout] = useState<ReturnType<typeof setTimeout>>();

    const intl = useIntl();

    const transFieldPrice = intl.formatMessage({ ...messages.fieldPrice });
    const transFieldSurcharge = intl.formatMessage({ ...messages.fieldSurcharge });
    const transFieldTotal = intl.formatMessage({ ...messages.fieldTotal });

    useEffect(() => {
        setInitialFormValues({
            value: position?.price?.value,
            surcharge: position?.price?.surcharge ? position?.price?.surcharge * 100 : undefined,
            total: position?.price?.total,
        })
    }, [position]);

    const handleChange = async (formValues) => {
        if (filteringTimeout) {
            clearTimeout(filteringTimeout);
        }

        const timeout = setTimeout(() => {
            setSaving(true);
            dispatch(
                onUpdatePositionBasics({
                    projectID: activeProject?.projectID as number,
                    positionID: position?.positionID as number,
                    data: {
                        price: {
                            value: formValues.value,
                            surcharge: formValues.surcharge ? formValues.surcharge/100 : 0 // convert to 100 -> 1
                        }
                    },
                })
            );
        }, 1000);

        setFilteringTimeout(timeout);
    };

    const validate = (values) => {
        const errors: any = {};
        // if (!values.name) {
        //     errors.name = "Required";
        // }
        return errors;
    };


    // react final form submitting
    const onSubmit = useCallback(async (values) => {
        await handleChange(values);
    }, []);

    const calculateTotal = (values: IPrice) => {
        return (values?.value ? values?.value : 0) * (values?.surcharge ? (1+values?.surcharge/100) : 1)
    }

    const calculator = createDecorator(
        {
            field: /price|surcharge/, // when a field matching this pattern changes...
            updates: {
                // ...update the total to the result of this function
                total: (ignoredValue, allValues) => calculateTotal(allValues as IPrice)
            }
        }
    )

    return (
        <Form
            onSubmit={onSubmit}
            decorators={[calculator]}
            initialValues={initialFormValues}
            validate={validate}
            mutators={{
                ...arrayMutators,
            }}
        >
            {({
                  handleSubmit,
                  form: {
                      mutators: { push },
                  },
                  values,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box className={classes.controlGroup}>
                        <TextField
                            name={`value`}
                            label={transFieldPrice}
                            placeholder={transFieldPrice}
                            variant="outlined"
                            margin="normal"
                            type="number"
                            // inputProps={{
                            //     ...params.inputProps,
                            // }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <OnChange name="value">
                            {(value, previous) => {
                                handleChange(values)
                            }}
                        </OnChange>

                        <TextField
                            name={`surcharge`}
                            label={transFieldSurcharge}
                            placeholder={transFieldPrice}
                            variant="outlined"
                            margin="normal"
                            type="number"
                            inputProps={{
                                min: 0
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <OnChange name="surcharge">
                            {(value, previous) => {
                                handleChange(values)
                            }}
                        </OnChange>

                        <TextField
                            name={`total`}
                            label={transFieldTotal}
                            placeholder={transFieldPrice}
                            variant="outlined"
                            margin="normal"
                            type="number"
                            // inputProps={{
                            //     ...params.inputProps,
                            // }}
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            InputProps={{
                                readOnly: true
                            }}
                        />
                    </Box>
                </form>
            )}
        </Form>
    );
};

export default SubcontractorPriceForm;