import { DispatchAction } from "@iolabs/redux-utils";
import { Box, CircularProgress, Paper, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import Icon from "../../components/Icon/Icon";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import ProjectViewer from "../../components/ProjectViewer/ProjectViewer";
import DataTableWrapper from "../../components/Specification/DataTableWrapper/DataTableWrapper";
import DialogExtern from "../../components/Specification/DialogExtern/DialogExtern";
import { SpecificationView } from "../../components/Specification/type";
import ViewSwitcher from "../../components/Specification/ViewSwitcher/ViewSwitcher";
import { FileType } from "../../components/Viewer/type";
import { getProjectFileVersionIdFromProject, getUrnFromProject } from "../../components/Viewer/utils/Project";
import { IViewerLeaveState } from "../../components/Viewer/Viewer/Viewer";
import { useGetProjectQuery } from "../../graphql/generated/graphql";
import { loadSpecialInstances, useActiveProject } from "../../redux/project";
import {
    useSpecificationViewIsDefault,
    useSpecificationViewIsTable,
    useSpecificationViewIsViewers,
    useSpecificationViews,
} from "../../redux/specification";
import Page from "../Page/Page";
import { SplitViewType } from "../ViewerPage/ViewerPage";
import useStyles from "./styles";
import { useSecurityContext } from "../../redux/keyclock/securityContext";

const SpecificationPage: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const { tokenInitialized } = useSecurityContext();
    const views: SpecificationView[] = useSpecificationViews();
    const isDefault: boolean = useSpecificationViewIsDefault();
    const isTable: boolean = useSpecificationViewIsTable();
    const isViewers: boolean = useSpecificationViewIsViewers();
    const activeProject: ProjectData | undefined = useActiveProject();

    const [splitView, setSplitView] = useState<SplitViewType>(SplitViewType.BOTH);

    const [lastViewerState2d, setLastViewerState2d] = useState<IViewerLeaveState>();
    const [lastViewerState3d, setLastViewerState3d] = useState<IViewerLeaveState>();
    const viewersRef = useRef();

    const { data, loading, error } = useGetProjectQuery({
        variables: {
            code: activeProject?.code,
        },
    });

    const project = data?.projects?.[0];
    const urn = getUrnFromProject(project);
    const projectFileVersionID = getProjectFileVersionIdFromProject(project, FileType.Geometry);

    useEffect(() => {
        if (views.includes(SpecificationView.Viewer3D) && !views.includes(SpecificationView.Viewer2D)) {
            setSplitView(SplitViewType["3D"]);
        } else if (!views.includes(SpecificationView.Viewer3D) && views.includes(SpecificationView.Viewer2D)) {
            setSplitView(SplitViewType["2D"]);
        } else if (views.includes(SpecificationView.Viewer3D) && views.includes(SpecificationView.Viewer2D)) {
            setSplitView(SplitViewType.BOTH);
        } else {
            setSplitView(SplitViewType.BOTH);
        }
    }, [views]);

    useEffect(() => {
        if (activeProject?.projectID && tokenInitialized) {
            dispatch(loadSpecialInstances({
                projectID: activeProject?.projectID
            }))
        }
    }, [activeProject, tokenInitialized])

    const storeViewerStates = (role: string, state: IViewerLeaveState) => {
        switch (role) {
            case "2D":
                setLastViewerState2d(state);
                break;
            case "3D":
                setLastViewerState3d(state);
                break;
        }
    };

    return (
        <Page>
            {activeProject?.isInitialized ? (
                <>
                    {!data && (error || loading) ? (
                        <Box className={classes.loadingBox}>
                            <CircularProgress size={36} className={classes.circularProgress} />
                        </Box>
                    ) : (
                        <>
                            <Box className={classes.box}>
                                <Box
                                    className={clsx(classes.viewSwitcher, {
                                        [classes.viewSwitcherPinnedToTop]: !isDefault,
                                    })}
                                >
                                    <ViewSwitcher />
                                </Box>
                                {!isTable && (
                                    <Paper
                                        className={clsx(classes.viewerBox, {
                                            [classes.fullHeight]: isViewers,
                                        })}
                                    >
                                        <ProjectViewer
                                            ref={viewersRef}
                                            urn={urn}
                                            projectId={activeProject?.externalData?.[0]?.projectUrn}
                                            splitView={splitView}
                                            hideMarkups
                                            hideAllIssuesBtn
                                            onStoreState={storeViewerStates}
                                            statesToRestore={{
                                                "2D": lastViewerState2d,
                                                "3D": lastViewerState3d,
                                            }}
                                            disableSplitViewSwitcher={true}
                                        />
                                    </Paper>
                                )}
                                {!isViewers && (
                                    <Box
                                        className={clsx(classes.tableBox, {
                                            [classes.fullHeight]: isTable,
                                        })}
                                    >
                                        <DataTableWrapper
                                            projectFileVersionID={projectFileVersionID}
                                            fullHeight={isTable}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {views.includes(SpecificationView.Viewer3DExtern) && (
                                <DialogExtern view={SpecificationView.Viewer3DExtern}>
                                    <ProjectViewer
                                        urn={urn}
                                        splitView={SplitViewType["3D"]}
                                        projectId={activeProject?.externalData?.[0]?.projectUrn}
                                        hideMarkups
                                        hideAllIssuesBtn
                                        statesToRestore={{
                                            // @ts-ignore
                                            "3D": viewersRef.current?.getState3d(),
                                        }}
                                        disableSplitViewSwitcher={true}
                                    />
                                </DialogExtern>
                            )}
                            {views.includes(SpecificationView.Viewer2DExtern) && (
                                <DialogExtern view={SpecificationView.Viewer2DExtern}>
                                    <ProjectViewer
                                        urn={urn}
                                        splitView={SplitViewType["2D"]}
                                        projectId={activeProject?.externalData?.[0]?.projectUrn}
                                        hideMarkups
                                        hideAllIssuesBtn
                                        statesToRestore={{
                                            // @ts-ignore
                                            "2D": viewersRef.current?.getState2d(),
                                        }}
                                        disableSplitViewSwitcher={true}
                                    />
                                </DialogExtern>
                            )}
                            {views.includes(SpecificationView.TableExtern) && (
                                <DialogExtern view={SpecificationView.TableExtern}>
                                    <DataTableWrapper projectFileVersionID={projectFileVersionID} fullHeight={false} />
                                </DialogExtern>
                            )}
                        </>
                    )}
                </>
            ) : (
                <Box className={classes.emptyBox}>
                    <Icon name="list-border" size={60} className={classes.emptyIcon} />
                    <Typography variant="body2">
                        {!activeProject
                          ? "No specification is available. Please select the project." // todo translate de
                          : `No specification is available for ${activeProject?.name}.` // todo translate de
                        }
                    </Typography>
                </Box>
            )}
        </Page>
    );
};

export default SpecificationPage;
