import { createContext, Dispatch, useContext } from "react";
import { Permission, TokenResponse } from "./api/types";
import { ScopePermission } from "./SecuredComponent/permissions";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakRealmRole } from "./types";

export interface ISecurityContext {
    tokenInitialized: boolean;
    token?: TokenResponse;
    permissions?: Permission[];
    syncPermissions: () => void
}

export const SecurityContext = createContext<ISecurityContext>({
    tokenInitialized: false,
    token: undefined,
    permissions: [],
    syncPermissions: () => {}
});


export const useSecurityContext = () => {
    const {
        tokenInitialized,
        token,
        permissions,
        syncPermissions
    } = useContext<ISecurityContext>(
        SecurityContext
    );

    const { keycloak } = useKeycloak();

    const isAllowed = (permission: ScopePermission) => {
        return permissions?.find(p => p.rsname === permission.resource)?.scopes?.includes(permission.scope);
    }

    const hasRole = (role: string) => {
        return keycloak?.hasRealmRole(role)
    }

    return {
        tokenInitialized,
        token,
        permissions,
        isAllowed,
        syncPermissions,
        hasRole
    };
};
