import React, { FC, useState } from "react";
import { TextField, Button, Grid, InputAdornment, Box, Divider, Icon, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import messages from "./messages";
import { IParsedUrlInfo, parseUrl } from "./urlParser";
import { AccountTree, DeviceHub, Help, InsertDriveFile, Link, Warning } from "@material-ui/icons";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { checkProject } from "../../../packages/Api/data/projects/client";
import { CheckProjectResponse } from "../../../packages/Api/data/projects/types";
import { Alert } from "@material-ui/lab";

interface IProjectNewWizardUrlLoaderProps{
    onProjectCheckSuccess?: (response: CheckProjectResponse | undefined) => void;
}


const ProjectNewWizardUrlLoader: FC<IProjectNewWizardUrlLoaderProps> = ({ onProjectCheckSuccess }) => {

    const classes = useStyles();
    const [ urlInfo, setUrlInfo ] = useState<IParsedUrlInfo>();
    const [ url, setUrl ] = useState<string>();

    const [ checking, setChecking ] = useState<boolean>(false);
    const [ checkFailed, setCheckFailed ] = useState<string>();
    const [ checkResponse, setCheckResponse ] = useState<CheckProjectResponse>();


    // translations
    const intl = useIntl();
    const transBim360Url = intl.formatMessage({ ...messages.bim360url });
    const transBim360UrlHelper = intl.formatMessage({ ...messages.bim360urlHelper });
    const transAccountID = intl.formatMessage({ ...messages.accountID });
    const transAccountIDHelper = intl.formatMessage({ ...messages.accountIDHelper });
    const transProjectID = intl.formatMessage({ ...messages.projectID });
    const transProjectIDHelper = intl.formatMessage({ ...messages.projectIDHelper });
    const transFileID = intl.formatMessage({ ...messages.fileID });
    const transFileIDHelper = intl.formatMessage({ ...messages.fileIDHelper });
    const transHubIdNotProvider = intl.formatMessage({ ...messages.hubIdNotProvided });
    const transProjectIdNotProvider = intl.formatMessage({ ...messages.projectIdNotProvided });
    const transFileIdNotProvider = intl.formatMessage({ ...messages.fileIdNotProvided });
    const transProcessCheckAndContinue = intl.formatMessage({ ...messages.processCheckAndContinue });
    const transCheckNotFound = intl.formatMessage({ ...messages.checkNotFound });
    const transCheckError = intl.formatMessage({ ...messages.checkError });

    const onOnBim360UrlChange = (event) => {
        setUrl(event.target.value);
        setUrlInfo(parseUrl(event.target.value));
        setCheckFailed(undefined);
    }

    const onAccountIDChange = (event) => {
        setUrlInfo({
            ...urlInfo,
            hubID: event.target.value
        });
    }

    const onProjectIDChange = (event) => {
        setUrlInfo({
            ...urlInfo,
            projectID: event.target.value
        });
    }

    const onFileIDChange = (event) => {
        setUrlInfo({
            ...urlInfo,
            fileID: event.target.value
        });
    }

    const handleCheck = () => {
        setChecking(true);
        setCheckFailed(undefined);
        checkProject({
                fileUrn: urlInfo?.fileID,
                hubUrn: urlInfo?.hubID,
                projectUrn: urlInfo?.projectID
            })
            .then((res) => {
                setCheckResponse(res);
                if (onProjectCheckSuccess) {
                    onProjectCheckSuccess(res);
                }
            })
            .catch((ex) => {
                setCheckResponse(undefined);
                if (ex.response.status == 404) {
                    setCheckFailed(transCheckNotFound);
                }
                else {
                    setCheckFailed(transCheckError);
                }
            })
            .finally(() => {
                setChecking(false);
            })
    }

    return (
        <Box className={classes.urlParserForm}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        id={'bim360url'}
                        label={transBim360Url}
                        onChange={onOnBim360UrlChange}
                        variant={"outlined"}
                        fullWidth
                        value={url ?? ""}
                        helperText={transBim360UrlHelper}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Link fontSize={"small"} />
                                </InputAdornment>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'accountID'}
                        label={transAccountID}
                        onChange={onAccountIDChange}
                        variant={"outlined"}
                        value={urlInfo?.hubID ?? ""}
                        fullWidth
                        helperText={transAccountIDHelper}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DeviceHub fontSize={"small"} />
                                </InputAdornment>
                            ),
                            endAdornment: !urlInfo?.hubID && (
                                <NullableTooltip title={transHubIdNotProvider}>
                                    <InputAdornment position="end">
                                        <Help fontSize={"small"}  color={"disabled"} />
                                    </InputAdornment>
                                </NullableTooltip>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'projectID'}
                        label={transProjectID}
                        onChange={onProjectIDChange}
                        variant={"outlined"}
                        value={urlInfo?.projectID ?? ""}
                        fullWidth
                        required
                        helperText={transProjectIDHelper}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountTree fontSize={"small"} />
                                </InputAdornment>
                            ),
                            endAdornment: !urlInfo?.projectID && (
                                <NullableTooltip title={transProjectIdNotProvider}>
                                    <InputAdornment position="end">
                                        <Warning fontSize={"small"}  color={"error"} />
                                    </InputAdornment>
                                </NullableTooltip>
                            )
                        }}
                    />
                </Grid>
                <Grid item sm={4} xs={12}>
                    <TextField
                        id={'fileID'}
                        label={transFileID}
                        onChange={onFileIDChange}
                        variant={"outlined"}
                        value={urlInfo?.fileID ?? ""}
                        fullWidth
                        helperText={transFileIDHelper}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InsertDriveFile fontSize={"small"} />
                                </InputAdornment>
                            ),
                            endAdornment: !urlInfo?.fileID && (
                                <NullableTooltip title={transFileIdNotProvider}>
                                    <InputAdornment position="end">
                                        <Help fontSize={"small"}  color={"disabled"} />
                                    </InputAdornment>
                                </NullableTooltip>
                            )
                        }}
                    />
                </Grid>
            </Grid>
            <Box className={classes.buttons}>
                {checkFailed && <Alert severity={"error"}>{checkFailed}</Alert>}
                <Button
                    type="submit"
                    onClick={handleCheck}
                    disabled={checking || !urlInfo?.projectID}
                    variant={"contained"}
                    color="secondary"
                    size="large"
                    endIcon={checking ? <CircularProgress size={22} color={"inherit"} /> : <Icon>send</Icon>}
                >
                    {transProcessCheckAndContinue}
                </Button>
            </Box>
        </Box>
    )
}
export default ProjectNewWizardUrlLoader;