import React from "react";
import ProjectMembersSearch from "../ProjectMembersSearch/ProjectMembersSearch";
import ProjectMembersList from "../ProjectMemberList/ProjectMembersList";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import ProjectMembersProvider from "./ProjectMembersContext";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Alert } from "@material-ui/lab";

interface IProjectMembersProps {
}

const ProjectMembers: React.FC<IProjectMembersProps> = ({  }) => {

    const activeProject: ProjectData | undefined = useActiveProject();

    // translations
    const intl = useIntl();
    const transMessageNotInitialized = intl.formatMessage({ ...messages.messageNotInitialized });

    return activeProject?.isInitialized ? (
        <ProjectMembersProvider projectID={activeProject?.projectID}>
            <ProjectMembersSearch />
            <ProjectMembersList />
        </ProjectMembersProvider>
    ) : (
        <Alert severity={"warning"}>{transMessageNotInitialized}</Alert>
    );
};

export default ProjectMembers;