import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import React, { useRef, useState } from "react";
import Icon from "../../../Icon/Icon";
import WorkDialog from "../WorkDialog/WorkDialog";
import useStyles from "./styles";
import ExporterImporter from "../../../ExporterImporter/ExporterImporter";
import { onWorkCatalogue } from "../../../../redux/mapping";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import messages from "./messages";
import { exportWork, importWork } from "../../../../packages/Api/data/work/client";
import SecuredComponent from "../../../../redux/keyclock/SecuredComponent/SecuredComponent";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";

interface IWorkDialogWrapperProps {}

const WorkDialogWrapper: React.FC<IWorkDialogWrapperProps> = () => {
    const classes = useStyles();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const dispatch = useDispatch<DispatchAction>();

    const [open, setOpen] = useState<boolean>(false);
    const [selectedWorkIds, setSelectedWorkIds] = useState<number[]>([]);
    const [addModeActive, setAddModeActive] = useState<boolean>(false);

    const workDialogRef = useRef();

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSelectedWorkIds = (rowIDs: number[]) => {
        setSelectedWorkIds(rowIDs);
    };

    const handleAddModeActive = (active: boolean) => {
        setAddModeActive(active);
    };

    // translations
    const intl = useIntl();
    const transExport = intl.formatMessage({ ...messages.export });
    const transImport = intl.formatMessage({ ...messages.import });
    const transWorkCatalog = intl.formatMessage({ ...messages.workCatalog });
    const transCreateNewWork = intl.formatMessage({ ...messages.createNewWork });
    const transWorkToThePosition = intl.formatMessage({ ...messages.workToThePosition });
    const transButtonPartWork1 = intl.formatMessage({ ...messages.buttonPartWork1 });
    const transButtonPartWork2 = intl.formatMessage({ ...messages.buttonPartWork2 });

    return (
        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
            <IconButton
                aria-label="add"
                color="primary"
                className={clsx("globalAddButton", classes.addButton)}
                onClick={handleOpen}
            >
                <Icon name="plus" size={20} />
            </IconButton>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="work-position-dialog-title"
                aria-describedby="work-position-dialog-description"
                maxWidth={false}
                fullWidth
                fullScreen={fullScreen}
                className={classes.root}
                scroll="paper"
            >
                <DialogTitle id="work-position-dialog-title">
                    {transWorkCatalog}
                </DialogTitle>
                <DialogContent classes={{ root: classes.dialogContentRoot }}>
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                    <WorkDialog
                        ref={workDialogRef}
                        addModeActive={addModeActive}
                        selectedWorkIds={selectedWorkIds}
                        handleAddModeActive={handleAddModeActive}
                        handleSelectedWorkIds={handleSelectedWorkIds}
                        handleClose={handleClose}
                    />
                </DialogContent>
                <DialogActions classes={{ root: classes.dialogActionsRoot }}>
                    {/*<NullableTooltip title="Create new work" placement="top">*/}
                    {/*    <IconButton*/}
                    {/*        aria-label="add"*/}
                    {/*        color="primary"*/}
                    {/*        className={clsx("globalAddButton", classes.addButton)}*/}
                    {/*        onClick={() => {*/}
                    {/*            // @ts-ignore*/}
                    {/*            workDialogRef?.current?.handlePrepareAddWork();*/}
                    {/*        }}*/}
                    {/*    >*/}
                    {/*        <Icon name="plus" size={20} />*/}
                    {/*    </IconButton>*/}
                    {/*</NullableTooltip>*/}
                    <SecuredComponent permission={{resource: "mapping", scope: "work:create", isProject: true}}>
                        <NullableTooltip title={"Creation disabled, only in ERP"}>
                            <Button
                                variant="outlined"
                                color="default"
                                size="medium"
                                disabled={true}
                                onClick={() => {
                                    // @ts-ignore
                                    workDialogRef?.current?.handlePrepareAddWork();
                                }}
                            >
                                {transCreateNewWork}
                            </Button>
                        </NullableTooltip>
                    </SecuredComponent>

                    <Box className={classes.exportImport}>
                        <ExporterImporter
                            fileName={"Arbeit.xlsx"}
                            exportLabel={transExport}
                            importLabel={transImport}
                            exportCallback={(data) => {
                                exportWork();
                            }}
                            importCallback={importWork}
                            onImportDoneCallback={(response) => {
                                dispatch(onWorkCatalogue({ }))
                            }}
                            importPermission={{resource: "mapping", scope: "work:import", isProject: true}}
                            exportPermission={{resource: "mapping", scope: "work:export", isProject: true}}
                            disableImport={true}
                        />
                    </Box>
                    <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="medium"
                            disabled={selectedWorkIds?.length === 0 || addModeActive}
                            onClick={() => {
                                // @ts-ignore
                                workDialogRef?.current?.handleAddWorkToPosition();
                                handleClose();
                            }}
                        >
                            {selectedWorkIds?.length > 1 ? transButtonPartWork2 : transButtonPartWork1} {transWorkToThePosition}
                        </Button>
                    </SecuredComponent>
                </DialogActions>
            </Dialog>
        </SecuredComponent>
    );
};

export default WorkDialogWrapper;
