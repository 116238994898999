import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "fixed",
            right: "10px",
            bottom: "30px",
            minWidth: "100px",
            padding: "1em",
            opacity: 0.3,
            maxHeight: "300px",
            overflow: "scroll",
            "&:hover": {
                opacity: 1,
            },
            "& .MuiListItem-root": {
                display: "block",
                paddingTop: 0,
                paddingBottom: 0,
            },
            "& .MuiList-root": {
                padding: 0,
            },
            "& .MuiListItem-gutters": {
                paddingLeft: "10px",
                paddingRight: "10px",
            }
        },
        reload: {
            position: "fixed",
            right: theme.spacing(1.5),
            bottom: theme.spacing(3.5),
        }
    })
);

export default useStyles;
