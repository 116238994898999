import { DispatchAction } from "@iolabs/redux-utils";
import React, { useCallback, useEffect, useState } from "react";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import { Form } from "react-final-form";
import { OnChange } from "react-final-form-listeners";
import { Box, CircularProgress, InputAdornment } from "@material-ui/core";
import { TextField } from "mui-rff";
import clsx from "clsx";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { HelpOutline } from "@material-ui/icons";
import { useIntl } from "react-intl";
import messages from "./messages";
import { IElementsPosition, IUpdatePositionBasicsRequestData } from "../../../packages/Api/data/elements/types";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { onUpdatePositionBasics, useDetailPosition, useMappingBasicPositionsLoading } from "../../../redux/mapping";

interface IFixedQuantityFormProps {
    position: IElementsPosition;
    positionLoading?: boolean;
}

interface IFixedQuantityFormValuesProps {
    fixedQuantity?: number
}

const FixedQuantityForm: React.FC<IFixedQuantityFormProps> = ({position, positionLoading}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();

    const activeProject: ProjectData | undefined = useActiveProject();

    const [saving, setSaving] = useState<boolean>(false);
    const [initialValues, setInititalValues] = useState<IFixedQuantityFormValuesProps>();
    const [filteringTimeout, setFilteringTimeout] = useState<ReturnType<typeof setTimeout>>();

    // translations
    const intl = useIntl();
    const transFieldFixedQuantity = intl.formatMessage({ ...messages.fieldFixedQuantity });
    const transFieldFixedQuantityPlaceholder = intl.formatMessage({ ...messages.fieldFixedQuantityPlaceholder });
    const transFieldFixedQuantityHelp = intl.formatMessage({ ...messages.fieldFixedQuantityHelp });

    useEffect(() => {
        if (position) {
            setInititalValues({
                fixedQuantity: position.fixedQuantity
            });
        }
    }, [position]);

    useEffect(() => {
        setSaving(!!positionLoading);
    }, [positionLoading]);

    const handleChange = async (values: IFixedQuantityFormValuesProps) => {
        if (filteringTimeout) {
            clearTimeout(filteringTimeout);
        }

        const data: IUpdatePositionBasicsRequestData = {}
        if (values.fixedQuantity) {
            data.fixedQuantity = values.fixedQuantity;
        }
        else {
            data.clearFixedQuantity = true;
        }

        const timeout = setTimeout(() => {
            setSaving(true);
            dispatch(
                onUpdatePositionBasics({
                    projectID: activeProject?.projectID as number,
                    positionID: position?.positionID as number,
                    data: data
                })
            );
        }, 1000);

        setFilteringTimeout(timeout);

    };

    const onSubmit = useCallback(async (values) => {
        await handleChange(values);
    }, []);


    const validate = (values) => {
        const errors: any = {};
        return errors;
    }
    return (
        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
            <Form onSubmit={onSubmit} initialValues={initialValues} validate={validate}>
                {({ handleSubmit, submitting, pristine, values }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="space-between"
                            alignItems="flex-end"
                            flexBasis="70%"
                        >
                            <TextField
                                required={false}
                                name="fixedQuantity"
                                label={transFieldFixedQuantity}
                                placeholder={transFieldFixedQuantityPlaceholder}
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                color="primary"
                                type={"number"}
                                disabled={saving}
                                className={clsx(classes.textField, {
                                    [classes.placeholder]: !values?.["fixedQuantity"],
                                })}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">
                                        {
                                            saving ? <CircularProgress size={13}/> : <NullableTooltip title={transFieldFixedQuantityHelp}>
                                                <HelpOutline className={classes.help} />
                                            </NullableTooltip>
                                        }
                                    </InputAdornment>,
                                }}
                            >
                            </TextField>
                            <OnChange name="fixedQuantity">
                                {(value, previous) => {
                                    if (value != previous) {
                                        handleChange(values)
                                    }
                                }}
                            </OnChange>

                        </Box>
                    </form>
                )}
            </Form>
        </SecuredComponent>
    );
};

export default FixedQuantityForm;