import React, { FC, useCallback } from "react";
import useStyles from "./styles";
import { HubResponse } from "../../packages/Hubs/api/types";
import { Avatar, Button, Card, CardContent, CardMedia, IconButton, Typography } from "@material-ui/core";
import { FormattedMessage, useIntl } from "react-intl";
import messages from "./messages";
import { stringAvatar } from "./avatarUtils";
import { DeviceHub } from "@material-ui/icons";
import SettingsIcon from "@material-ui/icons/Settings";
import NullableTooltip from "../NullableTooltip/NullableTooltip";
import { Path } from "../../pages/PageRouter";
import { Link } from "react-router-dom";
import { getDetailPath } from "../../packages/Crudder/pathUtils";

interface IProjectSelectorHubProps {
    hub: HubResponse;
    switchHubHandler?: (hub: HubResponse) => void;
    hudAdminClickHandler?: (hub: HubResponse) => void;
}

const ProjectSelectorHub: FC<IProjectSelectorHubProps> = ({ hub, switchHubHandler, hudAdminClickHandler}) => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transSwitchHub = intl.formatMessage({ ...messages.switchHub });
    const transHubAdmin = intl.formatMessage({ ...messages.hubAdmin });

    const handleSwitchHub = useCallback(() => {
        if (switchHubHandler) {
            switchHubHandler(hub);
        }
    }, []);

    const handleHubAdminClick = () => {
        if (hudAdminClickHandler) {
            hudAdminClickHandler(hub);
        }
    }

    return (
        <>
            <Card className={classes.hub} elevation={0}>
                <CardContent className={classes.hubContent}>
                    <Typography component="h5" variant="h5">
                        {hub.name}
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        <FormattedMessage values={{ count: hub.projects?.length }} {...messages.projectsCount} />
                        {switchHubHandler && (
                            <Button
                                size="small"
                                variant={"outlined"}
                                onClick={handleSwitchHub}
                                className={classes.switchHub}
                                startIcon={<DeviceHub />}
                            >
                                {transSwitchHub}
                            </Button>
                        )}
                        <NullableTooltip title={transHubAdmin}>
                            <Link to={getDetailPath(Path.HUBS, hub.hubID as number)} onClick={handleHubAdminClick}>
                                <IconButton className={classes.hubAdmin}>
                                    <SettingsIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </NullableTooltip>
                    </Typography>
                </CardContent>
                <CardMedia className={classes.hubAvatar}>
                    <Avatar {...stringAvatar(hub)} />
                </CardMedia>
            </Card>
        </>
    )
}
export default ProjectSelectorHub;