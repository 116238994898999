import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { addMemberToHub, getHubMembers } from "../../api/client";
import { HubMembersResponse } from "../../api/types";
import { MemberLead } from "../../../Api/data/projectMembers/member-lead";

export interface IHubMembersContext {
    hubID?: number;
    listResponse?: HubMembersResponse;
    invalidateList: () => void
    addMember: (value: MemberLead[]) => Promise<void>
}


export const HubMembersContext = createContext<IHubMembersContext>({
    invalidateList: () => {},
    addMember: (value: MemberLead[]) => new Promise<void>((resolve => {}))
});


export const useHubMembersContext = () => {
    const { hubID, listResponse, invalidateList, addMember } = useContext<IHubMembersContext>(HubMembersContext)

    return {
        hubID, listResponse, invalidateList, addMember
    }
}


interface IHubMembersProviderProps {
    hubID?: number
}

const HubMembersProvider: React.FC<PropsWithChildren<IHubMembersProviderProps>> = ({ children , hubID}) => {
    const [ state, setState ] = useState<number>(0);

    const [ listResponse, setListResponse ] = useState<HubMembersResponse|undefined>();


    const invalidateList = () => {
        setState((s) => s+1);
    }

    useEffect(() => {
        if (hubID) {
            getHubMembers(hubID, true).then((data) => {
                setListResponse(data);
            })
        }
    }, [hubID, state]);

    const addMember = (value: MemberLead[]) => {
        return new Promise<void>((resolve, reject) => {
            if (hubID) {
                addMemberToHub(hubID, value.map(m => m.userID as number))
                    .then(res => {
                        resolve()
                        invalidateList();
                    })
            }
            else {
                reject();
            }

        })

    }

    const value = { listResponse, hubID: hubID, invalidateList, addMember };

    return <HubMembersContext.Provider value={value}>{children}</HubMembersContext.Provider>
}
export default HubMembersProvider
