import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Checkbox,
    CircularProgress,
    IconButton,
    Input,
    MenuItem,
    TableSortLabel,
    TextField,
} from "@material-ui/core";
import clsx from "clsx";
import MuiVirtualizedTable from "mui-virtualized-table";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { AutoSizer } from "react-virtualized";
import {
    IElementsPosition,
    IPositionFormConfiguration,
    MaterialType,
} from "../../../../packages/Api/data/elements/types";
import {
    IMappingMaterial,
    PhysicalBehavior,
    StructuralAssetClass,
    ThermalMaterialType,
    ThermicalBehavior,
} from "../../../../packages/Api/data/material/types";
import {
    onAddMaterialCatalogue,
    onAddMaterialToPosition,
    onDeleteMaterialCatalogue,
    onMaterialCatalogue,
    onUpdateMaterialCatalogue,
    useDetailPosition,
    useMappingMaterialCatalogue,
    useMappingMaterialLoading,
    useMappingPositionFormConfiguration,
    useMappingPositionFormConfigurationLoading,
} from "../../../../redux/mapping";
import { useActiveProject } from "../../../../redux/project";
import { formatNumber, normalizeUnit } from "../../../../utils/Formatter";
import Icon from "../../../Icon/Icon";
import { ProjectData } from "../../../ProjectSelectorWrapper/type";
import ConfirmDialog from "../../../Dialogs/ConfirmDialog/ConfirmDialog";
import RowSubMenu from "../RowSubMenu/RowSubMenu";
import { getComparator, Order, stableSort } from "../WorkDialog/utils";
import useStyles from "./styles";
import NullableTooltip from "../../../NullableTooltip/NullableTooltip";
import { useSecurityContext } from "../../../../redux/keyclock/securityContext";
import SecuredComponent from "../../../../redux/keyclock/SecuredComponent/SecuredComponent";

// filtering
interface IFilter {
    columnName: string;
    value: string;
}

interface IDefaultProps {
    headerHeight: number;
    rowHeight: number;
    minTableWidth: number;
}

interface IMappingMaterialUpdate extends IMappingMaterial {
    isUpdateMode?: boolean;
    isAddMode?: boolean;
}

interface IMaterialDialogProps {
    addModeActive: boolean;
    selectedMaterialIds: number[];
    handleAddModeActive: (active: boolean) => void;
    handleSelectedMaterialIds: (rowIDs: number[]) => void;
    handleClose: () => void;
    ref: any;
}

const MaterialDialog: React.FC<IMaterialDialogProps> = forwardRef(
    ({ addModeActive, selectedMaterialIds, handleAddModeActive, handleSelectedMaterialIds, handleClose }, ref) => {
        const classes = useStyles();
        const { tokenInitialized } = useSecurityContext();
        const dispatch = useDispatch<DispatchAction>();
        const activeProject: ProjectData | undefined = useActiveProject();
        const detailPosition: IElementsPosition | undefined = useDetailPosition();
        const materialLoading: boolean = useMappingMaterialLoading();
        const materialCatalogue: IMappingMaterial[] = useMappingMaterialCatalogue();

        // position form configuration
        const positionFormConfigurationLoading: boolean = useMappingPositionFormConfigurationLoading();
        const positionFormConfiguration: IPositionFormConfiguration|undefined = useMappingPositionFormConfiguration();

        const addModeRef = useRef(null);

        const [defaultProps] = useState<IDefaultProps>({
            headerHeight: 28,
            rowHeight: 28,
            minTableWidth: 6000,
        });

        const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
        const [confirmDelete, setConfirmDelete] = useState<IMappingMaterialUpdate | null>(null);

        const [defaultMaterialCatalogue, setDefaultMaterialCatalogue] = useState<IMappingMaterialUpdate[]>([]);
        const [updateMaterialCatalogue, setUpdateMaterialCatalogue] = useState<IMappingMaterialUpdate[]>([]);
        const [previous, setPrevious] = useState({});

        const [order, setOrder] = useState<Order>(Order.ASC);
        const [orderBy, setOrderBy] = useState<keyof IMappingMaterialUpdate>("itemNumber");

        // filtering
        const [filter, setFilter] = useState<IFilter[]>([]);

        const handleConfirmOpen = () => {
            setConfirmOpen(true);
        };

        const handleConfirmClose = () => {
            setConfirmOpen(false);
        };

        useImperativeHandle(ref, () => ({
            handlePrepareAddMaterial() {
                // @ts-ignore
                addModeRef?.current?.scrollIntoView();
                setUpdateMaterialCatalogue((prevState) => {
                    return [
                        ...prevState,
                        {
                            materialID: -666,
                            class: "",
                            comment: "",
                            compressibility: 0,
                            electricalResistivity: 0,
                            emissivity: 0,
                            itemNumber: "New material",
                            itemTag: "",
                            kMenge: 0,
                            liPrice: 0,
                            link: "",
                            manufacturer: "",
                            model: "",
                            permeability: 0,
                            physicalBehavior: PhysicalBehavior.UNKNOWN,
                            physicalDensity: 0,
                            physicalDescription: "",
                            physicalSource: "",
                            physicalSourceLink: "",
                            poissonsRatioX: 0,
                            poissonsRatioY: 0,
                            poissonsRatioZ: 0,
                            porosity: 0,
                            price: 0,
                            productID: "",
                            reflectivity: 0,
                            shearModulusX: 0,
                            shearModulusY: 0,
                            shearModulusZ: 0,
                            specificHeat: 0,
                            structuralAssetClass: StructuralAssetClass.UNKNOWN,
                            subclass: "",
                            surcharge: 0,
                            tensileStrength: 0,
                            thermalConductivity: 0,
                            thermalExpansionCoefficientX: 0,
                            thermalExpansionCoefficientY: 0,
                            thermalExpansionCoefficientZ: 0,
                            thermalMaterialType: ThermalMaterialType.UNKNOWN,
                            thermicalBehavior: ThermicalBehavior.UNKNOWN,
                            thermicalDensity: 0,
                            thermicalDescription: "",
                            thermicalSource: "",
                            thermicalSourceLink: "",
                            transmitsLight: false,
                            yieldStrength: 0,
                            youngsModulusX: 0,
                            youngsModulusY: 0,
                            youngsModulusZ: 0,
                            // additional
                            isAddMode: true,
                            isUpdateMode: true,
                            referenceUniID: undefined,
                        },
                    ];
                });
                handleAddModeActive(true);
            },

            handleAddMaterialToPosition(materialType: MaterialType) {
                if (!activeProject && !detailPosition) return;
                dispatch(
                    onAddMaterialToPosition({
                        projectID: activeProject?.projectID as number,
                        positionID: detailPosition?.positionID as number,
                        materialIDs: selectedMaterialIds,
                        materialType,
                    })
                );
                handleAddModeActive(false);
                handleSelectedMaterialIds([]);
                handleClose();
            },
        }));

        const handleAddMaterial = (row: IMappingMaterialUpdate) => {
            dispatch(
                onAddMaterialCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    request: {
                        class: row?.class,
                        comment: row?.comment,
                        compressibility: row?.compressibility,
                        electricalResistivity: row?.electricalResistivity,
                        emissivity: row?.emissivity,
                        itemNumber: row?.itemNumber,
                        itemTag: row?.itemTag,
                        kMenge: row?.kMenge,
                        liPrice: row?.liPrice,
                        link: row?.link,
                        manufacturer: row?.manufacturer,
                        model: row?.model,
                        permeability: row?.permeability,
                        physicalBehavior: row?.physicalBehavior,
                        physicalDensity: row?.physicalDensity,
                        physicalDescription: row?.physicalDescription,
                        physicalSource: row?.physicalSource,
                        physicalSourceLink: row?.physicalSourceLink,
                        poissonsRatioX: row?.poissonsRatioX,
                        poissonsRatioY: row?.poissonsRatioY,
                        poissonsRatioZ: row?.poissonsRatioZ,
                        porosity: row?.porosity,
                        price: row?.price,
                        productID: row?.productID,
                        reflectivity: row?.reflectivity,
                        shearModulusX: row?.shearModulusX,
                        shearModulusY: row?.shearModulusY,
                        shearModulusZ: row?.shearModulusZ,
                        specificHeat: row?.specificHeat,
                        structuralAssetClass: row?.structuralAssetClass,
                        subclass: row?.subclass,
                        surcharge: row?.surcharge,
                        tensileStrength: row?.tensileStrength,
                        thermalConductivity: row?.thermalConductivity,
                        thermalExpansionCoefficientX: row?.thermalExpansionCoefficientX,
                        thermalExpansionCoefficientY: row?.thermalExpansionCoefficientY,
                        thermalExpansionCoefficientZ: row?.thermalExpansionCoefficientZ,
                        thermalMaterialType: row?.thermalMaterialType,
                        thermicalBehavior: row?.thermicalBehavior,
                        thermicalDensity: row?.thermicalDensity,
                        thermicalDescription: row?.thermicalDescription,
                        thermicalSource: row?.thermicalSource,
                        thermicalSourceLink: row?.thermicalSourceLink,
                        transmitsLight: row?.transmitsLight,
                        yieldStrength: row?.yieldStrength,
                        youngsModulusX: row?.youngsModulusX,
                        youngsModulusY: row?.youngsModulusY,
                        youngsModulusZ: row?.youngsModulusZ,
                        referenceUnitID: row?.referenceUnitID,
                    },
                })
            );
            handleAddModeActive(false);
        };

        const handleDiscardAddMaterial = (materialID: number) => {
            handleAddModeActive(false);
            setUpdateMaterialCatalogue((prevState) => {
                return prevState?.filter((material) => material?.materialID !== materialID);
            });
        };

        const handleChange = (event, row: IMappingMaterialUpdate, dependantEntity?: string) => {
            event.stopPropagation();

            if (!previous[row?.materialID] && !row?.isAddMode) {
                setPrevious((prevState) => ({ ...prevState, [row?.materialID]: row }));
            }
            const value = event.target.value;
            const name = event.target.name;
            const updatedMaterialCatalogue = updateMaterialCatalogue?.map((material) => {
                if (Object.is(material?.materialID, row?.materialID)) {
                    const out = { ...material, [name]: value };
                    if (dependantEntity) {
                        out[dependantEntity] = positionFormConfiguration?.allowedReferenceUnits?.find(p => p.attributeTypeID == value);
                    }
                    return out;
                }
                else {
                    return material;
                };
            });
            setUpdateMaterialCatalogue(updatedMaterialCatalogue);
        };

        const handleToggleUpdateMode = (materialID: number) => {
            setUpdateMaterialCatalogue((prevState) => {
                return prevState?.map((material) => {
                    return material?.materialID === materialID
                        ? { ...material, isUpdateMode: !material.isUpdateMode }
                        : material;
                });
            });
        };

        const handleFilterByColumn = (event, columnName: keyof IMappingMaterialUpdate) => {
            setFilter((prevState) => {
                const filtered = prevState?.filter((ps) => ps?.columnName !== columnName);

                return [
                    ...filtered,
                    {
                        columnName,
                        value: event.target.value,
                    },
                ];
            });
        };

        const handleUpdateMaterial = (materialID: number) => {
            const updatedMaterial = updateMaterialCatalogue?.find((umc) => umc?.materialID === materialID);
            if (!updatedMaterial) return;

            dispatch(
                onUpdateMaterialCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    materialID: materialID,
                    request: {
                        class: updatedMaterial?.class,
                        comment: updatedMaterial?.comment,
                        compressibility: updatedMaterial?.compressibility,
                        electricalResistivity: updatedMaterial?.electricalResistivity,
                        emissivity: updatedMaterial?.emissivity,
                        itemNumber: updatedMaterial?.itemNumber,
                        itemTag: updatedMaterial?.itemTag,
                        kMenge: updatedMaterial?.kMenge as number,
                        liPrice: updatedMaterial?.liPrice,
                        link: updatedMaterial?.link,
                        manufacturer: updatedMaterial?.manufacturer,
                        model: updatedMaterial?.model,
                        permeability: updatedMaterial?.permeability,
                        physicalBehavior: updatedMaterial?.physicalBehavior,
                        physicalDensity: updatedMaterial?.physicalDensity,
                        physicalDescription: updatedMaterial?.physicalDescription,
                        physicalSource: updatedMaterial?.physicalSource,
                        physicalSourceLink: updatedMaterial?.physicalSourceLink,
                        poissonsRatioX: updatedMaterial?.poissonsRatioX,
                        poissonsRatioY: updatedMaterial?.poissonsRatioY,
                        poissonsRatioZ: updatedMaterial?.poissonsRatioZ,
                        porosity: updatedMaterial?.porosity,
                        price: updatedMaterial?.price,
                        productID: updatedMaterial?.productID,
                        reflectivity: updatedMaterial?.reflectivity,
                        shearModulusX: updatedMaterial?.shearModulusX,
                        shearModulusY: updatedMaterial?.shearModulusY,
                        shearModulusZ: updatedMaterial?.shearModulusZ,
                        specificHeat: updatedMaterial?.specificHeat,
                        structuralAssetClass: updatedMaterial?.structuralAssetClass,
                        subclass: updatedMaterial?.subclass,
                        surcharge: updatedMaterial?.surcharge,
                        tensileStrength: updatedMaterial?.tensileStrength,
                        thermalConductivity: updatedMaterial?.thermalConductivity,
                        thermalExpansionCoefficientX: updatedMaterial?.thermalExpansionCoefficientX,
                        thermalExpansionCoefficientY: updatedMaterial?.thermalExpansionCoefficientY,
                        thermalExpansionCoefficientZ: updatedMaterial?.thermalExpansionCoefficientZ,
                        thermalMaterialType: updatedMaterial?.thermalMaterialType,
                        thermicalBehavior: updatedMaterial?.thermicalBehavior,
                        thermicalDensity: updatedMaterial?.thermicalDensity,
                        thermicalDescription: updatedMaterial?.thermicalDescription,
                        thermicalSource: updatedMaterial?.thermicalSource,
                        thermicalSourceLink: updatedMaterial?.thermicalSourceLink,
                        transmitsLight: updatedMaterial?.transmitsLight,
                        yieldStrength: updatedMaterial?.yieldStrength,
                        youngsModulusX: updatedMaterial?.youngsModulusX,
                        youngsModulusY: updatedMaterial?.youngsModulusY,
                        youngsModulusZ: updatedMaterial?.youngsModulusZ,
                        referenceUnitID: updatedMaterial?.referenceUnitID,
                    },
                })
            );
            handleToggleUpdateMode(materialID);
        };

        const handleRevertMaterial = (materialID: number) => {
            const revertedMaterialCatalogue = updateMaterialCatalogue?.map((material) => {
                return material.materialID === materialID && previous[materialID] ? previous[materialID] : material;
            });

            const revertedMaterial = revertedMaterialCatalogue?.find((rmc) => rmc?.materialID === materialID);
            if (!revertedMaterial) return;

            setUpdateMaterialCatalogue(revertedMaterialCatalogue);
            setPrevious((prevState) => {
                delete prevState[materialID];
                return prevState;
            });

            dispatch(
                onUpdateMaterialCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    materialID: materialID,
                    request: {
                        class: revertedMaterial?.class,
                        comment: revertedMaterial?.comment,
                        compressibility: revertedMaterial?.compressibility,
                        electricalResistivity: revertedMaterial?.electricalResistivity,
                        emissivity: revertedMaterial?.emissivity,
                        itemNumber: revertedMaterial?.itemNumber,
                        itemTag: revertedMaterial?.itemTag,
                        kMenge: revertedMaterial?.kMenge,
                        liPrice: revertedMaterial?.liPrice,
                        link: revertedMaterial?.link,
                        manufacturer: revertedMaterial?.manufacturer,
                        model: revertedMaterial?.model,
                        permeability: revertedMaterial?.permeability,
                        physicalBehavior: revertedMaterial?.physicalBehavior,
                        physicalDensity: revertedMaterial?.physicalDensity,
                        physicalDescription: revertedMaterial?.physicalDescription,
                        physicalSource: revertedMaterial?.physicalSource,
                        physicalSourceLink: revertedMaterial?.physicalSourceLink,
                        poissonsRatioX: revertedMaterial?.poissonsRatioX,
                        poissonsRatioY: revertedMaterial?.poissonsRatioY,
                        poissonsRatioZ: revertedMaterial?.poissonsRatioZ,
                        porosity: revertedMaterial?.porosity,
                        price: revertedMaterial?.price,
                        productID: revertedMaterial?.productID,
                        reflectivity: revertedMaterial?.reflectivity,
                        shearModulusX: revertedMaterial?.shearModulusX,
                        shearModulusY: revertedMaterial?.shearModulusY,
                        shearModulusZ: revertedMaterial?.shearModulusZ,
                        specificHeat: revertedMaterial?.specificHeat,
                        structuralAssetClass: revertedMaterial?.structuralAssetClass,
                        subclass: revertedMaterial?.subclass,
                        surcharge: revertedMaterial?.surcharge,
                        tensileStrength: revertedMaterial?.tensileStrength,
                        thermalConductivity: revertedMaterial?.thermalConductivity,
                        thermalExpansionCoefficientX: revertedMaterial?.thermalExpansionCoefficientX,
                        thermalExpansionCoefficientY: revertedMaterial?.thermalExpansionCoefficientY,
                        thermalExpansionCoefficientZ: revertedMaterial?.thermalExpansionCoefficientZ,
                        thermalMaterialType: revertedMaterial?.thermalMaterialType,
                        thermicalBehavior: revertedMaterial?.thermicalBehavior,
                        thermicalDensity: revertedMaterial?.thermicalDensity,
                        thermicalDescription: revertedMaterial?.thermicalDescription,
                        thermicalSource: revertedMaterial?.thermicalSource,
                        thermicalSourceLink: revertedMaterial?.thermicalSourceLink,
                        transmitsLight: revertedMaterial?.transmitsLight,
                        yieldStrength: revertedMaterial?.yieldStrength,
                        youngsModulusX: revertedMaterial?.youngsModulusX,
                        youngsModulusY: revertedMaterial?.youngsModulusY,
                        youngsModulusZ: revertedMaterial?.youngsModulusZ,
                    },
                })
            );
            handleToggleUpdateMode(materialID);
        };

        const handleDeleteMaterial = (row: IMappingMaterialUpdate) => {
            setConfirmDelete(row);
            handleConfirmOpen();
        };

        const handleConfirmDeleteMaterial = () => {
            if (!confirmDelete) return;

            dispatch(
                onDeleteMaterialCatalogue({
                    projectID: activeProject?.projectID as number,
                    positionID: detailPosition?.positionID as number,
                    materialID: confirmDelete?.materialID,
                })
            );
            setConfirmDelete(null);
            handleConfirmClose();
        };

        const handleRequestSort = (property: keyof IMappingMaterialUpdate) => () => {
            const isAsc = orderBy === property && order === Order.ASC;
            setOrder(isAsc ? Order.DESC : Order.ASC);
            setOrderBy(property);
        };

        // filtering
        useEffect(() => {
            const notFiltersClear = filter?.filter((f) => f?.value !== "");

            if (notFiltersClear?.length > 0) {
                const filteredMaterialCatalogue = defaultMaterialCatalogue?.filter((umc) => {
                    return filter?.every((f) => {
                        const valueToFind = typeof f?.value === 'string' ? f?.value?.toLowerCase() : f?.value;
                        return umc?.[f?.columnName]?.toString()?.toLowerCase()?.includes(valueToFind);
                    });
                });
                setUpdateMaterialCatalogue(filteredMaterialCatalogue);
            } else {
                setUpdateMaterialCatalogue(defaultMaterialCatalogue);
            }
        }, [defaultMaterialCatalogue, filter]);

        useEffect(() => {
            // @ts-ignore
            setUpdateMaterialCatalogue(stableSort(updateMaterialCatalogue, getComparator(order, orderBy)));

            // @ts-ignore
            setDefaultMaterialCatalogue(stableSort(updateMaterialCatalogue, getComparator(order, orderBy)));
        }, [order, orderBy]);

        useEffect(() => {
            setUpdateMaterialCatalogue(
                // @ts-ignore
                stableSort(materialCatalogue as IMappingMaterialUpdate[], getComparator(order, orderBy))
            );

            setDefaultMaterialCatalogue(
                // @ts-ignore
                stableSort(materialCatalogue as IMappingMaterialUpdate[], getComparator(order, orderBy))
            );
        }, [materialCatalogue]);

        useEffect(() => {
            dispatch(onMaterialCatalogue({ }));
        }, [tokenInitialized]);

        return (
            <Box className={classes.dialogBox}>
                {materialLoading && (
                    <Box className={classes.skeletonBox}>
                        <CircularProgress size={40} className={classes.circularProgress} />
                    </Box>
                )}

                <AutoSizer>
                    {({ width, height }) => (
                        <MuiVirtualizedTable
                            data={updateMaterialCatalogue}
                            columns={[
                                {
                                    name: "checkbox",
                                    header: (
                                        <Checkbox
                                            size="small"
                                            checked={selectedMaterialIds.length > 0}
                                            onChange={() =>
                                                handleSelectedMaterialIds(
                                                    selectedMaterialIds?.length === materialCatalogue?.length
                                                        ? []
                                                        : updateMaterialCatalogue?.map(
                                                              (material) => material?.materialID
                                                          )
                                                )
                                            }
                                            {...(selectedMaterialIds.length > 0 &&
                                                selectedMaterialIds.length !== updateMaterialCatalogue?.length && {
                                                    indeterminate: true,
                                                    color: "default",
                                                })}
                                        />
                                    ),
                                    cell: (row) => (
                                        <Checkbox
                                            size="small"
                                            checked={selectedMaterialIds?.some((id) => row?.materialID === id)}
                                            tabIndex={row?.materialID}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "itemNumber",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "itemNumber"}
                                                direction={orderBy === "itemNumber" ? order : Order.ASC}
                                                onClick={handleRequestSort("itemNumber")}
                                            >
                                                Artikel Nr. o. G TIN
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Artikel Nr. o. G TIN"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "itemNumber")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="itemNumber"
                                                    value={row?.itemNumber}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    autoFocus={row?.isAddMode}
                                                    ref={row?.isAddMode ? addModeRef : null}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.itemNumber
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "subMenu",
                                    header: "",
                                    cell: (row) => (
                                        <RowSubMenu
                                            isAddMode={row?.isAddMode as boolean}
                                            isUpdateMode={row?.isUpdateMode as boolean}
                                            disabledRevert={!previous[row?.materialID]}
                                            row={row}
                                            rowID={row?.materialID}
                                            handleAdd={handleAddMaterial}
                                            handleUpdate={handleUpdateMaterial}
                                            handleRevert={handleRevertMaterial}
                                            handleDiscardAdd={handleDiscardAddMaterial}
                                            handleToggleUpdateMode={handleToggleUpdateMode}
                                            handleDelete={handleDeleteMaterial}
                                            updatePermission={{resource: "mapping", scope: "material:update", isProject: true}}
                                            deletePermission={{resource: "mapping", scope: "material:delete", isProject: true}}
                                            disableUpdate={true}
                                            disableDelete={true}
                                        />
                                    ),
                                    width: 50,
                                },
                                {
                                    name: "productID",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "productID"}
                                                direction={orderBy === "productID" ? order : Order.ASC}
                                                onClick={handleRequestSort("productID")}
                                            >
                                                Produkt-ID
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Produkt-ID"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "productID")}
                                            />
                                        </>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="productID"
                                                    value={row?.productID}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.productID
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "manufacturer",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "manufacturer"}
                                                direction={orderBy === "manufacturer" ? order : Order.ASC}
                                                onClick={handleRequestSort("manufacturer")}
                                            >
                                                Manufacturer
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Manufacturer"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "manufacturer")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="manufacturer"
                                                    value={row?.manufacturer}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.manufacturer
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "model",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "model"}
                                                direction={orderBy === "model" ? order : Order.ASC}
                                                onClick={handleRequestSort("model")}
                                            >
                                                Model
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Model"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "model")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 450 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="model"
                                                    value={row?.model}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.model
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "class",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "class"}
                                                direction={orderBy === "class" ? order : Order.ASC}
                                                onClick={handleRequestSort("class")}
                                            >
                                                Class
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Class"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "class")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="class"
                                                    value={row?.class}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.class
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "referenceUnitID",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "referenceUnitID"}
                                                direction={orderBy === "referenceUnitID" ? order : Order.ASC}
                                                onClick={handleRequestSort("referenceUnitID")}
                                            >
                                                Mengeneinheit
                                            </TableSortLabel>
                                            <TextField
                                                onClick={(event) => event.stopPropagation()}
                                                // onChange={(event) => handleChange(event, row, "referenceUnit")}
                                                onChange={(event) => handleFilterByColumn(event, "referenceUnitID")}
                                                className={classes.mvtSelect}
                                                defaultValue={""}
                                                select
                                                placeholder="Filter nach Mengeneinheit"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                            >
                                                <MenuItem value={""}>Alles</MenuItem>
                                                {positionFormConfiguration?.allowedReferenceUnits?.map((aru, index) => (
                                                    <MenuItem key={index} value={aru?.attributeTypeID}>
                                                        {normalizeUnit(aru?.unit)}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {/*<TextField*/}
                                            {/*    placeholder="Filtern nach Class"*/}
                                            {/*    variant="outlined"*/}
                                            {/*    size="small"*/}
                                            {/*    margin="dense"*/}
                                            {/*    color="primary"*/}
                                            {/*    className={classes.textField}*/}
                                            {/*    onChange={(event) => handleFilterByColumn(event, "class")}*/}
                                            {/*/>*/}
                                        </>
                                    ),
                                    width: 150,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <TextField
                                                    name="referenceUnitID"
                                                    value={row?.referenceUnitID}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row, "referenceUnit")}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.referenceUnitID}
                                                    select
                                                >
                                                    {positionFormConfiguration?.allowedReferenceUnits?.map((aru, index) => (
                                                        <MenuItem key={index} value={aru?.attributeTypeID}>
                                                            {normalizeUnit(aru?.unit)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                normalizeUnit(row?.referenceUnit?.unit as string)
                                            )}
                                        </>
                                    ),
                                },
                                // {
                                //     name: "kMenge",
                                //     header: "K.-Menge", // "K Menge",
                                //     width: width < defaultProps.minTableWidth ? 200 : undefined,
                                //     cell: (row) => (
                                //         <>
                                //             {row?.isUpdateMode ? (
                                //                 <Input
                                //                     name="kMenge"
                                //                     value={row?.kMenge}
                                //                     onClick={(event) => event.stopPropagation()}
                                //                     onChange={(event) => handleChange(event, row)}
                                //                     className={classes.mvtInput}
                                //                     tabIndex={row?.materialID}
                                //                     type="number"
                                //                 />
                                //             ) : (
                                //                 row?.kMenge && formatNumber(row?.kMenge)
                                //             )}
                                //         </>
                                //     ),
                                // },
                                {
                                    name: "liPrice",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "liPrice"}
                                                direction={orderBy === "liPrice" ? order : Order.ASC}
                                                onClick={handleRequestSort("liPrice")}
                                            >
                                                Li.-Preis
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Li.-Preis"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "liPrice")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="liPrice"
                                                    value={row?.liPrice}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.liPrice && formatNumber(row?.liPrice)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "ep",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "ep"}
                                                direction={orderBy === "ep" ? order : Order.ASC}
                                                onClick={handleRequestSort("ep")}
                                            >
                                                EP
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach EP"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "ep")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="ep"
                                                    value={row?.ep}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    disabled
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.ep && formatNumber(row?.ep)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "surcharge",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "surcharge"}
                                                direction={orderBy === "surcharge" ? order : Order.ASC}
                                                onClick={handleRequestSort("surcharge")}
                                            >
                                                Zuschlag
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Zuschlag"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "surcharge")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="surcharge"
                                                    value={row?.surcharge}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.surcharge && `${formatNumber(row?.surcharge)} %`
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "itemTag",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "itemTag"}
                                                direction={orderBy === "itemTag" ? order : Order.ASC}
                                                onClick={handleRequestSort("itemTag")}
                                            >
                                                Artikel-tag
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Artikel-tag"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "itemTag")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="itemTag"
                                                    value={row?.itemTag}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.itemTag
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "comment",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "comment"}
                                                direction={orderBy === "comment" ? order : Order.ASC}
                                                onClick={handleRequestSort("comment")}
                                            >
                                                Comment
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Comment"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "comment")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 500 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="comment"
                                                    value={row?.comment}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.comment
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "price",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "price"}
                                                direction={orderBy === "price" ? order : Order.ASC}
                                                onClick={handleRequestSort("price")}
                                            >
                                                Price
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Price"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "price")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="price"
                                                    value={row?.price}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.price && formatNumber(row?.price)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "link",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "link"}
                                                direction={orderBy === "link" ? order : Order.ASC}
                                                onClick={handleRequestSort("link")}
                                            >
                                                Link
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Link"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "link")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 1000 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="link"
                                                    value={row?.link}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.link
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "structuralAssetClass",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "structuralAssetClass"}
                                                direction={orderBy === "structuralAssetClass" ? order : Order.ASC}
                                                onClick={handleRequestSort("structuralAssetClass")}
                                            >
                                                Structural Asset Class
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Structural Asset Class"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "structuralAssetClass")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="structuralAssetClass"
                                                    value={row?.structuralAssetClass}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.structuralAssetClass
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "subclass",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "subclass"}
                                                direction={orderBy === "subclass" ? order : Order.ASC}
                                                onClick={handleRequestSort("subclass")}
                                            >
                                                Subclass
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Subclass"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "subclass")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="subclass"
                                                    value={row?.subclass}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.subclass
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "physicalDescription",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "physicalDescription"}
                                                direction={orderBy === "physicalDescription" ? order : Order.ASC}
                                                onClick={handleRequestSort("physicalDescription")}
                                            >
                                                Physical Description
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Physical Description"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "physicalDescription")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="physicalDescription"
                                                    value={row?.physicalDescription}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.physicalDescription
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "physicalSource",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "physicalSource"}
                                                direction={orderBy === "physicalSource" ? order : Order.ASC}
                                                onClick={handleRequestSort("physicalSource")}
                                            >
                                                Physical Source
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Physical Source"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "physicalSource")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="physicalSource"
                                                    value={row?.physicalSource}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.physicalSource
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "physicalSourceLink",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "physicalSourceLink"}
                                                direction={orderBy === "physicalSourceLink" ? order : Order.ASC}
                                                onClick={handleRequestSort("physicalSourceLink")}
                                            >
                                                Physical Source Link
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Physical Source Link"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "physicalSourceLink")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 1000 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="physicalSourceLink"
                                                    value={row?.physicalSourceLink}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.physicalSourceLink
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "physicalBehavior",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "physicalBehavior"}
                                                direction={orderBy === "physicalBehavior" ? order : Order.ASC}
                                                onClick={handleRequestSort("physicalBehavior")}
                                            >
                                                Physical Behavior
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Physical Behavior"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "physicalBehavior")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="physicalBehavior"
                                                    value={row?.physicalBehavior}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.physicalBehavior
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermalExpansionCoefficientX",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermalExpansionCoefficientX"}
                                                direction={
                                                    orderBy === "thermalExpansionCoefficientX" ? order : Order.ASC
                                                }
                                                onClick={handleRequestSort("thermalExpansionCoefficientX")}
                                            >
                                                Thermal Expansion Coefficient X
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermal Expansion Coefficient X"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "thermalExpansionCoefficientX")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermalExpansionCoefficientX"
                                                    value={row?.thermalExpansionCoefficientX}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermalExpansionCoefficientX &&
                                                formatNumber(row?.thermalExpansionCoefficientX)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermalExpansionCoefficientY",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermalExpansionCoefficientY"}
                                                direction={
                                                    orderBy === "thermalExpansionCoefficientY" ? order : Order.ASC
                                                }
                                                onClick={handleRequestSort("thermalExpansionCoefficientY")}
                                            >
                                                Thermal Expansion Coefficient Y
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermal Expansion Coefficient Y"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "thermalExpansionCoefficientY")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermalExpansionCoefficientY"
                                                    value={row?.thermalExpansionCoefficientY}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermalExpansionCoefficientY &&
                                                formatNumber(row?.thermalExpansionCoefficientY)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermalExpansionCoefficientZ",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermalExpansionCoefficientZ"}
                                                direction={
                                                    orderBy === "thermalExpansionCoefficientZ" ? order : Order.ASC
                                                }
                                                onClick={handleRequestSort("thermalExpansionCoefficientZ")}
                                            >
                                                Thermal Expansion Coefficient Z
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermal Expansion Coefficient Z"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "thermalExpansionCoefficientZ")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermalExpansionCoefficientZ"
                                                    value={row?.thermalExpansionCoefficientZ}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermalExpansionCoefficientZ &&
                                                formatNumber(row?.thermalExpansionCoefficientZ)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "physicalDensity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "physicalDensity"}
                                                direction={orderBy === "physicalDensity" ? order : Order.ASC}
                                                onClick={handleRequestSort("physicalDensity")}
                                            >
                                                Physical Density
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Physical Density"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "physicalDensity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="physicalDensity"
                                                    value={row?.physicalDensity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.physicalDensity && formatNumber(row?.physicalDensity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "youngsModulusX",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "youngsModulusX"}
                                                direction={orderBy === "youngsModulusX" ? order : Order.ASC}
                                                onClick={handleRequestSort("youngsModulusX")}
                                            >
                                                Youngs Modulus X
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Youngs Modulus X"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "youngsModulusX")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="youngsModulusX"
                                                    value={row?.youngsModulusX}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.youngsModulusX && formatNumber(row?.youngsModulusX)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "youngsModulusY",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "youngsModulusY"}
                                                direction={orderBy === "youngsModulusY" ? order : Order.ASC}
                                                onClick={handleRequestSort("youngsModulusY")}
                                            >
                                                Youngs Modulus Y
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Youngs Modulus Y"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "youngsModulusY")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="youngsModulusY"
                                                    value={row?.youngsModulusY}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.youngsModulusY && formatNumber(row?.youngsModulusY)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "youngsModulusZ",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "youngsModulusZ"}
                                                direction={orderBy === "youngsModulusZ" ? order : Order.ASC}
                                                onClick={handleRequestSort("youngsModulusZ")}
                                            >
                                                Youngs Modulus Z
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Youngs Modulus Z"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "youngsModulusZ")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="youngsModulusZ"
                                                    value={row?.youngsModulusZ}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.youngsModulusZ && formatNumber(row?.youngsModulusZ)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "poissonsRatioX",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "poissonsRatioX"}
                                                direction={orderBy === "poissonsRatioX" ? order : Order.ASC}
                                                onClick={handleRequestSort("poissonsRatioX")}
                                            >
                                                Poissons Ratio X
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Poissons Ratio X"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "poissonsRatioX")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="poissonsRatioX"
                                                    value={row?.poissonsRatioX}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.poissonsRatioX && formatNumber(row?.poissonsRatioX)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "poissonsRatioY",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "poissonsRatioY"}
                                                direction={orderBy === "poissonsRatioY" ? order : Order.ASC}
                                                onClick={handleRequestSort("poissonsRatioY")}
                                            >
                                                Poissons Ratio Y
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Poissons Ratio Y"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "poissonsRatioY")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="poissonsRatioY"
                                                    value={row?.poissonsRatioY}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.poissonsRatioY && formatNumber(row?.poissonsRatioY)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "poissonsRatioZ",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "poissonsRatioZ"}
                                                direction={orderBy === "poissonsRatioZ" ? order : Order.ASC}
                                                onClick={handleRequestSort("poissonsRatioZ")}
                                            >
                                                Poissons Ratio Z
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Poissons Ratio Z"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "poissonsRatioZ")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="poissonsRatioZ"
                                                    value={row?.poissonsRatioZ}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.poissonsRatioZ && formatNumber(row?.poissonsRatioZ)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "shearModulusX",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "shearModulusX"}
                                                direction={orderBy === "shearModulusX" ? order : Order.ASC}
                                                onClick={handleRequestSort("shearModulusX")}
                                            >
                                                Shear Modulus X
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Shear Modulus X"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "shearModulusX")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="shearModulusX"
                                                    value={row?.shearModulusX}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.shearModulusX && formatNumber(row?.shearModulusX)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "shearModulusY",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "shearModulusY"}
                                                direction={orderBy === "shearModulusY" ? order : Order.ASC}
                                                onClick={handleRequestSort("shearModulusY")}
                                            >
                                                Shear Modulus Y
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Shear Modulus Y"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "shearModulusY")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="shearModulusY"
                                                    value={row?.shearModulusY}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.shearModulusY && formatNumber(row?.shearModulusY)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "shearModulusZ",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "shearModulusZ"}
                                                direction={orderBy === "shearModulusZ" ? order : Order.ASC}
                                                onClick={handleRequestSort("shearModulusZ")}
                                            >
                                                Shear Modulus Z
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Shear Modulus Z"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "shearModulusZ")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="shearModulusZ"
                                                    value={row?.shearModulusZ}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.shearModulusZ && formatNumber(row?.shearModulusZ)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "yieldStrength",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "yieldStrength"}
                                                direction={orderBy === "yieldStrength" ? order : Order.ASC}
                                                onClick={handleRequestSort("yieldStrength")}
                                            >
                                                Yield Strength
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Yield Strength"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "yieldStrength")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="yieldStrength"
                                                    value={row?.yieldStrength}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.yieldStrength && formatNumber(row?.yieldStrength)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "tensileStrength",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "tensileStrength"}
                                                direction={orderBy === "tensileStrength" ? order : Order.ASC}
                                                onClick={handleRequestSort("tensileStrength")}
                                            >
                                                Tensile Strength
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Tensile Strength"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "tensileStrength")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="tensileStrength"
                                                    value={row?.tensileStrength}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.tensileStrength && formatNumber(row?.tensileStrength)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermalMaterialType",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermalMaterialType"}
                                                direction={orderBy === "thermalMaterialType" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermalMaterialType")}
                                            >
                                                Thermal Material Type
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermal Material Type"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermalMaterialType")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermalMaterialType"
                                                    value={row?.thermalMaterialType}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermalMaterialType
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermicalDescription",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermicalDescription"}
                                                direction={orderBy === "thermicalDescription" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermicalDescription")}
                                            >
                                                Thermical Description
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermical Description"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "thermicalDescription")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermicalDescription"
                                                    value={row?.thermicalDescription}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermicalDescription
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermicalSource",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermicalSource"}
                                                direction={orderBy === "thermicalSource" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermicalSource")}
                                            >
                                                Thermical Source
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermical Source"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermicalSource")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermicalSource"
                                                    value={row?.thermicalSource}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermicalSource
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermicalSourceLink",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermicalSourceLink"}
                                                direction={orderBy === "thermicalSourceLink" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermicalSourceLink")}
                                            >
                                                Thermical Source Link
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermical Source Link"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermicalSourceLink")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 1000 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermicalSourceLink"
                                                    value={row?.thermicalSourceLink}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermicalSourceLink
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "transmitsLight",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "transmitsLight"}
                                                direction={orderBy === "transmitsLight" ? order : Order.ASC}
                                                onClick={handleRequestSort("transmitsLight")}
                                            >
                                                Transmits Light
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Transmits Light"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "transmitsLight")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="transmitsLight"
                                                    value={row?.transmitsLight}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.transmitsLight
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermicalBehavior",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermicalBehavior"}
                                                direction={orderBy === "thermicalBehavior" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermicalBehavior")}
                                            >
                                                Thermical Behavior
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermical Behavior"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermicalBehavior")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermicalBehavior"
                                                    value={row?.thermicalBehavior}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermicalBehavior
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermalConductivity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermalConductivity"}
                                                direction={orderBy === "thermalConductivity" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermalConductivity")}
                                            >
                                                Thermal Conductivity
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermal Conductivity"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermalConductivity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermalConductivity"
                                                    value={row?.thermalConductivity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermalConductivity && formatNumber(row?.thermalConductivity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "specificHeat",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "specificHeat"}
                                                direction={orderBy === "specificHeat" ? order : Order.ASC}
                                                onClick={handleRequestSort("specificHeat")}
                                            >
                                                Specific Heat
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Specific Heat"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "specificHeat")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="specificHeat"
                                                    value={row?.specificHeat}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.specificHeat && formatNumber(row?.specificHeat)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "thermicalDensity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "thermicalDensity"}
                                                direction={orderBy === "thermicalDensity" ? order : Order.ASC}
                                                onClick={handleRequestSort("thermicalDensity")}
                                            >
                                                Thermical Density
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Thermical Density"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "thermicalDensity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="thermicalDensity"
                                                    value={row?.thermicalDensity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.thermicalDensity && formatNumber(row?.thermicalDensity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "emissivity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "emissivity"}
                                                direction={orderBy === "emissivity" ? order : Order.ASC}
                                                onClick={handleRequestSort("emissivity")}
                                            >
                                                Emissivity
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Emissivity"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "emissivity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="emissivity"
                                                    value={row?.emissivity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.emissivity && formatNumber(row?.emissivity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "permeability",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "permeability"}
                                                direction={orderBy === "permeability" ? order : Order.ASC}
                                                onClick={handleRequestSort("permeability")}
                                            >
                                                Permeability
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Permeability"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "permeability")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="permeability"
                                                    value={row?.permeability}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.permeability && formatNumber(row?.permeability)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "porosity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "porosity"}
                                                direction={orderBy === "porosity" ? order : Order.ASC}
                                                onClick={handleRequestSort("porosity")}
                                            >
                                                Porosity
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Porosity"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "porosity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="porosity"
                                                    value={row?.porosity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.porosity && formatNumber(row?.porosity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "reflectivity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "reflectivity"}
                                                direction={orderBy === "reflectivity" ? order : Order.ASC}
                                                onClick={handleRequestSort("reflectivity")}
                                            >
                                                Reflectivity
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Reflectivity"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "reflectivity")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="reflectivity"
                                                    value={row?.reflectivity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.reflectivity && formatNumber(row?.reflectivity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "compressibility",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "compressibility"}
                                                direction={orderBy === "compressibility" ? order : Order.ASC}
                                                onClick={handleRequestSort("compressibility")}
                                            >
                                                Compressibility
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Compressibility"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) => handleFilterByColumn(event, "compressibility")}
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="compressibility"
                                                    value={row?.compressibility}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.compressibility && formatNumber(row?.compressibility)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "electricalResistivity",
                                    header: (
                                        <>
                                            <TableSortLabel
                                                active={orderBy === "electricalResistivity"}
                                                direction={orderBy === "electricalResistivity" ? order : Order.ASC}
                                                onClick={handleRequestSort("electricalResistivity")}
                                            >
                                                Electrical Resistivity
                                            </TableSortLabel>
                                            <TextField
                                                placeholder="Filtern nach Electrical Resistivity"
                                                variant="outlined"
                                                size="small"
                                                margin="dense"
                                                color="primary"
                                                className={classes.textField}
                                                onChange={(event) =>
                                                    handleFilterByColumn(event, "electricalResistivity")
                                                }
                                            />
                                        </>
                                    ),
                                    width: width < defaultProps.minTableWidth ? 200 : undefined,
                                    cell: (row) => (
                                        <>
                                            {row?.isUpdateMode ? (
                                                <Input
                                                    name="electricalResistivity"
                                                    value={row?.electricalResistivity}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onChange={(event) => handleChange(event, row)}
                                                    className={classes.mvtInput}
                                                    tabIndex={row?.materialID}
                                                    type="number"
                                                    onKeyDown={(event) => {
                                                        if(event.key == "Enter") {
                                                            event.stopPropagation();
                                                            row?.isAddMode
                                                                ? handleAddMaterial(row)
                                                                : handleUpdateMaterial(row?.materialID);
                                                        }
                                                    }}
                                                />
                                            ) : (
                                                row?.electricalResistivity && formatNumber(row?.electricalResistivity)
                                            )}
                                        </>
                                    ),
                                },
                                {
                                    name: "update",
                                    header: "",
                                    cell: (row) => (
                                        <SecuredComponent permission={{resource: "mapping", scope: "material:update", isProject: true}}>
                                            {row?.isUpdateMode ? (
                                                <>
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? "Neues Material hinzufügen" // "Add new material"
                                                                : "Änderungen speichern" // "Save changes"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="save"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                row?.isAddMode
                                                                    ? handleAddMaterial(row)
                                                                    : handleUpdateMaterial(row?.materialID);
                                                            }}
                                                            className={clsx(classes.iconButton, classes.iconButtonSave)}
                                                        >
                                                            <Icon name="check" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                    {!row?.isAddMode && (
                                                        <NullableTooltip title="Zurückkehren" placement="top">
                                                            {/*Revert*/}
                                                            <IconButton
                                                                size="small"
                                                                aria-label="revert"
                                                                onClick={(event) => {
                                                                    event.stopPropagation();
                                                                    handleRevertMaterial(row?.materialID);
                                                                }}
                                                                className={clsx(
                                                                    classes.iconButton,
                                                                    classes.iconButtonRevert,
                                                                    {
                                                                        [classes.disabled]: !previous[row?.materialID],
                                                                    }
                                                                )}
                                                                disabled={!previous[row?.materialID]}
                                                            >
                                                                <Icon name="history" size={18} />
                                                            </IconButton>
                                                        </NullableTooltip>
                                                    )}
                                                    <NullableTooltip
                                                        title={
                                                            row?.isAddMode
                                                                ? "Ablegen" // "Discard"
                                                                : "Schließen" // "Close"
                                                        }
                                                        placement="top"
                                                    >
                                                        <IconButton
                                                            size="small"
                                                            aria-label="close"
                                                            onClick={(event) => {
                                                                event.stopPropagation();

                                                                row?.isAddMode
                                                                    ? handleDiscardAddMaterial(row?.materialID)
                                                                    : handleToggleUpdateMode(row?.materialID);
                                                            }}
                                                            className={clsx(
                                                                classes.iconButton,
                                                                classes.iconButtonClose
                                                            )}
                                                        >
                                                            <Icon name="close" size={18} />
                                                        </IconButton>
                                                    </NullableTooltip>
                                                </>
                                            ) : (
                                                <NullableTooltip title="Update disabled, only in ERP" placement="top">
                                                    <IconButton
                                                        size="small"
                                                        aria-label="update"
                                                        disabled={true}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleToggleUpdateMode(row?.materialID);
                                                        }}
                                                        className={classes.iconButton}
                                                    >
                                                        <Icon name="pencil-solid" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </SecuredComponent>
                                    ),
                                    width: 40,
                                },
                                {
                                    name: "delete",
                                    header: "",
                                    cell: (row) => (
                                        <SecuredComponent permission={{resource: "mapping", scope: "material:delete", isProject: true}}>
                                            {!row?.isAddMode && (
                                                <NullableTooltip title="Delete disabled, only in ERP" placement="top">
                                                    {/*Delete*/}
                                                    <IconButton
                                                        size="small"
                                                        aria-label="delete"
                                                        disabled={true}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleDeleteMaterial(row);
                                                        }}
                                                        className={clsx(classes.iconButton, classes.iconButtonClose)}
                                                    >
                                                        <Icon name="trash-alt" size={18} />
                                                    </IconButton>
                                                </NullableTooltip>
                                            )}
                                        </SecuredComponent>
                                    ),
                                    width: 60,
                                },
                            ]}
                            width={width}
                            maxHeight={height}
                            includeHeaders={true}
                            fixedRowCount={1}
                            fixedColumnCount={3}
                            rowHeight={defaultProps.rowHeight}
                            isCellSelected={(column, row) =>
                                selectedMaterialIds?.some((id) => row && row?.materialID === id)
                            }
                            onCellClick={(event, { column, rowData, data}) => {
                                const materialID = rowData?.materialID as number;
                                if (materialID) {
                                    handleSelectedMaterialIds(
                                        selectedMaterialIds?.some((id) => materialID === id)
                                            ? selectedMaterialIds?.filter((id) => id !== materialID)
                                            : [...selectedMaterialIds, materialID]
                                    );
                                }
                            }}
                            orderBy={orderBy}
                            orderDirection={order}
                            classes={{
                                table: classes.mvtTable,
                                cellHeader: classes.mvtCellHeader,
                                cellContents: classes.mvtCellContents,
                                cellSelected: classes.mvtCellSelected,
                            }}
                        />
                    )}
                </AutoSizer>
                <ConfirmDialog
                    open={confirmOpen}
                    handleClose={handleConfirmClose}
                    handleConfirm={handleConfirmDeleteMaterial}
                    messages={{
                        dialogTitle: "Löschen", // "Delete confirm"
                        confirmMessage: (
                            <>
                                Sind Sie sicher, dass Sie das Material löschen wollen{" "}
                                {/* Are you sure you want to delete the material */}
                                <strong>
                                    {confirmDelete?.productID} - {confirmDelete?.itemNumber}
                                </strong>
                                ?
                            </>
                        ),
                        closeButton: "Abbrechen", // Close
                        confirmButton: "Löschen", // Delete
                    }}
                />
            </Box>
        );
    }
);

export default MaterialDialog;
