import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        urlLoaderInput: {
            width: "100%"
        },
        urlParserForm: {
            // color: theme.palette.common.black
            "& .MuiTextField-root": {
                "& input": {
                    // padding: "10px 10px !important",
                    fontSize: theme.typography.pxToRem(11),
                },
            },
            "& .MuiFormLabel-root, & .MuiFormHelperText-root": {
                color: theme.palette.primary.main,
            },
        },
        divider: {
            margin: theme.spacing(2, 0),
        },
        form: {

        },
        stepContentPaper: {
            padding: theme.spacing(2),
            marginTop: theme.spacing(2),
        },
        pageHeader: {
            marginBottom: theme.spacing(2),
        },
        buttons: {
            marginTop: theme.spacing(2),
            textAlign: "center",
            "& .MuiAlert-root": {
                marginBottom: theme.spacing(2),
            }
        }
    })
);

export default useStyles;
