import { AnyAction } from "redux";
import { ProjectData } from "../../components/ProjectSelectorWrapper/type";
import { ActionTypes } from "./action";
import { SpecialInstancesResponse } from "../../packages/Api/data/instances/types";
import { HubResponse } from "../../packages/Hubs/api/types";
import { flattenProjects } from "./utils";

export interface IProjectModuleState {
    projectState: IProjectState;
}

export interface IProjectState {
    activeProject?: ProjectData;
    projects?: ProjectData[];
    hubs?: HubResponse[];
    hubsLoading: boolean;
    specialInstances?: SpecialInstancesResponse;
}

export const initialState: Readonly<IProjectState> = {
    hubsLoading: false
};

export function reducer(state: IProjectState = initialState, action: AnyAction): IProjectState {
    switch (action.type) {
        case ActionTypes.SetActiveProject: {
            return {
                ...state,
                activeProject: action.payload.activeProject,
                specialInstances: undefined
            };
        }

        case ActionTypes.SetProjects: {
            return {
                ...state,
                projects: action.payload.projects,
            };
        }

        case ActionTypes.SetHubs: {
            return {
                ...state,
                hubs: action.payload.hubs,
                projects: flattenProjects(state.hubs),
                hubsLoading: false
            };
        }

        case ActionTypes.LoadHubs: {
            return {
                ...state,
                hubsLoading: true
            };
        }

        case ActionTypes.LoadSpecialInstancesDone: {
            return {
                ...state,
                specialInstances: action.payload.response,
            };
        }

        default:
            return state;
    }
}
