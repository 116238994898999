import { Box } from "@material-ui/core";
import React from "react";
import Page from "../Page/Page";
import useStyles from "./styles";
import HubModule from "../../packages/Hubs/components/HubModule/HubModule";

const HubsPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page noPadding>
            <Box className={classes.columnBox}>
                <HubModule />
            </Box>
        </Page>
    );
};

export default HubsPage;
