import { defineMessages } from "react-intl";

export default defineMessages({
    header: {
        id: "components.cockpit.header",
        defaultMessage: "Cockpit",
    },
    buttonSynchronize: {
        id: "components.cockpit.buttonSynchronize",
        defaultMessage: "Synchronize",
    },
    synchronizeErpData: {
        id: "components.cockpit.synchronizeErpData",
        defaultMessage: "Synchronize ERP data",
    },
    actions: {
        id: "components.cockpit.actions",
        defaultMessage: "Actions",
    },
    modules: {
        id: "components.cockpit.modules",
        defaultMessage: "Modules",
    },
    moduleHubs: {
        id: "components.cockpit.moduleHubs",
        defaultMessage: "Hubs",
    },
    moduleHubsDescription: {
        id: "components.cockpit.moduleHubsDescription",
        defaultMessage: "Hub represents separated group of projects",
    },
});
