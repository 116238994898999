import { DispatchAction } from "@iolabs/redux-utils";
import { Box, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Issue } from "../../../graphql/generated/graphql";
import { onChangeTab, onIssueEdit, useIsIssueFromViewerPage, useIssueTab } from "../../../redux/issue";
import { onViewableNavigate } from "../../../redux/viewer";
import CreateIssueWrapper from "../CreateIssueWrapper/CreateIssueWrapper";
import EditIssueWrapper from "../EditIssueWrapper/EditIssueWrapper";
import { getForgeIssueData } from "../helpers";
import IssuesTabPanel from "../IssuesTabPanel/IssuesTabPanel";
import useIssuesStyles from "./styles";
import ListIssueWrapper from "../ListIssueWrapper/ListIssueWrapper";
import { IIssueTab } from "../type";
import { useGetPath } from "../../../utils/Menu";
import SecuredComponent, { SecurityMode } from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

const IssuesWrapper: React.FC = () => {
    const classes = useIssuesStyles();
    const theme = useTheme();
    const issueTab: IIssueTab = useIssueTab();
    const dispatch = useDispatch<DispatchAction>();
    const isIssueFromViewerPage: boolean = useIsIssueFromViewerPage();

    const { getProjectPermission } = useGetPath();

    const handleChangeTab = (tab: IIssueTab) => {
        dispatch(onChangeTab({ issueTab: tab }));
    };

    const handleIssueEdit = (issue: Issue) => {
        dispatch(
            onIssueEdit({ issueEditId: issue?.issueExternals?.[0]?.externalID as string, issueTab: IIssueTab.Edit })
        );
        const externalIssueData = getForgeIssueData(issue);
        const viewableGuid = externalIssueData?.sheetMetadata?.sheetGuid;
        if (viewableGuid) {
            dispatch(onViewableNavigate({ guid: viewableGuid }));
        }
    };

    useEffect(() => {
        if (!isIssueFromViewerPage) {
            dispatch(onIssueEdit({ issueEditId: "" }));
        }
    }, [isIssueFromViewerPage]);

    return (

        <Box className={classes.root}>
            <IssuesTabPanel key={IIssueTab.List} value={issueTab} index={IIssueTab.List} dir={theme.direction}>
                <ListIssueWrapper handleChangeTab={handleChangeTab} handleIssueEdit={handleIssueEdit} />
            </IssuesTabPanel>

            <IssuesTabPanel key={IIssueTab.Create} value={issueTab} index={IIssueTab.Create} dir={theme.direction}>
                <SecuredComponent permission={getProjectPermission("issues", "issue:create")} securityMode={SecurityMode.FORBIDDEN}>
                    <CreateIssueWrapper handleChangeTab={handleChangeTab} />
                </SecuredComponent>
            </IssuesTabPanel>

            <IssuesTabPanel key={IIssueTab.Edit} value={issueTab} index={IIssueTab.Edit} dir={theme.direction}>
                <SecuredComponent permission={getProjectPermission("issues", "issue:update")} securityMode={SecurityMode.FORBIDDEN}>
                    <EditIssueWrapper handleChangeTab={handleChangeTab} />
                </SecuredComponent>
            </IssuesTabPanel>
        </Box>
    );
};

export default IssuesWrapper;
