import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CardMedia,
    Grid,
    Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import messages from "./messages";
import ActionButton from "../ActionButton/ActionButton";
import { syncErpData } from "../../packages/Api/data/erp/client";
import SecuredComponent from "../../redux/keyclock/SecuredComponent/SecuredComponent";
import { Module } from "../../graphql/generated/graphql";
import { Permissions } from "../../redux/keyclock/SecuredComponent/permissions";
import clsx from "clsx";
import HubIcon from "@material-ui/icons/DeviceHub";
import { Link } from "react-router-dom";
import { Path } from "../../pages/PageRouter";
import { useGetPath } from "../../utils/Menu";

interface IProjectToolsProps {
}

const Cockpit: React.FC<IProjectToolsProps> = ({  }) => {
    const classes = useStyles();

    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transHeader = intl.formatMessage({ ...messages.header });
    const transSynchronizeErpData = intl.formatMessage({ ...messages.synchronizeErpData });
    const transActions = intl.formatMessage({ ...messages.actions });
    const transModules = intl.formatMessage({ ...messages.modules });
    const transModulesHubs = intl.formatMessage({ ...messages.moduleHubs });
    const transModulesHubsDescription = intl.formatMessage({ ...messages.moduleHubsDescription });

    const { getPath } = useGetPath();



    const handleSyncErpData = async () => {
        return new Promise((resolve, reject) => {
            syncErpData(true).then(resolve).catch(reject);
        })
    };

    return <>
            <Box className={classes.root}>
                <Typography variant={"h2"}>{transHeader}</Typography>

                <Box className={clsx(classes.actions, classes.section)}>
                    <Typography className={classes.sectionHeader} variant="subtitle1">{transActions}</Typography>
                    <ActionButton header={transSynchronizeErpData} action={handleSyncErpData} />
                </Box>

                <Box className={clsx(classes.modules, classes.section)}>
                    <Typography className={classes.sectionHeader} variant="subtitle1">{transModules}</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6} sm={3} md={2}>
                            <SecuredComponent permission={Permissions.Hubs.view}>
                                <Card>
                                    <CardHeader
                                        avatar={
                                            <HubIcon />
                                        }
                                        title={transModulesHubs}
                                    />
                                    <CardContent>
                                        <Typography variant="body2">
                                            {transModulesHubsDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions >
                                        <Button size="small" component={Link} to={getPath(Path.HUBS)}>View</Button>
                                    </CardActions>
                                </Card>
                            </SecuredComponent>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
    </>;
};

export default Cockpit;