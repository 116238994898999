import { KeycloakEvent } from "@react-keycloak/web";
import { AnyAction } from "redux";
import { ActionTypes } from "./action";
import { Permission, UMA2Configuration } from "./api/types";

export interface IKeycloakModuleState {
    keycloakState: IKeycloakState;
}

export interface IKeycloakState {
    idToken: string;
    refreshToken: string;
    token: string;
    event: KeycloakEvent | null;
    permissions: Permission[];
    umaConfiguration?: UMA2Configuration;
    umaToken?: string;
    umaForbidden?: boolean;
}

const initialState: Readonly<IKeycloakState> = {
    idToken: "",
    refreshToken: "",
    token: "",
    event: null,
    permissions: []
};

export function reducer(state: IKeycloakState = initialState, action: AnyAction): IKeycloakState {
    switch (action.type) {
        case ActionTypes.OnEvent: {
            const { event } = action.payload;
            return { ...state, event };
        }

        case ActionTypes.OnTokens: {
            const { idToken, refreshToken, token } = action.payload;
            return { ...state, idToken, refreshToken, token };
        }

        case ActionTypes.SetUmaConfiguration: {
            const { config } = action.payload;
            return { ...state, umaConfiguration: config };
        }

        default:
            return state;
    }
}
