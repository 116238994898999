import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "50%",
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
            padding: "1rem",
            backgroundColor: theme.palette.error.light,
            color: theme.palette.error.contrastText,
            textAlign: "center",
        },
        icon: {
            fontSize: "4rem"
        },
        header: {
            fontSize: "2rem"
        }
    })
);

export default useStyles;
