import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

export const defaultMappingPanelSize = 40;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        columnBox: {
            backgroundColor: theme.palette.primary[50],
            height: "100%",
            display: "flex",
            flexFlow: "column nowrap",
        },
    })
);

export default useStyles;
