import React from "react";
import HubMembersProvider from "./HubMembersContext";
import { ICrudDetailLoadedProps, ICrudDetailProps } from "../../../Crudder/components/CrudDetail/CrudDetail";
import HubMembersSearch from "./HubMembersSearch";
import HubMembersList from "./HubMembersList";
import { HubResponse } from "../../api/types";

interface IHubMembersProp extends ICrudDetailLoadedProps<HubResponse> {

}

const HubMembers: React.FC<IHubMembersProp> = ({ data  }) => {

    return (
        <HubMembersProvider hubID={(data as HubResponse).hubID}>
            <HubMembersSearch />
            <HubMembersList />
        </HubMembersProvider>
    );
};

export default HubMembers;