import { Box, Divider, Grid, Paper } from "@material-ui/core";
import React from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router";
import BreadcrumbWrapper from "../../components/BreadcrumbWrapper/BreadcrumbWrapper";
import DataTablePanel from "../../components/DataTablePanel/DataTablePanel/DataTablePanel";
import DocumentViewWrapper from "../../components/DocumentView/DocumentViewWrapper/DocumentViewWrapper";
import PageTitle from "../../components/PageTitle/PageTitle";
import TreeWrapper from "../../components/Tree/TreeWrapper/TreeWrapper";
import { extractParam } from "../../utils/Params";
import Page from "../Page/Page";
import { IPageParams } from "../type";
import messages from "./messages";
import useStyles from "./styles";
import { useActiveNodeId } from "../../redux/tree";
import { useGetPath } from "../../utils/Menu";
import SecuredComponent from "../../redux/keyclock/SecuredComponent/SecuredComponent";
import { appPages, Path } from "../PageRouter";

const DocumentPage: React.FC = () => {
    const { nodeId } = useParams<IPageParams>();
    const classes = useStyles();
    const { nodeIds, projectId } = extractParam(nodeId as string);
    const activeNodeId = useActiveNodeId();

    const { getPermission } = useGetPath();

    // translations
    const intl = useIntl();
    const transDocuments = intl.formatMessage({ ...messages.documents });

    return (
        <Page>
            {/*<SecuredComponent permission={getPermission(appPages.find(p => p.path == Path.DOCUMENTS)!)}>*/}
                <Grid container spacing={0} className={classes.gridContainer}>
                    <Grid item xs={12} sm={12} className={classes.gridItemTitle}>
                        <PageTitle title={transDocuments} />
                        <BreadcrumbWrapper activeProjectId={projectId} activeFoldersIds={nodeIds} />
                    </Grid>


                    <Grid item xs={12} sm={12} className={classes.gridItemTree}>

                            <Grid container spacing={0} className={classes.documentContainer}>
                                <Grid item xs={12} sm={12} md={4} lg={3}>
                                    <Paper className={classes.paper}>
                                        {/*<TreePanelWrapper projects={projects} activeProjectId={projectId} />*/}
                                        {/*<Divider />*/}
                                        <Box className={classes.treeBox}>
                                            <TreeWrapper projectIdFromParam={projectId ? projectId : activeNodeId} activeFoldersIds={nodeIds} />
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={9}>
                                    <Paper className={classes.paper}>
                                        <DataTablePanel />
                                        <Divider />
                                        <DocumentViewWrapper activeProjectId={projectId} activeFoldersIds={nodeIds} />
                                    </Paper>
                                </Grid>
                            </Grid>
                    </Grid>
                </Grid>
            {/*</SecuredComponent>*/}
        </Page>
    );
};

export default DocumentPage;
