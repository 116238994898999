import React from "react";
import { useDebouncedMembersLookup } from "../../MemberSearch/hooks";
import { searchProjectLeads } from "../../../packages/Api/data/projectMembers/client";
import { useProjectMembersContext } from "../ProjectMembers/ProjectMembersContext";
import MembersSearch from "../../MemberSearch/MembersSearch";

interface IProjectMembersSearchProps {
}
interface MemberSearchOption {
    type: "person"|"company",
    id: number,
    name: string,
    email?: string,
}

const useSearchMembers = () => useDebouncedMembersLookup((projectID, text) => searchProjectLeads(projectID, text))

const ProjectMembersSearch: React.FC<IProjectMembersSearchProps> = ({ }) => {

    const { projectID, addMember } = useProjectMembersContext();
    return (
        <MembersSearch addMember={addMember} ownerID={projectID as number} membersLookup={useSearchMembers}  />
    );
}
export default ProjectMembersSearch
