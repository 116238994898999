import { DispatchAction } from "@iolabs/redux-utils";
import { IonRouterLink } from "@ionic/react";
import { Badge, Box, CircularProgress, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { IPageParams } from "../../../pages/type";
import { onIssueViewerChangePath } from "../../../redux/issue";
import { onLoadUrn } from "../../../redux/viewer";
import theme from "../../../theme/Theme";
import Icon from "../../Icon/Icon";
import { Data } from "../DocumentViewWrapper/DocumentViewWrapper";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";
import { useGetPath } from "../../../utils/Menu";
import SecuredComponent, { SecurityMode } from "../../../redux/keyclock/SecuredComponent/SecuredComponent";
import SecuredWrapper from "../../../redux/keyclock/SecuredComponent/SecuredWrapper";

interface IDocumentIssuesProps {
    item: Data;
}

const DocumentIssues: React.FC<IDocumentIssuesProps> = ({ item }) => {
    const { accountUrn, projectUrn } = useParams<IPageParams>();
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const { tokenInitialized } = useSecurityContext();
    const [issuesCount, setIssuesCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const { getProjectPermission } = useGetPath();

    const handleClick = () => {
        dispatch(
            onLoadUrn({
                urn: item.urn,
                fileId: item.fileId as string,
                fileType: item.fileType as string,
            })
        );
        dispatch(onIssueViewerChangePath({ path: item?.pathIssues }));
    };

    // useEffect(() => {
    //     if (item.issuesCount === undefined && tokenInitialized && item.nodeId) {
    //         setLoading(true);
    //         getIssuesCount(item.nodeId)
    //             .then((count: number) => {
    //                 setIssuesCount(count);
    //                 item.issuesCount = count;
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //             .finally(() => {
    //                 setLoading(false);
    //             });
    //     }
    //     return () => {
    //         setIssuesCount(undefined);
    //     };
    // }, [item.nodeId, tokenInitialized]);

    const getCurrentItemIssuesCount = () => {
        return item.issuesCount ? item.issuesCount : issuesCount;
    };


    // translations
    const intl = useIntl();
    const transTooltipHasIssues = intl.formatMessage({ ...messages.tooltipHasIssues });
    const transTooltipNoIssues = intl.formatMessage({ ...messages.tooltipNoIssues });

    return (
        <Box className={classes.box}>
            {loading ? (
                <CircularProgress size={18} className={classes.circularProgress} />
            ) : (
                <SecuredWrapper
                    wrapper={(children) => (
                        <IonRouterLink routerLink={item.pathIssues} routerDirection="forward" onClick={handleClick}>
                            {children}
                        </IonRouterLink>
                    )}
                    permission={getProjectPermission("issues", "issue:view")}
                >
                    {getCurrentItemIssuesCount() !== 0 ? (
                        <NullableTooltip title={transTooltipHasIssues} placement="top">
                            <IconButton aria-label="go to the issues">
                                <SecuredWrapper
                                    permission={getProjectPermission("issues", "issue:count")}
                                    wrapper={ (children) => (
                                        <Badge badgeContent={getCurrentItemIssuesCount()} classes={{ badge: classes.badge }}>
                                            {children}
                                        </Badge>
                                    )}
                                >
                                    <Icon name="blade" size={18} fill={theme.palette.success.dark} />
                                </SecuredWrapper>
                            </IconButton>
                        </NullableTooltip>
                    ) : (
                        <NullableTooltip title={transTooltipNoIssues} placement="top">
                            <IconButton aria-label="go to the issues">
                                <Icon name="blade" size={18} fill={theme.palette.text.secondary} />
                            </IconButton>
                        </NullableTooltip>
                    )}
                </SecuredWrapper>
            )}
        </Box>
    );
};

export default DocumentIssues;
