import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative",
            display: "contents",
            "& .permission-info": {
                // display: "none",
                fontSize: "10px",
                position: "absolute",
                top: "-10px",
                left: "0",
            }
        },
        inline: {
        },
        wrapperInline: {
            display: "contents"
        },
        notAllowed: {
            cursor: "not-allowed",
            position: "relative",
            "& *": {
                cursor: "not-allowed",
                pointerEvents: "none",
            },
            "&>*": {
                opacity: 0.8
            },
            "&:hover": {
                "&::after": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                    opacity: "0.2",
                    pointerEvents: "none",
                    border: "5px dashed red"
                },
                "& .permission-info": {
                    display: "block"
                }
            }
        },
        allowed: {
            position: "relative",
            "&>*": {
                // height: "auto"
            },
            "&:hover": {
                "&::after": {
                    content: "''",
                    display: "block",
                    position: "absolute",
                    left: "0px",
                    top: "0px",
                    width: "100%",
                    height: "100%",
                    opacity: "0.2",
                    pointerEvents: "none",
                    border: "5px dashed green"
                },
                "& .permission-info": {
                    display: "block"
                }
            }
        },
    })
);

export default useStyles;
