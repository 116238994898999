import React from "react";
import useStyles from "./styles";
import { Button, IconButton, List, ListItem, ListItemText, Paper } from "@material-ui/core";
import { useSecurityContext } from "../securityContext";
import { Sync } from "@material-ui/icons";
import { fetchUma } from "../action";

const PermissionsInfo: React.FC = () => {
    const classes = useStyles();
    const { permissions, syncPermissions } = useSecurityContext();
    const handleSync = () => {
        syncPermissions()
    }
    return (
        <Paper className={classes.root} elevation={12}>
            <List dense={true}>
                {permissions?.sort((a,b) => a.rsname.localeCompare(b.rsname)).map(resource => (
                    <ListItem key={`resource-${resource.rsname}`}>
                        <ListItemText>{resource.rsname}</ListItemText>
                        <List dense={true}>
                            {resource.scopes?.sort((a,b) => a.localeCompare(b)).map(scope => (
                                <ListItem key={`scope-${resource.rsname}-${scope}`}>
                                    <ListItemText>{scope}</ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    </ListItem>
                ))}
            </List>
            <IconButton size={"small"} className={classes.reload} onClick={handleSync}>
                <Sync fontSize={"small"} />
            </IconButton>
        </Paper>
    );
};

export default PermissionsInfo;
