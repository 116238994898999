import { Box } from "@material-ui/core";
import React from "react";
import Page from "../Page/Page";
import useStyles from "./styles";
import ProjectDashboard from "../../components/ProjectDashboard/ProjectDashboard";


const ProjectDashboardPage: React.FC = () => {
    const classes = useStyles();

    return (
        <Page noPadding>
            <Box className={classes.box}>
                <Box className={classes.contentBox}>
                    <Box className={classes.scrollContent}>
                        <ProjectDashboard />
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};

export default ProjectDashboardPage;
