import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { SearchMemberLeadResponse as SearchMemberLeadResponse } from "./search-member-lead-response";
import { IoLabsBimProjectsApiModelsSearchMemberLeadRequest as SearchMemberLeadRequest } from "./io-labs-bim-projects-api-models-search-member-lead-request";
import { AddMembersRequest as AddProjectMembersRequest } from "./add-members-request";
import { IoLabsBimProjectsApiModelsProjectMembersResponse as ProjectMembersResponse } from "./io-labs-bim-projects-api-models-project-members-response";
import { IoLabsBimProjectsApiModelsModuleAccess as ModuleAccess } from "./io-labs-bim-projects-api-models-module-access";
import { IoLabsBimProjectsApiModelsProjectModuleMemberUpdateRequest as ProjectModuleMemberUpdateRequest } from "./io-labs-bim-projects-api-models-project-module-member-update-request";

const projectsApi = new ApiClient("/Projects", config.api.baseUrl);

/**
 * Returns a project leads
 */
export const searchProjectLeads = (projectID: number, text: string): Promise<SearchMemberLeadResponse> => {
    return projectsApi.post(`/${projectID}/members/leads`, {
        text: text
    } as SearchMemberLeadRequest).then((response: AxiosResponse<SearchMemberLeadResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Add members to project
 */
export const addMemberToProject = (projectID: number, ids: number[]): Promise<void> => {
    return projectsApi.post(`/${projectID}/members/add`, {
        userIdentifiers: ids
    } as AddProjectMembersRequest).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Get project members
 */
export const getProjectMembers = (projectID: number, refetch: boolean = false): Promise<ProjectMembersResponse> => {
    return projectsApi.get(`/${projectID}/members`,{
        clearCacheEntry: refetch,
    }).then((response: AxiosResponse<ProjectMembersResponse>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Update member
 */
export const updateMember = (projectID: number, memberID: number, data: ProjectModuleMemberUpdateRequest): Promise<void> => {
    return projectsApi.post(`/${projectID}/members/${memberID}`, data).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};

/**
 * Set module access
 */
export const deleteProjectMember = (projectID: number, memberID: number): Promise<void> => {
    return projectsApi.delete(`/${projectID}/members/${memberID}`).then((response: AxiosResponse<void>) => {
        return Promise.resolve(response.data);
    });
};
