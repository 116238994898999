import { List, ListItem, ListItemText, ListSubheader } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getMarkups } from "../../../packages/Api/data/markups/client";
import { IObjectMarkup } from "../../../packages/Api/data/markups/types";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { useSecurityContext } from "../../../redux/keyclock/securityContext";
import { useGetPath } from "../../../utils/Menu";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IMarkupsSampleProps extends React.HTMLAttributes<HTMLElement> {
    objectID: string;
    stateHackValue?: any;
    onMarkupSelected?: (markup: IObjectMarkup) => void;
}

const MarkupsSample: React.FC<IMarkupsSampleProps> = ({ objectID, stateHackValue, onMarkupSelected }) => {
    const classes = useStyles();
    const { tokenInitialized } = useSecurityContext();


    const [data, setData] = useState<IObjectMarkup[]>([]);

    useEffect(() => {
        if (tokenInitialized) {
            getMarkups(objectID)
                .then((response: IObjectMarkup[]) => {
                    setData(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [objectID, tokenInitialized, stateHackValue]);

    // translations
    const intl = useIntl();
    const transMarkups = intl.formatMessage({ ...messages.markups });

    return (
        <div className={classes.root}>
            <SecuredComponent permission={{resource: "markups", scope:"markup:view", isProject: true}}>
                <List
                    component="nav"
                    aria-label="main mailbox folders"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          {transMarkups}
                        </ListSubheader>
                    }
                >
                    {data.map((markup) => {
                        return (
                            <ListItem
                                key={`markup-${markup.markupID}`}
                                button
                                onClick={() => {
                                    if (onMarkupSelected) {
                                        onMarkupSelected(markup);
                                    }
                                }}
                            >
                                <ListItemText primary={markup.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </SecuredComponent>
        </div>
    );
};

export default MarkupsSample;
