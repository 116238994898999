import React, { useEffect, useState } from "react";
import Forbidden from "./Forbidden";
import { useKeycloak } from "@react-keycloak/web";
import { KeycloakRealmRole } from "../../redux/keyclock/types";
import { CircularProgress } from "@material-ui/core";

const ForbiddenChecker: React.FC = ({children}) => {
    const { keycloak, initialized: keycloakInitialized } = useKeycloak();

    const [forbidden, setForbidden] = useState<boolean|undefined>(undefined);

    useEffect(() => {
        if (keycloakInitialized) {
            setForbidden(!keycloak?.hasRealmRole(KeycloakRealmRole.DEFAULT_USER));
        }
    }, [keycloakInitialized]);


    return forbidden === true
        ? <Forbidden />
        : forbidden === undefined
            ? <CircularProgress />
            : <>{children}</>
        ;
};
export default ForbiddenChecker;