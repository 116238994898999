import { defineMessages } from "react-intl";

export default defineMessages({
    select: {
        id: "components.projectSelector.select",
        defaultMessage: "Select Project", // Projekt auswählen
    },
    initialized: {
        id: "components.projectSelector.initialized",
        defaultMessage: "Initialized in Zapper, but without master file",
    },
    hasMasterFile: {
        id: "components.projectSelector.hasMasterFile",
        defaultMessage: "Initialized in Zapper and has master file assigned",
    },
    projects: {
        id: "components.projectSelector.projects",
        defaultMessage: "Projects",
    },
    hubs: {
        id: "components.projectSelector.hubs",
        defaultMessage: "Hubs",
    },
    backToProjects: {
        id: "components.projectSelector.backToProjects",
        defaultMessage: "Back to projects",
    },
    projectsCount: {
        id: "components.projectSelector.projectsCount",
        defaultMessage: "{count, plural, =0 {No project} one {# project} other {# projects}}",
    },
    switchHub: {
        id: "components.projectSelector.switchHub",
        defaultMessage: "Switch hub",
    },
    hubAdmin: {
        id: "components.projectSelector.hubAdmin",
        defaultMessage: "Hub admin",
    },
    noProject: {
        id: "components.projectSelector.noProject",
        defaultMessage: "No project matching criteria",
    },
    searchAllProjects: {
        id: "components.projectSelector.searchAllProjects",
        defaultMessage: "Search all projects",
    },
});
