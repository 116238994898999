import { AxiosResponse } from "axios";
import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import { IViewerProxyResponse } from "./types";

const forgeApi = new ApiClient("/DataManagement", config.api.baseUrl);

export const viewerProxy = (
    urn: string,
    projectId: string,
    fetchLatest = true
): Promise<IViewerProxyResponse> => {
    const url = `/Derivative/${encodeURIComponent(urn)}/viewer?projectId=${projectId}&fetchLatest=${fetchLatest}`;

    return forgeApi.get(url).then((response: AxiosResponse<IViewerProxyResponse>) => {
        return Promise.resolve(response.data);
    });
};

export const viewerToken = (
): Promise<string> => {
    const url = `/Viewer/Token`;

    return forgeApi.get(url).then((response: AxiosResponse<string>) => {
        return Promise.resolve(response.data);
    });
};
