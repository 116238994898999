import MuiQueryBuilder from "@iolabs/mui-querybuilder";
import { Box, TextField } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import Icon from "../../Icon/Icon";
import useStyles from "./styles";
import { IQuery, IQueryCombinator, IQueryOperator } from "./type";

interface IElementQueryBuilderProps {
    loading: boolean;
    query: IQuery | undefined;
    operators: IQueryOperator[] | null;
    autocompletePsets: string[];
    autocompleteAttributes: string[];
    onQueryChange: (query, valid) => void;
}

const ElementQueryBuilder: React.FC<IElementQueryBuilderProps> = ({
    loading,
    query,
    operators,
    autocompletePsets,
    autocompleteAttributes,
    onQueryChange
}) => {
    const classes = useStyles();
    const theme = useTheme();

    const [saved, setSaved] = useState<boolean>(false);
    const [initialQuery] = useState<IQuery>({
        combinator: IQueryCombinator.AND,
        rules: [],
    });

    useEffect(() => {
        if (saved) {
            setTimeout(() => {
                setSaved(false);
            }, 3000);
        }
    }, [saved]);

    const getPsetValue = (field) => {
        let pset = autocompletePsets?.find((pset) => pset === field?.pset);
        return pset ? pset : field.ps
    }

    const renderFields = (field, onChange) => {
        return (
          <>
            <Box className={classes.cursorDefault} onMouseDown={(event) => event.stopPropagation()}>
                <Autocomplete
                    freeSolo
                    defaultValue={field?.pset}
                    options={autocompletePsets}
                    // defaultValue={autocompletePsets?.find((pset) => pset?.label === field?.pset)}
                    // getOptionLabel={(option) => option?.label}
                    onChange={(event, value) => {
                        onChange({
                            pset: value,
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Pset"
                            placeholder="Placeholder"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                onChange({
                                    pset: event.target.value,
                                });
                            }}
                        />
                    )}
                    fullWidth
                    autoHighlight
                    size="small"
                    className={classes.autocomplete}
                />
            </Box>
            <Box className={clsx(classes.cursorDefault, classes.fieldAttribute)} onMouseDown={(event) => event.stopPropagation()}>
                <Autocomplete
                    freeSolo
                    defaultValue={field?.attribute}
                    options={autocompleteAttributes}
                    // defaultValue={autocompleteAttributes?.find((attribute) => attribute?.label === field?.attribute)}
                    // getOptionLabel={(option) => option?.label}
                    onChange={(event, value) => {
                        onChange({
                            pset: field?.pset,
                            attribute: value,
                        });
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Attribute"
                            placeholder="Placeholder"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(event) => {
                                onChange({
                                    pset: field?.pset,
                                    attribute: event.target.value,
                                });
                            }}
                        />
                    )}
                    disabled={!field?.pset}
                    fullWidth
                    autoHighlight
                    size="small"
                    className={classes.autocomplete}
                />
            </Box>
          </>
        );
    };

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Skeleton variant="rect" height={30} width={300} className={classes.skeleton} />
            <Box mt={3.7}>
                <Skeleton
                    variant="rect"
                    height={30}
                    width={300}
                    className={classes.skeleton}
                    style={{ marginLeft: theme.spacing(2) }}
                />
                <Skeleton
                    variant="rect"
                    height={40}
                    width="88%"
                    className={classes.skeleton}
                    style={{ marginLeft: theme.spacing(2) }}
                />
                <Skeleton
                    variant="rect"
                    height={30}
                    width={300}
                    className={classes.skeleton}
                    style={{ marginLeft: theme.spacing(2) }}
                />
                <Skeleton
                    variant="rect"
                    height={40}
                    width="88%"
                    className={classes.skeleton}
                    style={{ marginLeft: theme.spacing(2) }}
                />
            </Box>
        </Box>
    );

    const handleQueryChange = (query, valid) => {
        // onQueryChange(MuiQueryBuilder.formatQuery(query), valid);

        onQueryChange(query, valid);
    }

    return (
        <Box className={classes.root}>
            {loading ? (
                <>{skeleton}</>
            ) : (
                <>
                    {operators !== null ? (
                        <>
                            <Box className={classes.mqbBox}>
                                <MuiQueryBuilder
                                    maxLevels={5}
                                    query={query !== null ? query : initialQuery}
                                    operators={operators}
                                    renderFieldComponent={renderFields}
                                    getFilterType={(field) => {
                                        return null;
                                    }}
                                    onChange={handleQueryChange}
                                    classes={{
                                        group: classes.mqbGroup,
                                        rule: classes.mqbRule,
                                        field: classes.mqbField,
                                        operator: classes.mqbOperator,
                                        value: classes.mqbValue,
                                        conditionsBtnGroup: classes.mqbConditionsBtnGroup,
                                        removeBtn: classes.mqbRemoveBtn,
                                        clearBtn: classes.mqbClearBtn,
                                        addRuleBtn: classes.mqbAddRuleBtn,
                                        addGroupBtn: classes.mqbAddGroupBtn,
                                    }}
                                    icons={{
                                        addIcon: <Icon name="plus" size={20} />,
                                        removeIcon: <Icon name="minus" size={20} />,
                                    }}
                                    // translations={{
                                    //     groupBtn: "",
                                    //     ruleBtn: "",
                                    //     fieldLabel: "",
                                    //     fieldPlaceholder: "",
                                    //     operatorLabel: "",
                                    //     operatorPlaceholder: "",
                                    //     fieldDateLabel: "",
                                    //     fieldDatePlaceholder: "",
                                    //     fieldIntegerLabel: "",
                                    //     fieldIntegerPlaceholder: "",
                                    //     fieldMultiSelectLabel: "",
                                    //     fieldMultiSelectPlaceholder: "",
                                    //     fieldNumberLabel: "",
                                    //     fieldNumberPlaceholder: "",
                                    //     fieldSelectLabel: "",
                                    //     fieldSelectPlaceholder: "",
                                    //     fieldDefaultLabel: "",
                                    //     fieldDefaultPlaceholder: "",
                                    //     fieldRadioLabelTrue: "",
                                    //     fieldRadioLabelFalse: "",
                                    //  }}
                                />
                            </Box>
                        </>
                    ) : (
                        <>{skeleton}</>
                    )}
                </>
            )}
        </Box>
    );
};

export default ElementQueryBuilder;
