import { defineMessages } from "react-intl";

export default defineMessages({
    header: {
        id: "components.projects.projectTools.header",
        defaultMessage: "Project actions",
    },
    synchronizeVersions: {
        id: "components.projects.projectTools.synchronizeVersions",
        defaultMessage: "Synchronize versions",
    },
    buttonSynchronize: {
        id: "components.projects.projectTools.buttonSynchronize",
        defaultMessage: "Synchronize",
    },
    synchronizeResources: {
        id: "components.projects.projectTools.synchronizeResources",
        defaultMessage: "Synchronize resources",
    },
    initializeModules: {
        id: "components.projects.projectTools.initializeModules",
        defaultMessage: "Initialize modules",
    },
    buttonSynchronizeResources: {
        id: "components.projects.projectTools.buttonSynchronizeResources",
        defaultMessage: "Synchronize resources",
    },
    extractInstancesFromModel: {
        id: "components.projects.projectTools.extractInstancesFromModel",
        defaultMessage: "Extract instances from model",
    },
    buttonExtract: {
        id: "components.projects.projectTools.buttonExtract",
        defaultMessage: "Extract",
    },
    exportInstancesFromModel: {
        id: "components.projects.projectTools.exportInstancesFromModel",
        defaultMessage: "Export instances from model"
    },
    buttonExportInstances: {
        id: "components.projects.projectTools.buttonExportInstances",
        defaultMessage: "Export instances",
    },
    messageSynchronizationIsBeingProceed: {
        id: "components.projects.projectTools.messageSynchronizationIsBeingProceed",
        defaultMessage: "Synchronization is being proceed",
    },
    messageSynchronizationIsDone: {
        id: "components.projects.projectTools.messageSynchronizationIsDone",
        defaultMessage: "Synchronization is done.",
    },
    messageExtractingIsBeingProceed: {
        id: "components.projects.projectTools.messageExtractingIsBeingProceed",
        defaultMessage: "Extracting is being proceed.",
    },
    messageExtractingIsDone: {
        id: "components.projects.projectTools.messageExtractingIsDone",
        defaultMessage: "Extracting is done.",
    },
    messageNotInitialized: {
        id: "components.projects.projectTools.messageNotInitialized",
        defaultMessage: "Project is not initialized. Initialize it using details form first.",
    },
});
