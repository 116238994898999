import MuiQueryBuilder from "@iolabs/mui-querybuilder";
import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Button } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { saveMappingProjectElements } from "../../../packages/Api/data/mapping/client";
import { IMappingElements } from "../../../packages/Api/data/mapping/types";
import {
    onMappingPositions,
    setElementQuery,
    useDetailPosition,
    useMappingQuery,
    useMappingQueryAttributes,
    useMappingQueryLoading,
    useMappingQueryOperators,
    useMappingQueryPsets,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { getElements } from "../StepContentWrapper/utils";
import useStyles from "./styles";
import { IQuery, IQueryOperator } from "../ElementQueryBuilder/type";
import ElementQueryBuilder from "../ElementQueryBuilder/ElementQueryBuilder";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IStepElementQueryBuilderProps {}

const StepElementQueryBuilder: React.FC<IStepElementQueryBuilderProps> = ({}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    // query
    const loading: boolean = useMappingQueryLoading();
    const query: IQuery | undefined = useMappingQuery();
    const autocompletePsets: string[] = useMappingQueryPsets();
    const autocompleteAttributes: string[] = useMappingQueryAttributes();
    const operators: IQueryOperator[] | null = useMappingQueryOperators();

    const [saving, setSaving] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [validQuery, setValidQuery] = useState<boolean>(false);

    const handleSave = async () => {
        if (query) {
            setSaving(true);
            const newElements: IMappingElements = getElements(query);

            await saveMappingProjectElements(
                activeProject?.projectID as number,
                detailPosition?.positionID as number,
                newElements
            )
                .then(() => {
                    setSaved(true);
                    dispatch(
                        onMappingPositions({})
                    );
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    setSaving(false);
                });
        }
    };

    useEffect(() => {
        if (saved) {
            setTimeout(() => {
                setSaved(false);
            }, 3000);
        }
    }, [saved]);

    const handleChangeQuery = (query, valid) => {
        dispatch(setElementQuery({ query: query }));
        setValidQuery(
            query?.rules?.length === 0 ? false : MuiQueryBuilder.isQueryValid(query)
        );
    }

    return (
        <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
            <ElementQueryBuilder
                loading={loading}
                query={query}
                operators={operators}
                autocompletePsets={autocompletePsets}
                autocompleteAttributes={autocompleteAttributes}
                onQueryChange={handleChangeQuery}
            />
            <Box mt={2} display="flex" flexDirection="row" justifyContent="center">
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="medium"
                    disabled={!validQuery || saving || saved}
                    onClick={handleSave}
                >
                    {saved ? "Saved" : saving ? "Saving ..." : "Save"}
                </Button>
            </Box>
        </SecuredComponent>
    );
};

export default StepElementQueryBuilder;