import { Box } from "@material-ui/core";
import React from "react";
import ProjectFormWrapper from "../../components/Project/ProjectFormWrapper/ProjectFormWrapper";
import Page from "../Page/Page";
import useStyles from "./styles";
import ProjectPanel from "../../components/Project/ProjectPanel/ProjectPanel";
import { useParams } from "react-router";
import ProjectTools from "../../components/Project/ProjectTools/ProjectTools";
import ProjectMembers from "../../components/Project/ProjectMembers/ProjectMembers";


interface IProjectSettingsPageParams {
    tool?: string;
}

const ProjectSettingsPage: React.FC = () => {
    const classes = useStyles();

    const { tool } = useParams<IProjectSettingsPageParams>();

    const pageContents = () => {
        switch (tool) {
            case "tools":
                return <ProjectTools />;
            case "members":
                return <ProjectMembers />;
            default:
                return <ProjectFormWrapper editMode />;
        }
    }

    return (
        <Page noPadding>
            <Box className={classes.box}>
                <ProjectPanel />
                <Box className={classes.contentBox}>
                    <Box className={classes.scrollContent}>
                        {pageContents()}
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};

export default ProjectSettingsPage;
