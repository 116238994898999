import { DispatchAction } from "@iolabs/redux-utils";
import {
    Box,
    Dialog,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Popover,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import {
    onDeleteMappingPositions,
    onMappingPositionDetails,
    setDetailContext,
    setDetailPosition,
} from "../../../redux/mapping";
import { IMappingContext } from "../../../redux/mapping/type";
import ConfirmDialog from "../../Dialogs/ConfirmDialog/ConfirmDialog";
import messages from "./messages";
import { ListItemPopoverType } from "./type";
import PositionDialogForm from "../Dialogs/PositionDialogForm/PositionDialogForm";
import { PositionDialogType } from "../Dialogs/PositionDialogForm/type";
import Icon from "../../Icon/Icon";
import ContextMenuItem from "../../ContextMenuIcon/ContextMenuItem";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import { useGetPath } from "../../../utils/Menu";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IListItemPopover {
    type: ListItemPopoverType;
    position: IElementsPosition;
    handleChangeMainView: (mainView: boolean) => void;
}

const ListItemPopover: React.FC<IListItemPopover> = ({ type, position, handleChangeMainView }) => {
    const dispatch = useDispatch<DispatchAction>();
    const theme = useTheme();

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
    const [editPositionOpen, setEditPositionOpen] = useState<boolean>(false);
    const [movePositionOpen, setMovePositionOpen] = useState<boolean>(false);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? "master-item-popover" : undefined;

    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [showNewPositionForm, setShowNewPositionForm] = useState<boolean>(false);

    const { getProjectPermission } = useGetPath();

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClosePopover = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickItem = (event) => {
        event.stopPropagation();
        dispatch(onMappingPositionDetails({ positionID: position.positionID }));
        dispatch(
            setDetailContext({
                context: type === ListItemPopoverType.MASTER ? IMappingContext.MASTER : IMappingContext.CATALOG,
            })
        );
        handleChangeMainView(false);
        handleClosePopover(event);
    };

    const handleEditPositionOpen = (event) => {
        setEditPositionOpen(true);
        handleClosePopover(event);
    };

    const handleEditPositionClose = () => {
        setEditPositionOpen(false);
    };

    const handleMovePositionOpen = (event) => {
        setMovePositionOpen(true);
        handleClosePopover(event);
    };

    const handleMovePositionClose = () => {
        setMovePositionOpen(false);
    };

    const handleShowNewPositionForm = () => {
        setShowNewPositionForm(true);
    };

    const handleHideNewPositionForm = () => {
        setShowNewPositionForm(false);
    };

    const handleDeleteCustomPosition = (event) => {
        dispatch(onDeleteMappingPositions({ positionIDs: [position?.positionID] }));
        handleClosePopover(event);
    };

    const handleConfirmOpen = (event) => {
        event.stopPropagation();
        handleClosePopover(event);
        setConfirmOpen(true);
    };

    const handleConfirmClose = (event) => {
        event.stopPropagation();
        setConfirmOpen(false);
    };

    const isMappingEnabled = (position: IElementsPosition) => {
        return position.isInstantiable;
    }

    // translations
    const intl = useIntl();
    const transMappingDetail = intl.formatMessage({ ...messages.mappingDetail });
    const transDeleteCustomPosition = intl.formatMessage({ ...messages.deleteCustomPosition });
    const transMoveCustomPosition = intl.formatMessage({ ...messages.moveCustomPosition });
    const transMoveCustomPositionTooltip = intl.formatMessage({ ...messages.moveCustomPositionTooltip });
    const transMoveCustomPositionTooltipDisabled = intl.formatMessage({ ...messages.moveCustomPositionTooltipDisabled });
    const transDeletePosition = intl.formatMessage({ ...messages.deletePosition });
    const transEditCustomPosition = intl.formatMessage({ ...messages.editCustomPosition });
    const transEditPosition = intl.formatMessage({ ...messages.editPosition });
    const transConfirmDialogTitle = intl.formatMessage({ ...messages.confirmDialogTitle });
    const transConfirmDialogMessage = intl.formatMessage({ ...messages.confirmDialogMessage });
    const transConfirmDialogClose = intl.formatMessage({ ...messages.confirmDialogClose });
    const transConfirmDialogDelete = intl.formatMessage({ ...messages.confirmDialogDelete });
    const transOperationNotPermitted = intl.formatMessage({ ...messages.operationNotPermitted });
    const transMappingNotPossible = intl.formatMessage({ ...messages.mappingNotPossible });

    return (
        <Box onClick={(e) => {e.stopPropagation()}}>
            <IconButton aria-label="actions" onClick={handleOpenPopover}>
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Popover
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <List component="nav">
                    <NullableTooltip title={isMappingEnabled(position) ? "" : transMappingNotPossible}>
                        <Box>
                            <SecuredComponent permission={getProjectPermission("mapping", "mapping:view")}>
                                <ListItem  disabled={!isMappingEnabled(position)} button onClick={handleClickItem}>
                                    <ContextMenuItem>
                                        <Icon name="info" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={transMappingDetail} />
                                </ListItem>
                            </SecuredComponent>
                        </Box>
                    </NullableTooltip>
                    { type === ListItemPopoverType.MASTER &&
                        <>
                            <SecuredComponent permission={getProjectPermission("mapping", "position:update")}>
                                <ListItem button onClick={handleEditPositionOpen} disabled={!(type === ListItemPopoverType.MASTER)}>
                                    <ContextMenuItem>
                                        <Icon name="pencil-solid" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={position.isCustom ? transEditCustomPosition : transEditPosition} />
                                </ListItem>
                            </SecuredComponent>
                            <NullableTooltip title={position?.isCustom ? "" : transOperationNotPermitted}>
                                <Box>
                                    <SecuredComponent permission={getProjectPermission("mapping", "position:delete")}>
                                        <ListItem button onClick={handleConfirmOpen} disabled={!(type === ListItemPopoverType.MASTER && position?.isCustom)}>
                                            <ContextMenuItem>
                                                <Icon name="trash-alt" size={20} />
                                            </ContextMenuItem>
                                            <ListItemText primary={transDeleteCustomPosition} />
                                        </ListItem>
                                    </SecuredComponent>
                                </Box>
                            </NullableTooltip>
                        </>
                    }
                    <NullableTooltip title={!(type === ListItemPopoverType.MASTER && true/*position?.isCustom*/) ? transMoveCustomPositionTooltipDisabled : transMoveCustomPositionTooltip}>
                        <Box>
                            <SecuredComponent permission={getProjectPermission("mapping", "position:move")}>
                                <ListItem button onClick={handleMovePositionOpen} disabled={!(type === ListItemPopoverType.MASTER && true/*position?.isCustom*/)}>
                                    <ContextMenuItem>
                                        <Icon name="arrow-move-y" size={20} />
                                    </ContextMenuItem>
                                    <ListItemText primary={transMoveCustomPosition} />
                                </ListItem>
                            </SecuredComponent>
                        </Box>
                    </NullableTooltip>
                </List>
            </Popover>
            <Dialog
                open={editPositionOpen}
                onClose={handleEditPositionClose}
                aria-labelledby="new-position-dialog-title"
                aria-describedby="new-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                // className={classes.root}
            >
                <PositionDialogForm
                    position={position}
                    type={PositionDialogType.editPosition}
                    showNewPositionForm={true}
                    handleClose={handleEditPositionClose}
                />
            </Dialog>
            <Dialog
                open={movePositionOpen}
                onClose={handleMovePositionClose}
                aria-labelledby="move-position-dialog-title"
                aria-describedby="move-position-dialog-description"
                maxWidth="lg"
                fullWidth
                fullScreen={fullScreen}
                // className={classes.root}
            >
                <PositionDialogForm
                    type={PositionDialogType.movePosition}
                    handleClose={handleMovePositionClose}
                    position={position}
                />
            </Dialog>
            <ConfirmDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleConfirm={handleDeleteCustomPosition}
                messages={{
                    dialogTitle: transConfirmDialogTitle,
                    confirmMessage: (
                        <>
                            {transConfirmDialogMessage}{" "}
                            <strong>
                                {position?.code} - {position?.name}
                            </strong>
                            ?
                        </>
                    ),
                    closeButton: transConfirmDialogClose,
                    confirmButton: transConfirmDialogDelete,
                }}
            />
        </Box>
    );
};

export default ListItemPopover;
