import { ProjectData } from "../components/ProjectSelectorWrapper/type";
import { useActiveProject } from "../redux/project";
import { generatePath } from "react-router";
import { AppPage } from "../pages/PageRouter";
import { Permission } from "../redux/keyclock/api/types";
import { ScopePermission } from "../redux/keyclock/SecuredComponent/permissions";
import { useCallback } from "react";

/**
 * Detect active menu item
 * @param url
 * @param item
 */
const isMenuItemActive = (url: string, item: string): boolean => {
    return url.startsWith(item);
};


export const useGetPath = () => {
    const activeProject: ProjectData | undefined = useActiveProject();

    const getPath = (path: string, params: {[key: string]: string|undefined} = {}): string => {
        try {
            return activeProject ? generatePath(path, {
                accountUrn: activeProject?.externalData?.[0]?.accountUrn,
                projectUrn: activeProject?.externalData?.[0]?.projectUrn,
                ...params
            }) : generatePath(path, {
                ...params
            });
        }
        catch (e) {
            return "#";
        }
    };

    const getPagePath = (page: AppPage): string => {
        return getPath(page.path)
    };

    const getProjectCode =  useCallback(() : string => {
        return activeProject?.code ? activeProject.code : "__UNINITIALIZED__";
    },[activeProject]);

    const getPermission = useCallback((page: AppPage) : ScopePermission|null => {
        if (page.scope) {
            return {
                resource: page.projectResource ? `projects/${getProjectCode()}${page.resource ? `/${page.resource}` : ''}` : page.resource as string,
                scope: page.scope
            }
        }
        else {
            return null;
        }
    }, [activeProject]);

    const getProjectPermission = useCallback((resource: string|null, scope: string) : ScopePermission|null => {
        if (scope) {
            return {
                resource: `projects/${getProjectCode()}${resource ? `/${resource}` : ''}`,
                scope: scope
            }
        }
        else {
            return null;
        }
    }, [activeProject]);

    const getGlobalPermission = (resource: string|undefined, scope: string) : ScopePermission|null => {
        if (resource && scope) {
            return {
                resource: resource,
                scope: scope
            }
        }
        else {
            return null;
        }
    }

    return { getPath, getPagePath, getPermission, getProjectPermission, getGlobalPermission };
}

export default isMenuItemActive;
