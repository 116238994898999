import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        box: {
            display: "flex",
            flexFlow: "column nowrap",
            flexBasis: "100%",
            height: "100%",
        },
        contentBox: {
            flex: "auto",
            position: "relative",
            overflow: "scroll",
        },
        scrollContent: {
            height: "100%",
            padding: theme.spacing(1),
            [theme.breakpoints.up("md")]: {
                flexFlow: "row",
                padding: theme.spacing(2),
            },
            [theme.breakpoints.up("lg")]: {
                padding: theme.spacing(4),
            },
        }
    })
);

export default useStyles;
