import { defineMessages } from "react-intl";

export default defineMessages({
    forbidden: {
        id: "components.forbidden.forbidden",
        defaultMessage: "Forbidden",
    },
    forbiddenInfo: {
        id: "components.forbidden.forbiddenInfo",
        defaultMessage: "You are not allowed to view this content, please contact system administrator to grant the access",
    },
});
