import { defineMessages } from "react-intl";

export default defineMessages({
    details: {
        id: "components.project.projectPanel.details",
        defaultMessage: "Details",
    },
    members: {
        id: "components.project.projectPanel.members",
        defaultMessage: "Members",
    },
    tools: {
        id: "components.project.projectPanel.tools",
        defaultMessage: "Tools",
    },
});
