import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        buttonBox: {
            display: "flex",
            flexFlow: "row wrap",
            width: "100%",
            flexBasis: "100%",
            alignItems: "center",
            gap: theme.spacing(2),
            justifyContent: "space-between",
        },
        processingBox: {
            flexBasis: "100%"
        },
        header: {
          width: "30%",
        },
        boxSyncInner: {
            display: "flex",
            flexFlow: "row",
        },
        progress: {
            color: theme.palette.primary.main,
            height: theme.spacing(1)
        },
    })
);

export default useStyles;
