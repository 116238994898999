import { Box, Button, CircularProgress, Icon, InputAdornment, MenuItem, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ValidationErrors } from "final-form";
import { CheckboxData, Checkboxes, TextField } from "mui-rff";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useIntl } from "react-intl";
import { IProjectOptionsResponseData } from "../../../packages/Api/data/projects/types";
import messages from "./messages";
import useStyles from "./styles";
import { IProjectFormValues } from "./type";
import { Alert, AlertTitle } from "@material-ui/lab";
import OutlinedComponent from "../../OutlinedComponent/OutlinedComponent";
import { ISubmitError } from "../../../packages/Crudder/components/CrudDetail/CrudDetailForm";

interface IProjectFormProps {
    loading: boolean;
    options: IProjectOptionsResponseData | undefined;
    initialFormValues: IProjectFormValues | null;
    formValues: IProjectFormValues | null;
    validate: (values: IProjectFormValues) => ValidationErrors | Promise<ValidationErrors> | undefined;
    onSubmit: (values: IProjectFormValues) => Promise<void>;
    editMode?: boolean;
    initMode?: boolean;
}

const ProjectForm: React.FC<IProjectFormProps> = ({
    loading,
    options,
    initialFormValues,
    formValues,
    validate,
    onSubmit,
    editMode,
    initMode
}) => {
    const classes = useStyles();

    const [ submitError, setSubmitError ] = useState<ISubmitError>();

    // translations
    const intl = useIntl();
    const transGeneral = intl.formatMessage({ ...messages.general });
    const transUnits = intl.formatMessage({ ...messages.units });
    const transUnitsDescription = intl.formatMessage({ ...messages.unitsDescription });
    const transBim360 = intl.formatMessage({ ...messages.bim360 });
    const transRevit = intl.formatMessage({ ...messages.revit });
    const transNewBim360 = intl.formatMessage({ ...messages.newBim360 });
    const transSubmitting = intl.formatMessage({ ...messages.submitting });
    const transSave = intl.formatMessage({ ...messages.save });
    const transReset = intl.formatMessage({ ...messages.reset });
    const transFieldName = intl.formatMessage({ ...messages.fieldName });
    const transFieldDescription = intl.formatMessage({ ...messages.fieldDescription });
    const transFieldCode = intl.formatMessage({ ...messages.fieldCode });
    const transFieldLengthUnits = intl.formatMessage({ ...messages.fieldLengthUnits });
    const transFieldAreaUnits = intl.formatMessage({ ...messages.fieldAreaUnits });
    const transFieldVolumeUnits = intl.formatMessage({ ...messages.fieldVolumeUnits });
    const transFieldTemperature = intl.formatMessage({ ...messages.fieldTemperature });
    const transFieldCurrency = intl.formatMessage({ ...messages.fieldCurrency });
    const transFieldMasterFileName = intl.formatMessage({ ...messages.fieldMasterFileName });
    const transFieldUpdateType = intl.formatMessage({ ...messages.fieldUpdateType });
    const transFieldRevitEnhancers = intl.formatMessage({ ...messages.fieldRevitEnhancers });
    const transPlaceholderName = intl.formatMessage({ ...messages.placeholderName });
    const transPlaceholderDescription = intl.formatMessage({ ...messages.placeholderDescription });
    const transPlaceholderCode = intl.formatMessage({ ...messages.placeholderCode });
    const transPlaceholderLengthUnits = intl.formatMessage({ ...messages.placeholderLengthUnits });
    const transPlaceholderAreaUnits = intl.formatMessage({ ...messages.placeholderAreaUnits });
    const transPlaceholderVolumeUnits = intl.formatMessage({ ...messages.placeholderVolumeUnits });
    const transPlaceholderTemperature = intl.formatMessage({ ...messages.placeholderTemperature });
    const transPlaceholderCurrency = intl.formatMessage({ ...messages.placeholderCurrency });
    const transPlaceholderMasterFileName = intl.formatMessage({ ...messages.placeholderMasterFileName });
    const transPlaceholderAccountURN = intl.formatMessage({ ...messages.placeholderAccountURN });
    const transPlaceholderProjectURN = intl.formatMessage({ ...messages.placeholderProjectURN });
    const transPlaceholderUpdateType = intl.formatMessage({ ...messages.placeholderUpdateType });
    const transPlaceholderFieldRevitEnhancers = intl.formatMessage({ ...messages.placeholdeFieldRevitEnhancers });
    const transInitMode = intl.formatMessage({ ...messages.initMode });
    const transSubmitErrorConflict = intl.formatMessage({ ...messages.submitErrorConflict });
    const transSubmitErrorCommon = intl.formatMessage({ ...messages.submitErrorCommon });

    const handleSubmit = (values) => {
        return new Promise<void>((resolve, reject) => {
            setSubmitError(undefined);
            onSubmit(values)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => {
                    console.log("xxx submit error", e);
                    if (e.response.status === 409) {
                        setSubmitError({
                            title: transSubmitErrorConflict,
                            details: e.response?.data?.ErrorMessage
                        });
                    }
                    else {
                        setSubmitError({
                            title: transSubmitErrorCommon,
                            details: e.response?.data?.ErrorMessage
                        });
                    }
                    reject(e);

                })

        });

    }
    const handleReset = (form: any) => () => {
        setSubmitError(undefined);
        form?.reset();
    }

    return (
        <Form onSubmit={handleSubmit} initialValues={initialFormValues} validate={validate} className={classes.form}>
            {({ handleSubmit, submitting, pristine, form, values }) => (
                <form onSubmit={handleSubmit}>
                    {initMode && (
                        <Alert severity={"info"} className={classes.modeMessage}>{transInitMode}</Alert>
                    )}
                    <Typography variant="body2" className={classes.fieldset}>
                        {transGeneral}
                    </Typography>
                    <TextField
                        required
                        name="code"
                        label={transFieldCode}
                        placeholder={transPlaceholderCode}
                        autoFocus={!editMode}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        disabled={editMode}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        required
                        name="name"
                        label={transFieldName}
                        placeholder={transPlaceholderName}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        name="description"
                        label={transFieldDescription}
                        placeholder={transPlaceholderDescription}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        multiline
                        rows={4}
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <Box mt={2}>
                        <Typography variant="body2" className={classes.fieldset}>
                            {transUnits}
                        </Typography>
                        <Alert severity={"warning"}>
                            {transUnitsDescription}
                        </Alert>
                    </Box>
                    <TextField
                        name="lengthUnitID"
                        label={transFieldLengthUnits}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["lengthUnitID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderLengthUnits}
                        </MenuItem>
                        {options?.lengthUnits?.map((lengthUnit, index) => (
                            <MenuItem key={index} value={lengthUnit?.code}>
                                {lengthUnit?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="areaUnitID"
                        label={transFieldAreaUnits}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["areaUnitID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderAreaUnits}
                        </MenuItem>
                        {options?.areaUnits?.map((areaUnit, index) => (
                            <MenuItem key={index} value={areaUnit?.code}>
                                {areaUnit?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="volumeUnitID"
                        label={transFieldVolumeUnits}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["volumeUnitID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderVolumeUnits}
                        </MenuItem>
                        {options?.volumeUnits?.map((volumeUnit, index) => (
                            <MenuItem key={index} value={volumeUnit?.code}>
                                {volumeUnit?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="temperatureUnitID"
                        label={transFieldTemperature}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["temperatureUnitID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderTemperature}
                        </MenuItem>
                        {options?.temperatureUnits?.map((temperatureUnit, index) => (
                            <MenuItem key={index} value={temperatureUnit?.code}>
                                {temperatureUnit?.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        name="currencyID"
                        label={transFieldCurrency}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["currencyID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderCurrency}
                        </MenuItem>
                        {options?.currencies?.map((currency, index) => (
                            <MenuItem key={index} value={currency?.alpha3Code}>
                                {currency?.alpha3Code} - {currency?.name}
                            </MenuItem>
                        ))}
                    </TextField>

                    <Box mt={2}>
                        <Typography variant="body2" className={classes.fieldset}>
                            {transRevit}
                        </Typography>
                    </Box>

                    <OutlinedComponent label={transFieldRevitEnhancers} className={clsx(classes.textField, classes.checkboxes)}>
                        <Checkboxes
                            name="revitEnhancersIDs"
                            color="primary"
                            className={clsx()}
                            size={"small"}
                            data={options ? options?.revitEnhancers?.map((revitEnhancer, index) => {
                                return {
                                    label: revitEnhancer.displayName,
                                    value: revitEnhancer.revitEnhancerID,
                                } as CheckboxData
                            }) as CheckboxData[] : []}
                        />
                    </OutlinedComponent>



                    <Box mt={2}>
                        <Typography variant="body2" className={classes.fieldset}>
                            {transBim360}
                        </Typography>
                    </Box>

                    {/*<Box className={classes.newProjectBox}>
                        <TextField
                            required
                            name="masterFileName"
                            label={transFieldMasterFileName}
                            placeholder={transPlaceholderMasterFileName}
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            color="primary"
                            className={classes.textField}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => setGlobalDialogOpen(true)}
                            classes={{
                                root: classes.newProject,
                            }}
                        >
                            {transNewBim360}
                        </Button>
                    </Box>*/}

                    <TextField
                        required
                        name="accountUrn"
                        label="Account URN"
                        disabled={editMode}
                        placeholder={transPlaceholderAccountURN}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        required
                        name="projectUrn"
                        disabled={editMode}
                        label="Project URN"
                        placeholder={transPlaceholderProjectURN}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        // required
                        name="fileUrn"
                        label="File URN"
                        disabled={editMode && !!initialFormValues?.fileUrn}
                        placeholder={transPlaceholderMasterFileName}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        name="updateTypeID"
                        label={transFieldUpdateType}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        color="primary"
                        className={clsx(classes.textField, {
                            [classes.placeholder]: !values?.["updateTypeID"],
                        })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        InputProps={{
                            endAdornment: loading && (
                                <InputAdornment position="end">
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                </InputAdornment>
                            ),
                        }}
                        select
                        SelectProps={{
                            displayEmpty: true,
                        }}
                        disabled={loading}
                    >
                        <MenuItem value="" disabled>
                            {transPlaceholderUpdateType}
                        </MenuItem>
                        {options?.updateTypes?.map((updateType, index) => (
                            <MenuItem key={index} value={updateType}>
                                {updateType}
                            </MenuItem>
                        ))}
                    </TextField>
                    {submitError && (
                        <Alert className={classes.submitError} severity={"error"}>
                            <AlertTitle>{submitError.title}</AlertTitle>
                            {submitError.details}
                        </Alert>
                    )}
                    <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-start">
                        <Button
                            type="submit"
                            variant="contained"
                            color="secondary"
                            size="large"
                            disabled={submitting || (pristine && editMode) || loading}
                            endIcon={submitting ? <CircularProgress size={22} color={"inherit"} /> : <Icon>send</Icon>}
                        >
                            {submitting ? transSubmitting : transSave}
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="large"
                            disabled={submitting || pristine || loading}
                            onClick={handleReset(form)}
                            style={{
                                marginLeft: "1.5rem",
                            }}
                        >
                            {transReset}
                        </Button>
                    </Box>
                </form>
            )}
        </Form>
    );
};

export default ProjectForm;
