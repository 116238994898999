import { Box, Button, CircularProgress, LinearProgress, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Alert } from "@material-ui/lab";


interface IActionButtonProps {
    header: string;
    buttonText?: string;
    action: () => Promise<any>;
}

const ActionButton: React.FC<IActionButtonProps> = ({header, buttonText, action}) => {
    const classes = useStyles();

    const [processing, setProcessing] = useState<boolean>(false);
    const [processingResult, setProcessingResult] = useState<any>();
    const [processingError, setProcessingError] = useState<any>();

    // translations
    const intl = useIntl();
    const transButtonSynchronize = intl.formatMessage({ ...messages.buttonSynchronize });
    const transProcessingDone = intl.formatMessage({ ...messages.processingDone });
    const transProcessingError = intl.formatMessage({ ...messages.processingError });

    const printResponse = (response: any) => (
        <pre>{JSON.stringify(response, null, 2)}</pre>
    )

    const showProcessing = () => processing || processingError || processingResult;

    const handleAction = () => {
        setProcessing(true);
        setProcessingResult(null);
        setProcessingError(null);

        action()
            .then((result) => {
                setProcessingResult(result);
            })
            .catch((error) => {
                setProcessingError(error);
            })
            .finally(() => {
                setProcessing(false);
            })
    }

    return (
        <Paper className={classes.paper}>
            <Box className={classes.buttonBox}>
                <Typography variant="h5" className={classes.header}>
                    {header}
                </Typography>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    size="large"
                    disabled={processing}
                    onClick={handleAction}
                >
                    {buttonText ? buttonText : transButtonSynchronize}
                </Button>
                {
                    showProcessing() && (
                        <Box className={classes.processingBox}>
                            {
                                processing ? (
                                    <LinearProgress variant={"indeterminate"} color={"primary"} className={classes.progress} />
                                ) : processingResult ? (
                                    <Alert severity={"success"}>
                                        {transProcessingDone}
                                        {printResponse(processingResult)}
                                    </Alert>
                                ) : processingError ? (
                                    <Alert severity={"error"}>
                                        {transProcessingError}
                                        {printResponse(processingError)}
                                    </Alert>
                                ) : (
                                    <></>
                                )
                            }
                        </Box>
                    )
                }
            </Box>
        </Paper>

    );
};

export default ActionButton;
