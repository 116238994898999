import { createStyles, darken, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchBox: {
            // alignItems: "center",
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "space-between",
            marginBottom: theme.spacing(1),
        },
        searchField: {
            width: "100%",
            "& .MuiTextField-root": {
                width: "100%",
            },
            "& .MuiFormLabel-root": {
                color: theme.palette.primary.main,
            },
            "& .MuiSelect-outlined.MuiSelect-outlined": {
                padding: "13px 14px",
                fontSize: ".8rem",
            },
            "& .MuiOutlinedInput-multiline": {
                padding: "10px 14px",
            },
        },
        addBtn: {
            marginTop: theme.spacing(0.9),
            padding: theme.spacing(1.3),
        }
    })
);

export default useStyles;
