import { defineMessages } from "react-intl";

export default defineMessages({
    labelMaterial: {
        id: "components.specification.dialogPrice.labelMaterial",
        defaultMessage: "Material",
    },
    labelWork: {
        id: "components.specification.dialogPrice.labelWork",
        defaultMessage: "Work",
    },
    labelEquipment: {
        id: "components.specification.dialogPrice.labelEquipment",
        defaultMessage: "Equipment",
    },
    labelName: {
        id: "components.specification.dialogPrice.labelName",
        defaultMessage: "Name",
    },
    labelItemNumber: {
        id: "components.specification.dialogPrice.labelItemNumber",
        defaultMessage: "Item number", // Artikel Nr. o. G TIN
    },
    labelProductId: {
        id: "components.specification.dialogPrice.labelProductId",
        defaultMessage: "Product ID", // Produkt-ID
    },
    labelMenge: {
        id: "components.specification.dialogPrice.",
        defaultMessage: "kMenge", // K.-Menge // todo translate de
    },
    labelListPrice: {
        id: "components.specification.dialogPrice.labelListPrice",
        defaultMessage: "Li Price", // Li.-Preis
    },
    labelEp: {
        id: "components.specification.dialogPrice.labelEp",
        defaultMessage: "EP", // EP
    },
    labelSurcharge: {
        id: "components.specification.dialogPrice.labelSurcharge",
        defaultMessage: "Surcharge", // Zuschlag
    },
    labelEpPerMenge: {
        id: "components.specification.dialogPrice.labelEpPerMenge",
        defaultMessage: "EP Per Menge", // EP/Menge  // todo translate de
    },
    labelPhysical: {
        id: "components.specification.dialogPrice.labelPhysical",
        defaultMessage: "Physical material",
    },
    noMaterial: {
        id: "components.specification.dialogPrice.noMaterial",
        defaultMessage: "No material assigned",
    },
    noWork: {
        id: "components.specification.dialogPrice.noWork",
        defaultMessage: "No work assigned",
    },
    noEquipment: {
        id: "components.specification.dialogPrice.noEquipment",
        defaultMessage: "No equipment assigned",
    },
    subcontractor: {
        id: "components.specification.dialogPrice.subcontractor",
        defaultMessage: "Subcontractor price",
    },
    manual: {
        id: "components.specification.dialogPrice.manual",
        defaultMessage: "Manual position",
    },
});
