import React, { useEffect, useState } from "react";
import { Button, Chip, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import { IMembersLookup } from "./hooks";
import { MemberLead } from "../../packages/Api/data/projectMembers/member-lead";
import { SearchMemberLeadResponse } from "../../packages/Api/data/projectMembers/search-member-lead-response";

interface IProjectMembersSearchProps {
    ownerID: number;
    addMember: (member: MemberLead[]) => Promise<void>
    membersLookup: () => IMembersLookup; // todo
    addText?: string;
    alreadyMemberText?: string;
}
interface MemberSearchOption {
    type: "person"|"company",
    id: number,
    name: string,
    email?: string,
}

const MembersSearch: React.FC<IProjectMembersSearchProps> = ({ ownerID, addMember, membersLookup, addText, alreadyMemberText}) => {
    const classes = useStyles();

    const [value, setValue] = useState<MemberLead[]>([]);

    const [options, setOptions] = useState<MemberLead[]>([]);

    const { searchText, setSearchText, setOwnerID, memberLeadsPromise } = membersLookup();

    const intl = useIntl();
    const transSearch = intl.formatMessage({ ...messages.search });
    const transAlreadyMember = intl.formatMessage({ ...messages.alreadyMember });
    const transAddMember = intl.formatMessage({ ...messages.addMember });

    useEffect(() => {
        setOwnerID(ownerID);
    }, [ownerID]);

    useEffect(() => {
        if (memberLeadsPromise.status == "success") {
            const response: SearchMemberLeadResponse = memberLeadsPromise.result;
            const opts = response.leads ? response.leads : [];
            setOptions(opts/*.map(o => `${o.firstName} ${o.lastName}`)*/);
        }
    }, [memberLeadsPromise.status])

    const handleAdd = () => {
        if (ownerID && value) {
            addMember(value).then(res => {
                    setValue([]);
                })
        }

    }

    return <>


        <Grid container className={classes.searchBox} spacing={2}>
            <Grid item className={clsx(classes.searchField)}>
                <Autocomplete
                    id="add-members"
                    multiple
                    // getOptionLabel={(option) => {
                    //     return typeof option === 'string' ? option : `${option.firstName} ${option.lastName}`
                    // }}
                    options={options}
                    autoComplete
                    includeInputInList
                    value={value}
                    inputValue={searchText}
                    filterOptions={(x) => x.filter(l => !value.map(l => l.userID).includes(l.userID))}
                    filterSelectedOptions
                    onChange={(event: any, newValue: MemberLead[] | null) => {
                        // setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue ? newValue : []);
                    }}
                    renderTags={(value: readonly MemberLead[], getTagProps) => {
                        return value.map((option: MemberLead, index: number) => (
                            <Chip variant="outlined" label={`${option.firstName} ${option.lastName}`} {...getTagProps({ index })} />
                        ))
                    }}
                    onInputChange={(event, newInputValue) => {
                        if (event) { // why this needed?
                            setSearchText(newInputValue);
                        }
                    }}
                    // loading={memberLeadsPromise.loading}
                    renderInput={(params) => (
                        <TextField {...params}
                                   label={transSearch}
                                   fullWidth
                            // className={clsx(classes.textField)}
                                   variant="outlined"
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} className={classes.leadsGrid}>
                                <Grid container className={classes.leadsGrid}>
                                    <Grid item xs={3}>
                                        {props.firstName}
                                    </Grid>
                                    <Grid item xs={3}>
                                        {props.lastName}
                                    </Grid>
                                    <Grid item xs={4}>
                                        {props.email}
                                    </Grid>
                                    <Grid item xs={2}>
                                        {props.isMember && <Chip label={alreadyMemberText ?? transAlreadyMember} color="primary" />}
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            </Grid>
            <Grid item>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    className={classes.addBtn}
                    onClick={handleAdd}
                >
                    {addText ?? transAddMember}
                </Button>
            </Grid>
        </Grid>
    </>;
}
export default MembersSearch
