import React, { useState } from "react";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { Box, Typography } from "@mui/material";
import { Form } from "react-final-form";
import { TextField } from "mui-rff";
import { Button, CircularProgress, Icon } from "@material-ui/core";
import { ICrudDetailFormProps } from "./CrudDetail";
import { Alert, AlertTitle } from "@material-ui/lab";

export interface ISubmitError {
    title: string,
    details: string,
}

const CrudDetailForm = <DT,>({ config, itemFields, data, loading, onSubmit, backLink} : ICrudDetailFormProps<DT> ) => {
    const [ submitRunning, setSubmitRunning ] = useState(false);

    const [ submitError, setSubmitError ] = useState<ISubmitError>();

    const classes = useStyles();


    function handleSubmit(values: FormData) {
        return new Promise<number>((resolve, reject) => {
            setSubmitRunning(true);
            onSubmit(values)
                .then((id) => {
                    resolve(id);
                })
                .catch((e) => {
                    setSubmitError({
                        title: "Error submitting",
                        details: e.response?.data?.ErrorMessage
                    });
                    reject(e);
                })
                .finally(() => {
                    setSubmitRunning(false);
                })
            ;
        });
    }

    const handleReset = (form?: any) => {
        form?.reset();
        setSubmitRunning(false);
        setSubmitError(undefined);
    }

    // yes, this can even be async!
    async function validate(values: FormData) {
        // if (!values.hello) {
        //     return { hello: 'Saying hello is nice.' };
        // }
        return;
    }

    const getInitialFormValue = (): any => {
        return data;
    }

    return (
        <>
            <Typography>{config.title}</Typography>
            <Form
                onSubmit={handleSubmit}
                validate={validate}
                initialValues={getInitialFormValue()}
                className={classes.form}
                render={({ handleSubmit, values, submitting, pristine, form}) => (
                    <form onSubmit={handleSubmit} noValidate className={submitRunning ? classes.submitting : ""}>
                        {itemFields
                            .filter((field) => !field.formFieldDef?.ignore)
                            .map((field) => (
                                field.formFieldDef?.render ? field.formFieldDef?.render(field, classes, submitting || loading) : (
                                    <TextField
                                        key={`field-${field.name}`}
                                        required
                                        name={field.name}
                                        label={field.title}
                                        placeholder={field.title}
                                        type={field.formFieldDef?.type ? field.formFieldDef.type : "text"}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={classes.textField}
                                        InputProps={{
                                            readOnly: submitting || loading
                                        }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                )

                            ))
                        }
                        {submitError && (
                            <Alert className={classes.submitError} severity={"error"}>
                                <AlertTitle>{submitError.title}</AlertTitle>
                                {submitError.details}
                            </Alert>
                        )}
                        <Box mt={2} display="flex" flexDirection="row" justifyContent="flex-start">
                            <Button
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={submitting || pristine  || loading}
                                endIcon={submitting ? <CircularProgress size={22} color={"inherit"} /> : <Icon>send</Icon>}
                            >
                                Save
                            </Button>
                            <Button
                                type="reset"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={submitting || pristine || loading}
                                onClick={() => handleReset(form)}
                                style={{
                                    marginLeft: "1.5rem",
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                type="button"
                                variant="text"
                                color="primary"
                                size="large"
                                disabled={submitting}
                                component={Link}
                                to={backLink}
                                style={{
                                    marginLeft: "1.5rem",
                                }}
                            >
                                Back to list
                            </Button>
                        </Box>
                    </form>
                )}
            >
            </Form>
        </>
    );
};
export default CrudDetailForm;