import { defineMessages } from "react-intl";

export default defineMessages({
    specifications: {
        id: "components.specification.dataTablePanel.specifications",
        defaultMessage: "Service Specifications", // Leistungsverzeichnis
    },
    addNewPosition: {
        id: "components.specification.dataTablePanel.addNewPosition",
        defaultMessage: "Add new position", // Neue Position hinzufügen
    },
    ebkph: {
        id: "components.specification.dataTablePanel.ebkph",
        defaultMessage: "eBKP-H", // eBKP-H
    },
    trades: {
        id: "components.specification.dataTablePanel.trades",
        defaultMessage: "Trades No.", // Gewerke Nr.
    },
    din: {
        id: "components.specification.dataTablePanel.din",
        defaultMessage: "DIN 276", // DIN 276
    },
    exportStarted: {
        id: "components.specification.dataTablePanel.exportStarted",
        defaultMessage: "Export started",
    },
});
