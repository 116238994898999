import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import messages from "./messages";
import useStyles from "./styles";
import { Error } from "@material-ui/icons";


const Forbidden: React.FC = () => {
    const classes = useStyles();

    // translations
    const intl = useIntl();
    const transForbidden = intl.formatMessage({ ...messages.forbidden });
    const transForbiddenInfo = intl.formatMessage({ ...messages.forbiddenInfo });

    return (
        <Paper className={classes.root}>
            <Error className={classes.icon} />
            <Typography className={classes.header} variant={"h3"}>{transForbidden}</Typography>
            <Typography variant={"body1"}>{transForbiddenInfo}</Typography>
        </Paper>
    );
};
export default Forbidden;