import React, { useEffect } from "react";
import { DispatchAction } from "@iolabs/redux-utils";
import { ProjectFileVersionViewable } from "../../../graphql/generated/graphql";
import { onInstancesAttributes, useMappingQuery, useSelectedViewable } from "../../../redux/mapping";
import { IQuery } from "../ElementQueryBuilder/type";
import { useDeepCompareEffectNoCheck } from "use-deep-compare-effect";
import { getElements } from "../StepContentWrapper/utils";
import { useDispatch } from "react-redux";

// todo try to use as hook instead
const MappingDetailViewObserver: React.FC = ({ children }) => {

    const selectedViewable: ProjectFileVersionViewable | undefined = useSelectedViewable();
    const elementQuery: IQuery | undefined = useMappingQuery();


    const dispatch = useDispatch<DispatchAction>();

    const query = elementQuery ? getElements(elementQuery): undefined;

    const processEffect = () => {
        if (query && selectedViewable?.projectFileVersionViewableID) {
            const options = {
                rule: query,
                viewableID: selectedViewable?.projectFileVersionViewableID
            };
            dispatch(onInstancesAttributes(options));
        }
    }

    useDeepCompareEffectNoCheck(() => {
        processEffect()
    }, [query]);

    useEffect(() => {
        processEffect()
    }, [selectedViewable?.projectFileVersionViewableID]);

    return (
        <>{children}</>
    );
};

export default MappingDetailViewObserver;