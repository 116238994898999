import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import {
    getAttachedEquipment,
    getAttachedMaterial,
    getAttachedWork,
} from "../../../packages/Api/data/elements/client";
import { CategoryReport } from "../../../graphql/generated/graphql";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork,
    MaterialType,
} from "../../../packages/Api/data/elements/types";
import { formatNumber } from "../../../utils/Formatter";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Alert } from "@material-ui/lab";

interface IDialogPriceModelDataProps {
    category: CategoryReport;
}


const DialogPriceModelData: React.FC<IDialogPriceModelDataProps> = ({ category }) => {
    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();
    const [ material, setMaterial] = useState<IAttachedMaterial[]>([]);
    const [ work, setWork] = useState<IAttachedWork[]>([]);
    const [ equipment, setEquipment] = useState<IAttachedEquipment[]>([]);


    const intl = useIntl();

    const transLabelMaterial = intl.formatMessage({ ...messages.labelMaterial });
    const transLabelWork = intl.formatMessage({ ...messages.labelWork });
    const transLabelEquipment = intl.formatMessage({ ...messages.labelEquipment });
    const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
    const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
    const transLabelName = intl.formatMessage({ ...messages.labelName });
    const transLabelMenge = intl.formatMessage({ ...messages.labelMenge });
    const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
    const transLabelEp = intl.formatMessage({ ...messages.labelEp });
    const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });
    const transLabelEpPerMenge = intl.formatMessage({ ...messages.labelEpPerMenge });
    const transLabelPhysical = intl.formatMessage({ ...messages.labelPhysical });
    const transNoMaterial = intl.formatMessage({ ...messages.noMaterial });
    const transNoWork = intl.formatMessage({ ...messages.noWork });
    const transNoEquipment = intl.formatMessage({ ...messages.noEquipment });

    useEffect(() => {
        if (category && activeProject) {
            getAttachedMaterial(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setMaterial(list)
                });
            getAttachedWork(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setWork(list)
                });
            getAttachedEquipment(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setEquipment(list)
                });
        }
    }, [category, activeProject]);

    const round = (num) => {
        return Math.round(num * 100) / 100;
    };

    return (
        <>
            <Box className={classes.subTable}>
                <Typography variant={"subtitle1"}>{transLabelMaterial}</Typography>
                {material?.length ? (
                    <TableContainer className={classes.table}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{transLabelItemNumber}</TableCell>
                                    <TableCell align="left">{transLabelProductId}</TableCell>
                                    <TableCell align="left">{transLabelMenge}</TableCell>
                                    <TableCell align="left">{transLabelListPrice}</TableCell>
                                    <TableCell align="left">{transLabelEp}</TableCell>
                                    <TableCell align="left">{transLabelSurcharge}</TableCell>
                                    <TableCell align="left">{transLabelEpPerMenge}</TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {material?.map(m => (
                                    <TableRow key={m.attachedMaterialID}>
                                        <TableCell component="th" scope="row" className={classes.overflow}>
                                            {
                                                m.materialType == MaterialType.PHYSICAL && (
                                                    <Box className={classes.linePrefix}>
                                                        <NullableTooltip title={transLabelPhysical}>
                                                            <span>*</span>
                                                        </NullableTooltip>
                                                    </Box>
                                                )
                                            }
                                            {m.material.itemNumber}
                                        </TableCell>
                                        <TableCell component="th" scope="row">{m.material.productID}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.kMenge as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.material.liPrice)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.material.ep as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.material.surcharge)}%</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.epPerMenge as number)}</TableCell>
                                        <TableCell align="left">
                                            {/* <IconButton */}
                                            {/*     aria-label="collapse" */}
                                            {/*     size="small" */}
                                            {/*     onClick={handleOpenAnother} */}
                                            {/*     className={classes.iconButton} */}
                                            {/* > */}
                                            {/*     <EditIcon fontSize="small" /> */}
                                            {/* </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Alert severity="info">{transNoMaterial}</Alert>
                )}
            </Box>

            <Box className={classes.subTable}>
                <Typography variant={"subtitle1"}>{transLabelWork}</Typography>
                {work?.length ? (
                    <TableContainer className={classes.table}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{transLabelItemNumber}</TableCell>
                                    <TableCell align="left">{transLabelProductId}</TableCell>
                                    {/*<TableCell align="left">{transLabelName}</TableCell>*/}
                                    <TableCell align="left">{transLabelMenge}</TableCell>
                                    <TableCell align="left">{transLabelListPrice}</TableCell>
                                    <TableCell align="left">{transLabelEp}</TableCell>
                                    <TableCell align="left">{transLabelSurcharge}</TableCell>
                                    <TableCell align="left">{transLabelEpPerMenge}</TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {work?.map(m => (
                                    <TableRow key={m.attachedWorkID}>
                                        <TableCell component="th" scope="row">{m.work.itemNumber}</TableCell>
                                        <TableCell component="th" scope="row">{m.work.productID}</TableCell>
                                        {/*<TableCell component="th" scope="row">{m.work.productName}</TableCell>*/}
                                        <TableCell component="th" scope="row">{formatNumber(m.kMenge as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.work.liPrice)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.work.ep as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.work.surcharge)}%</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.epPerMenge as number)}</TableCell>
                                        <TableCell align="left">
                                            {/* <IconButton */}
                                            {/*     aria-label="collapse" */}
                                            {/*     size="small" */}
                                            {/*     onClick={handleOpenAnother} */}
                                            {/*     className={classes.iconButton} */}
                                            {/* > */}
                                            {/*     <EditIcon fontSize="small" /> */}
                                            {/* </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Alert severity="info">{transNoWork}</Alert>
                )}
            </Box>

            <Box className={classes.subTable}>
                <Typography variant={"subtitle1"}>{transLabelEquipment}</Typography>
                {equipment?.length ? (
                    <TableContainer className={classes.table}>
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{transLabelItemNumber}</TableCell>
                                    <TableCell align="left">{transLabelProductId}</TableCell>
                                    {/*<TableCell align="left">{transLabelName}</TableCell>*/}
                                    <TableCell align="left">{transLabelMenge}</TableCell>
                                    <TableCell align="left">{transLabelListPrice}</TableCell>
                                    <TableCell align="left">{transLabelEp}</TableCell>
                                    <TableCell align="left">{transLabelSurcharge}</TableCell>
                                    <TableCell align="left">{transLabelEpPerMenge}</TableCell>
                                    <TableCell align="left" />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {equipment?.map(m => (
                                    <TableRow key={m.attachedEquipmentID}>
                                        <TableCell component="th" scope="row">{m.equipment.itemNumber}</TableCell>
                                        <TableCell component="th" scope="row">{m.equipment.productID}</TableCell>
                                        {/*<TableCell component="th" scope="row">{m.equipment.productName}</TableCell>*/}
                                        <TableCell component="th" scope="row">{formatNumber(m.kMenge as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.equipment.liPrice)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.equipment.ep as number)}</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.equipment.surcharge)}%</TableCell>
                                        <TableCell component="th" scope="row">{formatNumber(m.epPerMenge as number)}</TableCell>
                                        <TableCell align="left">
                                            {/* <IconButton */}
                                            {/*     aria-label="collapse" */}
                                            {/*     size="small" */}
                                            {/*     onClick={handleOpenAnother} */}
                                            {/*     className={classes.iconButton} */}
                                            {/* > */}
                                            {/*     <EditIcon fontSize="small" /> */}
                                            {/* </IconButton> */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                ) : (
                    <Alert severity="info">{transNoEquipment}</Alert>
                )}
            </Box>
        </>
    );
};

export default DialogPriceModelData;
