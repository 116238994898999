import { createStyles, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            position: "relative"
        },
        filesList: {
            marginTop: theme.spacing(1),
        },
        modules: {
            marginTop: theme.spacing(1),
        },
        viewer: {
            width: "100%",
            height: "400px",
        },
        bim360: {
            marginLeft: theme.spacing(1),
            "& img": {
                height: "16px"
            }
        },
        settings: {
            position: "absolute",
            top: theme.spacing(0),
            right: theme.spacing(0),
            "& .MuiSvgIcon-root": {
                color: theme.palette.text.primary,
            }
        }
    })
);

export default useStyles;
