import { defineMessages } from "react-intl";

export default defineMessages({
    labelItemNumber: {
        id: "components.mapping.dialogs.equipmentDialog.labelItemNumber",
        defaultMessage: "Item Number",
    },
    labelProductId: {
        id: "components.mapping.dialogs.equipmentDialog.labelProductId",
        defaultMessage: "Product-ID",
    },
    labelProductName: {
        id: "components.mapping.dialogs.equipmentDialog.labelProductName",
        defaultMessage: "Product name",
    },
    labelListPrice: {
        id: "components.mapping.dialogs.equipmentDialog.labelListPrice",
        defaultMessage: "List price",
    },
    labelSurcharge: {
        id: "components.mapping.dialogs.equipmentDialog.labelSurcharge",
        defaultMessage: "Surcharge",
    },
    tooltipAddNewEquipment: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipAddNewEquipment",
        defaultMessage: "Add new equipment",
    },
    tooltipSaveChanges: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipSaveChanges",
        defaultMessage: "Save changes",
    },
    tooltipToReturn: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipToReturn",
        defaultMessage: "To return",
    },
    tooltipDiscard: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipDiscard",
        defaultMessage: "Discard",
    },
    tooltipDelete: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipDelete",
        defaultMessage: "Delete disabled, only in ERP",
    },
    tooltipClose: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipClose",
        defaultMessage: "Close",
    },
    tooltipUpdate: {
        id: "components.mapping.dialogs.equipmentDialog.tooltipUpdate",
        defaultMessage: "Update disabled, only in ERP",
    },
    confirmDeleteTitle: {
        id: "components.mapping.dialogs.equipmentDialog.confirmDeleteTitle",
        defaultMessage: "Delete confirm",
    },
    confirmDeleteMessage: {
        id: "components.mapping.dialogs.equipmentDialog.confirmDeleteMessage",
        defaultMessage: "Are you sure you want to delete the equipment",
    },
    confirmClose: {
        id: "components.mapping.dialogs.equipmentDialog.confirmClose",
        defaultMessage: "Close",
    },
    confirmDelete: {
        id: "components.mapping.dialogs.equipmentDialog.confirmDelete",
        defaultMessage: "Delete",
    },
});
