import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import useStyles from "./styles";
import KnownAttribute from "./KnownAttribute";
import { IDerivedMappingAttribute } from "../../../packages/Api/data/mapping/types";
import { TextField } from "mui-rff";
import { IStepperAttributeFormValues } from "./type";
import { useMappingAttributeFormValues, useMappingDerivedPositions } from "../../../redux/mapping";
import { IDerivedPosition, IElementsPosition } from "../../../packages/Api/data/elements/types";
import { normalizeUnit } from "../../../utils/Formatter";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IStepAttributeDerivedProps {
}

const StepAttributeDerived: React.FC<IStepAttributeDerivedProps> = ({  }) => {
    const classes = useStyles();

    const mappingDerivedPositions: IDerivedPosition[] = useMappingDerivedPositions();
    const initialFormValues: IStepperAttributeFormValues | undefined = useMappingAttributeFormValues();


    const getDerivedPositionDescription = (derivedMappingAttribute: IDerivedMappingAttribute): string => {
        const position: IElementsPosition = mappingDerivedPositions?.find(dp => dp?.derivedPositionID == derivedMappingAttribute.derivedPositionID)?.position as IElementsPosition;
        return `${position.code} - ${position.name} [${normalizeUnit(position.referenceUnit)}]`;
    }
    console.log("Rendering StepAttributeDerived");

    return initialFormValues && initialFormValues?.derivedPositionsReferenceUnits?.length > 0 ?
        (
            <Box mt={4}>
                <Paper className={classes.paper}>
                    <Typography variant="body2">{"Abgeleitete positionen"}</Typography>
                    <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                        <Box mt={1}>
                            {initialFormValues?.derivedPositionsReferenceUnits?.map((dp, index) => (
                                <Box className={classes.derivedPosition} key={`derivedPosition-${dp.derivedPositionID}`}>
                                    <Typography variant="caption" className={classes.derivedPositionLabel}>
                                    {getDerivedPositionDescription(dp as IDerivedMappingAttribute)}
                                    </Typography>
                                    <TextField
                                        name={`derivedPositionsReferenceUnits[${index}].derivedPositionID`}
                                        hidden
                                    />
                                    <KnownAttribute name={`derivedPositionsReferenceUnits[${index}]`} /*values={values}/* attributeValues={values?.derivedPositionsReferenceUnits?.[index]}*/ />
                                </Box>
                            ))}
                        </Box>
                    </SecuredComponent>
                </Paper>
            </Box>
        ) : (
            null
        )
    ;
};

export default StepAttributeDerived;