import React from "react";
import { Box, Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import messages from "./messages";
import messagesGraphics from "../Dialogs/GraphicsDialog/messages";
import useStyles from "./styles";
import GraphicsDialogWrapper from "../Dialogs/GraphicsDialogWrapper/GraphicsDialogWrapper";
import ColorPickerPopover from "../ColorPickerPopover/ColorPickerPopover";
import clsx from "clsx";
import { useAttachedGraphics, useGraphicsLoading } from "../../../redux/mapping";
import { IGraphics } from "../../../packages/Api/data/graphics/types";
import OutlinedComponent from "../../OutlinedComponent/OutlinedComponent";
import { useTheme } from "@material-ui/core/styles";
import { Alert, Skeleton } from "@material-ui/lab";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

interface IStepElementGraphicsProps {}

const StepElementGraphics: React.FC<IStepElementGraphicsProps> = () => {

    const classes = useStyles();
    const theme = useTheme();

    const graphicsLoading: boolean = useGraphicsLoading();
    const graphics: IGraphics | undefined = useAttachedGraphics();

    // translations
    const intl = useIntl();
    const transTitle = intl.formatMessage({ ...messages.title });
    const transName = intl.formatMessage({ ...messagesGraphics.name });
    const transShading = intl.formatMessage({ ...messagesGraphics.shading });
    const transTransparency = intl.formatMessage({ ...messagesGraphics.transparency });
    const transBackground = intl.formatMessage({ ...messages.background });
    const transForeground = intl.formatMessage({ ...messages.foreground });
    const transCut = intl.formatMessage({ ...messages.cut });
    const transSurface = intl.formatMessage({ ...messages.surface });
    const transPattern = intl.formatMessage({ ...messages.pattern });
    const transColor = intl.formatMessage({ ...messages.color });
    const transNoGraphics = intl.formatMessage({ ...messages.noGraphics });

    const colorField = (label: string, color: string) => {
        return <OutlinedComponent label={label} className={clsx(classes.fieldCode, classes.colorBox)}>
            <ColorPickerPopover
                color={color}
                disabled={true}
                classes={{
                    root: classes.colorButton
                }}
            />
        </OutlinedComponent>
    }

    const textField = (label: string, text?: string|number, className = classes.fieldCode) => {
        return <OutlinedComponent label={label} className={clsx(className, classes.textControl)}>
            {text}
        </OutlinedComponent>
    }
    const skeletonField = (className = classes.fieldCode) => {
        return <Skeleton className={clsx(className, classes.skeleton)}></Skeleton>
    }

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Box className={classes.fieldBoxSkeleton}>
                {skeletonField(classes.fieldName)}
                {skeletonField(classes.fieldCode)}
                {skeletonField(classes.fieldCode)}
                <Box className={classes.fieldCode}>
                </Box>
            </Box>

            <Box className={classes.fieldBoxSkeleton} mt={1}>
                <Box className={clsx(classes.fieldTitle, classes.skeleton)}>
                </Box>
                {skeletonField(classes.fieldForeBack)}
                {skeletonField(classes.fieldForeBack)}
            </Box>
            <Box className={classes.fieldBoxSkeleton}>
                {skeletonField(classes.fieldTitle)}
                {skeletonField()}
                {skeletonField()}
                {skeletonField()}
                {skeletonField()}
            </Box>
            <Box className={classes.fieldBoxSkeleton}>
                {skeletonField(classes.fieldTitle)}
                {skeletonField()}
                {skeletonField()}
                {skeletonField()}
                {skeletonField()}
            </Box>
        </Box>
    );

    return (
        <Paper className={classes.paper}>
            <Typography variant="body2">{transTitle}</Typography>
            <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                <>
                    <GraphicsDialogWrapper />
                    {graphicsLoading ? skeleton : (
                        <>
                            {graphics ? (
                                <>
                                    <Box className={classes.fieldBox}>
                                        {textField(transName, graphics?.name, classes.fieldName)}
                                        {colorField(transShading, graphics?.shading)}
                                        {textField(transTransparency, graphics?.transparency)}
                                        <Box className={classes.fieldCode}>
                                        </Box>
                                    </Box>

                                    <Box className={classes.fieldBox} mt={1}>
                                        <Box className={classes.fieldTitle}>
                                        </Box>
                                        <Box className={classes.fieldForeBack}>
                                            <Typography variant="body2">{transBackground}</Typography>
                                        </Box>
                                        <Box className={classes.fieldForeBack}>
                                            <Typography variant="body2">{transForeground}</Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.fieldBox}>
                                        <Box className={classes.fieldTitle}>
                                            <Typography variant="body2">{transCut}</Typography>
                                        </Box>
                                        {textField(transPattern, graphics?.cutBackgroundPattern?.name)}
                                        {colorField(transColor, graphics?.cutBackground)}
                                        {textField(transPattern, graphics?.cutForegroundPattern?.name)}
                                        {colorField(transColor, graphics?.cutForeground)}
                                    </Box>
                                    <Box className={classes.fieldBox}>
                                        <Box className={classes.fieldTitle}>
                                            <Typography variant="body2">{transSurface}</Typography>
                                        </Box>
                                        {textField(transPattern, graphics?.surfaceBackgroundPattern?.name)}
                                        {colorField(transColor, graphics?.surfaceBackground)}
                                        {textField(transPattern, graphics?.surfaceForegroundPattern?.name)}
                                        {colorField(transColor, graphics?.surfaceForeground)}
                                    </Box>
                                </>
                            ) : (
                                <Alert className={classes.attachmentInfo} severity="info">{transNoGraphics}</Alert>
                            )}
                        </>
                    )}
                </>
            </SecuredComponent>

        </Paper>
    )
}
export default StepElementGraphics;