import { addNotification } from "@iolabs/notifier";
import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import useStyles from "./styles";
import { initializeModules, syncProject, syncProjectResources } from "../../../packages/Api/data/projects/client";
import { extractProjectElements } from "../../../packages/Api/data/elements/client";
import { exportInstances } from "../../../packages/Api/data/instances/client";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import messages from "./messages";
import { Alert } from "@material-ui/lab";
import ActionButton from "../../ActionButton/ActionButton";

interface IProjectToolsProps {
}

const ProjectTools: React.FC<IProjectToolsProps> = ({  }) => {
    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();

    const dispatch = useDispatch<DispatchAction>();

    // translations
    const intl = useIntl();
    const transHeader = intl.formatMessage({ ...messages.header });
    const transSynchronizeVersions = intl.formatMessage({ ...messages.synchronizeVersions });
    const transInitializeModules = intl.formatMessage({ ...messages.initializeModules });
    const transButtonSynchronizeResources = intl.formatMessage({ ...messages.buttonSynchronizeResources });
    const transButtonExtract = intl.formatMessage({ ...messages.extractInstancesFromModel });
    const transButtonExportInstances = intl.formatMessage({ ...messages.buttonExportInstances });
    const transMessageNotInitialized = intl.formatMessage({ ...messages.messageNotInitialized });

    const handleSyncVersions = async () => {
        return new Promise((resolve, reject) => {
            syncProject(activeProject?.projectID as number).then(resolve).catch(reject);
        })
    };

    const handleSyncResources = async () => {
        return new Promise((resolve, reject) => {
            syncProjectResources(activeProject?.projectID as number).then(resolve).catch(reject);
        })
    };
    const handleInitializeModules = async () => {
        return new Promise((resolve, reject) => {
            initializeModules(activeProject?.projectID as number).then(resolve).catch(reject);
        })
    };

    const handleExtractProject = async () => {
        return new Promise((resolve, reject) => {
            extractProjectElements(activeProject?.projectID as number).then(resolve).catch(reject);
        })
    };

    const handleExportInstances = async () => {
        return new Promise((resolve, reject) => {
            dispatch(addNotification({
                notification: {
                    variant: "success",
                    message: "Export has started",
                }}));
            exportInstances(activeProject?.projectID as number).then(resolve).catch(reject);
        })
    };

    return <>
        {activeProject?.isInitialized ? (
            <Box className={classes.boxSync}>
                <Typography variant={"h2"}>{transHeader}</Typography>
                <ActionButton header={transSynchronizeVersions} action={handleSyncVersions} />
                <ActionButton header={transButtonSynchronizeResources} action={handleSyncResources} />
                <ActionButton header={transInitializeModules} action={handleInitializeModules} />
                <ActionButton header={transButtonExtract} action={handleExtractProject} />
                <ActionButton header={transButtonExportInstances} action={handleExportInstances} />
            </Box>
        ) : (
            <Alert severity={"warning"}>{transMessageNotInitialized}</Alert>
        )}
    </>;
};

export default ProjectTools;