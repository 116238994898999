import { CategoryReport } from "../../../graphql/generated/graphql";
import React from "react";
import SubcontractorPriceForm from "../../Mapping/StepWorkFormSubcontractor/SubcontractorForm";
import { IElementsPosition } from "../../../packages/Api/data/elements/types";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import messages from "./messages";

interface IDialogPriceSubcontractorDataProps {
    category: CategoryReport;
    position: IElementsPosition;
}

const DialogPriceSubcontractorData: React.FC<IDialogPriceSubcontractorDataProps> = ({ category, position }) => {

    const intl = useIntl();

    const transSubcontractor = intl.formatMessage({ ...messages.subcontractor });
    return (
        <>
            <Typography variant={"subtitle1"}>{transSubcontractor}</Typography>
            <SubcontractorPriceForm position={position} />
        </>
    );
};

export default DialogPriceSubcontractorData;
