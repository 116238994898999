import { DispatchAction } from "@iolabs/redux-utils";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { IPageParams } from "../../pages/type";
import { loadHubs, useHubs, useHubsLoading, useProjects } from "../../redux/project";
import ProjectSelector from "../ProjectSelector/ProjectSelector";
import { ProjectData } from "./type";
import { useSecurityContext } from "../../redux/keyclock/securityContext";

interface IProjectSelectorWrapper {
    pageParams: IPageParams;
    location: any; // location from react-router-dom
    activeProject?: ProjectData;
}

const ProjectSelectorWrapper: React.FC<IProjectSelectorWrapper> = ({ pageParams, location , activeProject}) => {

    const dispatch = useDispatch<DispatchAction>();

    const hubs = useHubs();
    const loading = useHubsLoading();
    const projects = useProjects();

    const { tokenInitialized } = useSecurityContext();

    const handleGetProjectList = async () => {
        if (tokenInitialized && !hubs?.length) {
            dispatch(loadHubs({
                forceReload: true
            }));
        }
    };

    const onOpened = () => {
        dispatch(loadHubs({
            forceReload: true
        }));
    }

    useEffect(() => {
        handleGetProjectList();
    }, [tokenInitialized]);

    return <ProjectSelector projects={projects} activeProject={activeProject} loading={loading} hubs={hubs} onOpened={onOpened} />;
};

export default ProjectSelectorWrapper;
