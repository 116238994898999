import config from "../../../../config/config";
import ApiClient from "../../../ApiClient/ApiClient";
import fileDownload from "js-file-download";

const specificationsApi = new ApiClient("/Specifications", config.api.baseUrl);
/**
 * Export positions, file is downloaded
 */
export const exportSpecifications = (
    projectFileVersionID: number
): Promise<void> => {
    return specificationsApi
        .get(`/${projectFileVersionID}/export`, {
            responseType: "blob"
        })
        .then((response) => {
            // Promise.resolve(response.data)
            fileDownload(response.data, "specifications.xlsx");
            return;
        });
};