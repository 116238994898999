import React, { useCallback, useEffect, useState } from "react";
import { DataGrid, GridRowsProp, GridColDef, GridRowIdGetter } from "@mui/x-data-grid";
import { Box, Button, Grid, Icon, IconButton, Typography } from "@mui/material";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";
import { ItemField } from "../../CrudModule";
import useStyles from "./styles";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog/ConfirmDialog";
import { getDetailPath, getNewPath } from "../../pathUtils";


export interface CrudListConfiguration<LT> {
    title: string,
    disableAdd?: boolean,
    disableEdit?: boolean,
    disableRemove?: boolean,
    getRowID?: GridRowIdGetter<LT>,
}

export interface ICrudListProps<LT> {
    itemFields: ItemField[],
    data: (() => Promise<LT[]>) | LT[],
    onDelete?: (id: number) => Promise<void>,
    config: CrudListConfiguration<LT>,
}


const getColumnDefinitions = (fields: ItemField[], onDelete?: (id: number) => Promise<void>, disableEdit?: boolean, disableRemove?: boolean): GridColDef[] => {
    let { url } = useRouteMatch();
    let columns: GridColDef[] = fields.filter(f => !f.columnDef?.ignore).map((f) => {
        let colDef =  {
            field: f.name,
            headerName: f.title,
        }
        return f.columnDef ? {
            ...f.columnDef,
            ...colDef
        } : colDef;
    });

    const handleClick = (params) => {
        if (onDelete) {
            onDelete(params["id"]);
        }
    }

    const hideActionColumn = () => {
        return disableEdit && disableRemove;
    }

    return hideActionColumn() ? columns : [
        ...columns,
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 0,
            renderCell: (params) => (
                <>
                    {!disableEdit && (
                        <IconButton component={Link} to={`${getDetailPath(url, params.id)}`} size={"small"}>
                            <Icon fontSize={"small"}>edit</Icon>
                        </IconButton>
                    )}
                    {
                        !disableRemove && (
                            <IconButton onClick={() => handleClick(params)} size={"small"}>
                                <Icon fontSize={"small"}>close</Icon>
                            </IconButton>
                        )
                    }
                </>
            )
        }
    ];
}


const CrudList = <LT,>({config, itemFields, data, onDelete}: ICrudListProps<LT>) => {
    const classes = useStyles();
    let { url } = useRouteMatch();

    const [ loading, setLoading ] = useState<boolean>(true);
    const [ rows, setRows ] = useState<LT[]>([]);
    const [ stateCount, setStateCount ] = useState<number>(0);

    const [ rowToDelete, setRowToDelete ] = useState<number>();

    const confirmOpen = Boolean(rowToDelete);

    useEffect(() => {
        if (typeof data == "function") {
            (data as (() => Promise<LT[]>))().then((response) => {
                setRows(response);
                setLoading(false);
            })
        }
        else {
            setRows(data);
            setLoading(false);
        }

    }, [data, stateCount]);

    const handleConfirmOpen = (id: number): Promise<void> => {
        setRowToDelete(id);
        return Promise.resolve();
    };

    const handleConfirmClose = () => {
        setRowToDelete(undefined);
    };

    const handleDelete = useCallback(() => {
        return new Promise<void>((resolve, reject) => {
            if (onDelete && rowToDelete) {
                onDelete(rowToDelete).then(res => {
                    handleConfirmClose();
                    setStateCount(stateCount+1);
                    resolve();
                });
            }
            else {
                resolve();
            }
        });

    },[stateCount, rowToDelete]);


    return (
        <>
            <Grid className={classes.header} container spacing={2}>
                <Grid item xs={8}>
                    <Box className={classes.gridItemContent}>
                        <Typography>{config.title}</Typography>
                    </Box>
                </Grid>
                <Grid className={classes.buttons} item xs={4}>
                    <Box className={classes.gridItemContent}>
                        {!config.disableAdd && (
                            <Button variant={"contained"} component={Link} to={`${getNewPath(url)}`} size={"small"}>
                                <Icon fontSize={"small"}>add</Icon>
                                Add new
                            </Button>
                        )}
                    </Box>
                </Grid>
            </Grid>

            <DataGrid getRowId={config?.getRowID} autoHeight={true} loading={loading} rows={rows} columns={getColumnDefinitions(itemFields, handleConfirmOpen, config.disableEdit, config.disableRemove)} />

            <ConfirmDialog
                open={confirmOpen}
                handleClose={handleConfirmClose}
                handleConfirm={handleDelete}
                messages={{
                    dialogTitle: "Confirm delete", // "Delete confirm"
                    confirmMessage: "Are you sure to delete the record?"
                }}
            />
        </>
    );
};
export default CrudList;