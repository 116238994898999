import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a date and time. `DateTime` expects
   * timestamps to be formatted in accordance with the
   * [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard.
   */
  DateTime: any;
  Long: any;
  /**
   * The `Seconds` scalar type represents a period of time represented as the total
   * number of seconds in range [-922337203685, 922337203685].
   */
  Seconds: any;
};




export type AttributeMapping = {
  attributeMappingID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  normalizationFunctionCode?: Maybe<Scalars['String']>;
  normalizationFunctionParam?: Maybe<Scalars['String']>;
  propertySet?: Maybe<Scalars['String']>;
  propertySetAttribute?: Maybe<Scalars['String']>;
  targetAttributeName?: Maybe<Scalars['String']>;
  attributeType?: Maybe<AttributeType>;
  element?: Maybe<Element>;
  elementCategory?: Maybe<ElementCategory>;
};

export type AttributeRole = {
  attributeRoleID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  roleType?: Maybe<AttributeRoleType>;
  elementAttribute?: Maybe<ElementAttribute>;
  role?: Maybe<Role>;
};

export enum AttributeRoleType {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export type AttributeType = {
  attributeTypeID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  dataType?: Maybe<DataType>;
  isReferenceUnit?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  projectAttributes: Array<ProjectAttribute>;
};


export type AttributeTypeProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type CategoryEquipment = {
  categoryEquipmentID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  kMenge?: Maybe<Scalars['Float']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  elementCategory?: Maybe<ElementCategory>;
  equipment?: Maybe<Equipment>;
};

export type CategoryMaterial = {
  categoryMaterialID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  kMenge?: Maybe<Scalars['Float']>;
  materialType?: Maybe<MaterialType>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  elementCategory?: Maybe<ElementCategory>;
  material?: Maybe<Material>;
};

/** Represents single row in category filter */
export type CategoryReport = {
  elementCategory?: Maybe<ElementCategory>;
  children?: Maybe<Array<Maybe<CategoryReport>>>;
  geab?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  attributeType?: Maybe<AttributeType>;
  unitPrice?: Maybe<Scalars['Float']>;
  workNo?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  extraCharge?: Maybe<Scalars['Float']>;
  totalPrice?: Maybe<Scalars['Float']>;
  instancesPerFileVersionViewable?: Maybe<Array<Maybe<ProjectFileVersionViewableInstances>>>;
};

export type CategoryWork = {
  categoryWorkID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  kMenge?: Maybe<Scalars['Float']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  elementCategory?: Maybe<ElementCategory>;
  work?: Maybe<Work>;
};

export type Company = {
  /** Company ID */
  companyID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Company name */
  name?: Maybe<Scalars['String']>;
  projects: Array<ProjectMember>;
  usersInCompany: Array<UserInCompany>;
};


export type CompanyProjectsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type CompanyUsersInCompanyArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum Comparison {
  contains = 'contains',
  endsWith = 'endsWith',
  equal = 'equal',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  notIn = 'notIn',
  in = 'in',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  like = 'like',
  startsWith = 'startsWith'
}

export enum Connector {
  and = 'and',
  or = 'or'
}

/** Represents whole report per categories */
export type CostReport = {
  categories?: Maybe<Array<Maybe<CategoryReport>>>;
  totalAmount?: Maybe<Scalars['Float']>;
  priceAttributeType?: Maybe<AttributeType>;
};

export type Currency = {
  currencyID?: Maybe<Scalars['Int']>;
  alpha3Code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  displayFormat?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
};

export enum DashboardType {
  IST_SOLL = 'IST_SOLL',
  SINGLE_COLUMN = 'SINGLE_COLUMN'
}

export type DataModel = {
  dataModelID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  elements: Array<Element>;
  relationTrees: Array<RelationTree>;
  specialInstances: Array<SpecialModelInstance>;
};


export type DataModelElementsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type DataModelRelationTreesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type DataModelSpecialInstancesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum DataType {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  INT = 'INT',
  DOUBLE = 'DOUBLE',
  LINK = 'LINK'
}


export type DerivedCategory = {
  derivedCategoryID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  conditionalRule?: Maybe<LookupRule>;
  elementCategoryFrom?: Maybe<ElementCategory>;
  elementCategoryTo?: Maybe<ElementCategory>;
  referenceAttributeMapping?: Maybe<AttributeMapping>;
};

export type Element = {
  elementID?: Maybe<Scalars['Int']>;
  /**
   * Full element code
   *
   * Containing full category structure code, only for performance
   */
  code?: Maybe<Scalars['String']>;
  /**
   * Element code suffix
   *
   * Suffix will be prepended all category structure codes
   */
  codeSuffix?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  elementTag?: Maybe<ElementTagType>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /**
   * Element name
   *
   * Name of the element
   */
  name?: Maybe<Scalars['String']>;
  /**
   * Priority
   *
   * Priority of the element
   */
  priority?: Maybe<Scalars['Int']>;
  attributes: Array<ElementAttribute>;
  attributesMapping: Array<AttributeMapping>;
  dataModel?: Maybe<DataModel>;
  elementAttribute?: Maybe<ElementAttribute>;
  elementCategory?: Maybe<ElementCategory>;
  elementPrices: Array<ElementPrice>;
  instances: Array<Instance>;
  lookupRule?: Maybe<LookupRule>;
  priceSource?: Maybe<PriceSource>;
};


export type ElementAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementAttributesMappingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementElementPricesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementInstancesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ElementAttribute = {
  elementAttributeID?: Maybe<Scalars['Int']>;
  /**
   * Element attribute code
   *
   * Alphanumeric attribute code
   */
  code?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  multiplicityLimit?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  sortOrder?: Maybe<Scalars['Int']>;
  attributeRoles: Array<AttributeRole>;
  attributeType?: Maybe<AttributeType>;
  element?: Maybe<Element>;
};


export type ElementAttributeAttributeRolesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ElementAttributeValue = {
  elementAttributeValueID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  elementAttribute?: Maybe<ElementAttribute>;
  elementInstance?: Maybe<Instance>;
};

export type ElementCategory = {
  elementCategoryID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  codeSuffix?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  din276Structure?: Maybe<Scalars['String']>;
  fixedQuantity?: Maybe<Scalars['Float']>;
  isCustom?: Maybe<Scalars['Boolean']>;
  isFromEbkpCatalogue?: Maybe<Scalars['Boolean']>;
  isInstantiable?: Maybe<Scalars['Boolean']>;
  longText?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  omniclass?: Maybe<Scalars['String']>;
  pricingType?: Maybe<ElementCategoryPricingType>;
  shortText?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  state?: Maybe<EntityStatus>;
  stlbBauCode?: Maybe<Scalars['String']>;
  type?: Maybe<ElementCategoryType>;
  unitPrice?: Maybe<Scalars['Float']>;
  attributesMapping: Array<AttributeMapping>;
  categoryMaterials: Array<CategoryMaterial>;
  categoryWork: Array<CategoryWork>;
  childElementCategories: Array<ElementCategory>;
  derivedCategories: Array<DerivedCategory>;
  element: Array<Element>;
  graphics?: Maybe<Graphics>;
  lookupRule?: Maybe<LookupRule>;
  parentElementCategory?: Maybe<ElementCategory>;
  priceSource?: Maybe<PriceSource>;
  prices: Array<ElementPrice>;
  projectCategories: Array<ProjectCategory>;
  referenceUnit?: Maybe<AttributeType>;
};


export type ElementCategoryAttributesMappingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryCategoryMaterialsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryCategoryWorkArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryChildElementCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryDerivedCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryElementArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryPricesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ElementCategoryProjectCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum ElementCategoryPricingType {
  CALCULATED = 'CALCULATED',
  SUBCONTRACTOR = 'SUBCONTRACTOR'
}

export enum ElementCategoryType {
  MODEL = 'MODEL',
  MANUAL = 'MANUAL',
  DERIVED = 'DERIVED'
}

export type ElementPrice = {
  elementPriceID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Valid flag
   *
   * Simple representation of validity
   */
  isValid?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  surcharge?: Maybe<Scalars['Float']>;
  /**
   * Valid from
   *
   * The begining of the valid period
   */
  validFrom?: Maybe<Scalars['DateTime']>;
  /**
   * Valid to
   *
   * The end of the valid period, null if no end of the valid period
   */
  validTo?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['Float']>;
  currency?: Maybe<Currency>;
  element?: Maybe<Element>;
  elementCategory?: Maybe<ElementCategory>;
  previousElementPrice?: Maybe<ElementPrice>;
  priceSource?: Maybe<PriceSource>;
};

export enum ElementTagType {
  NOT_INCLUDED = 'NOT_INCLUDED',
  NORMAL = 'NORMAL',
  UNCATEGORIZED = 'UNCATEGORIZED'
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  VOIDED = 'VOIDED'
}

export type Equipment = {
  equipmentID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  itemNumber?: Maybe<Scalars['String']>;
  liPrice?: Maybe<Scalars['Float']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  productID?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  surcharge?: Maybe<Scalars['Float']>;
};

export type ExternalSystem = {
  externalSystemID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  projectExternals: Array<ProjectExternal>;
  projectFileExternals: Array<ProjectFileExternal>;
  projectFileVersionExternals: Array<ProjectFileVersionExternal>;
  projectFileVersionViewableExternals: Array<ProjectFileVersionViewableExternal>;
  sensorAssemblyGeometryExternals: Array<SensorAssemblyGeometryExternal>;
  sensorAssemblyItemExternals: Array<SensorAssemblyItemExternal>;
  sensorLocationExternals: Array<SensorLocationExternal>;
};


export type ExternalSystemProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemProjectFileVersionViewableExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemSensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ExternalSystemSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Family = {
  /** Numerical identifier */
  familyID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Family name */
  familyName?: Maybe<Scalars['String']>;
  /** Latest Version Author System */
  latestVersionAuthorSystem?: Maybe<Scalars['String']>;
  /** Latest Version Author User */
  latestVersionAuthorUser?: Maybe<Scalars['String']>;
  /** Latest Version */
  latestVersionString?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  familyExternals: Array<FamilyExternal>;
};


export type FamilyFamilyExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type FamilyExternal = {
  familyExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  latestVersionExternalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  family?: Maybe<Family>;
};

export type FileType = {
  fileTypeID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isModel?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  projectFiles: Array<ProjectFile>;
};


export type FileTypeProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum FilterCondition {
  CONTAINS = 'CONTAINS',
  EQUALS = 'EQUALS',
  STARTS_WITH = 'STARTS_WITH',
  IS_DEFINED = 'IS_DEFINED',
  IS_LESS_THAN = 'IS_LESS_THAN',
  IS_LESS_THAN_OR_EQUALS = 'IS_LESS_THAN_OR_EQUALS',
  IS_GREATER_THAN = 'IS_GREATER_THAN',
  IS_GREATER_THAN_OR_EQUALS = 'IS_GREATER_THAN_OR_EQUALS'
}

export type Graphics = {
  graphicsID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  cutBackground?: Maybe<Scalars['String']>;
  cutForeground?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  shading?: Maybe<Scalars['String']>;
  surfaceBackground?: Maybe<Scalars['String']>;
  surfaceForeground?: Maybe<Scalars['String']>;
  transparency?: Maybe<Scalars['Int']>;
  cutBackgroundPattern?: Maybe<GraphicsPattern>;
  cutForegroundPattern?: Maybe<GraphicsPattern>;
  surfaceBackgroundPattern?: Maybe<GraphicsPattern>;
  surfaceForegroundPattern?: Maybe<GraphicsPattern>;
};

export type GraphicsPattern = {
  graphicsPatternID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
};

export type IgnoredRevitCategory = {
  ignoredRevitCategoryID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Project>;
  revitCategory?: Maybe<RevitCategory>;
};

export type Instance = {
  instanceID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  globalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  sourceID?: Maybe<Scalars['String']>;
  attributeValues: Array<ElementAttributeValue>;
  derivedFromInstance?: Maybe<Instance>;
  derivedInstances: Array<Instance>;
  element?: Maybe<Element>;
  followingInstance?: Maybe<Instance>;
  instanceExternals: Array<InstanceExternal>;
  instanceInViewables: Array<InstanceInViewable>;
  instanceRelations1: Array<InstanceRelation>;
  instanceRelations2: Array<InstanceRelation>;
  previousInstance?: Maybe<Instance>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
  projectFileVersionViewable?: Maybe<ProjectFileVersionViewable>;
};


export type InstanceAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InstanceDerivedInstancesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InstanceInstanceExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InstanceInstanceInViewablesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InstanceInstanceRelations1Args = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type InstanceInstanceRelations2Args = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type InstanceExternal = {
  instanceExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  instance?: Maybe<Instance>;
};

export type InstanceInViewable = {
  instanceInViewableID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  instance?: Maybe<Instance>;
  projectFileVersionViewable?: Maybe<ProjectFileVersionViewable>;
};

export type InstanceRelation = {
  instanceRelationID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  instance1?: Maybe<Instance>;
  instance2?: Maybe<Instance>;
  relationDefinition?: Maybe<RelationDefinition>;
};

export type Issue = {
  /** Numerical identifier */
  issueID?: Maybe<Scalars['Int']>;
  /** Unique issue code */
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Issue description */
  description?: Maybe<Scalars['String']>;
  /** Issue due date */
  dueDate?: Maybe<Scalars['DateTime']>;
  /**
   * Location description
   *
   * Text description of the issue location
   */
  locationDescription?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Issue title */
  name?: Maybe<Scalars['String']>;
  /**
   * Assigned to
   *
   * User the issue is assigned to
   */
  assignedTo?: Maybe<User>;
  /**
   * Issue cause
   *
   * Cause of the issue
   */
  issueCause?: Maybe<IssueCause>;
  /**
   * Issues in external system
   *
   * Issues specific data in external system
   */
  issueExternals: Array<IssueExternal>;
  /**
   * Issue status
   *
   * Current status of the issue
   */
  issueStatus?: Maybe<IssueStatus>;
  /**
   * Issue type
   *
   * Type of the issue
   */
  issueType?: Maybe<IssueType>;
  /**
   * Owner
   *
   * User who owns the issue
   */
  owner?: Maybe<User>;
  /**
   * Related project
   *
   * Project where the issue is
   */
  project?: Maybe<Project>;
  /**
   * Related project file version
   *
   * File version where the issue is
   */
  projectFileVersion?: Maybe<ProjectFileVersion>;
  /**
   * Reporter
   *
   * User the issue was reported by
   */
  reporter?: Maybe<User>;
  /**
   * Permitted statuses transitions
   *
   * Stauses which current issue can be moved to
   */
  allowedStatuses?: Maybe<Array<Maybe<IssueStatus>>>;
};


export type IssueIssueExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type IssueCause = {
  /** Issue cause ID */
  issueCauseID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Cause description */
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Cause name */
  name?: Maybe<Scalars['String']>;
  /** Child cause */
  children: Array<IssueCause>;
  /**
   * Causes in external system
   *
   * Issues cause specific data in external system
   */
  issueCauseExternals: Array<IssueCauseExternal>;
  /** Parent cause */
  parentCause?: Maybe<IssueCause>;
  /** Project */
  project?: Maybe<Project>;
};


export type IssueCauseChildrenArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type IssueCauseIssueCauseExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type IssueCauseExternal = {
  issueCauseExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Identifier in external system */
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** External system */
  externalSystem?: Maybe<ExternalSystem>;
  /** Related cause */
  issueCause?: Maybe<IssueCause>;
};

export type IssueExternal = {
  issueExternalID?: Maybe<Scalars['Int']>;
  /** Unique issue code */
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Identifier in external system */
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Position of the issue according to external system */
  position?: Maybe<Scalars['String']>;
  /** External system */
  externalSystem?: Maybe<ExternalSystem>;
  /** Related issue */
  issue?: Maybe<Issue>;
};

export type IssueInExternalSystemInput = {
  /** External system code */
  externalSystemCode: Scalars['String'];
  /** Location */
  location?: Maybe<Scalars['String']>;
  /** Issue ID */
  issueID?: Maybe<Scalars['String']>;
  /** Issue type ID */
  issueTypeID?: Maybe<Scalars['String']>;
  /** Issue cause ID */
  issueCauseID?: Maybe<Scalars['String']>;
  /** Issue status ID */
  issueStatusID?: Maybe<Scalars['String']>;
  /** Starting version */
  versionFrom?: Maybe<Scalars['Int']>;
};

export type IssueInput = {
  /** Issue name */
  name: Scalars['String'];
  /** Issue decription */
  description?: Maybe<Scalars['String']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Text description of location of an issue */
  locationDescription?: Maybe<Scalars['String']>;
  /** Project ID */
  projectID?: Maybe<Scalars['Int']>;
  /** Project file version ID */
  projectFileVersionID?: Maybe<Scalars['Int']>;
  /** Issue status ID */
  issueStatusID?: Maybe<Scalars['Int']>;
  /** Issue type ID */
  issueTypeID?: Maybe<Scalars['Int']>;
  /** Issue cause ID */
  issueCauseID?: Maybe<Scalars['Int']>;
  /** Assigned to user ID */
  assignedToUserID?: Maybe<Scalars['Int']>;
  /** Reporter user ID */
  reporterUserID?: Maybe<Scalars['Int']>;
  /** Owner user ID */
  ownerUserID?: Maybe<Scalars['Int']>;
  externalSystemData: IssueInExternalSystemInput;
};

export type IssueStatus = {
  /** Numerical identifier */
  issueStatusID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Flag whether the state is allowed to be assigned during issue creation */
  creationEnabled?: Maybe<Scalars['Boolean']>;
  /** Default sorting order */
  defaultSortOrder?: Maybe<Scalars['Int']>;
  /** Status description */
  description?: Maybe<Scalars['String']>;
  /**
   * Highlight color
   *
   * Color used for some kinds of highlighting
   */
  highlightColor?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Status name */
  name?: Maybe<Scalars['String']>;
  /**
   * Status in external system
   *
   * Issues Status specific data in external system
   */
  issueStatusExternals: Array<IssueStatusExternal>;
};


export type IssueStatusIssueStatusExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type IssueStatusExternal = {
  issueStatusExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Identifier in external system */
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** External system */
  externalSystem?: Maybe<ExternalSystem>;
  /** Related status */
  issueStatus?: Maybe<IssueStatus>;
};

export type IssueType = {
  /** Issue type ID */
  issueTypeID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Type description */
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Type name */
  name?: Maybe<Scalars['String']>;
  /** Child types */
  children: Array<IssueType>;
  /**
   * Types in external system
   *
   * Issues type specific data in external system
   */
  issueTypeExternals: Array<IssueTypeExternal>;
  /** Parent type */
  parent?: Maybe<IssueType>;
  /** Project */
  project?: Maybe<Project>;
};


export type IssueTypeChildrenArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type IssueTypeIssueTypeExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type IssueTypeExternal = {
  issueTypeExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Identifier in external system */
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** External system */
  externalSystem?: Maybe<ExternalSystem>;
  /** Related type */
  issueTypes?: Maybe<IssueType>;
};

export type IssueUpdateInput = {
  /** Issue name */
  name?: Maybe<Scalars['String']>;
  /** Issue decription */
  description?: Maybe<Scalars['String']>;
  /** Due date */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Text description of location of an issue */
  locationDescription?: Maybe<Scalars['String']>;
  /** Project ID */
  projectID?: Maybe<Scalars['Int']>;
  /** Project file version ID */
  projectFileVersionID?: Maybe<Scalars['Int']>;
  /** Issue status ID */
  issueStatusID?: Maybe<Scalars['Int']>;
  /** Issue type ID */
  issueTypeID?: Maybe<Scalars['Int']>;
  /** Issue cause ID */
  issueCauseID?: Maybe<Scalars['Int']>;
  /** Assigned to user ID */
  assignedToUserID?: Maybe<Scalars['Int']>;
  /** Reporter user ID */
  reporterUserID?: Maybe<Scalars['Int']>;
  /** Owner user ID */
  ownerUserID?: Maybe<Scalars['Int']>;
  externalSystemData: IssueInExternalSystemInput;
};

export type KeyValuePairInput = {
  /** Key */
  key: Scalars['String'];
  /** Value */
  value: Scalars['String'];
};

export type Language = {
  languageID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  iSO6391Code?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  nativeName?: Maybe<Scalars['String']>;
};


export type LookupRule = {
  elementLookupRuleID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
};

export type MappingFilter = {
  mappingFilterID?: Maybe<Scalars['Int']>;
  attribute?: Maybe<Scalars['String']>;
  condition?: Maybe<FilterCondition>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  propertySet?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  mappingFilterGroup?: Maybe<MappingFilterGroup>;
};

export type MappingFilterGroup = {
  mappingFilterGroupID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  element?: Maybe<Element>;
  mappingFilters: Array<MappingFilter>;
};


export type MappingFilterGroupMappingFiltersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Material = {
  materialID?: Maybe<Scalars['Int']>;
  class?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  compressibility?: Maybe<Scalars['Float']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  electricalResistivity?: Maybe<Scalars['Float']>;
  emissivity?: Maybe<Scalars['Float']>;
  itemNumber?: Maybe<Scalars['String']>;
  itemTag?: Maybe<Scalars['String']>;
  liPrice?: Maybe<Scalars['Float']>;
  link?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  permeability?: Maybe<Scalars['Float']>;
  physicalBehavior?: Maybe<PhysicalBehavior>;
  physicalDensity?: Maybe<Scalars['Float']>;
  physicalDescription?: Maybe<Scalars['String']>;
  physicalSource?: Maybe<Scalars['String']>;
  physicalSourceLink?: Maybe<Scalars['String']>;
  poissonsRatioX?: Maybe<Scalars['Float']>;
  poissonsRatioY?: Maybe<Scalars['Float']>;
  poissonsRatioZ?: Maybe<Scalars['Float']>;
  porosity?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  productID?: Maybe<Scalars['String']>;
  reflectivity?: Maybe<Scalars['Float']>;
  shearModulusX?: Maybe<Scalars['Float']>;
  shearModulusY?: Maybe<Scalars['Float']>;
  shearModulusZ?: Maybe<Scalars['Float']>;
  specificHeat?: Maybe<Scalars['Float']>;
  structuralAssetClass?: Maybe<StructuralAssetClass>;
  subclass?: Maybe<Scalars['String']>;
  surcharge?: Maybe<Scalars['Float']>;
  tensileStrength?: Maybe<Scalars['Float']>;
  thermalConductivity?: Maybe<Scalars['Float']>;
  thermalExpansionCoefficientX?: Maybe<Scalars['Float']>;
  thermalExpansionCoefficientY?: Maybe<Scalars['Float']>;
  thermalExpansionCoefficientZ?: Maybe<Scalars['Float']>;
  thermalMaterialType?: Maybe<ThermalMaterialType>;
  thermicalBehavior?: Maybe<ThermicalBehavior>;
  thermicalDensity?: Maybe<Scalars['Float']>;
  thermicalDescription?: Maybe<Scalars['String']>;
  thermicalSource?: Maybe<Scalars['String']>;
  thermicalSourceLink?: Maybe<Scalars['String']>;
  transmitsLight?: Maybe<Scalars['Boolean']>;
  yieldStrength?: Maybe<Scalars['Float']>;
  youngsModulusX?: Maybe<Scalars['Float']>;
  youngsModulusY?: Maybe<Scalars['Float']>;
  youngsModulusZ?: Maybe<Scalars['Float']>;
  referenceUnit?: Maybe<AttributeType>;
};

export enum MaterialType {
  PHYSICAL = 'PHYSICAL',
  ADDITIONAL = 'ADDITIONAL'
}

export type Module = {
  /** Module ID */
  moduleID?: Maybe<Scalars['Int']>;
  /** Module code */
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Module description */
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Module name */
  name?: Maybe<Scalars['String']>;
};

export type Mutation = {
  createIssue?: Maybe<Issue>;
  createIssueRemote?: Maybe<Issue>;
  updateIssueRemote?: Maybe<Issue>;
};


export type MutationCreateIssueArgs = {
  issue: IssueInput;
};


export type MutationCreateIssueRemoteArgs = {
  issue: IssueInput;
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


export type MutationUpdateIssueRemoteArgs = {
  issue: IssueUpdateInput;
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};

export type OrderBy = {
  path: Scalars['String'];
  descending?: Maybe<Scalars['Boolean']>;
};

export enum PhysicalBehavior {
  ISOTROPIC = 'ISOTROPIC',
  UNKNOWN = 'UNKNOWN'
}

export type PriceSource = {
  priceSourceID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  priceType?: Maybe<PriceType>;
};

export enum PriceType {
  TOTAL = 'TOTAL',
  UNIT = 'UNIT'
}

/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type Project = {
  projectID?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  lastUpdate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  notificationReceiver?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  updateType?: Maybe<UpdateType>;
  dataModel?: Maybe<DataModel>;
  projectAttributes: Array<ProjectAttribute>;
  projectCategories: Array<ProjectCategory>;
  projectDashboards: Array<ProjectDashboard>;
  projectExternals: Array<ProjectExternal>;
  projectFile?: Maybe<ProjectFile>;
  projectFiles: Array<ProjectFile>;
  projectMembers: Array<ProjectMember>;
  projectModules: Array<ProjectModule>;
  projectRevitEnhancers: Array<ProjectRevitEnhancer>;
  sensorModelStocks: Array<SensorModelStock>;
  viewerViewpoint?: Maybe<ViewerViewpoint>;
  viewerViewpoints: Array<ViewerViewpoint>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectDashboardsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectMembersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectModulesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectProjectRevitEnhancersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectSensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/**
 * BIM Project
 *
 * Represents single BIM project (in eg. BIM 360)
 */
export type ProjectViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectAttribute = {
  projectAttributeID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  highlighColor?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  valueGetter?: Maybe<Scalars['String']>;
  attributeType?: Maybe<AttributeType>;
  parentProjectAttribute?: Maybe<ProjectAttribute>;
  project?: Maybe<Project>;
  projectAttributeGroup?: Maybe<ProjectAttributeGroup>;
  projectAttributeValues: Array<ProjectAttributeValue>;
  projectAttributes: Array<ProjectAttribute>;
  projectDashboardAttributes: Array<ProjectDashboardAttribute>;
  projectFile?: Maybe<ProjectFile>;
};


export type ProjectAttributeProjectAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectAttributeProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectAttributeProjectDashboardAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectAttributeGroup = {
  projectAttributeGroupID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  projectAttributes: Array<ProjectAttribute>;
};


export type ProjectAttributeGroupProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectAttributeValue = {
  projectAttributeValueID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  value?: Maybe<Scalars['String']>;
  projectAttribute?: Maybe<ProjectAttribute>;
  projectFile?: Maybe<ProjectFile>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
};

export type ProjectCategory = {
  projectCategoryID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  elementCategory?: Maybe<ElementCategory>;
  project?: Maybe<Project>;
};

export type ProjectDashboard = {
  projectDashboardID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  dashboardType?: Maybe<DashboardType>;
  description?: Maybe<Scalars['String']>;
  includeLastUpdate?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  attributes: Array<ProjectDashboardAttribute>;
  project?: Maybe<Project>;
};


export type ProjectDashboardAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectDashboardAttribute = {
  projectDashboardAttributeID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  sortOrder?: Maybe<Scalars['Int']>;
  projectAttribute?: Maybe<ProjectAttribute>;
  projectDashboard?: Maybe<ProjectDashboard>;
};

export type ProjectExternal = {
  projectExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  project?: Maybe<Project>;
  /**
   * External System code
   *
   * External System code
   */
  externalSystemCode?: Maybe<Scalars['String']>;
};

export type ProjectFile = {
  projectFileID?: Maybe<Scalars['Int']>;
  adapter?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['Int']>;
  fileType?: Maybe<FileType>;
  project?: Maybe<Project>;
  projectAttributeValues: Array<ProjectAttributeValue>;
  projectAttributes: Array<ProjectAttribute>;
  projectFileExternals: Array<ProjectFileExternal>;
  projectFileVersions: Array<ProjectFileVersion>;
  projects: Array<Project>;
  viewerViewpoint?: Maybe<ViewerViewpoint>;
  /**
   * Last version
   *
   * Last version of the file
   */
  lastVersion?: Maybe<ProjectFileVersion>;
};


export type ProjectFileProjectAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileProjectsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectFileExternal = {
  projectFileExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  externalFolder?: Maybe<Scalars['String']>;
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  projectFile?: Maybe<ProjectFile>;
};

export type ProjectFileVersion = {
  projectFileVersionID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Valid flag
   *
   * Simple representation of validity
   */
  isValid?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /**
   * Valid from
   *
   * The begining of the valid period
   */
  validFrom?: Maybe<Scalars['DateTime']>;
  /**
   * Valid to
   *
   * The end of the valid period, null if no end of the valid period
   */
  validTo?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
  projectAttributeValues: Array<ProjectAttributeValue>;
  projectFile?: Maybe<ProjectFile>;
  projectFileVersionExternals: Array<ProjectFileVersionExternal>;
  projectFileVersionSensors: Array<ProjectFileVersionSensor>;
  projectFileVersionViewables: Array<ProjectFileVersionViewable>;
  sensorAssemblyGeometries: Array<SensorAssemblyGeometry>;
};


export type ProjectFileVersionProjectAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileVersionProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileVersionProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileVersionProjectFileVersionViewablesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileVersionSensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectFileVersionExternal = {
  projectFileVersionExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
  /**
   * External System code
   *
   * External System code
   */
  externalSystemCode?: Maybe<Scalars['String']>;
};

export type ProjectFileVersionSensor = {
  projectFileVersionSensorID?: Maybe<Scalars['Int']>;
  displayGraph?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['Boolean']>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
  sensorGraphColor?: Maybe<SensorGraphColor>;
  sensorLocationExternals: Array<SensorLocationExternal>;
  sensorModelStock?: Maybe<SensorModelStock>;
};


export type ProjectFileVersionSensorSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectFileVersionViewable = {
  projectFileVersionViewableID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  validFrom?: Maybe<Scalars['DateTime']>;
  validTo?: Maybe<Scalars['DateTime']>;
  viewableType?: Maybe<Scalars['Int']>;
  instancesInViewable: Array<InstanceInViewable>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
  projectFileVersionViewableExternals: Array<ProjectFileVersionViewableExternal>;
};


export type ProjectFileVersionViewableInstancesInViewableArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ProjectFileVersionViewableProjectFileVersionViewableExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ProjectFileVersionViewableExternal = {
  projectFileVersionViewableExternalID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  externalID?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  externalSystem?: Maybe<ExternalSystem>;
  projectFileVersionViewable?: Maybe<ProjectFileVersionViewable>;
};

/** Represents instance by filter and project file version viewable */
export type ProjectFileVersionViewableInstances = {
  viewable?: Maybe<ProjectFileVersionViewable>;
  instances?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProjectMember = {
  /** Project member ID */
  projectMemberID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Company entity */
  company?: Maybe<Company>;
  modulesAccess: Array<ProjectMemberModule>;
  /** Project entity */
  project?: Maybe<Project>;
  /** User entity */
  user?: Maybe<User>;
  /** User ID */
  userID?: Maybe<Scalars['Int']>;
  /** Company ID */
  companyID?: Maybe<Scalars['Int']>;
  /** Project ID */
  projectID?: Maybe<Scalars['Int']>;
};


export type ProjectMemberModulesAccessArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export enum ProjectMemberLevel {
  MEMBER = 'MEMBER',
  ADMINISTRATOR = 'ADMINISTRATOR'
}

export type ProjectMemberModule = {
  /** Project member module ID */
  projectMemberModuleID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** Member level */
  memberLevel?: Maybe<ProjectMemberLevel>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Project module entity */
  module?: Maybe<Module>;
  /** Project member entity */
  projectMember?: Maybe<ProjectMember>;
  /** Project member ID */
  projectMemberID?: Maybe<Scalars['Int']>;
  /** Module ID */
  moduleID?: Maybe<Scalars['Int']>;
};

export type ProjectModule = {
  /** Project Module ID */
  projectModuleID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Module entity */
  module?: Maybe<Module>;
  /** Project entity */
  project?: Maybe<Project>;
  /** Project ID */
  projectID?: Maybe<Scalars['Int']>;
  /** Module ID */
  moduleID?: Maybe<Scalars['Int']>;
};

export type ProjectRevitEnhancer = {
  projectRevitEnhancerID?: Maybe<Scalars['Int']>;
  sortOrder?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  revitEnhancer?: Maybe<RevitEnhancer>;
};

/** Basic Queries */
export type Query = {
  /** Get list of Currency */
  currencies: Array<Currency>;
  /** Get list of Language */
  languages: Array<Language>;
  /** Get list of PriceSource */
  priceSources: Array<PriceSource>;
  /** Get list of Translation */
  translations: Array<Translation>;
  /** Get list of TranslationTag */
  translationTags: Array<TranslationTag>;
  /** Get list of TranslationTagMapping */
  translationTagMappings: Array<TranslationTagMapping>;
  /** Get list of ProjectDashboardAttribute */
  dashboardAttributes: Array<ProjectDashboardAttribute>;
  /** Get list of AttributeType */
  attributeTypes: Array<AttributeType>;
  /** Get list of ExternalSystem */
  externalSystems: Array<ExternalSystem>;
  /** Get list of FileType */
  fileTypes: Array<FileType>;
  /** Get list of Project */
  projects: Array<Project>;
  /** Get list of ProjectAttribute */
  projectAttributes: Array<ProjectAttribute>;
  /** Get list of ProjectAttributeGroup */
  projectAttributeGroups: Array<ProjectAttributeGroup>;
  /** Get list of ProjectAttributeValue */
  projectAttributeValues: Array<ProjectAttributeValue>;
  /** Get list of ProjectDashboard */
  projectDashboards: Array<ProjectDashboard>;
  /** Get list of ProjectExternal */
  projectExternals: Array<ProjectExternal>;
  /** Get list of ProjectFile */
  projectFiles: Array<ProjectFile>;
  /** Get list of ProjectFileExternal */
  projectFileExternals: Array<ProjectFileExternal>;
  /** Get list of ProjectFileVersion */
  projectFileVersions: Array<ProjectFileVersion>;
  /** Get list of ProjectFileVersionExternal */
  projectFileVersionExternals: Array<ProjectFileVersionExternal>;
  /** Get list of ProjectFileVersionViewable */
  projectFileVersionViewables: Array<ProjectFileVersionViewable>;
  /** Get list of ProjectFileVersionViewableExternal */
  projectFileVersionViewableExternals: Array<ProjectFileVersionViewableExternal>;
  /** Get list of Role */
  roles: Array<Role>;
  /** Get list of TransformationMatrix */
  transformationMatrixes: Array<TransformationMatrix>;
  /** Get list of User */
  users: Array<User>;
  /** Get list of Company */
  companies: Array<Company>;
  /** Get list of ProjectMember */
  projectMembers: Array<ProjectMember>;
  /** Get list of Module */
  modules: Array<Module>;
  /** Get list of ProjectModule */
  projectModules: Array<ProjectModule>;
  /** Get list of ProjectMemberModule */
  projectMemberModules: Array<ProjectMemberModule>;
  /** Get list of UserInCompany */
  usersInCompanies: Array<UserInCompany>;
  /** Get list of Vector */
  vectors: Array<Vector>;
  /** Get list of ViewerViewpoint */
  viewerViewpoints: Array<ViewerViewpoint>;
  /** Get list of RevitEnhancer */
  revitEnhancers: Array<RevitEnhancer>;
  /** Get list of ProjectRevitEnhancer */
  projectRevitEnhancers: Array<ProjectRevitEnhancer>;
  /** Get list of DataModel */
  dataModels: Array<DataModel>;
  /** Get list of AttributeRole */
  attributeRoles: Array<AttributeRole>;
  /** Get list of Element */
  elements: Array<Element>;
  /** Get list of ElementAttribute */
  elementAttributes: Array<ElementAttribute>;
  /** Get list of ElementAttributeValue */
  elementAttributeValues: Array<ElementAttributeValue>;
  /** Get list of ElementCategory */
  elementCategories: Array<ElementCategory>;
  /** Get list of DerivedCategory */
  derivedCategories: Array<DerivedCategory>;
  /** Get list of ProjectCategory */
  projectCategories: Array<ProjectCategory>;
  /** Get list of ElementPrice */
  elementPrices: Array<ElementPrice>;
  /** Get list of Instance */
  instances: Array<Instance>;
  /** Get list of InstanceRelation */
  instanceRelations: Array<InstanceRelation>;
  /** Get list of InstanceExternal */
  instanceExternals: Array<InstanceExternal>;
  /** Get list of InstanceInViewable */
  instanceInViewables: Array<InstanceInViewable>;
  /** Get list of RelationDefinition */
  relationDefinitions: Array<RelationDefinition>;
  /** Get list of RelationTree */
  relationTree: Array<RelationTree>;
  /** Get list of RelationTreeNodeSet */
  relationTreeNodeSet: Array<RelationTreeNodeSet>;
  /** Get list of RelationTreeRootNodeSet */
  relationTreeRootNodeSet: Array<RelationTreeRootNodeSet>;
  /** Get list of Material */
  materials: Array<Material>;
  /** Get list of Work */
  work: Array<Work>;
  /** Get list of Equipment */
  equipment: Array<Equipment>;
  /** Get list of Graphics */
  graphics: Array<Graphics>;
  /** Get list of GraphicsPattern */
  graphicsPatterns: Array<GraphicsPattern>;
  /** Get list of SpecialModelInstance */
  specialModelInstances: Array<SpecialModelInstance>;
  /** Get list of CategoryMaterial */
  categoryMaterial: Array<CategoryMaterial>;
  /** Get list of CategoryWork */
  categoryWork: Array<CategoryWork>;
  /** Get list of CategoryEquipment */
  categoryEquipment: Array<CategoryEquipment>;
  /** Get list of RevitCategory */
  revitCategories: Array<RevitCategory>;
  /** Get list of IgnoredRevitCategory */
  ignoredRevitCategories: Array<IgnoredRevitCategory>;
  /** Get list of Issue */
  issues: Array<Issue>;
  /** Get list of IssueExternal */
  issuesExternals: Array<IssueExternal>;
  /** Get list of IssueCause */
  issueCauses: Array<IssueCause>;
  /** Get list of IssueCauseExternal */
  issueCausesExternals: Array<IssueCauseExternal>;
  /** Get list of IssueStatus */
  issueStatuses: Array<IssueStatus>;
  /** Get list of IssueStatusExternal */
  issueStatusesExternals: Array<IssueStatusExternal>;
  /** Get list of IssueType */
  issueTypes: Array<IssueType>;
  /** Get list of IssueTypeExternal */
  issueTypesExternals: Array<IssueTypeExternal>;
  /** Get list of AttributeMapping */
  attributeMappings: Array<AttributeMapping>;
  /** Get list of MappingFilter */
  mappingFilters: Array<MappingFilter>;
  /** Get list of MappingFilterGroup */
  mappingFilterGroups: Array<MappingFilterGroup>;
  /** Get list of LookupRule */
  elementLookupRules: Array<LookupRule>;
  /** Get list of ProjectFileVersionSensor */
  projectFileVersionSensors: Array<ProjectFileVersionSensor>;
  /** Get list of SensorAssembly */
  sensorAssemblies: Array<SensorAssembly>;
  /** Get list of SensorAssemblyGeometry */
  sensorAssemblyGeometries: Array<SensorAssemblyGeometry>;
  /** Get list of SensorAssemblyGeometryExternal */
  sensorAssemblyGeometryExternals: Array<SensorAssemblyGeometryExternal>;
  /** Get list of SensorAssemblyItem */
  sensorAssemblyItems: Array<SensorAssemblyItem>;
  /** Get list of SensorAssemblyItemExternal */
  sensorAssemblyItemExternals: Array<SensorAssemblyItemExternal>;
  /** Get list of SensorGraph */
  sensorGraphs: Array<SensorGraph>;
  /** Get list of SensorGraphColor */
  sensorGraphColors: Array<SensorGraphColor>;
  /** Get list of SensorLocationExternal */
  sensorLocationExternals: Array<SensorLocationExternal>;
  /** Get list of SensorModel */
  sensorModels: Array<SensorModel>;
  /** Get list of SensorModelStock */
  sensorModelStocks: Array<SensorModelStock>;
  /** Get list of SensorType */
  sensorTypes: Array<SensorType>;
  /** Get list of Family */
  families: Array<Family>;
  /** Get list of FamilyExternal */
  familyExternals: Array<FamilyExternal>;
  syncedIssues?: Maybe<Array<Maybe<Issue>>>;
  remoteIssues?: Maybe<Array<Maybe<Issue>>>;
  remoteIssuesCount?: Maybe<Scalars['Int']>;
  remoteIssueCauses?: Maybe<Array<Maybe<IssueCause>>>;
  remoteIssueTypes?: Maybe<Array<Maybe<IssueType>>>;
  remoteIssueStatuses?: Maybe<Array<Maybe<IssueStatus>>>;
  costsPerCategories?: Maybe<CostReport>;
};


/** Basic Queries */
export type QueryCurrenciesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryLanguagesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryPriceSourcesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryTranslationsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryTranslationTagsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryTranslationTagMappingsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryDashboardAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryAttributeTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryExternalSystemsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryFileTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectAttributeGroupsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectDashboardsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileVersionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileVersionExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileVersionViewablesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileVersionViewableExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRolesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryTransformationMatrixesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryUsersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryCompaniesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectMembersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryModulesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectModulesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectMemberModulesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryUsersInCompaniesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryVectorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRevitEnhancersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectRevitEnhancersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryDataModelsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryAttributeRolesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementAttributesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementAttributeValuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryDerivedCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementPricesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryInstancesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryInstanceRelationsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryInstanceExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryInstanceInViewablesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRelationDefinitionsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRelationTreeArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRelationTreeNodeSetArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRelationTreeRootNodeSetArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryMaterialsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryWorkArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryEquipmentArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryGraphicsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryGraphicsPatternsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySpecialModelInstancesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryCategoryMaterialArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryCategoryWorkArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryCategoryEquipmentArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRevitCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIgnoredRevitCategoriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssuesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssuesExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueCausesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueCausesExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueStatusesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueStatusesExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryIssueTypesExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryAttributeMappingsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryMappingFiltersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryMappingFilterGroupsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryElementLookupRulesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorAssembliesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorGraphsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorGraphColorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorModelsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySensorTypesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryFamiliesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryFamilyExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QuerySyncedIssuesArgs = {
  projectFileVersionId?: Maybe<Scalars['Int']>;
};


/** Basic Queries */
export type QueryRemoteIssuesArgs = {
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


/** Basic Queries */
export type QueryRemoteIssuesCountArgs = {
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


/** Basic Queries */
export type QueryRemoteIssueCausesArgs = {
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


/** Basic Queries */
export type QueryRemoteIssueTypesArgs = {
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


/** Basic Queries */
export type QueryRemoteIssueStatusesArgs = {
  externalSystemCode?: Maybe<Scalars['String']>;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
};


/** Basic Queries */
export type QueryCostsPerCategoriesArgs = {
  projectFileVersionId?: Maybe<Scalars['Int']>;
};

export type RelationDefinition = {
  relationDefinitionID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  instance1Getter?: Maybe<Scalars['String']>;
  instance2Getter?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<RelationType>;
  instanceRelations: Array<InstanceRelation>;
};


export type RelationDefinitionInstanceRelationsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RelationTree = {
  relationTreeID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  dataModel?: Maybe<DataModel>;
  relationTreeRootNodeSets: Array<RelationTreeRootNodeSet>;
};


export type RelationTreeRelationTreeRootNodeSetsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RelationTreeNodeSet = {
  relationTreeNodeSetID?: Maybe<Scalars['Int']>;
  childNodeSets: Array<RelationTreeNodeSet>;
  parentNodeSet?: Maybe<RelationTreeNodeSet>;
  relationDefinition?: Maybe<RelationDefinition>;
};


export type RelationTreeNodeSetChildNodeSetsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type RelationTreeRootNodeSet = {
  relationTreeRootNodeSetID?: Maybe<Scalars['Int']>;
  relationTree?: Maybe<RelationTree>;
  relationTreeNodeSet?: Maybe<RelationTreeNodeSet>;
};

export enum RelationType {
  GEOMETRY_BASED = 'GEOMETRY_BASED',
  META_DATA_BASED = 'META_DATA_BASED'
}

export type RevitCategory = {
  revitCategoryID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  nameTranslationKey?: Maybe<Scalars['String']>;
  revitID?: Maybe<Scalars['Int']>;
};

export type RevitEnhancer = {
  revitEnhancerID?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  projectRevitEnhancers: Array<ProjectRevitEnhancer>;
};


export type RevitEnhancerProjectRevitEnhancersArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Role = {
  roleID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};


export type SensorAssembly = {
  sensorAssemblyID?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sensorAssemblyGeometries: Array<SensorAssemblyGeometry>;
  sensorAssemblyItems: Array<SensorAssemblyItem>;
  sensorModels: Array<SensorModel>;
};


export type SensorAssemblySensorAssemblyGeometriesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type SensorAssemblySensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type SensorAssemblySensorModelsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorAssemblyGeometry = {
  sensorAssemblyGeometryID?: Maybe<Scalars['Int']>;
  projectFileVersion?: Maybe<ProjectFileVersion>;
  sensorAssembly?: Maybe<SensorAssembly>;
  sensorAssemblyGeometryExternals: Array<SensorAssemblyGeometryExternal>;
};


export type SensorAssemblyGeometrySensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorAssemblyGeometryExternal = {
  sensorAssemblyGeometryExternalID?: Maybe<Scalars['Int']>;
  externalSystem?: Maybe<ExternalSystem>;
  sensorAssemblyGeometry?: Maybe<SensorAssemblyGeometry>;
  transformationMatrix?: Maybe<TransformationMatrix>;
};

export type SensorAssemblyItem = {
  sensorAssemblyItemID?: Maybe<Scalars['Int']>;
  sensorAssembly?: Maybe<SensorAssembly>;
  sensorAssemblyItemExternals: Array<SensorAssemblyItemExternal>;
  sensorModel?: Maybe<SensorModel>;
};


export type SensorAssemblyItemSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorAssemblyItemExternal = {
  sensorAssemblyItemExternalID?: Maybe<Scalars['Int']>;
  externalSystem?: Maybe<ExternalSystem>;
  sensorAssemblyItem?: Maybe<SensorAssemblyItem>;
  transformationMatrix?: Maybe<TransformationMatrix>;
};

export type SensorGraph = {
  sensorGraphID?: Maybe<Scalars['Int']>;
  doubleValue1?: Maybe<Scalars['Float']>;
  doubleValue2?: Maybe<Scalars['Float']>;
  doubleValue3?: Maybe<Scalars['Float']>;
  graphDate?: Maybe<Scalars['DateTime']>;
  graphTime?: Maybe<Scalars['Seconds']>;
  mqqtCode?: Maybe<Scalars['String']>;
  sourceValue?: Maybe<Scalars['String']>;
};

export type SensorGraphColor = {
  sensorGraphColorID?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  projectFileVersionSensors: Array<ProjectFileVersionSensor>;
};


export type SensorGraphColorProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorLocationExternal = {
  sensorLocationExternalID?: Maybe<Scalars['Int']>;
  externalSystem?: Maybe<ExternalSystem>;
  projectFileVersionSensor?: Maybe<ProjectFileVersionSensor>;
  vector?: Maybe<Vector>;
};

export type SensorModel = {
  sensorModelID?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  isLocalizationSensor?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  thumbnailImage?: Maybe<Scalars['String']>;
  sensorAssembly?: Maybe<SensorAssembly>;
  sensorAssemblyItems: Array<SensorAssemblyItem>;
  sensorModelStocks: Array<SensorModelStock>;
  sensorType?: Maybe<SensorType>;
};


export type SensorModelSensorAssemblyItemsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type SensorModelSensorModelStocksArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorModelStock = {
  sensorModelStockID?: Maybe<Scalars['Int']>;
  inventaryCode?: Maybe<Scalars['String']>;
  mqqtCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  projectFileVersionSensors: Array<ProjectFileVersionSensor>;
  sensorModel?: Maybe<SensorModel>;
};


export type SensorModelStockProjectFileVersionSensorsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SensorType = {
  sensorTypeID?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  displayFormat?: Maybe<Scalars['String']>;
  isAssembly?: Maybe<Scalars['Boolean']>;
  measureUnits?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  sensorModels: Array<SensorModel>;
};


export type SensorTypeSensorModelsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type SpecialModelInstance = {
  specialModelInstanceID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  type?: Maybe<SpecialModelInstanceType>;
  dataModel?: Maybe<DataModel>;
  instance?: Maybe<Instance>;
};

export enum SpecialModelInstanceType {
  CONTAINS_UNKNOWN_ATTRIBUTE = 'CONTAINS_UNKNOWN_ATTRIBUTE',
  UNCATEGORIZED = 'UNCATEGORIZED'
}

export enum StringComparison {
  CURRENT_CULTURE = 'CURRENT_CULTURE',
  CURRENT_CULTURE_IGNORE_CASE = 'CURRENT_CULTURE_IGNORE_CASE',
  INVARIANT_CULTURE = 'INVARIANT_CULTURE',
  INVARIANT_CULTURE_IGNORE_CASE = 'INVARIANT_CULTURE_IGNORE_CASE',
  ORDINAL = 'ORDINAL',
  ORDINAL_IGNORE_CASE = 'ORDINAL_IGNORE_CASE'
}

export enum StructuralAssetClass {
  MAUERWERK = 'MAUERWERK',
  GENERIC = 'GENERIC',
  UNKNOWN = 'UNKNOWN'
}

export enum ThermalMaterialType {
  SOLID = 'SOLID',
  UNKNOWN = 'UNKNOWN'
}

export enum ThermicalBehavior {
  ISOTROPIC = 'ISOTROPIC',
  UNKNOWN = 'UNKNOWN'
}

export type TransformationMatrix = {
  transformationMatrixID?: Maybe<Scalars['Int']>;
  matrix?: Maybe<Scalars['String']>;
  sensorAssemblyGeometryExternals: Array<SensorAssemblyGeometryExternal>;
  sensorAssemblyItemExternals: Array<SensorAssemblyItemExternal>;
};


export type TransformationMatrixSensorAssemblyGeometryExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type TransformationMatrixSensorAssemblyItemExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type Translation = {
  translationID?: Maybe<Scalars['Long']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  fallbackText?: Maybe<Scalars['String']>;
  isLong?: Maybe<Scalars['Boolean']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  translationKey?: Maybe<Scalars['String']>;
  translationLongDE?: Maybe<Scalars['String']>;
  translationLongEN?: Maybe<Scalars['String']>;
  translationLongFR?: Maybe<Scalars['String']>;
  translationLongIT?: Maybe<Scalars['String']>;
  translationShortDE?: Maybe<Scalars['String']>;
  translationShortEN?: Maybe<Scalars['String']>;
  translationShortFR?: Maybe<Scalars['String']>;
  translationShortIT?: Maybe<Scalars['String']>;
  translationTagMapping: Array<TranslationTagMapping>;
};


export type TranslationTranslationTagMappingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type TranslationTag = {
  translationTagID?: Maybe<Scalars['Long']>;
  code?: Maybe<Scalars['String']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  translationTagMapping: Array<TranslationTagMapping>;
};


export type TranslationTagTranslationTagMappingArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type TranslationTagMapping = {
  translationTagMappingID?: Maybe<Scalars['Long']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  translation?: Maybe<Translation>;
  translationTag?: Maybe<TranslationTag>;
};

export enum UpdateType {
  ON_DEMAND = 'ON_DEMAND',
  AUTOMATIC = 'AUTOMATIC'
}

export type User = {
  /** User ID */
  userID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** E-mail */
  email?: Maybe<Scalars['String']>;
  /** First name */
  firstName?: Maybe<Scalars['String']>;
  /** First name */
  lastName?: Maybe<Scalars['String']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** User name */
  userName?: Maybe<Scalars['String']>;
  projects: Array<ProjectMember>;
  roles: Array<Role>;
  userInCompanies: Array<UserInCompany>;
};


export type UserProjectsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type UserRolesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type UserUserInCompaniesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type UserInCompany = {
  /** User in company ID */
  userInCompanyID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Company>;
  user?: Maybe<User>;
  /** User ID */
  userID?: Maybe<Scalars['Int']>;
  /** Company ID */
  companyID?: Maybe<Scalars['Int']>;
};

export type Vector = {
  vectorID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
  cameraViewerViewpoints: Array<ViewerViewpoint>;
  directionViewerViewpoints: Array<ViewerViewpoint>;
  positionViewerViewpoints: Array<ViewerViewpoint>;
  sensorLocationExternals: Array<SensorLocationExternal>;
};


export type VectorCameraViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type VectorDirectionViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type VectorPositionViewerViewpointsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type VectorSensorLocationExternalsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type ViewerViewpoint = {
  viewerViewpointID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  cameraVector?: Maybe<Vector>;
  directionVector?: Maybe<Vector>;
  positionVector?: Maybe<Vector>;
  project?: Maybe<Project>;
  projectFiles: Array<ProjectFile>;
  projects: Array<Project>;
};


export type ViewerViewpointProjectFilesArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};


export type ViewerViewpointProjectsArgs = {
  id?: Maybe<Scalars['ID']>;
  ids?: Maybe<Array<Scalars['ID']>>;
  where?: Maybe<Array<WhereExpression>>;
  orderBy?: Maybe<Array<OrderBy>>;
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type WhereExpression = {
  path?: Maybe<Scalars['String']>;
  comparison?: Maybe<Comparison>;
  negate?: Maybe<Scalars['Boolean']>;
  case?: Maybe<StringComparison>;
  value?: Maybe<Array<Maybe<Scalars['String']>>>;
  connector?: Maybe<Connector>;
  groupedExpressions?: Maybe<Array<Maybe<WhereExpression>>>;
};

export type Work = {
  workID?: Maybe<Scalars['Int']>;
  /**
   * Created by
   *
   * ID of the user, who created this entity
   */
  createdBy?: Maybe<Scalars['Int']>;
  /**
   * Creation timestamp
   *
   * Date and time when the entity was created
   */
  createdDate?: Maybe<Scalars['DateTime']>;
  itemNumber?: Maybe<Scalars['String']>;
  liPrice?: Maybe<Scalars['Float']>;
  /**
   * Last modification user
   *
   * ID of the user, who has done the last modification of this entity
   */
  modifiedBy?: Maybe<Scalars['Int']>;
  /**
   * Last modification timestamp
   *
   * Date and time when the entity was lastly modified
   */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  productID?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  surcharge?: Maybe<Scalars['Float']>;
};

export type CreateIssueRemoteMutationVariables = Exact<{
  issue: IssueInput;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
}>;


export type CreateIssueRemoteMutation = { createIssueRemote?: Maybe<{ name?: Maybe<string>, issueID?: Maybe<number>, issueStatus?: Maybe<{ name?: Maybe<string> }>, issueExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, issueType?: Maybe<{ name?: Maybe<string>, parent?: Maybe<{ name?: Maybe<string> }> }> }> };

export type UpdateIssueRemoteMutationVariables = Exact<{
  issue: IssueUpdateInput;
  externalArguments?: Maybe<Array<Maybe<KeyValuePairInput>>>;
}>;


export type UpdateIssueRemoteMutation = { updateIssueRemote?: Maybe<{ name?: Maybe<string>, issueID?: Maybe<number>, issueStatus?: Maybe<{ name?: Maybe<string> }>, issueType?: Maybe<{ name?: Maybe<string>, parent?: Maybe<{ name?: Maybe<string> }> }> }> };

export type GetFormConfigQueryVariables = Exact<{
  accountUrn: Scalars['String'];
  projectUrn: Scalars['String'];
}>;


export type GetFormConfigQuery = { causes?: Maybe<Array<Maybe<(
    { children: Array<CauseFieldsFragment> }
    & CauseFieldsFragment
  )>>>, types?: Maybe<Array<Maybe<(
    { children: Array<TypeFieldsFragment> }
    & TypeFieldsFragment
  )>>>, statuses?: Maybe<Array<Maybe<{ issueStatusID?: Maybe<number>, name?: Maybe<string>, description?: Maybe<string>, highlightColor?: Maybe<string>, creationEnabled?: Maybe<boolean>, defaultSortOrder?: Maybe<number>, issueStatusExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> }>>>, users: Array<{ userID?: Maybe<number> }> };

export type TypeFieldsFragment = { issueTypeID?: Maybe<number>, name?: Maybe<string>, description?: Maybe<string>, issueTypeExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> };

export type CauseFieldsFragment = { issueCauseID?: Maybe<number>, name?: Maybe<string>, description?: Maybe<string>, issueCauseExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> };

export type GetIssuesRemoteQueryVariables = Exact<{
  accountUrn: Scalars['String'];
  projectUrn: Scalars['String'];
  fileUrn: Scalars['String'];
  fromVersion: Scalars['String'];
  viewableGuid: Scalars['String'];
}>;


export type GetIssuesRemoteQuery = { issues?: Maybe<Array<Maybe<{ issueID?: Maybe<number>, name?: Maybe<string>, code?: Maybe<string>, description?: Maybe<string>, dueDate?: Maybe<any>, locationDescription?: Maybe<string>, issueCause?: Maybe<{ issueCauseID?: Maybe<number>, name?: Maybe<string>, issueCauseExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> }>, issueExternals: Array<{ position?: Maybe<string>, externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, issueStatus?: Maybe<{ issueStatusID?: Maybe<number>, name?: Maybe<string>, highlightColor?: Maybe<string>, issueStatusExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> }>, issueType?: Maybe<{ issueTypeID?: Maybe<number>, name?: Maybe<string>, issueTypeExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, parent?: Maybe<{ issueTypeID?: Maybe<number>, name?: Maybe<string>, issueTypeExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> }> }>, allowedStatuses?: Maybe<Array<Maybe<{ issueStatusID?: Maybe<number>, name?: Maybe<string>, highlightColor?: Maybe<string>, issueStatusExternals: Array<{ externalID?: Maybe<string> }> }>>> }>>> };

export type GetIssuesRemoteCountQueryVariables = Exact<{
  accountId: Scalars['String'];
  projectId: Scalars['String'];
  fileId: Scalars['String'];
}>;


export type GetIssuesRemoteCountQuery = { count?: Maybe<number> };

export type GetSensorGraphDefaultColorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSensorGraphDefaultColorQuery = { sensorGraphColors: Array<{ sensorGraphColorID?: Maybe<number>, name?: Maybe<string>, color?: Maybe<string>, isDefault?: Maybe<boolean> }> };

export type GetSensorGraphColorByIdQueryVariables = Exact<{
  colorId?: Maybe<Scalars['String']>;
}>;


export type GetSensorGraphColorByIdQuery = { sensorGraphColors: Array<{ sensorGraphColorID?: Maybe<number>, name?: Maybe<string>, color?: Maybe<string>, isDefault?: Maybe<boolean> }> };

export type CostsPerCategoriesQueryVariables = Exact<{
  projectFileVersionId?: Maybe<Scalars['Int']>;
}>;


export type CostsPerCategoriesQuery = { costsPerCategories?: Maybe<{ totalAmount?: Maybe<number>, priceAttributeType?: Maybe<{ unit?: Maybe<string>, dataType?: Maybe<DataType> }>, categories?: Maybe<Array<Maybe<(
      { children?: Maybe<Array<Maybe<(
        { children?: Maybe<Array<Maybe<(
          { children?: Maybe<Array<Maybe<(
            { children?: Maybe<Array<Maybe<(
              { children?: Maybe<Array<Maybe<CostsValueFragment>>> }
              & CostsValueFragment
            )>>> }
            & CostsValueFragment
          )>>> }
          & CostsValueFragment
        )>>> }
        & CostsValueFragment
      )>>> }
      & CostsValueFragment
    )>>> }> };

export type CostsValueFragment = { geab?: Maybe<string>, source?: Maybe<string>, quantity?: Maybe<number>, unitPrice?: Maybe<number>, workNo?: Maybe<string>, price?: Maybe<number>, extraCharge?: Maybe<number>, totalPrice?: Maybe<number>, elementCategory?: Maybe<{ elementCategoryID?: Maybe<number>, code?: Maybe<string>, name?: Maybe<string>, description?: Maybe<string>, type?: Maybe<ElementCategoryType> }>, attributeType?: Maybe<{ unit?: Maybe<string>, dataType?: Maybe<DataType> }>, instancesPerFileVersionViewable?: Maybe<Array<Maybe<{ instances?: Maybe<Array<Maybe<string>>>, viewable?: Maybe<{ projectFileVersionViewableID?: Maybe<number>, viewableType?: Maybe<number>, name?: Maybe<string> }> }>>> };

export type GetProjectQueryVariables = Exact<{
  code?: Maybe<Scalars['String']>;
}>;


export type GetProjectQuery = { projects: Array<{ projectID?: Maybe<number>, name?: Maybe<string>, code?: Maybe<string>, dataModel?: Maybe<{ dataModelID?: Maybe<number> }>, projectExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, projectModules: Array<{ module?: Maybe<{ code?: Maybe<string>, name?: Maybe<string>, description?: Maybe<string> }> }>, projectFiles: Array<{ projectFileID?: Maybe<number>, name?: Maybe<string>, fileName?: Maybe<string>, fileType?: Maybe<{ code?: Maybe<string> }>, projectFileExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, projectFileVersions: Array<{ projectFileVersionID?: Maybe<number>, version?: Maybe<number>, projectFileVersionExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }>, projectFileVersionViewables: Array<{ projectFileVersionViewableID?: Maybe<number>, name?: Maybe<string>, viewableType?: Maybe<number>, projectFileVersionViewableExternals: Array<{ externalID?: Maybe<string>, externalSystem?: Maybe<{ code?: Maybe<string> }> }> }> }> }> }> };

export const TypeFieldsFragmentDoc = gql`
    fragment typeFields on IssueType {
  issueTypeID
  name
  description
  issueTypeExternals {
    externalID
    externalSystem {
      code
    }
  }
}
    `;
export const CauseFieldsFragmentDoc = gql`
    fragment causeFields on IssueCause {
  issueCauseID
  name
  description
  issueCauseExternals {
    externalID
    externalSystem {
      code
    }
  }
}
    `;
export const CostsValueFragmentDoc = gql`
    fragment CostsValue on CategoryReport {
  elementCategory {
    elementCategoryID
    code
    name
    description
    type
  }
  geab
  source
  quantity
  attributeType {
    unit
    dataType
  }
  unitPrice
  workNo
  price
  extraCharge
  totalPrice
  instancesPerFileVersionViewable {
    viewable {
      projectFileVersionViewableID
      viewableType
      name
    }
    instances
  }
}
    `;
export const CreateIssueRemoteDocument = gql`
    mutation CreateIssueRemote($issue: IssueInput!, $externalArguments: [KeyValuePairInput]) {
  createIssueRemote(
    issue: $issue
    externalSystemCode: "forge"
    externalArguments: $externalArguments
  ) {
    name
    issueID
    issueStatus {
      name
    }
    issueExternals {
      externalID
      externalSystem {
        code
      }
    }
    issueType {
      name
      parent {
        name
      }
    }
  }
}
    `;
export type CreateIssueRemoteMutationFn = Apollo.MutationFunction<CreateIssueRemoteMutation, CreateIssueRemoteMutationVariables>;

/**
 * __useCreateIssueRemoteMutation__
 *
 * To run a mutation, you first call `useCreateIssueRemoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIssueRemoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIssueRemoteMutation, { data, loading, error }] = useCreateIssueRemoteMutation({
 *   variables: {
 *      issue: // value for 'issue'
 *      externalArguments: // value for 'externalArguments'
 *   },
 * });
 */
export function useCreateIssueRemoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateIssueRemoteMutation, CreateIssueRemoteMutationVariables>) {
        return Apollo.useMutation<CreateIssueRemoteMutation, CreateIssueRemoteMutationVariables>(CreateIssueRemoteDocument, baseOptions);
      }
export type CreateIssueRemoteMutationHookResult = ReturnType<typeof useCreateIssueRemoteMutation>;
export type CreateIssueRemoteMutationResult = Apollo.MutationResult<CreateIssueRemoteMutation>;
export type CreateIssueRemoteMutationOptions = Apollo.BaseMutationOptions<CreateIssueRemoteMutation, CreateIssueRemoteMutationVariables>;
export const UpdateIssueRemoteDocument = gql`
    mutation UpdateIssueRemote($issue: IssueUpdateInput!, $externalArguments: [KeyValuePairInput]) {
  updateIssueRemote(
    issue: $issue
    externalSystemCode: "forge"
    externalArguments: $externalArguments
  ) {
    name
    issueID
    issueStatus {
      name
    }
    issueType {
      name
      parent {
        name
      }
    }
  }
}
    `;
export type UpdateIssueRemoteMutationFn = Apollo.MutationFunction<UpdateIssueRemoteMutation, UpdateIssueRemoteMutationVariables>;

/**
 * __useUpdateIssueRemoteMutation__
 *
 * To run a mutation, you first call `useUpdateIssueRemoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIssueRemoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIssueRemoteMutation, { data, loading, error }] = useUpdateIssueRemoteMutation({
 *   variables: {
 *      issue: // value for 'issue'
 *      externalArguments: // value for 'externalArguments'
 *   },
 * });
 */
export function useUpdateIssueRemoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIssueRemoteMutation, UpdateIssueRemoteMutationVariables>) {
        return Apollo.useMutation<UpdateIssueRemoteMutation, UpdateIssueRemoteMutationVariables>(UpdateIssueRemoteDocument, baseOptions);
      }
export type UpdateIssueRemoteMutationHookResult = ReturnType<typeof useUpdateIssueRemoteMutation>;
export type UpdateIssueRemoteMutationResult = Apollo.MutationResult<UpdateIssueRemoteMutation>;
export type UpdateIssueRemoteMutationOptions = Apollo.BaseMutationOptions<UpdateIssueRemoteMutation, UpdateIssueRemoteMutationVariables>;
export const GetFormConfigDocument = gql`
    query getFormConfig($accountUrn: String!, $projectUrn: String!) {
  causes: remoteIssueCauses(
    externalSystemCode: "forge"
    externalArguments: [{key: "accountId", value: $accountUrn}, {key: "projectId", value: $projectUrn}]
  ) {
    ...causeFields
    children {
      ...causeFields
    }
  }
  types: remoteIssueTypes(
    externalSystemCode: "forge"
    externalArguments: [{key: "accountId", value: $accountUrn}, {key: "projectId", value: $projectUrn}]
  ) {
    ...typeFields
    children {
      ...typeFields
    }
  }
  statuses: remoteIssueStatuses(
    externalSystemCode: "forge"
    externalArguments: [{key: "accountId", value: $accountUrn}, {key: "projectId", value: $projectUrn}]
  ) {
    issueStatusID
    name
    description
    highlightColor
    creationEnabled
    defaultSortOrder
    issueStatusExternals {
      externalID
      externalSystem {
        code
      }
    }
  }
  users: users {
    userID
  }
}
    ${CauseFieldsFragmentDoc}
${TypeFieldsFragmentDoc}`;

/**
 * __useGetFormConfigQuery__
 *
 * To run a query within a React component, call `useGetFormConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormConfigQuery({
 *   variables: {
 *      accountUrn: // value for 'accountUrn'
 *      projectUrn: // value for 'projectUrn'
 *   },
 * });
 */
export function useGetFormConfigQuery(baseOptions?: Apollo.QueryHookOptions<GetFormConfigQuery, GetFormConfigQueryVariables>) {
        return Apollo.useQuery<GetFormConfigQuery, GetFormConfigQueryVariables>(GetFormConfigDocument, baseOptions);
      }
export function useGetFormConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormConfigQuery, GetFormConfigQueryVariables>) {
          return Apollo.useLazyQuery<GetFormConfigQuery, GetFormConfigQueryVariables>(GetFormConfigDocument, baseOptions);
        }
export type GetFormConfigQueryHookResult = ReturnType<typeof useGetFormConfigQuery>;
export type GetFormConfigLazyQueryHookResult = ReturnType<typeof useGetFormConfigLazyQuery>;
export type GetFormConfigQueryResult = Apollo.QueryResult<GetFormConfigQuery, GetFormConfigQueryVariables>;
export const GetIssuesRemoteDocument = gql`
    query GetIssuesRemote($accountUrn: String!, $projectUrn: String!, $fileUrn: String!, $fromVersion: String!, $viewableGuid: String!) {
  issues: remoteIssues(
    externalSystemCode: "forge"
    externalArguments: [{key: "accountId", value: $accountUrn}, {key: "projectId", value: $projectUrn}, {key: "fileId", value: $fileUrn}, {key: "fromVersion", value: $fromVersion}, {key: "viewableGuid", value: $viewableGuid}]
  ) {
    issueID
    name
    code
    issueCause {
      issueCauseID
      name
      issueCauseExternals(
        where: {path: "externalSystem.code", comparison: equal, value: "forge"}
      ) {
        externalID
        externalSystem {
          code
        }
      }
    }
    description
    dueDate
    issueExternals(
      where: {path: "externalSystem.code", comparison: equal, value: "forge"}
    ) {
      position
      externalID
      externalSystem {
        code
      }
    }
    locationDescription
    issueStatus {
      issueStatusID
      name
      highlightColor
      issueStatusExternals(
        where: {path: "externalSystem.code", comparison: equal, value: "forge"}
      ) {
        externalID
        externalSystem {
          code
        }
      }
    }
    issueType {
      issueTypeID
      name
      issueTypeExternals(
        where: {path: "externalSystem.code", comparison: equal, value: "forge"}
      ) {
        externalID
        externalSystem {
          code
        }
      }
      parent {
        issueTypeID
        name
        issueTypeExternals(
          where: {path: "externalSystem.code", comparison: equal, value: "forge"}
        ) {
          externalID
          externalSystem {
            code
          }
        }
      }
    }
    allowedStatuses {
      issueStatusID
      name
      highlightColor
      issueStatusExternals {
        externalID
      }
    }
  }
}
    `;

/**
 * __useGetIssuesRemoteQuery__
 *
 * To run a query within a React component, call `useGetIssuesRemoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesRemoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesRemoteQuery({
 *   variables: {
 *      accountUrn: // value for 'accountUrn'
 *      projectUrn: // value for 'projectUrn'
 *      fileUrn: // value for 'fileUrn'
 *      fromVersion: // value for 'fromVersion'
 *      viewableGuid: // value for 'viewableGuid'
 *   },
 * });
 */
export function useGetIssuesRemoteQuery(baseOptions?: Apollo.QueryHookOptions<GetIssuesRemoteQuery, GetIssuesRemoteQueryVariables>) {
        return Apollo.useQuery<GetIssuesRemoteQuery, GetIssuesRemoteQueryVariables>(GetIssuesRemoteDocument, baseOptions);
      }
export function useGetIssuesRemoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesRemoteQuery, GetIssuesRemoteQueryVariables>) {
          return Apollo.useLazyQuery<GetIssuesRemoteQuery, GetIssuesRemoteQueryVariables>(GetIssuesRemoteDocument, baseOptions);
        }
export type GetIssuesRemoteQueryHookResult = ReturnType<typeof useGetIssuesRemoteQuery>;
export type GetIssuesRemoteLazyQueryHookResult = ReturnType<typeof useGetIssuesRemoteLazyQuery>;
export type GetIssuesRemoteQueryResult = Apollo.QueryResult<GetIssuesRemoteQuery, GetIssuesRemoteQueryVariables>;
export const GetIssuesRemoteCountDocument = gql`
    query GetIssuesRemoteCount($accountId: String!, $projectId: String!, $fileId: String!) {
  count: remoteIssuesCount(
    externalSystemCode: "forge"
    externalArguments: [{key: "accountId", value: $accountId}, {key: "projectId", value: $projectId}, {key: "fileId", value: $fileId}]
  )
}
    `;

/**
 * __useGetIssuesRemoteCountQuery__
 *
 * To run a query within a React component, call `useGetIssuesRemoteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIssuesRemoteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIssuesRemoteCountQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      projectId: // value for 'projectId'
 *      fileId: // value for 'fileId'
 *   },
 * });
 */
export function useGetIssuesRemoteCountQuery(baseOptions?: Apollo.QueryHookOptions<GetIssuesRemoteCountQuery, GetIssuesRemoteCountQueryVariables>) {
        return Apollo.useQuery<GetIssuesRemoteCountQuery, GetIssuesRemoteCountQueryVariables>(GetIssuesRemoteCountDocument, baseOptions);
      }
export function useGetIssuesRemoteCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIssuesRemoteCountQuery, GetIssuesRemoteCountQueryVariables>) {
          return Apollo.useLazyQuery<GetIssuesRemoteCountQuery, GetIssuesRemoteCountQueryVariables>(GetIssuesRemoteCountDocument, baseOptions);
        }
export type GetIssuesRemoteCountQueryHookResult = ReturnType<typeof useGetIssuesRemoteCountQuery>;
export type GetIssuesRemoteCountLazyQueryHookResult = ReturnType<typeof useGetIssuesRemoteCountLazyQuery>;
export type GetIssuesRemoteCountQueryResult = Apollo.QueryResult<GetIssuesRemoteCountQuery, GetIssuesRemoteCountQueryVariables>;
export const GetSensorGraphDefaultColorDocument = gql`
    query getSensorGraphDefaultColor {
  sensorGraphColors(
    where: {path: "isDefault", comparison: equal, value: ["true"]}
  ) {
    sensorGraphColorID
    name
    color
    isDefault
  }
}
    `;

/**
 * __useGetSensorGraphDefaultColorQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphDefaultColorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphDefaultColorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphDefaultColorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSensorGraphDefaultColorQuery(baseOptions?: Apollo.QueryHookOptions<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>) {
        return Apollo.useQuery<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>(GetSensorGraphDefaultColorDocument, baseOptions);
      }
export function useGetSensorGraphDefaultColorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>) {
          return Apollo.useLazyQuery<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>(GetSensorGraphDefaultColorDocument, baseOptions);
        }
export type GetSensorGraphDefaultColorQueryHookResult = ReturnType<typeof useGetSensorGraphDefaultColorQuery>;
export type GetSensorGraphDefaultColorLazyQueryHookResult = ReturnType<typeof useGetSensorGraphDefaultColorLazyQuery>;
export type GetSensorGraphDefaultColorQueryResult = Apollo.QueryResult<GetSensorGraphDefaultColorQuery, GetSensorGraphDefaultColorQueryVariables>;
export const GetSensorGraphColorByIdDocument = gql`
    query getSensorGraphColorById($colorId: String) {
  sensorGraphColors(
    where: {path: "sensorGraphColorId", comparison: equal, value: [$colorId]}
  ) {
    sensorGraphColorID
    name
    color
    isDefault
  }
}
    `;

/**
 * __useGetSensorGraphColorByIdQuery__
 *
 * To run a query within a React component, call `useGetSensorGraphColorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSensorGraphColorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSensorGraphColorByIdQuery({
 *   variables: {
 *      colorId: // value for 'colorId'
 *   },
 * });
 */
export function useGetSensorGraphColorByIdQuery(baseOptions?: Apollo.QueryHookOptions<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>) {
        return Apollo.useQuery<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>(GetSensorGraphColorByIdDocument, baseOptions);
      }
export function useGetSensorGraphColorByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>) {
          return Apollo.useLazyQuery<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>(GetSensorGraphColorByIdDocument, baseOptions);
        }
export type GetSensorGraphColorByIdQueryHookResult = ReturnType<typeof useGetSensorGraphColorByIdQuery>;
export type GetSensorGraphColorByIdLazyQueryHookResult = ReturnType<typeof useGetSensorGraphColorByIdLazyQuery>;
export type GetSensorGraphColorByIdQueryResult = Apollo.QueryResult<GetSensorGraphColorByIdQuery, GetSensorGraphColorByIdQueryVariables>;
export const CostsPerCategoriesDocument = gql`
    query costsPerCategories($projectFileVersionId: Int) {
  costsPerCategories: costsPerCategories(
    projectFileVersionId: $projectFileVersionId
  ) {
    totalAmount
    priceAttributeType {
      unit
      dataType
    }
    categories {
      ...CostsValue
      children {
        ...CostsValue
        children {
          ...CostsValue
          children {
            ...CostsValue
            children {
              ...CostsValue
              children {
                ...CostsValue
              }
            }
          }
        }
      }
    }
  }
}
    ${CostsValueFragmentDoc}`;

/**
 * __useCostsPerCategoriesQuery__
 *
 * To run a query within a React component, call `useCostsPerCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCostsPerCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCostsPerCategoriesQuery({
 *   variables: {
 *      projectFileVersionId: // value for 'projectFileVersionId'
 *   },
 * });
 */
export function useCostsPerCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<CostsPerCategoriesQuery, CostsPerCategoriesQueryVariables>) {
        return Apollo.useQuery<CostsPerCategoriesQuery, CostsPerCategoriesQueryVariables>(CostsPerCategoriesDocument, baseOptions);
      }
export function useCostsPerCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CostsPerCategoriesQuery, CostsPerCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<CostsPerCategoriesQuery, CostsPerCategoriesQueryVariables>(CostsPerCategoriesDocument, baseOptions);
        }
export type CostsPerCategoriesQueryHookResult = ReturnType<typeof useCostsPerCategoriesQuery>;
export type CostsPerCategoriesLazyQueryHookResult = ReturnType<typeof useCostsPerCategoriesLazyQuery>;
export type CostsPerCategoriesQueryResult = Apollo.QueryResult<CostsPerCategoriesQuery, CostsPerCategoriesQueryVariables>;
export const GetProjectDocument = gql`
    query getProject($code: String) {
  projects: projects(where: {path: "code", comparison: equal, value: [$code]}) {
    projectID
    name
    code
    dataModel {
      dataModelID
    }
    projectExternals {
      externalID
      externalSystem {
        code
      }
    }
    projectModules {
      module {
        code
        name
        description
      }
    }
    projectFiles {
      projectFileID
      name
      fileName
      fileType {
        code
      }
      projectFileExternals {
        externalID
        externalSystem {
          code
        }
      }
      projectFileVersions(orderBy: {path: "version", descending: true}) {
        projectFileVersionID
        version
        projectFileVersionExternals {
          externalID
          externalSystem {
            code
          }
        }
        projectFileVersionViewables(orderBy: {path: "name"}) {
          projectFileVersionViewableID
          name
          viewableType
          projectFileVersionViewableExternals {
            externalID
            externalSystem {
              code
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: Apollo.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return Apollo.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return Apollo.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = Apollo.QueryResult<GetProjectQuery, GetProjectQueryVariables>;