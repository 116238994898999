import { defineMessages } from "react-intl";

const messages = defineMessages({
    general: {
        id: "components.newProject.newProjectForm.general",
        defaultMessage: "General", // Allgemein
    },
    units: {
        id: "components.newProject.newProjectForm.units",
        defaultMessage: "Units", // Einheiten
    },
    unitsDescription: {
        id: "components.newProject.newProjectForm.unitsDescription",
        defaultMessage: "Units are currently not implemented",
    },
    bim360: {
        id: "components.newProject.newProjectForm.bim360",
        defaultMessage: "BIM 360 Docs", // BIM 360 Docs
    },
    revit: {
        id: "components.newProject.newProjectForm.revit",
        defaultMessage: "Revit",
    },
    newBim360: {
        id: "components.newProject.newProjectForm.newBim360",
        defaultMessage: "Create new project", // Neues Projekt anlegen
    },
    save: {
        id: "components.newProject.newProjectForm.save",
        defaultMessage: "Save",
    },
    submitting: {
        id: "components.newProject.newProjectForm.submitting",
        defaultMessage: "Submitting",
    },
    reset: {
        id: "components.newProject.newProjectForm.reset",
        defaultMessage: "Reset",
    },

    // fields
    fieldName: {
        id: "components.newProject.newProjectForm.fieldName",
        defaultMessage: "Name",
    },
    fieldDescription: {
        id: "components.newProject.newProjectForm.fieldDescription",
        defaultMessage: "Description",
    },
    fieldCode: {
        id: "components.newProject.newProjectForm.fieldCode",
        defaultMessage: "Code",
    },
    fieldLengthUnits: {
        id: "components.newProject.newProjectForm.fieldLengthUnits",
        defaultMessage: "Length units",
    },
    fieldAreaUnits: {
        id: "components.newProject.newProjectForm.fieldAreaUnits",
        defaultMessage: "Area units",
    },
    fieldVolumeUnits: {
        id: "components.newProject.newProjectForm.fieldVolumeUnits",
        defaultMessage: "Volume units",
    },
    fieldTemperature: {
        id: "components.newProject.newProjectForm.fieldTemperature",
        defaultMessage: "Temperature",
    },
    fieldCurrency: {
        id: "components.newProject.newProjectForm.fieldCurrency",
        defaultMessage: "Currency",
    },
    fieldMasterFileName: {
        id: "components.newProject.newProjectForm.fieldMasterFileName",
        defaultMessage: "Master file name",
    },
    fieldUpdateType: {
        id: "components.newProject.newProjectForm.fieldUpdateType",
        defaultMessage: "Type of data update",
    },
    fieldRevitEnhancers: {
        id: "components.newProject.newProjectForm.fieldRevitEnhancers",
        defaultMessage: "Revit enhancers",
    },

    // placeholders
    placeholderName: {
        id: "components.newProject.newProjectForm.placeholderName",
        defaultMessage: "Specify...", // Angeben...
    },
    placeholderDescription: {
        id: "components.newProject.newProjectForm.placeholderDescription",
        defaultMessage: "Specify...", // Angeben...
    },
    placeholderCode: {
        id: "components.newProject.newProjectForm.placeholderCode",
        defaultMessage: "Specify...", // Angeben...
    },
    placeholderLengthUnits: {
        id: "components.newProject.newProjectForm.placeholderLengthUnits",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholderAreaUnits: {
        id: "components.newProject.newProjectForm.placeholderAreaUnits",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholderVolumeUnits: {
        id: "components.newProject.newProjectForm.placeholderVolumeUnits",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholderTemperature: {
        id: "components.newProject.newProjectForm.placeholderTemperature",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholderCurrency: {
        id: "components.newProject.newProjectForm.placeholderCurrency",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholderMasterFileName: {
        id: "components.newProject.newProjectForm.placeholderMasterFileName",
        defaultMessage: "Specify master model file URN to enable model related modules.", // Angeben...
    },
    placeholderProjectURN: {
        id: "components.newProject.newProjectForm.placeholderProjectURN",
        defaultMessage: "Specify Forge project URN (with b. prefix).", // Angeben...
    },
    placeholderAccountURN: {
        id: "components.newProject.newProjectForm.placeholderAccountURN",
        defaultMessage: "Specify Forge account URN (with b. prefix).", // Angeben...
    },
    placeholderUpdateType: {
        id: "components.newProject.newProjectForm.placeholderUpdateType",
        defaultMessage: "Select...", // Auswählen...
    },
    placeholdeFieldRevitEnhancers: {
        id: "components.newProject.newProjectForm.placeholdeFieldRevitEnhancers",
        defaultMessage: "Select...", // Auswählen...
    },
    initMode: {
        id: "components.newProject.newProjectForm.initMode",
        defaultMessage: "Enter project details and save the form to initialize this project in Zapper.",
    },
    submitErrorConflict: {
        id: "components.newProject.submitErrorConflict",
        defaultMessage: "Error storing project, duplicate found",
    },
    submitErrorCommon: {
        id: "components.newProject.submitErrorCommon",
        defaultMessage: "Error storing project",
    }
});

export default messages;
