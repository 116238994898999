import React, { useCallback, useState } from "react";
import CrudModule, { ItemField } from "../../../Crudder/CrudModule";
import { createHub, deleteHub, getHub, getHubs, updateHub } from "../../api/client";
import { GridRowsProp } from "@mui/x-data-grid";
import { Chip } from "@mui/material";
import { Checkboxes } from "mui-rff";
import clsx from "clsx";
import OutlinedComponent from "../../../../components/OutlinedComponent/OutlinedComponent";
import CrudDetailForm from "../../../Crudder/components/CrudDetail/CrudDetailForm";
import HubProjects from "../HubProjects/HubProjects";
import { HubRequest, HubResponse } from "../../api/types";
import HubMembers from "../HubMembers/HubMembers";
import HubRoles from "../HubRoles/HubRoles";
import HubTools from "../HubTools/HubTools";
import HubCompanies from "../HubCompanies/HubCompanies";

const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50,
            ignore: true,
        },
        formFieldDef: {
            ignore: true
        }
    },
    {
        name: "name",
        title: "Name",
        columnDef: {
            flex: 2
        },
    },
    {
        name: "code",
        title: "Code",
        columnDef: {
            flex: 1
        },
    },
    {
        name: "description",
        title: "Description",
        columnDef: {
            flex: 3
        },
    },
    {
        name: "projects",
        title: "Projects",
        formFieldDef: {
            ignore: true,
        },
        columnDef: {
            flex: 1,
            renderCell: (params) => (
                <>
                    {params.row.projects ? params.row.projects.length : 0}
                </>
            )
        },
    },
    {
        name: "active",
        title: "Active",
        formFieldDef: {
            render: ((field, classes) => (
                <OutlinedComponent
                    key={`field-${field.name}`}
                    label={"Active"}
                    className={clsx(classes.textField, classes.checkboxes)}
                >
                    <Checkboxes
                        name={field.name}
                        color="primary"
                        data={
                            {
                                label: null,
                                value: "active"
                            }
                        }
                    />
                </OutlinedComponent>

            ))
        },
        columnDef: {
            flex: 1,
            renderCell: (params) => (
                params.row.active ?  <Chip size={"small"} label="YES" color="success" /> : <Chip size={"small"} label="NO" color="error" />
            ),
        },
    },
];

export enum TabSlugs {
    DETAIL = "detail",
    PROJECTS = "projects",
    ROLES = "roles",
    MEMBERS = "members",
    COMPANIES = "companies",
    TOOLS = "tools",
}


const HubModule: React.FC = () => {

    const [ data, setData ] = useState<HubResponse[]>([]);


    const fetchHubs = useCallback(() => {
        return new Promise<HubResponse[]>((resolve, reject) => {
            getHubs(true).then((data) => {
                setData(data);
                resolve(data)
            })
        });
    }, []);


    const handleSubmit = useCallback((formValues: FormData) => {
        return new Promise<number>((resolve, reject) => {
            if (formValues["hubID"]) {
                // update
                updateHub(formValues["hubID"], formValues as HubRequest).then((response) => {
                    resolve(response.hubID as number);
                })
            }
            else {
                // create
                createHub(formValues as HubRequest).then((response) => {
                    resolve(response.hubID as number);
                })
            }

        })
    }, []);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteHub(id).then((response) => {
                resolve();
            })
        });
    }, []);

    const getDetail = useCallback((id) => {
        return new Promise<any>((resolve, reject) => {
            getHub(id).then((data) => {
                resolve(data);
            })
        });
    }, [data]);


    return (
        <CrudModule<HubResponse, HubResponse>
            name={"Hubs"}
            list={{
                title: "Hubs list",
                getRowID: (hub) => hub.hubID
            }}
            detail={{
                title: "Hub detail",
                tabs: [
                    {
                        title: "Details",
                        component: CrudDetailForm,
                        slug: TabSlugs.DETAIL
                    },
                    {

                        title: "Projects",
                        component: HubProjects,
                        slug: TabSlugs.PROJECTS
                    },
                    {

                        title: "Roles",
                        component: HubRoles,
                        slug: TabSlugs.ROLES
                    },
                    {

                        title: "Companies",
                        component: HubCompanies,
                        slug: TabSlugs.COMPANIES
                    },
                    {

                        title: "Members",
                        component: HubMembers,
                        slug: TabSlugs.MEMBERS
                    },
                    {

                        title: "Tools",
                        component: HubTools,
                        slug: TabSlugs.TOOLS
                    }
                ]
            }}
            itemFields={fields}
            data={fetchHubs}
            getDetail={getDetail}
            onDelete={handleDelete}
            onSubmit={handleSubmit}
        />
    );
};

export default HubModule;