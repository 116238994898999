import {
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    PaperProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import Draggable from "react-draggable";
import useStyles from "./styles";
import { IDraggableData, IPosition } from "../type";
import NullableTooltip from "../../NullableTooltip/NullableTooltip";
import {
    getAttachedEquipment,
    getAttachedMaterial,
    getAttachedWork,
    getPosition,
} from "../../../packages/Api/data/elements/client";
import { CategoryReport } from "../../../graphql/generated/graphql";
import {
    IAttachedEquipment,
    IAttachedMaterial,
    IAttachedWork,
    IElementsPosition,
    PositionPriceSource,
    PositionType,
} from "../../../packages/Api/data/elements/types";
import { normalizeUnit } from "../../../utils/Formatter";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import { useActiveProject } from "../../../redux/project";
import { useIntl } from "react-intl";
import messages from "./messages";
import clsx from "clsx";
import DialogPriceModelData from "./DialogPriceModelData";
import DialogPriceSubcontractorData from "./DialogPriceSubcontractorData";
import DialogPriceManualData from "./DialogPriceManualData";

interface IDialogPriceProps {
    open: boolean;
    category?: CategoryReport;
    handleOpen?: (row: CategoryReport) => void;
    handleClose: (row: CategoryReport) => void;
}

function PaperComponent(props: PaperProps) {
    const [position, setPosition] = useState<IPosition>({
        x: 0,
        y: 0,
    });
    const handleDraggableEvent = (e: any, data: IDraggableData) => {
        setPosition({
            x: data.x,
            y: data.y,
        });
    };
    return (
        <Draggable
            handle="#price-dialog-title"
            cancel='[class*="MuiDialogContent-root"]'
            position={position}
            onStop={handleDraggableEvent}
            key="draggabdle-outer"
        >
            <Paper {...props} key="draggabdle-paper" />
        </Draggable>
    );
}

const DialogPrice: React.FC<IDialogPriceProps> = ({ open, handleClose, category }) => {
    const classes = useStyles();
    const activeProject: ProjectData | undefined = useActiveProject();
    const [ material, setMaterial] = useState<IAttachedMaterial[]>([]);
    const [ work, setWork] = useState<IAttachedWork[]>([]);
    const [ equipment, setEquipment] = useState<IAttachedEquipment[]>([]);

    const [listData, setListData] = useState<any>({
        code: "G_C 2.1.003.100",
        name: "Mauerwerk, Planziegel, d=36,5 cm",
        quantity: 56, // Menge
        unitPrice: 257.485, // Einheitpreis
        surcharge: 0, // Zuschlag [%]
        totalPrice: 0, // Gasamtpreis
        profit: 13.5,
    });

    const [position, setPosition] = useState<IElementsPosition>();

    const intl = useIntl();

    const transLabelMaterial = intl.formatMessage({ ...messages.labelMaterial });
    const transLabelWork = intl.formatMessage({ ...messages.labelWork });
    const transLabelEquipment = intl.formatMessage({ ...messages.labelEquipment });
    const transLabelItemNumber = intl.formatMessage({ ...messages.labelItemNumber });
    const transLabelProductId = intl.formatMessage({ ...messages.labelProductId });
    const transLabelName = intl.formatMessage({ ...messages.labelName });
    const transLabelMenge = intl.formatMessage({ ...messages.labelMenge });
    const transLabelListPrice = intl.formatMessage({ ...messages.labelListPrice });
    const transLabelEp = intl.formatMessage({ ...messages.labelEp });
    const transLabelSurcharge = intl.formatMessage({ ...messages.labelSurcharge });
    const transLabelEpPerMenge = intl.formatMessage({ ...messages.labelEpPerMenge });
    const transLabelPhysical = intl.formatMessage({ ...messages.labelPhysical });
    const transNoMaterial = intl.formatMessage({ ...messages.noMaterial });
    const transNoWork = intl.formatMessage({ ...messages.noWork });
    const transNoEquipment = intl.formatMessage({ ...messages.noEquipment });

    useEffect(() => {
        if (category && activeProject) {
            getPosition(category.elementCategory?.elementCategoryID as number)
                .then((detail) => {
                    setPosition(detail);
                });
            getAttachedMaterial(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setMaterial(list)
                });
            getAttachedWork(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setWork(list)
                });
            getAttachedEquipment(activeProject.projectID as number, category.elementCategory?.elementCategoryID as number)
                .then(list => {
                    setEquipment(list)
                });
        }
        else {
            setPosition(undefined);
        }
    }, [category, activeProject]);

    const round = (num) => {
        return Math.round(num * 100) / 100;
    };
    const getPrice = (data) => {
        return round(data.quantity * data.unitPrice);
    };
    const getTotal = (data) => {
        return round(data.quantity * data.unitPrice * (1 + data.profit / 100));
    };

    const handleChange = (name) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setListData({ ...listData, [name]: event.target.value });
    };

    const getDialogContent = () => {
        if (position) {
            if (position.type !== PositionType.MANUAL) {
                if (position.priceSource === PositionPriceSource.SUBCONTRACTOR) {
                    return <DialogPriceSubcontractorData category={category as CategoryReport} position={position} />
                }
                else {
                    return <DialogPriceModelData category={category as CategoryReport} />
                }
            }
            else {
                return <DialogPriceManualData category={category as CategoryReport} position={position} />
            }
        }
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="price-dialog-title"
            aria-describedby="price-dialog-description"
            PaperComponent={PaperComponent}
            maxWidth="md"
            fullWidth
            hideBackdrop
        >
            <DialogTitle id="price-dialog-title" className={classes.title}>
                Detailansicht
            </DialogTitle>
            <DialogContent>
                <IconButton aria-label="close" className={classes.closeButton} size="small" onClick={() => handleClose(category as CategoryReport)}>
                    <CloseIcon />
                </IconButton>
                {position && category ? (
                    <>
                        <TableContainer>
                            <Table className={clsx(classes.table, classes.positionTable)} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>eBKP-H</TableCell>
                                        <TableCell align="left">Name</TableCell>
                                        <TableCell align="left">Menge</TableCell>
                                        <TableCell align="left" className={classes.columnPrice}>
                                            Einheitspreis
                                        </TableCell>
                                        <TableCell align="left" className={classes.columnPrice}>
                                            Preis
                                        </TableCell>
                                        <TableCell align="left" className={classes.columnProfit}>
                                            Zuschlag
                                        </TableCell>
                                        <TableCell align="left" className={classes.columnPrice}>
                                            Gesamtpreis
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow key={1}>
                                        <TableCell component="th" scope="row">
                                            {position.code}
                                        </TableCell>
                                        <TableCell align="left">{position.name}</TableCell>
                                        <TableCell align="left">{category?.quantity}</TableCell>
                                        <TableCell key="price-cell" align="left" className={classes.columnPrice}>
                                            <NullableTooltip title={`${position.price?.value} €`} aria-label="add">
                                                <Input
                                                    className={classes.input}
                                                    id="unitPrice"
                                                    type="number"
                                                    value={round(position.price?.value)}
                                                    fullWidth
                                                    disableUnderline
                                                    endAdornment=" €"
                                                    readOnly
                                                    // onChange={handleChange("unitPrice")}
                                                />
                                            </NullableTooltip>
                                        </TableCell>
                                        <TableCell align="left" className={classes.columnPrice}>
                                            <NullableTooltip title={`${getPrice(position)} €`} aria-label="add">
                                                <Input
                                                    className={classes.input}
                                                    id="preis"
                                                    type="number"
                                                    value={getPrice(position)}
                                                    fullWidth
                                                    disableUnderline
                                                    endAdornment=" €"
                                                    readOnly
                                                />
                                            </NullableTooltip>
                                        </TableCell>
                                        <TableCell key="profit-cell" align="left" className={classes.columnProfit}>
                                            <NullableTooltip title={`${round(position.price?.surcharge)} %`} aria-label="add">
                                                <Input
                                                    className={classes.input}
                                                    id="profit-percent"
                                                    type="number"
                                                    value={position.price?.surcharge}
                                                    fullWidth
                                                    disableUnderline
                                                    endAdornment=" %"
                                                    readOnly
                                                    // onChange={handleChange("profit")}
                                                />
                                            </NullableTooltip>
                                        </TableCell>
                                        <TableCell align="left" className={classes.columnPrice}>
                                            <NullableTooltip title={`${round(position.price?.total)} €`} aria-label="add">
                                                <Input
                                                    className={classes.input}
                                                    id="total"
                                                    type="number"
                                                    value={round(position.price?.total)}
                                                    fullWidth
                                                    disableUnderline
                                                    endAdornment=" €"
                                                    readOnly
                                                />
                                            </NullableTooltip>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        {/* <Box className={classes.syncContainer}> */}
                        {/*     <NullableTooltip title={"Upload to CRM"} aria-label="add"> */}
                        {/*         <IconButton onClick={handleUploadToCrm} disabled={uploading || downloading}> */}
                        {/*             {uploading ? <CircularProgress size={25} /> : <UploadIcon />} */}
                        {/*         </IconButton> */}
                        {/*     </NullableTooltip> */}
                        {/*     <NullableTooltip title={"Download from CRM"} aria-label="add"> */}
                        {/*         <IconButton onClick={handleDownloadFromCrm} disabled={uploading || downloading}> */}
                        {/*             {downloading ? <CircularProgress size={25} /> : <DownloadIcon />} */}
                        {/*         </IconButton> */}
                        {/*     </NullableTooltip> */}
                        {/* </Box> */}

                        <Grid container spacing={0} className={classes.grid}>
                            <Grid item xs={12} sm={2}>
                                <Box className={classes.box}>
                                    <Typography variant="body2">Bezugseinheit</Typography>
                                    <Typography>
                                        1 {normalizeUnit(position.referenceUnit)}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={10} style={{ height: "100%" }}>
                                {getDialogContent()}
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <CircularProgress />
                )}

            </DialogContent>
        </Dialog>
    );
};

export default DialogPrice;
