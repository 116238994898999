import { AnyAction } from "redux";
import { viewerToken } from "../../packages/Api/data/viewer/client";
import { ActionTypes, onViewerToken } from "./action";

const viewerMiddleware = ({ dispatch, getState }) => (next) => (action: AnyAction) => {
    const resolve = next(action);

    if (action.type === ActionTypes.OnViewerTokenRequest) {
        const { onTokenFetched } = action.payload;
        viewerToken().then(token => {
            if (onTokenFetched) {
                onTokenFetched(token);
            }
            dispatch(onViewerToken({token}))
        });
    }

    return resolve;
}


export default [viewerMiddleware];
