import { isPlatform } from "@ionic/react";
import { AppBar, Box, Container, CssBaseline, Drawer, IconButton, useTheme } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import drawerLogo from "../../assets/images/zapper-logo-primary.svg";
import drawerLogoSm from "../../assets/images/zapper-logo-red-mark.svg";
import { useDrawer } from "../../redux/layout";
import Footer from "../Footer/Footer";
import Header from "../Header/Header/Header";
import HeaderProvider from "../Header/HeaderProvider/HeaderProvider";

import Icon from "../Icon/Icon";
import Menu from "../Menu/Menu";
import useStyles, { LayoutStylesProps } from "./styles";
import { KeycloakRealmRole } from "../../redux/keyclock/types";
import { useSecurityContext } from "../../redux/keyclock/securityContext";
import Forbidden from "../Forbidden/Forbidden";
import ForbiddenChecker from "../Forbidden/ForbiddenChecker";

interface ILayoutProps extends React.HTMLAttributes<HTMLElement> {
    logo?: string;
    drawer?: {
        disabled?: boolean;
    };
    stylesProps?: LayoutStylesProps;
}

const Layout: React.FC<ILayoutProps> = ({ children, logo, drawer, stylesProps }) => {
    const classes = useStyles(stylesProps);
    const theme = useTheme();
    const drawerData = useDrawer();

    const [drawerOpen, setDrawerOpen] = useState(drawerData);

    useEffect(() => {
        setDrawerOpen(drawerData);
    }, [drawerData]);

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    return (
        <ForbiddenChecker>
            <HeaderProvider>
                <Container className={classes.root} maxWidth={false}>
                    <CssBaseline />

                    <AppBar
                        elevation={0}
                        position="fixed"
                        className={clsx(classes.appBar, {
                            [classes.appBarShift]: !drawer?.disabled && drawerOpen,
                        })}
                    >
                        <Header
                            drawerDisabled={drawer?.disabled}
                            logo={logo}
                            drawerOpen={drawerOpen}
                            handleDrawerOpen={handleDrawerOpen}
                            stylesProps={{
                                headerHeight: stylesProps?.headerHeight,
                            }}
                        />
                    </AppBar>

                    {drawer?.disabled ? null : (
                        <Drawer
                            variant="permanent"
                            className={clsx(classes.drawer, {
                                [classes.drawerOpen]: drawerOpen,
                                [classes.drawerClose]: !drawerOpen,
                            })}
                            classes={{
                                paper: clsx(classes.drawerPaper, {
                                    [classes.drawerOpen]: drawerOpen,
                                    [classes.drawerClose]: !drawerOpen,
                                }),
                            }}
                        >
                            <div className={classes.drawerHeader}>
                                <Box
                                    className={clsx(classes.logo, {
                                        [classes.logoShift]: drawerOpen,
                                    })}
                                    onClick={handleDrawerOpen}
                                >
                                    <img alt="Zapper logo" src={drawerOpen ? drawerLogo : drawerLogoSm} />
                                </Box>

                                <IconButton color="inherit" size="small" onClick={handleDrawerClose}>
                                    {theme.direction === "ltr" ? (
                                        <Icon name="chevron-left-circle" fill={theme.palette.primary["700"]} size={20} />
                                    ) : (
                                        <Icon name="chevron-right-circle" fill={theme.palette.primary["700"]} size={20} />
                                    )}
                                </IconButton>
                            </div>
                            <Menu
                                drawerOpen={drawerOpen}
                                handleDrawerOpen={handleDrawerOpen}
                                handleDrawerClose={handleDrawerClose}
                            />
                        </Drawer>
                    )}

                    <main
                        className={clsx(classes.main, {
                            [classes.mainShift]: drawerOpen,
                        })}
                    >
                        <div
                            className={clsx(classes.content, {
                                [classes.hybridContent]: isPlatform("hybrid"),
                            })}
                        >
                            {children}
                        </div>
                    </main>

                    <AppBar
                        component="footer"
                        elevation={0}
                        className={clsx(classes.footerBar, {
                            [classes.footerBarShift]: drawerOpen,
                        })}
                    >
                        <Footer
                            stylesProps={{
                                footerHeight: stylesProps?.footerHeight,
                                footerBarColor: stylesProps?.footerBarColor,
                                footerBarBackground: stylesProps?.footerBarBackground,
                            }}
                        />
                    </AppBar>
                </Container>
            </HeaderProvider>
        </ForbiddenChecker>
    );
};

export default Layout;
