// Generic reusable hook
import { useState } from "react";
import useConstant from "use-constant";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { useAsync } from "react-async-hook";

export interface IMembersLookup {
    invalidate: () => void,
    searchText: string,
    setSearchText: (text: string) => void,
    ownerID: number|undefined,
    setOwnerID: (ownerID: number) => void,
    memberLeadsPromise: any, //Promise<SearchMemberLeadResponse>,
}

export const useDebouncedMembersLookup = (searchFunction) => {

    // Handle the input text state
    const [searchText, setSearchText] = useState<string>("");
    const [ownerID, setOwnerID] = useState<number>();

    // Debounce the original search async function
    const debouncedSearchFunction = useConstant(() =>
        AwesomeDebouncePromise(searchFunction, 300)
    );

    const invalidate = () => {
        setSearchText("")
    }

    // The async callback is run each time the text changes,
    // but as the search function is debounced, it does not
    // fire a new request on each keystroke
    const memberLeadsPromise = useAsync(
        async () => {
            if (!searchText || !ownerID) {
                return [];
            } else {

                const result = await debouncedSearchFunction(ownerID, searchText);
                return result;
            }
        },
        [debouncedSearchFunction, searchText, ownerID]
    );

    // Return everything needed for the hook consumer
    return {
        invalidate,
        searchText,
        setSearchText,
        ownerID,
        setOwnerID,
        memberLeadsPromise,
    };
};