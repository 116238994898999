import { DispatchAction } from "@iolabs/redux-utils";
import { Box, Chip, IconButton, Paper, TextField, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
    DerivedPositionMappingType,
    IDerivedPosition,
    IElementsPosition,
} from "../../../packages/Api/data/elements/types";
import {
    onDeleteDerivedPositions,
    onUpdateDerivedPosition,
    useDetailPosition,
    useMappingBasicPositions,
    useMappingBasicPositionsLoading,
    useMappingDerivedPositions,
    useMappingDerivedPositionsLoading,
} from "../../../redux/mapping";
import { useActiveProject } from "../../../redux/project";
import Icon from "../../Icon/Icon";
import { ProjectData } from "../../ProjectSelectorWrapper/type";
import DerivedDialog from "../Dialogs/DerivedDialog/DerivedDialog";
import useStyles from "./styles";
import { useIntl } from "react-intl";
import messages from "./messages";
import StepElementDerivedPositionsPopover
    from "../StepElementDerivedPositionsPopover/StepElementDerivedPositionsPopover";
import clsx from "clsx";
import { IMappingElements } from "../../../packages/Api/data/mapping/types";
import { normalizeUnit } from "../../../utils/Formatter";
import ConfirmDialog from "../../Dialogs/ConfirmDialog/ConfirmDialog";
import SecuredComponent from "../../../redux/keyclock/SecuredComponent/SecuredComponent";

export enum DerivedPositionMode {
    From,
    To
}
interface IStepElementDerivedPositionsProps {
    mode: DerivedPositionMode
}

const StepElementDerivedPositions: React.FC<IStepElementDerivedPositionsProps> = ({
    mode
}) => {
    const classes = useStyles();
    const dispatch = useDispatch<DispatchAction>();
    const activeProject: ProjectData | undefined = useActiveProject();
    const detailPosition: IElementsPosition | undefined = useDetailPosition();

    const [saving, setSaving] = useState<boolean>(false);

    let mappingDerivedPositionsLoading: boolean = false;
    let mappingDerivedPositions: IDerivedPosition[] = [];

    switch (mode) {
        case DerivedPositionMode.From:
            mappingDerivedPositionsLoading = useMappingDerivedPositionsLoading();
            mappingDerivedPositions = useMappingDerivedPositions();
            break;
        case DerivedPositionMode.To:
            mappingDerivedPositionsLoading = useMappingBasicPositionsLoading();
            mappingDerivedPositions = useMappingBasicPositions();
            break;
    }

    const handleDelete = (derivedPositionID: number) => () => {
        if (activeProject && detailPosition) {
            dispatch(
                onDeleteDerivedPositions({
                    derivedPositionIDs: [derivedPositionID],
                })
            );
        }
    };

    // translations
    const intl = useIntl();
    const transFieldEbkph = intl.formatMessage({ ...messages.fieldEbkph });
    const transFieldName = intl.formatMessage({ ...messages.fieldName });
    const transFieldDescription = intl.formatMessage({ ...messages.fieldDescription });
    const transFieldReferenceUnit = intl.formatMessage({ ...messages.fieldReferenceUnit });
    const transDerivedPositionTitleDerived = intl.formatMessage({ ...messages.derivedPositionTitleDerived });
    const transDerivedPositionTitleBasic = intl.formatMessage({ ...messages.derivedPositionTitleBasic });
    const transConfirmDeleteMessage = intl.formatMessage({ ...messages.confirmDeleteMessage });
    const transConfirmClose = intl.formatMessage({ ...messages.confirmClose });
    const transConfirmDelete = intl.formatMessage({ ...messages.confirmDelete });

    useEffect(() => {
        setSaving(false);
    }, [mappingDerivedPositions])

    const skeleton = (
        <Box className={classes.skeletonBox}>
            <Box>
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
            </Box>
            <Box mt={3}>
                <Skeleton variant="rect" height={30} className={classes.skeleton} />
                <Skeleton variant="rect" height={20} width={20} className={classes.skeletonCircle} />
            </Box>
        </Box>
    );

    const updateDerivedPosition = (derivedPosition: IDerivedPosition) => {
        setSaving(true);
        dispatch(
            onUpdateDerivedPosition({
                derivedPositionID: derivedPosition?.derivedPositionID as number,
                derivedPosition: derivedPosition,
                projectID: activeProject?.projectID as number,
                positionID: detailPosition?.positionID as number
            })
        );
    }

    const handleChangeMappingType = (value: string, derivedPosition: IDerivedPosition) => {
        derivedPosition.derivedPositionMappingType = value as DerivedPositionMappingType;
        updateDerivedPosition(derivedPosition);
    }

    const handleChangeIfCondition = (derivedPosition: IDerivedPosition, value?: IMappingElements) => {
        derivedPosition.conditionalRule = value;
        updateDerivedPosition(derivedPosition);
    }


    const DeleteRowCta = ({derivedPositionID, positionCode, positionName}) => {

      const [open, setOpen] = React.useState<boolean>(false)
      const handleOpen = () => setOpen(true)
      const handleConfirmClose = () => setOpen(false)

      return (
        <>
          <IconButton
            aria-label="remove"
            color="secondary"
            className={classes.removeButton}
            onClick={handleOpen}
          >
            <Icon name="minus" size={20} />
          </IconButton>
          <ConfirmDialog
            open={open}
            handleClose={handleConfirmClose}
            handleConfirm={handleDelete(derivedPositionID)}
            messages={{
              dialogTitle: "Löschen", // "Delete confirm" // todo translate de
              confirmMessage: (
                <>
                  {transConfirmDeleteMessage}
                  <Box>
                    <strong>{positionCode}</strong> - {positionName}
                  </Box>
                </>
              ),
              closeButton: transConfirmClose, // Close
              confirmButton: transConfirmDelete, // Delete
            }}
          />
        </>
      )
    }

    return (
        <Paper className={classes.paper}>
            <Typography variant="body2">{mode == DerivedPositionMode.From ? transDerivedPositionTitleDerived : transDerivedPositionTitleBasic}</Typography>

            <SecuredComponent permission={{resource: "mapping", scope: "mapping:update", isProject: true}}>
                {saving || mappingDerivedPositionsLoading ? (
                    <>{skeleton}</>
                ) : (
                    <>
                        <>
                            {mappingDerivedPositions?.sort((a, b) => (a?.position?.code as string).localeCompare(b?.position?.code as string)).map((dp, index) => (
                                <Box className={classes.fieldBox} key={index}>
                                    {dp.conditionalRule && <Chip
                                        label={"if"}
                                        size={"small"}
                                        color={"primary"}
                                        className={classes.ifConditionBadge}
                                    />}
                                    <TextField
                                        label={transFieldEbkph}
                                        value={dp?.position?.code}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, classes.fieldCode)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transFieldName}
                                        value={dp?.position?.name}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, classes.fieldName)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transFieldDescription}
                                        value={dp?.position?.description}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, classes.fieldDescription)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        label={transFieldReferenceUnit}
                                        value={normalizeUnit(dp?.position?.referenceUnit)}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        color="primary"
                                        className={clsx(classes.textField, classes.fieldReferenceUnit)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Box display="flex" alignItems="center">
                                        <div className={classes.derivedPositionPopover}>
                                            <StepElementDerivedPositionsPopover
                                                derivedPosition={dp}
                                                handleSaveQuery={(value) => {
                                                    handleChangeIfCondition(dp, value)
                                                }}
                                            />
                                        </div>
                                      <DeleteRowCta
                                        derivedPositionID={dp?.derivedPositionID}
                                        positionCode={dp?.position?.code}
                                        positionName={dp?.position?.name}
                                      />
                                    </Box>
                                </Box>
                            ))}
                            <Box>
                                <DerivedDialog mode={mode} />
                            </Box>
                        </>
                    </>
                )}
            </SecuredComponent>
        </Paper>
    );
};

export default StepElementDerivedPositions;
