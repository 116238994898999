import React, { useCallback, useEffect, useState } from "react";
import { GridRowsProp } from "@mui/x-data-grid";
import { deleteHubRole, setRoleAccess } from "../../api/client";
import { useHubRolesContext } from "./HubRolesContext";
import { ItemField } from "../../../Crudder/CrudModule";
import CrudList from "../../../Crudder/components/CrudList/CrudList";
import ActionCheckboxSwitch from "../../../../components/ActionCheckboxSwitch/ActionCheckboxSwitch";
import { ProjectMemberLevel } from "../../../../graphql/generated/graphql";
import AccessLevelSwitch from "../../../../components/AccessLevelSwitch/AccessLevelSwitch";
import {
    IoLabsCoreDataEnumsProjectMemberLevel,
} from "../../../Api/data/projectMembers/io-labs-core-data-enums-project-member-level";
import { HubRole } from "../../api/types";

interface IHubMemberListProps {
}


const fields : ItemField[] = [
    {
        name: "id",
        title: "ID",
        description: "Identifier",
        columnDef: {
            width: 50,
            ignore: true,
        },
    },
    {
        name: "name",
        title: "Name",
        columnDef: {
            flex: 1
        },
    },
];


const HubRolesList: React.FC<IHubMemberListProps> = ({ }) => {

    const [ fieldsDef, setFieldsDef ] = useState<ItemField[]>(fields);

    const [ listData, setListData ] = useState<HubRole[]>();

    const { hubID, listResponse, invalidateList } = useHubRolesContext();

    const refreshData = () => {
        invalidateList();
    }

    const createSwitchAccessHandle = (roleID: number, moduleID: number) => (level: IoLabsCoreDataEnumsProjectMemberLevel|undefined) => {
        setRoleAccess(hubID as number, roleID, [
            {
                memberLevel: level,
                moduleID: moduleID
            }
        ]).then((res) => {
            refreshData()
        });
    }

    useEffect(() => {
        if (listResponse) {

            const moduleFields : ItemField[] = listResponse.modules ? listResponse.modules?.map((m) => {
                return {
                    name: m.code,
                    title: m.name,
                    columnDef: {
                        flex: 1,
                        renderCell: (params) => {
                            return (
                                <>
                                    <AccessLevelSwitch
                                        currentLevel={params.row.isProjectAdmin ? ProjectMemberLevel.ADMINISTRATOR : params.row.moduleAccess?.find(ma => ma.moduleID == m.moduleID)?.memberLevel}
                                        disabled={params.row.isProjectAdmin}
                                        onChange={createSwitchAccessHandle(params.id as number, m.moduleID as number)}
                                    />
                                </>
                            )
                        }
                    },
                } as ItemField
            }) : [];

            setFieldsDef([
                ...fields,
                {
                    name: "isProjectAdmin",
                    title: "Project admin",
                    columnDef: {
                        width: 110,
                        renderCell: (params) => {
                            return (
                                <>
                                    <ActionCheckboxSwitch
                                        checked={params.value}
                                        onChange={(checked) => {
                                            setRoleAccess(hubID as number, params.id as number, undefined, undefined, checked).then(() => {
                                                invalidateList();
                                            });
                                        }}
                                    />
                                </>
                            )
                        }
                    },
                },
                ...moduleFields
            ]);

            setListData(listResponse.roles ? listResponse.roles : []);
        }
        else {
            setFieldsDef([]);
            setListData([]);
        }
    }, [listResponse]);

    const handleDelete: (values: number) => Promise<void> = useCallback((id: number) => {
        return new Promise<void>((resolve, reject) => {
            deleteHubRole(hubID as number, id).then((response) => {
                refreshData();
                resolve();
            });
        })
    }, []);

    return listData ? <CrudList<HubRole>
        config={{
            title: "Roles",
            disableAdd: true,
            disableEdit: true,
            getRowID: (hubRole) => hubRole.hubRoleID
        }}
        itemFields={fieldsDef}
        data={listData}
        onDelete={handleDelete}
    /> : <></>
};

export default HubRolesList;