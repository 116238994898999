import { DispatchAction } from "@iolabs/redux-utils";
import { KeycloakProvider } from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import config from "../../config/config";
import { fetchUma, onEvent, onTokens, setUmaConfiguration } from "./action";
import ApiClient from "../../packages/ApiClient/ApiClient";
import { getUmaConfiguration, initKeycloakApi } from "./api/client";
import { Box, CircularProgress } from "@material-ui/core";
import { Permission, TokenResponse } from "./api/types";
import { SecurityContext } from "./securityContext";

const keycloakDefaultInitConfig: Keycloak.KeycloakInitOptions = {
    onLoad: "login-required",
    checkLoginIframe: false,
    // adapter: "cordova",
};

interface ISecurityProvider {
    keycloakInitConfig?: Keycloak.KeycloakInitOptions;
}

interface IPageParams {
    idpHint: string;
}

const getIdpHint = () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop as string),
    });
    // @ts-ignore
    return params.idpHint;
}

const SecurityProvider = (
    props: PropsWithChildren<ISecurityProvider>
) => {
    const { keycloakInitConfig, children } = props;
    const dispatch = useDispatch<DispatchAction>();

    const [tokenInitialized, setTokenInitialized] = useState<boolean>(false);
    const [token, setToken] = useState<TokenResponse>();
    const [permissions, setPermissions] = useState<Permission[]>();

    const [keycloakInstance, setKeycloakInstance] = useState<Keycloak.KeycloakInstance>();


    useEffect(() => {
        const keycloakInstance1 = Keycloak({
            clientId: config.security.clientId,
            realm: config.security.realm,
            url: config.security.realmUrl,
        });

        const idpHint = getIdpHint();
        if (idpHint) {
            let kcLogin = keycloakInstance1.login;

            // @ts-ignore
            keycloakInstance1.login = (options) => {
                // @ts-ignore
                options.idpHint = idpHint;
                kcLogin(options);
            };
        }

        ApiClient.setKeycloakInstance(keycloakInstance1);
        setKeycloakInstance(keycloakInstance1);
        initKeycloakApi(config.security.realmUrl, config.security.realm);

    }, []);


    const syncPermissions = () => {
        keycloakInstance?.updateToken(99999)
    }
    const syncUma = () => {
        dispatch(fetchUma({
            onFetchPermissions: (permissions) => {
                setPermissions(permissions);
            },
            onFetchToken: (token) => {
                setTokenInitialized(true);
                if (token) {
                    setToken(token)
                }
            }
        }));
    }

    // getUmaConfiguration().then(config => {
    //     dispatch(setUmaConfiguration({
    //         config: config
    //     }));
    // })

    return (
        keycloakInstance ?
            <SecurityContext.Provider value={{ tokenInitialized, token, permissions, syncPermissions }}>
                <KeycloakProvider
                    keycloak={keycloakInstance}
                    initConfig={keycloakInitConfig ? keycloakInitConfig : keycloakDefaultInitConfig}
                    onEvent={(event) =>
                        dispatch(
                            onEvent({
                                event: event,
                            })
                        )
                    }
                    onTokens={(tokens) => {
                        dispatch(onTokens(tokens));
                        syncUma()
                    }}
                >
                    {tokenInitialized ? children : <CircularProgress />}
                </KeycloakProvider>
            </SecurityContext.Provider> : <></>
    );
};

export default SecurityProvider;
